<template>
  <section>
    <div class="reassign-jobs-dropdown-component">
      <div class="reassign-jobs-sites">
        <b-dropdown
          :scrollable="true"
          v-if="isReassignJobsActive"
          label="Select the site which the new assigned user is in"
          v-model="selectedSite"
          aria-role="list"
          position="is-top-right"
        >
          <template #trigger="{ active }">
            <b-button
              :label="
                selectedSite ? selectedSite.siteName : 'Select the user site'
              "
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <b-dropdown-item
            v-for="(site, index) in sites"
            :key="index"
            :value="site"
            aria-role="listitem"
          >
            <div class="media">
              <div class="media-content">
                <h3>{{ site.siteName }}</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="reassign-jobs-users">
        <b-dropdown
          :scrollable="true"
          v-if="isReassignJobsActive && users && users.length > 0"
          v-model="selectedUser"
          aria-role="list"
          position="is-top-right"
        >
          <template #trigger="{ active }">
            <b-button
              :label="
                selectedUser
                  ? selectedUser.fullname + ' - ' + selectedUser.email
                  : 'Select a user to reassign the jobs'
              "
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <b-dropdown-item
            v-for="(user, index) in sortedUsers"
            :key="index"
            :value="user"
            aria-role="listitem"
          >
            <div class="media">
              <div class="media-content">
                <h3>{{ user.fullname + ' - ' + user._id }}</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isReassignJobsActive: Boolean,
    currentOwner: Object,
  },
  data() {
    return {
      selectedSite: null,
      selectedUser: null,
      users: null,
    };
  },
  computed: {
    sites() {
      const { sites } = this.$store.state;
      return sites;
    },

    sortedUsers() {
      if (this.users && this.users.length > 0) {
        const sorted = this.users.sort((a, b) =>
          a.fullname > b.fullname ? 1 : -1
        );
        /*  if (this.currentOwner) {
          sorted = sorted.filter((user) => user._id !== this.currentOwner._id);
        } */
        return sorted;
      }

      return [];
    },
  },
  watch: {
    selectedSite(newSite, oldSite) {
      if ((!oldSite && newSite) || newSite._id !== oldSite._id) {
        this.users = [];
        this.selectedUser = null;
        this.getUsersBySite(newSite._id);
        this.$emit('siteSelected', newSite);
      }
    },
    selectedUser(newUser, oldUser) {
      if ((!oldUser && newUser) || newUser._id !== oldUser._id) {
        this.$emit('userSelected', newUser);
      }
    },
  },

  async mounted() {
    const parsedSites = JSON.parse(JSON.stringify(this.$store.state.sites));
    if (parsedSites.length < 1) {
      await this.$store.dispatch('getSites');
    }

    if (!this.sites || this.sites.length < 1) {
      await this.$store.dispatch('getSites');
    }
  },

  methods: {
    async reassignUserJobs() {
      if (!this.isReassignJobsActive) {
        this.isReassignJobsActive = true;
      } else {
        this.isReassignJobsActive = false;
      }
    },

    async getUsersBySite(siteId) {
      this.users = await this.$store.dispatch('getUsers', {
        location: siteId,
      });
    },
  },
};
</script>

<style lang="scss">
.reassign-jobs-dropdown-component {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  .reassign-jobs-sites {
  }

  .reassign-jobs-users {
    margin-left: 8px;
  }

  .dropdown-content {
    margin-bottom: 20px;
  }
}
</style>
