<template>
  <header id="tool-bar">
    <div id="report-header">
      <div class="back-button" @click="onBackButtonClick">
        <img src="/img/icons/back_arrow.png" />
      </div>
      <div class="breadcrumb-outer">
        <span
          :hidden="this.$route.params.token || this.user._id === this.oilUser"
          id="gotoProductList"
          @click="gotoProductList"
          class="breadcrumb-text"
        >
          {{ $t('work_order_list') }}
        </span>

        <span class="divider-text">/</span>

        {{ jobWorkName }}

        <span class="divider-text">/</span>

        <b-dropdown
          aria-role="list"
          v-model="selectedTask"
          @change="onChangeItem"
          :close-on-click="true"
          :scrollable="true"
          :max-height="500"
        >
          <template #trigger="{ active }">
            <div class="container-select">
              <div class="dropdown-input-container">
                <input
                  type="text"
                  class="dropdown-input"
                  @mousedown="preventMouseDown"
                  :value="dropdownInputText"
                />

                <b-icon
                  :icon="active ? 'menu-up' : 'menu-down'"
                  size="is-small"
                />
              </div>
            </div>
          </template>

          <template v-for="task in dropDownTasks">
            <b-dropdown-item
              aria-role="listitem"
              :value="task._id"
              id="task-dropdown-item"
              v-if="task.taskType !== 'folder'"
            >
              <div
                :style="{
                  'padding-left': getIndentationLevel(task.folderLevel),
                }"
                :class="isTaskExclude(task._id) && 'excluded'"
              >
                <b-checkbox
                  v-if="enableExcludeItems"
                  :value="isTaskExclude(task._id)"
                  @click.native.capture="stopPropagation"
                  @input="(e) => onExcludeTask(e, task)"
                ></b-checkbox>
                <b-icon
                  :icon="
                    task.taskType === 'instruction'
                      ? 'format-list-bulleted'
                      : 'wrench'
                  "
                />
                {{ task.taskName }}
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              aria-role="listitem"
              :value="task._id"
              id="task-dropdown-item"
              v-if="task.taskType === 'folder'"
              :disabled="true"
            >
              <div
                :style="{
                  'padding-left': getIndentationLevel(task.folderLevel),
                }"
              >
                <b-checkbox
                  v-if="enableExcludeItems"
                  @input="(e) => onExcludeFolder(e, task._id)"
                  :value="isTaskExclude(task._id)"
                ></b-checkbox>
                <b-icon :icon="'folder'" />
                {{ task.taskName }}
              </div>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>

      <b-button
        size="is-small"
        class="header-button"
        :focused="isInternalReport"
        @click="onReportType(true)"
        >Internal Report</b-button
      >
      <b-button
        size="is-small"
        class="header-button"
        :focused="!isInternalReport"
        @click="onReportType(false)"
        >Customer Report</b-button
      >
      <b-button
        size="is-small"
        class="header-button"
        :focused="false"
        @click="onExcludeItems"
      >
        <template v-if="enableExcludeItems"> Done </template>
        <template v-else>Exclude Items</template>
      </b-button>
    </div>
    <div class="download-container">
      <div style="margin-right: 10px">
        <b-button
          type="is-info"
          outlined
          icon-left="file-document-arrow-right"
          @click="showDocumentsModal"
        >
          Documents</b-button
        >
      </div>
      <div>
        <b-dropdown
          aria-role="list"
          id="revision-dropdown"
          position="is-bottom-left"
          :scrollable="true"
          :max-height="500"
        >
          <template #trigger="{ active }">
            <b-button :icon-right="active ? 'menu-up' : 'menu-down'"
              >Revisions</b-button
            >
          </template>

          <b-dropdown-item
            aria-role="listitem"
            v-for="report in generatedReports"
            :key="report.id"
            @click="onDownloadGeneratedReport(report.id)"
          >
            <div class="columns">
              <div class="column">
                <p>Description: {{ report.revisionDescription }}</p>
                <div class="columns">
                  <div class="column">{{ report.fileName }}</div>
                  <div class="column">{{ parseDate(report.date) }}</div>
                </div>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div>
        <b-button
          size="is-large"
          type="is-info"
          @click="onDownload"
          :disabled="disabledDownload"
          :loading="disabledDownload"
          >{{ 'Download' }}</b-button
        >
      </div>
    </div>
    <documents-modal
      v-if="isDocumentModalVisible"
      :isDocumentModalVisible="isDocumentModalVisible"
      @close="closeDocumentModal"
    />
  </header>
</template>

<script>
import {
  REPORTS_TOGGLE_EXCLUDE_ITEMS,
  REPORTS_TOGGLE_INTERNAL_REPORT,
} from '../../store/reports/actions/actionTypes';
import ExcludeItemsMixin from '../../mixins/Report/ExcludeItemsMixin';
import client from '../../config/api';
import DocumentsModal from '../../components/Shared/DocumentsModal';

export default {
  name: 'report-header',
  components: {
    DocumentsModal,
  },
  props: {
    onDownload: {
      type: Function,
    },
    disabledDownload: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ExcludeItemsMixin],

  data() {
    return {
      oilUser: '',
      selectedTask: '',
      isDocumentModalVisible: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    },

    job() {
      return this.$store.state.ReportsModule.reportView.job;
    },

    tasks() {
      return this.$store.state.ReportsModule.reportView.tasks;
    },

    isInternalReport() {
      return this.$store.state.ReportsModule.isInternalReport;
    },

    jobWorkName() {
      if (!this.job.workOrderName) return '';

      if (
        typeof this.job.workOrderName === 'string' ||
        this.job.workOrderName instanceof String
      )
        return this.job.workOrderName;

      if (Array.isArray(this.job.workOrderName))
        return this.job.workOrderName[0].text;
    },

    hierarchy() {
      return this.buildTaskHierarchy([...this.tasks]);
    },

    dropDownTasks() {
      if (this.job.childConfig === undefined) return [];

      if (this.tasks.length > 0) return this.flattenHierarchy(this.hierarchy);
      return [];
    },

    dropdownInputText() {
      if (this.tasks.length === 0) return '';
      const task = this.tasks.find((t) => t._id === this.selectedTask);
      if (task) return task.taskName;
      if (this.tasks) return this.tasks[0].taskName;
    },

    generatedReports() {
      if (!this.job.reports) return [];
      return this.job.reports
        .filter((report) => {
          if (report.readyToDownload && report.date && report.preparedBy)
            return report;
        })
        .reverse();
    },
  },
  mounted() {
    this.oilUser = process.env.VUE_APP_OIL_USER_EMAIL;
  },

  methods: {
    async onDownloadGeneratedReport(reportId) {
      try {
        const response = await client.get(
          `/job/${this.job._id}/presign-report/${reportId}`
        );
        if (response.status === 200) {
          this.triggerDownload(response.data);
        }
      } catch (error) {
        console.error('Error checking server status:', error);
      }
    },

    triggerDownload(downloadLink) {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = downloadLink;
      anchor.setAttribute('download', '');

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    onBackButtonClick() {
      this.$router.push('/view/report');
    },
    gotoProductList() {
      this.$router.push('/view/report');
    },
    preventMouseDown(event) {
      event.preventDefault();
    },
    buildTaskHierarchy(tasks) {
      const tasksMap = new Map();
      const hierarchy = [];

      // Sort tasks based on taskType and folderId
      tasks.sort((a, b) => {
        if (a.folderId && !b.folderId) {
          return 1; // Folder comes after root task
        }
        if (!a.folderId && b.folderId) {
          return -1; // Root task comes before folder
        }
        return 0; // Maintain the original order
      });

      tasks.forEach((task) => {
        task.children = [];
        tasksMap.set(task._id, task);
      });

      // Traverse the tasks and assign children to their parent folders
      tasks.forEach((task) => {
        if (task.folderId) {
          const parentTask = tasksMap.get(task.folderId);
          if (parentTask) {
            parentTask.children.push(task);
          }
        } else {
          hierarchy.push(task); // Task is at the root level
        }
      });

      return hierarchy;
    },
    flattenHierarchy(hierarchy, folderLevel = 0) {
      const flattened = [];

      hierarchy.forEach((task) => {
        task.folderLevel = folderLevel;
        flattened.push(task);

        if (task.children && task.children.length) {
          const children = this.flattenHierarchy(
            task.children,
            folderLevel + 1
          );
          flattened.push(...children);
        }
      });

      return flattened;
    },
    getIndentationLevel(level) {
      if (!level) {
        return '0px';
      }
      return `${(level || 0) * 25}px`;
    },
    onExcludeItems() {
      this.$store.dispatch(REPORTS_TOGGLE_EXCLUDE_ITEMS);
    },
    onChangeItem(e) {
      const element = document.getElementById(e);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    onReportType(value) {
      this.$store.dispatch(REPORTS_TOGGLE_INTERNAL_REPORT, value);
    },
    onExcludeTask(e, task) {
      if (e === false) {
        if (task.folderId) {
          this.onTaskToExclude(false, task.folderId);
        }
      }
      this.onTaskToExclude(e, task._id);
    },
    onExcludeFolder(value, folderId) {
      this.dropDownTasks.forEach((task) => {
        if (task._id === folderId) {
          this.onTaskToExclude(value, task._id);
          if (task.children) {
            task.children.forEach((t) => {
              this.onTaskToExclude(value, t._id);
              if (t.children) {
                this.onExcludeFolder(value, t._id);
              }
            });
          }
          if (task.folderId && value === false) {
            this.onTaskToExclude(false, task.folderId);
          }
        }
      });
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString([]);
    },
    showDocumentsModal() {
      this.isDocumentModalVisible = true;
    },
    closeDocumentModal() {
      this.isDocumentModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
#tool-bar {
  display: flex;
  background-color: #fafafa;
  font-size: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  color: #333;
  border-bottom: 0.1px solid #ccc;
}
#report-header {
  font-family: 'Siemens Sans';
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;

  .back-button {
    display: inline-flex;
    width: 80px;
    height: 100%;
    cursor: pointer;
    img {
      width: 25px;
      margin: auto;
      &:hover {
        filter: brightness(0%);
      }
    }
  }

  #gotoProductList {
    color: #ccc;
    &:hover {
      color: inherit;
      cursor: pointer;
    }
  }

  .breadcrumb-outer {
    display: flex;
    align-items: center;
  }

  .breadcrumb-text {
    margin-right: 5px;
  }

  .container-select {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .divider-text {
    margin: 0 5px;
  }

  .dropdown-input {
    height: 27px;
    border: none;
    font-size: 16px;
    background: transparent;
    cursor: default;
  }
  .dropdown-input-container {
    border-bottom: 1px solid #ccc;
  }

  #task-dropdown-item {
    font-size: 16px;
    vertical-align: auto;
  }
}

.dropdown-item.is-active {
  background-color: rgba(33, 33, 33, 0.12);
}

.header-button {
  margin-left: 10px;
}

.download-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  #revision-dropdown {
    margin-right: 10px;
    height: 35px;
  }
}

.excluded {
  text-decoration: line-through;
}
</style>
