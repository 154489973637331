<template>
  <div class="site-selector">
    <b-field class="search-field">
      <b-input
        class="search-input"
        placeholder="Search..."
        type="search"
        icon="magnify"
        rounded
        v-model="searchField"
      ></b-input>
    </b-field>
    <div class="site-grid">
      <div
        class="site"
        :class="{
          'selected-site': selectedSites.includes(site._id),
          'primary-site': selectedSites[0] === site._id,
        }"
        v-for="site in sites"
        :key="site._id"
        @click="handleSelectSite(site)"
      >
        <p class="site-type">
          <template v-if="selectedSites[0] === site._id"> Primary </template>
          <template v-else-if="selectedSites.includes(site._id)">
            Secondary
          </template>
        </p>
        <p class="site-title">
          {{ site.siteName }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-sites',
  emits: ['selectedSites'],
  props: {
    initialSelectedSiteIds: {
      type: String,
    },
  },
  data() {
    return {
      selectedSites: [],
      searchField: '',
    };
  },
  computed: {
    sites() {
      const { sites } = this.$store.state;
      const filteredSites = sites.filter((site) =>
        site.search_sitename.includes(this.searchField)
      );
      filteredSites.sort((a, b) => (a.siteName > b.siteName ? 1 : -1));
      return filteredSites;
    },
  },
  async created() {
    if (!this.sites || this.sites.length === 0) {
      await this.$store.dispatch('getSites');
    }
    if (this.initialSelectedSiteIds) {
      this.initialSelectedSiteIds.forEach((initialSelectedSiteId) => {
        const initialSite = this.sites.find(
          (item) => item._id === initialSelectedSiteId
        );
        this.selectedSites.push(initialSite);
      });

      this.$forceUpdate();
    }
  },
  methods: {
    handleSelectSite(site) {
      if (this.selectedSites.includes(site._id)) {
        const index = this.selectedSites.findIndex((item) => item === site._id);
        if (index === 0) {
          this.selectedSites[index] = null;
        } else {
          this.selectedSites.splice(index, 1);
        }
      } else if (this.selectedSites[0] === null) {
        this.selectedSites[0] = site._id;
      } else {
        this.selectedSites.push(site._id);
      }

      this.$emit('selectedSites', this.selectedSites);

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  display: flex;
  justify-content: center;
}
.search-input {
  width: 500px;
}
.site-grid {
  overflow: auto;
  height: calc(100vh - 275px);
  margin: 20px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 30px;
}

.site {
  position: relative;
  box-shadow: 0px 0px 5px lightgrey;
  color: gray;
  width: 150px;
  height: 150px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  background-color: white;
}

.selected-site {
  border: 2px solid $icon-active;

  p {
    color: $icon-active;
  }
}

.primary-site {
  border: 2px solid rgb(30, 205, 30);

  p {
    color: rgb(30, 205, 30);
  }
}

.site-type {
  position: absolute;
  top: 5px;
  left: 5px;
  color: gray;
  font-size: 13px;
}

.site-title {
  text-align: center;
}
</style>
