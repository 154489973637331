<template>
  <div>
    <p class="error">{{ error }}</p>
    <p class="error" v-if="noFrontCamera">
      You don't seem to have a front camera on your device
    </p>

    <p class="error" v-if="noRearCamera">
      You don't seem to have a rear camera on your device
    </p>
    <qrcode-stream :track="paintBoundingBox" @decode="onDecode" @init="onInit">
      <button @click="switchCamera">Change Camera</button>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: { QrcodeStream },

  data() {
    return {
      error: '',
      camera: 'rear',
      noRearCamera: false,
      noFrontCamera: false,
    };
  },

  methods: {
    onDecode(result) {
      this.$emit('data', result);
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permission';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        } else if (error.name === 'InsecureContextError') {
          this.error =
            'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
        const triedFrontCamera = this.camera === 'front';
        const triedRearCamera = this.camera === 'rear';

        const cameraMissingError = error.name === 'OverconstrainedError';

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      detectedCodes.forEach((detectedCode) => {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = '#007bff';
        ctx.strokeRect(x, y, width, height);
      });
    },
    switchCamera() {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear';
          break;
        case 'rear':
          this.camera = 'front';
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>
