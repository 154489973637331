<template>
  <div class="swagger" id="swagger"></div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import '../../public/docs/dsso.json';

export default {
  name: 'Swagger',
  mounted() {
    // eslint-disable-next-line global-require
    const spec = require('../../public/docs/dsso.json');
    SwaggerUI({
      spec,
      dom_id: '#swagger',
    });
  },
};
</script>

<style>
pre.microlight {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 10px !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  word-break: break-word !important;
  -webkit-hyphens: auto !important;
  -ms-hyphens: auto !important;
  hyphens: auto !important;
  border-radius: 4px !important;
  background: #333 !important;
  overflow-wrap: break-word !important;
  font-family: monospace !important;
  font-weight: 600 !important;
  color: #fff !important;
}
/* "host": "api.dij.mosaic-dev.siemens-energy.cloud", */
</style>
