<template>
  <div>
    <h1 class="select-tasks-title">{{ $t('configure_table_order') }}</h1>
    <div class="configuration-menu">
      <b-dropdown
        v-if="selectedJobToConfigure"
        :scrollable="isScrollable"
        v-model="selectedJobToConfigure"
        aria-role="list"
      >
        <button class="button is-primary" type="button" slot="trigger">
          <template>
            <span :key="componentKey">{{ selectedJobToConfigure.title }}</span>
          </template>
          <b-icon icon="menu-down"></b-icon>
        </button>

        <b-dropdown-item
          v-for="(job, index) in jobsToConfigure"
          :key="index"
          :value="job"
          aria-role="listitem"
        >
          <div class="media">
            <!-- <b-icon class="media-left" :icon="menu.icon"></b-icon> -->
            <div class="media-content">
              <h3>{{ job.title }}</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <md-field
        v-if="
          (partNumberOption && !selectPartLevelProcess) || otherOptionPartLevel
        "
        class="other-field"
      >
        <label>{{
          otherOptionPartLevel ? $t('part_level') : $t('part_number')
        }}</label>
        <md-input required v-model="partLevelOther"></md-input>
      </md-field>
      <md-field>
        <v-select
          :placeholder="$t('part_level')"
          v-model="partLevelSelected"
          class="select-part-level"
          id="partNumber"
          required
          value=""
          :options="partLevelsCleaned"
        >
          <template #no-options="{ search, searching, loading }">
            No options found. If you want to create one, type "other" and select
            it.
          </template></v-select
        >
      </md-field>

      <b-button
        :disabled="
          this.edit || this.configuredJobs.length < this.jobsWithTable.length
        "
        style="margin-left: 8px"
        type="is-success"
        @click="handleDoneConfiguration"
      >
        All configurations in this job done
        <b-icon style="margin-left: 2px" icon="send-check"></b-icon>
      </b-button>
      <b-button
        :disabled="!enableDoneButton || this.edit"
        style="margin-left: 8px"
        type="is-info"
        @click="handleDoneConfiguration($event, (addOther = true))"
      >
        Finish this configuration
        <b-icon style="margin-left: 2px" icon="check"></b-icon>
      </b-button>
    </div>

    <div v-if="configuredJobs.length > 0" class="configured-jobs-menu">
      <h2>Configured jobs</h2>

      <b-dropdown
        :scrollable="isScrollable"
        :disabled="this.edit"
        v-model="selectedJobToReconfigure"
        aria-role="list"
      >
        <button class="button is-primary" type="button" slot="trigger">
          <template>
            <span>
              {{ reconfigureJobTitleSelected(selectedJobToReconfigure) }}
            </span>
          </template>
          <b-icon icon="menu-down"></b-icon>
        </button>

        <b-dropdown-item
          v-for="(object, index) in configuredJobs"
          :key="index"
          :value="object"
          aria-role="listitem"
        >
          <div class="media">
            <div class="media-content">
              <h3>
                {{ reconfigureJobTitle(object) }}
              </h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        @click="handleRemoveConfiguration"
        class="remove-configuration"
        type="is-danger"
        >{{ this.deleteButtonActive ? 'Confirm' : 'Delete' }}
      </b-button>
      <b-button
        v-if="this.deleteButtonActive"
        @click="() => (this.deleteButtonActive = false)"
        class="remove-configuration"
        type="is-warning"
        >Cancel
      </b-button>
      <b-button
        :disabled="this.edit && !enableDoneButton"
        @click="handleEditConfiguration"
        class="remove-configuration"
        :type="this.edit ? 'is-success' : 'is-info'"
        >{{ this.edit ? $t('done') : $t('edit') }}</b-button
      >
    </div>
    <div class="stages-inputs">
      <b-field
        label="Draw No (B-Plan)"
        v-if="
          partLevelSelected &&
          (partLevelSelected.includes('GBC') || partLevelSelected === 'Rotor')
        "
      >
        <b-input
          type="text"
          placeholder="Draw No (B-Plan)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoBplan"
        ></b-input>
      </b-field>
      <b-field label="Draw No" v-if="partLevelSelected !== 'Rotor'">
        <b-input
          type="text"
          placeholder="Draw No"
          :disabled="!isGBCTable"
          v-model="partObject.drawNo1"
        ></b-input>
      </b-field>
      <b-field label="Draw No" v-if="partLevelSelected !== 'Rotor'">
        <b-input
          type="text"
          placeholder="Draw No"
          :disabled="!isGBCTable"
          v-model="partObject.drawNo2"
        ></b-input>
      </b-field>

      <b-field
        label="Draw No"
        v-if="
          !partLevelSelected ||
          (partLevelSelected &&
            !partLevelSelected.includes('GBC') &&
            partLevelSelected !== 'Rotor')
        "
      >
        <b-input
          type="text"
          placeholder="Draw No"
          :disabled="!isGBCTable"
          v-model="partObject.drawNo4"
        ></b-input>
      </b-field>

      <b-field
        label="Draw No (Turbinenlaufer)"
        v-if="partLevelSelected === 'Rotor'"
      >
        <b-input
          type="text"
          placeholder="Draw No (Turbinenlaufer)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoTurbinenlaufer"
        ></b-input>
      </b-field>
    </div>

    <div class="stages-inputs">
      <b-field label="Parts List">
        <b-input
          type="text"
          placeholder="Parts List"
          :disabled="!isGBCTable"
          v-model="partObject.partList"
        ></b-input>
      </b-field>
      <b-field label="Hallmark">
        <b-input
          type="text"
          placeholder="Hallmark"
          :disabled="!isGBCTable"
          v-model="partObject.hallmark"
        ></b-input>
      </b-field>
      <b-field label="Draw No" v-if="partLevelSelected !== 'Rotor'">
        <b-input
          type="text"
          placeholder="Draw No"
          :disabled="!isGBCTable"
          v-model="partObject.drawNo3"
        ></b-input>
      </b-field>

      <b-field label="Rotor Id" v-if="partLevelSelected === 'Rotor'">
        <b-input
          type="text"
          placeholder="Rotor Id"
          :disabled="!isGBCTable"
          v-model="partObject.rotorId"
        ></b-input>
      </b-field>
    </div>

    <div class="stages-inputs">
      <b-field label="Draw No (Nutenplan)" v-if="partLevelSelected === 'Rotor'">
        <b-input
          type="text"
          placeholder="Draw No (Nutenplan)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoNutenplan"
        ></b-input>
      </b-field>

      <b-field
        label="Draw No (Wellenende vorne)"
        v-if="partLevelSelected === 'Rotor'"
        style="width: 210px"
      >
        <b-input
          type="text"
          placeholder="Draw No (Wellenende vorne)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoWelleVorne"
        ></b-input>
      </b-field>

      <b-field
        label="Draw No (Wellenende hinten)"
        v-if="partLevelSelected === 'Rotor'"
        style="width: 220px"
      >
        <b-input
          type="text"
          placeholder="Draw No (Wellenende hinten)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoWelleHinten"
        ></b-input>
      </b-field>
    </div>

    <div class="stages-inputs">
      <b-field
        label="Draw No (Einstromteil)"
        v-if="partLevelSelected === 'Rotor'"
      >
        <b-input
          type="text"
          placeholder="Draw No (Einstromteil)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoEinstromteil"
        ></b-input>
      </b-field>

      <b-field
        label="Draw No (Abstromteil)"
        v-if="partLevelSelected === 'Rotor'"
      >
        <b-input
          type="text"
          placeholder="Draw No (Abstromteil)"
          :disabled="!isGBCTable"
          v-model="partObject.drawNoAbstromteil"
        ></b-input>
      </b-field>
    </div>

    <div class="stages-inputs">
      <b-field label="Start of stages">
        <b-input
          type="number"
          placeholder="Start of stages"
          :disabled="!isGBCTable"
          v-model="stagesStart"
          @blur="checkStagesStart"
        ></b-input>
      </b-field>
      <b-field label="End of stages">
        <b-input
          type="number"
          :min="parseInt(stagesStart, 10)"
          placeholder="End of stages"
          :disabled="!isGBCTable"
          v-model="stagesEnd"
          @blur="checkStagesEnd"
        ></b-input>
      </b-field>
    </div>

    <div class="measurement-points-input">
      <b-field
        :v-if="stagesStart !== 0 && stagesEnd !== 0"
        v-for="i in measurementPoints"
        :key="i"
        :label="
          $t('number_of_measurement_points') + ' - ' + $t('stage') + ': ' + i
        "
      >
        <b-input
          type="number"
          v-model="measurementPointsValues[i - stagesStart]"
          :key="'measurementPoint' + i"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'publisher-table-configuration',
  props: {
    selectedJobs: Array,
    activeStep: Number,
  },
  data() {
    return {
      partObject: {},
      showHeading: true,
      isGBCTable: true,
      defaultStageOptions: [],
      measurementPoints: [],
      measurementPointsValues: [],
      numberOfStages: 0,
      stagesStart: null,
      stagesEnd: null,
      isScrollable: true,
      jobsToConfigure: [],
      jobsWithTable: [],
      selectedJobToConfigure: '',
      selectedJobToReconfigure: '',

      partLevelsCleaned: [],
      partLevelSelected: '',
      selectPartLevelProcess: false,
      otherOptionPartLevel: false,
      partNumberOption: null,
      partLevelOther: '',
      filledMeasurementPoints: false,

      configuredJobs: [],
      edit: false,

      componentKey: 1,
      deleteButtonActive: false,
      configId: 0,
    };
  },
  async mounted() {
    await this.$store.dispatch('getPartLevels');
    this.partLevels.map((object) => {
      this.partLevelsCleaned.push(object.name);
    });
    this.partLevelsCleaned.sort();
  },
  computed: {
    jobs() {
      return this.$store.state.jobs;
    },
    partLevels() {
      return this.$store.state.partLevels;
    },
    selectPartLevelData() {
      if (
        this.partNumberOption &&
        this.selectPartLevelProcess &&
        !this.otherOptionPartLevel
      )
        return true;
      return false;
    },

    enableDoneButton() {
      if (
        (this.selectedJobToConfigure || this.selectedJobToReconfigure) &&
        this.stagesStart &&
        this.stagesEnd &&
        this.filledMeasurementPoints &&
        ((this.partLevelSelected && this.partLevelSelected !== 'Other') ||
          (this.otherOptionPartLevel && this.partLevelOther))
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    reconfigureJobTitle(object) {
      let textToReturn = `${object.job.title} `;
      if (object.partLevel.name) {
        textToReturn = textToReturn.concat(
          object.partLevel.name,
          ' - ',
          `[${object.stages[0]}, ${object.stages[1]}]`
        );
      } else {
        textToReturn = textToReturn.concat(
          object.partLevel,
          ' - ',
          `[${object.stages[0]}, ${object.stages[1]}]`
        );
      }
      return textToReturn;
    },

    reconfigureJobTitleSelected(object) {
      let textToReturn = `${object.job.title} `;
      if (object.partLevel.name) {
        textToReturn = textToReturn.concat(
          object.partLevel.name,
          ' - ',
          `[${object.stages[0]}, ${object.stages[1]}]`
        );
      } else {
        textToReturn = textToReturn.concat(object.partLevel);
      }
      return textToReturn;
    },

    handleDoneConfiguration(event, addOther = null) {
      let partLevelToBeSent;

      if (!addOther) {
        const jobToRemoveIndex = this.jobsToConfigure.findIndex(
          (job) => job._id === this.selectedJobToConfigure._id
        );
        this.jobsToConfigure.splice(jobToRemoveIndex, 1);

        if (this.jobsToConfigure.length < 1) {
          this.$emit('selectedConfiguration', this.configuredJobs);
        }
      } else {
        if (!this.otherOptionPartLevel) {
          partLevelToBeSent = this.partLevels.find(
            // eslint-disable-next-line eqeqeq
            (partLevelObject) => partLevelObject.name == this.partLevelSelected
          );
        } else {
          partLevelToBeSent = this.partLevelOther;
        }

        const measurementPointsValuesFormatted = JSON.parse(
          JSON.stringify(this.measurementPointsValues)
        );

        this.partObject.isPart = true;
        const configurationData = {
          partLevel: partLevelToBeSent,
          partLevelFields: this.partObject,
          job: this.selectedJobToConfigure,
          stages: [this.stagesStart, this.stagesEnd],
          measurementPoints: measurementPointsValuesFormatted,
          id: this.configId,
        };

        this.configuredJobs.push(configurationData);

        this.configId++;
        this.clearFields();
      }
    },

    clearFields() {
      [this.stagesStart, this.stagesEnd] = [];
      this.measurementPoints.splice(0, this.measurementPoints);
      this.measurementPointsValues.splice(0, this.measurementPointsValues);
      this.partObject = {};
    },

    handleEditConfiguration() {
      if (this.edit) {
        let partLevelToBeSent;

        if (!this.otherOptionPartLevel) {
          partLevelToBeSent = this.partLevels.find(
            (partLevelObject) =>
              // eslint-disable-next-line eqeqeq
              partLevelObject.name == this.partLevelSelected
          );
        } else {
          partLevelToBeSent = this.partLevelOther;
        }

        const measurementPointsValuesFormatted = JSON.parse(
          JSON.stringify(this.measurementPointsValues)
        );

        const configurationData = {
          partLevel: partLevelToBeSent,
          partLevelFields: this.partObject,
          job: this.selectedJobToReconfigure.job,
          stages: [this.stagesStart, this.stagesEnd],
          measurementPoints: measurementPointsValuesFormatted,
          id: this.selectedJobToReconfigure.id,
        };

        const indexToEdit = this.configuredJobs.findIndex(
          (obj) =>
            // eslint-disable-next-line eqeqeq
            obj.id == this.selectedJobToReconfigure.id
        );

        this.configuredJobs[indexToEdit] = configurationData;
        this.selectedJobToReconfigure = this.configuredJobs[indexToEdit];

        this.clearFields();
        this.edit = false;
      } else {
        this.edit = true;
        let partLevelFound;

        [this.stagesStart, this.stagesEnd] =
          this.selectedJobToReconfigure.stages;

        this.fillArrayWithStages();

        if (this.selectedJobToReconfigure.partLevelFields) {
          this.partObject = this.selectedJobToReconfigure.partLevelFields;
        }

        this.measurementPointsValues = JSON.parse(
          JSON.stringify(this.selectedJobToReconfigure.measurementPoints)
        );

        if (this.selectedJobToReconfigure.partLevel.name) {
          this.otherOptionPartLevel = false;
        }

        if (!this.otherOptionPartLevel) {
          partLevelFound = this.partLevels.find(
            (partLevelObject) =>
              // eslint-disable-next-line eqeqeq
              partLevelObject.name ==
              this.selectedJobToReconfigure.partLevel.name
          );

          if (partLevelFound) {
            this.otherOptionPartLevel = false;
            this.partLevelSelected = partLevelFound?.name;
          }
        }

        if (!partLevelFound && this.selectedJobToReconfigure.partLevel) {
          this.otherOptionPartLevel = true;
          this.partLevelSelected = 'Other';
          partLevelFound = this.partLevelOther;

          this.partLevelSelected = partLevelFound;
        }
      }
    },

    handleRemoveConfiguration() {
      if (this.deleteButtonActive) {
        const indexToRemove = this.configuredJobs.findIndex(
          (configuredJob) =>
            configuredJob.job._id === this.selectedJobToReconfigure.job_id
        );
        this.configuredJobs.splice(indexToRemove, 1);
        this.deleteButtonActive = false;
        if (this.configuredJobs.length < 1) {
          this.jobsToConfigure = this.jobsWithTable;
        }
      } else {
        this.deleteButtonActive = true;
      }
    },

    fillArrayWithStages() {
      this.measurementPoints = new Array(this.stagesEnd - this.stagesStart + 1);

      for (let index = 0; index < this.measurementPoints.length; index++) {
        this.measurementPoints[index] = this.stagesStart + index;
      }

      if (!this.edit) {
        this.measurementPointsValues = new Array(
          this.stagesEnd - this.stagesStart + 1
        );
      }
    },

    checkStagesEnd(e) {
      if (e?.target?.value) {
        this.stagesEnd = parseInt(e.target.value, 10);
      }
      if (
        this.stagesStart &&
        this.stagesEnd !== 0 &&
        this.stagesEnd >= this.stagesStart
      ) {
        this.fillArrayWithStages();
      }
    },
    checkStagesStart(e) {
      if (e?.target?.value) {
        this.stagesStart = parseInt(e.target.value, 10);
        if (this.stagesEnd) {
          this.checkStagesEnd();
        }
      }
    },
  },
  watch: {
    async partNumberOption(selectedValue) {
      if (selectedValue === 'partLevel') {
        this.selectPartLevelProcess = true;
      } else {
        this.selectPartLevelProcess = false;
        this.otherOptionPartLevel = false;
      }
    },

    async otherOptionPartLevel(newValue) {
      this.otherOptionPartLevel = newValue;
    },

    async partLevelSelected(selectedValue) {
      if (
        selectedValue &&
        (selectedValue === 'Other' ||
          this.partLevelsCleaned.every(
            (partLevelName) => partLevelName !== selectedValue
          ))
      ) {
        this.otherOptionPartLevel = true;
        this.partLevelSelected = 'Other';
      } else {
        this.otherOptionPartLevel = false;
      }
    },

    measurementPointsValues(newValues) {
      let allFieldsFilled = true;
      for (let index = 0; index < newValues.length; index++) {
        this.measurementPointsValues[index] = parseInt(newValues[index], 10);
        const measurementPoint = newValues[index];
        if (!measurementPoint) {
          allFieldsFilled = false;
          break;
        }
      }

      this.filledMeasurementPoints = allFieldsFilled;
    },
    measurementPoints() {},

    jobsWithTable: {
      handler(newValues) {
        if (newValues.length > 0) {
          this.jobsToConfigure = [...newValues];
        }
      },
    },
    jobsToConfigure: {
      handler(newValues) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedJobToConfigure = newValues[0];
        this.componentKey += 1;
      },
    },
    configuredJobs: {
      handler(newValues) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedJobToReconfigure = newValues[0];
      },
    },
    selectedJobToConfigure: {
      handler() {},
    },
    selectedJobs: {
      handler(newValues) {
        this.jobsWithTable = newValues;
      },
    },

    activeStep: {
      handler(newValue, oldValue) {
        if (newValue < oldValue) {
          this.jobsToConfigure = this.jobsWithTable;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.stages-inputs {
  display: flex;
  max-width: 80%;
  .field {
    width: 200px;

    & + .field {
      margin-left: 8px;
    }
  }
}
.measurement-points-input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80%;
  .field {
    width: 200px;

    & + .field {
      margin-left: 8px;
    }
  }
}
.other-field {
  margin-top: -12px;
  width: 60%;
  margin-left: 8px;

  label {
    padding-left: 8px;
  }

  input {
    border: 0.5px solid black;
  }
}
.configuration-menu {
  display: flex;

  .select-part-level {
    width: 40%;
    margin-left: 8px;
    margin-top: 0;
    padding: 0;
    margin-top: -18px;
  }

  .md-field:after,
  .md-field:before {
    display: none;
  }
}

.configured-jobs-menu {
  margin-top: 12px;
  margin-bottom: 8px;

  .remove-configuration {
    margin-left: 4px;
  }
}
</style>
