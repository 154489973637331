export default {
  SET_BUBBLES: (state, bubbles) => {
    state.bubbles = bubbles;
  },
  REMOVE_BUBBLE: (state, bubbleId) => {
    state.bubbles = state.bubbles.filter((bubble) => bubble._id !== bubbleId);
  },
  LOCAL_UPDATE_BUBBLE: (state, updatedBubble) => {
    const index = state.bubbles.findIndex(
      (bubble) => bubble._id === updatedBubble._id
    );
    if (index !== -1) {
      // Update the bubble at the found index
      state.bubbles[index] = updatedBubble;
    } else {
      // If the bubble is not found, push it to the array
      state.bubbles.push(updatedBubble);
    }
  },
};
