<template>
  <div class="dropContainer">
    <md-field class="selectField">
      <v-select
        :placeholder="placeHolder"
        v-model="selectedValue"
        class="selectProdLine"
        :options="dropValues"
        @input="emitSelected"
      ></v-select>
    </md-field>
    <b-button
      class="actionBtn"
      @click="addEdit"
      :disabled="disabled"
      v-if="privil || type !== 'prodLine'"
      >{{ selectedValue ? 'Edit' : 'Add' }}</b-button
    >

    <div
      class="editZone"
      v-if="
        (privil || type !== 'prodLine') && showAction && selectedType === type
      "
    >
      <b-field>
        <p class="control">
          <b-tooltip label="Cancel" type="is-dark">
            <b-button class="button is-dark" @click="showAction = false">
              <b-icon icon="close"></b-icon>
            </b-button>
          </b-tooltip>
        </p>
        <b-input placeholder="Name" type="text" v-model="editValue"></b-input>
        <p class="control" v-if="editValue">
          <b-tooltip label="Save" type="is-dark">
            <b-button class="button is-success" @click="sendAction(true)">
              <b-icon icon="check"></b-icon>
            </b-button>
          </b-tooltip>
        </p>
        <p class="control" v-if="privil && selectedValue">
          <b-tooltip label="Delete" type="is-dark">
            <b-button class="button is-danger" @click="confirmDelete">
              <b-icon icon="delete"></b-icon>
            </b-button>
          </b-tooltip>
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fleetDataDropdowns',
  props: ['dropValues', 'placeHolder', 'disabled', 'type'],
  data() {
    return {
      selectedValue: '',
      showAction: false,
      editValue: '',
      privil: false,
    };
  },
  computed: {
    selectedType() {
      return this.$parent.selectedType;
    },
    user() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    if (this.user) {
      this.privil = Boolean(parseInt(this.user.privil, 10));
    }
  },
  methods: {
    emitSelected(value) {
      if (this.showAction) this.addEdit();
      return this.$emit('dropSelected', value);
    },
    addEdit() {
      this.showAction = true;
      this.editValue = '';
      this.$parent.selectedType = this.type;
      if (this.selectedValue) {
        this.editValue = this.selectedValue.label;
      }
    },
    sendAction(save) {
      let action = 'delete';
      if (save) {
        action = 'create';
        if (this.selectedValue) {
          action = 'edit';
        }
      }
      return this.$emit('action', action, this.selectedValue, this.editValue);
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete?',
        type: 'is-dark',
        hasIcon: true,
        icon: 'delete',
        confirmText: 'Delete',
        onConfirm: async () => {
          return this.sendAction(false);
        },
      });
    },
  },
  watch: {
    dropValues: {
      handler(value) {
        if (value.length === 0) {
          this.selectedValue = '';
        } else if (!value.includes(this.selectedValue)) {
          this.selectedValue = '';
        }
        this.showAction = false;
      },
      deep: true,
    },
    selectedValue: {
      handler(val) {
        if (!val) {
          return this.emitSelected(val);
        }
      },
    },
    user: {
      handler(currentUser) {
        if (currentUser) {
          this.privil = Boolean(parseInt(currentUser.privil, 10));
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.selectField,
.editZone {
  max-width: 400px;
  display: inline-flex;
}
.editZone {
  margin-left: 10px;
}
.dropContainer {
  width: 100%;
}
.actionBtn {
  height: 40px;
  margin: 10px;
}
</style>
