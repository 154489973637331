<template>
  <template-list
    :type="'inspector'"
    :columnsData="columnsData"
    v-if="showList"
  />
</template>
<script>
import TemplateList from '../layout/TemplateList';
import store from '../../store/index';

export default {
  name: 'inspector-list',
  components: {
    TemplateList,
  },
  data() {
    return {
      // columnsData: [
      //   'title',
      //   'original_oem',
      //   'frame_type',
      //   'model',
      //   'part_number',
      //   'customer',
      //   'project_number',
      //   'serial_number',
      //   'work_order_number',
      //   'sales_force_opportunity_number',
      // ],
      columnsData: [
        'title',
        'customer',
        'project_number',
        'work_order_number',
        'executing_site',
      ],
      showList: false,
      prevRoute: null,
    };
  },
  beforeRouteEnter({}, from, next) {
    const { state } = store;
    state.job = null;
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    ) {
      if (window.orientation === 90) {
        this.columnsData = [
          'title',
          'customer',
          'project_number',
          'work_order_number',
        ];
      }
    }
    this.showList = true;

    const myself = this;
    // eslint-disable-next-line func-names
    window.addEventListener('orientationchange', function (event) {
      if (event.target.screen.orientation.angle === 0) {
        // myself.columnsData = [
        //   'title',
        //   'original_oem',
        //   'frame_type',
        //   'model',
        //   'part_number',
        //   'customer',
        //   'project_number',
        //   'serial_number',
        //   'work_order_number',
        //   'sales_force_opportunity_number',
        // ];
        myself.columnsData = [
          'title',
          'customer',
          'project_number',
          'work_order_number',
        ];
      } else {
        myself.columnsData = [
          'title',
          'customer',
          'project_number',
          'work_order_number',
        ];
      }
      myself.showList = false;
      myself.$nextTick(() => {
        // Add the component back in
        myself.showList = true;
      });
    });
  },
};
</script>
