<template>
  <div class="dij-camera-content">
    <b-icon
      class="dij-camera-close"
      size="is-medium"
      icon="close"
      @click.native="close"
    />
    <div class="dij-camera-display">
      <b-loading
        :is-full-page="false"
        :active="isStarting"
        :can-cancel="false"
      ></b-loading>
      <video ref="video" autoplay></video>
      <b-icon
        class="dij-camera-capture"
        size="is-large"
        icon="camera-iris"
        @click.native="capture"
      />
      <b-icon
        v-if="hasMoreThanOneCamera"
        class="dij-camera-change"
        size="is-medium"
        icon="orbit-variant"
        @click.native="changeCamera"
      />
      <b-icon
        v-if="isTorchAvailable"
        class="dij-camera-torch"
        size="is-medium"
        icon="flashlight"
        @click.native="toggleTorch"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'image-annotator',
  emits: ['cancel', 'ok'],
  data() {
    return {
      isStarting: true,
      devices: [],
      horizontalConstraints: {
        video: {
          width: {
            ideal: 3840,
          },
          height: {
            ideal: 2160,
          },
        },
        audio: false,
      },
      verticalConstraints: {
        video: {
          width: {
            ideal: 2160,
          },
          height: {
            ideal: 3840,
          },
        },
        audio: false,
      },
      constraints: null,
      activeTrack: null,
    };
  },
  computed: {
    hasMoreThanOneCamera() {
      return this.devices.length > 1;
    },
    isTorchAvailable() {
      if (this.activeTrack) {
        return !!this.activeTrack.getCapabilities().torch;
      }

      return false;
    },
  },
  async mounted() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      await this.setPrimaryCamera();
      this.startCamera();
    }
  },
  methods: {
    async startCamera() {
      // set the cosntraints options and the selected device for the stream
      const constraints = {
        ...this.constraints,
      };

      if (this.devices.length > 1) {
        constraints.video.deviceId = {
          exact: this.selectedDevice,
        };
      }

      try {
        // try to start the stream
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        [this.activeTrack] = stream.getVideoTracks();

        const settings = this.activeTrack.getSettings();

        console.log(
          `Starting camera stream with ${settings.width}x${settings.height}`
        );

        this.$refs.video.srcObject = stream;

        // Disable loading indicator
        this.isStarting = false;
      } catch (err) {
        if (
          err.name === 'NotFoundError' ||
          err.name === 'DevicesNotFoundError'
        ) {
          // required track is missing
          console.warn('Media Devices not found');
        } else if (
          err.name === 'OverconstrainedError' ||
          err.name === 'ConstraintNotSatisfiedError'
        ) {
          // constraints can not be satisfied by avb. devices
          if (constraints.video === this.verticalConstraints.video) {
            console.log(
              'vertical options not available, restarting in horizontal'
            );
            this.constraints = this.horizontalConstraints;
          } else {
            console.log(
              'horizontal options not available, restarting in vertical'
            );
            this.constraints = this.verticalConstraints;
          }
          // restart the stream with different constraints
          // * caused by the device not supporting the first provided resolutions
          this.stopCamera();
          this.startCamera();
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError'
        ) {
          // permission denied in browser
          console.warn('Permission denied');
        }
        console.warn('Error while starting camera', err);
      }
    },

    /**
     * check devices available and select one to stream
     */
    async setPrimaryCamera() {
      // Get available devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );

      // Default to the rear-facing camera if available
      const rearCamera = videoDevices.find(
        (device) =>
          device.label.toLowerCase().includes('back') ||
          device.label.toLowerCase().includes('rear') ||
          device.facingMode === 'environment'
      );

      this.devices = videoDevices.map((videoDevice) => videoDevice.deviceId);

      // Select the rear camera or fallback to the first camera
      if (rearCamera) {
        this.selectedDevice = rearCamera.deviceId;
      } else if (this.devices.length > 0) {
        [this.selectedDevice] = this.devices;
      }

      // Use horizontal constraints for the default camera setup
      this.constraints = this.horizontalConstraints;
    },

    /**
     * close the camera and stop streaming
     */
    stopCamera() {
      const stream = this.$refs.video.srcObject;
      if (stream) {
        stream.getTracks().forEach((track) => {
          if (track.readyState === 'live' && track.kind === 'video') {
            track.stop();
          }
        });
      }
    },

    /**
     * take a picture
     */
    capture() {
      const { video } = this.$refs;

      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // save a screenshot of the video into a canvas element
      canvas.getContext('2d').drawImage(video, 0, 0);

      // Close the camera and stop streaming
      this.stopCamera();

      // Emit the canvas with the image
      this.$emit('ok', canvas);
    },

    close() {
      this.stopCamera();
      this.$emit('cancel');
    },

    /**
     * change the camera if there are other
     */
    changeCamera() {
      // if we have more than 1 camera device
      if (this.devices && this.devices.length > 1) {
        // if the selected device is the first one
        if (this.selectedDevice === this.devices[0]) {
          // change to second camera
          [, this.selectedDevice] = this.devices;
        } else {
          // change to first camera
          [this.selectedDevice] = this.devices;
        }
        this.stopCamera();
        this.startCamera();
      }
    },

    toggleTorch() {
      this.activeTrack.applyConstraints({
        advanced: [{ torch: true }],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-camera-content {
  height: 100%;
}
.dij-camera-close {
  position: absolute;
  right: 0;
  background-color: white;
  opacity: 0.6;
  z-index: 1;
}
.dij-camera-actions .dij-camera-capture {
  background-color: white;
  opacity: 0.6;
  z-index: 1;
}

@media screen and (orientation: portrait) {
  .dij-camera-capture {
    position: absolute;
    bottom: calc(100% - 48px - 24px);
    left: calc((100% - 48px) / 2);
    display: flex;
  }
}

video {
  width: 100%;
  height: 100%;
}

.dij-camera-display {
  background-color: black;
  height: 100%;
}

.dij-camera-capture {
  position: absolute;
  border-radius: 100px;
  background-color: rgba(240, 240, 240, 1);
  opacity: 0.6;
  color: rgba(60, 60, 60, 1);
  top: calc((100% - 48px) / 2);
  left: calc(100% - (48px * 2));
  padding: calc(48px * 0.75);
}

.dij-camera-change {
  position: absolute;
  border-radius: 100px;
  background-color: rgba(240, 240, 240, 1);
  opacity: 0.6;
  color: rgba(60, 60, 60, 1);
  top: calc((100% - (36px * 5)) / 2);
  left: calc(100% - (36px * 2 + 14px));
  padding: calc(36px * 0.75);
}

.dij-camera-torch {
  position: absolute;
  border-radius: 100px;
  background-color: rgba(240, 240, 240, 1);
  opacity: 0.6;
  color: rgba(60, 60, 60, 1);
  top: calc((100% - (36px * 9)) / 2);
  left: calc(100% - (36px * 2 + 14px));
  padding: calc(36px * 0.75);
}
</style>
