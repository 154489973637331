import ReportTemplate from '../modules/Report/Template';
import ReportTemplatePol from '../modules/Report/TemplatePol';
import ReportList from '../components/Report/List';

const reportRoutes = [
  {
    path: '/view/report',
    name: 'report-list',
    component: ReportList,
    meta: { authRequired: true },
  },
  {
    path: '/view/report/:id',
    name: 'report-template',
    component: ReportTemplate,
    meta: { authRequired: true },
  },
  {
    path: '/view/report/pol/:id',
    name: 'report-template',
    component: ReportTemplatePol,
    meta: { authRequired: true },
  },
];

export default reportRoutes;
