export default {
  methods: {
    parseInstructionData(task) {
      const data = [];
      task.childConfig[0].childConfig.forEach((item) => {
        let answare = '-';
        if (item.checked) answare = 'DONE';
        if (item.inputValue) answare = item.inputValue;
        if (item.yesOrNo) answare = item.yesOrNo.toUpperCase();
        if (item.mc && item.mcValue) {
          item.mcValue.forEach((value, index) => {
            if (value) {
              if (answare === '-') answare = item.mc[index].defaultMC;
              else answare += `, ${item.mc[index].defaultMC}`;
            }
          });
        }
        if (item.tableData) answare = 'See below';

        if (item.question === undefined) return;

        data.push({
          question: item.question,
          answare,
          attachment: item.image.length > 0 ? item.image.length : '-',
          inspector: item.lastModifiedBy ? item.lastModifiedBy.fullname : '-',
        });
      });
      return data;
    },

    parseSubTableInspectionData(tableData) {
      const columns = [];
      tableData.tableData[0].forEach((item, index) => {
        columns.push({
          field: index.toString(),
          label: item,
        });
      });
      const data = [];
      for (let i = 1; i < tableData.tableData.length; i++) {
        const row = tableData.tableData[i].map((item, index) => {
          const cell = {};
          cell[columns[index].field] = item;
          return cell;
        });
        data.push(
          row.reduce((prev, curr) => {
            return { ...prev, ...curr };
          })
        );
      }

      return {
        data,
        columns,
      };
    },

    parseDiameterData(task) {
      const data = [];
      if (!task.childConfig[0]) return data;

      // Is needed for the length of the values
      let longgestInputsValues = 0;
      task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
        if (item.measurement_type === 'diameter') {
          if (longgestInputsValues < item.inputValues.length)
            longgestInputsValues = item.inputValues.length;
        }
      });

      task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
        if (item.measurement_type === 'diameter') {
          let decimalPlaces = item.decimalPlaces ? item.decimalPlaces : 4;
          item.inputValues.forEach((element) => {
            // Check if inputValue exists
            if (element && element.value) {
              const value = parseFloat(element.value);
              decimalPlaces = Math.max(
                decimalPlaces,
                this.getNumberOfDecimalPlaces(value)
              );
            }
          });
          let date = null;

          const values = [];
          for (let index = 0; index < longgestInputsValues; index++) {
            const element = item.inputValues[index];
            if (!element || isNaN(element.value)) {
              values.push({
                name: `Value${index + 1}`,
                value: '-',
              });
            } else {
              if (element.bubbleModifierDate) {
                date = new Date(element.bubbleModifierDate);
              }
              let diff = element.value - parseFloat(item.expectedValue);
              if (diff > 0) {
                diff = Math.max(0, diff - parseFloat(item.positiveTolerance));
              } else if (diff < 0) {
                diff = Math.min(
                  0,
                  diff + Math.abs(parseFloat(item.negativeTolerance))
                );
              }
              let value = `${parseFloat(element.value).toFixed(decimalPlaces)}`;
              if (diff !== 0.0 && this.isInternalReport) {
                value += `\n(${diff.toFixed(decimalPlaces)})`;
              }

              values.push({
                name: `Value${index + 1}`,
                value,
              });
            }
          }

          const { tolerance1Sign, tolerance2Sign } =
            this.getToleranceType(item);

          data.push({
            label: item.name,
            description: item.description ? item.description : '-',
            values,
            expectedValue: `${parseFloat(item.expectedValue).toFixed(
              decimalPlaces
            )}`,
            tolerance1: `${tolerance1Sign}${parseFloat(
              item.positiveTolerance
            ).toFixed(decimalPlaces)}`,
            tolerance2: `${tolerance2Sign}${parseFloat(
              item.negativeTolerance
            ).toFixed(decimalPlaces)}`,
            unit: item.unit,
            lastModifiedBy: item.lastModifiedBy
              ? item.lastModifiedBy.fullname
              : '-',
            date: date ? date.toLocaleDateString([]) : '-',
          });
        }
      });
      return data;
    },

    parseLengthData(task) {
      const data = [];
      if (!task.childConfig[0]) return [];

      // Is needed for the length of the values
      let longgestInputsValues = 0;
      task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
        if (item.measurement_type === 'length') {
          if (longgestInputsValues < item.inputValues.length)
            longgestInputsValues = item.inputValues.length;
        }
      });

      task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
        if (item.measurement_type === 'length') {
          let decimalPlaces = item.decimalPlaces ? item.decimalPlaces : 4;
          item.inputValues.forEach((element) => {
            // Check if inputValue exists
            if (element && element.value) {
              const value = parseFloat(element.value);
              decimalPlaces = Math.max(
                decimalPlaces,
                this.getNumberOfDecimalPlaces(value)
              );
            }
          });
          let date = null;

          const values = [];
          for (let index = 0; index < longgestInputsValues; index++) {
            const element = item.inputValues[index];

            if (!element || isNaN(element.value)) {
              values.push({
                name: `Value${index + 1}`,
                value: '-',
              });
            } else {
              if (element.bubbleModifierDate) {
                date = new Date(element.bubbleModifierDate);
              }
              let diff = element.value - parseFloat(item.expectedValue);
              if (diff > 0) {
                diff = Math.max(0, diff - parseFloat(item.positiveTolerance));
              } else if (diff < 0) {
                diff = Math.min(
                  0,
                  diff + Math.abs(parseFloat(item.negativeTolerance))
                );
              }
              let value = `${parseFloat(element.value).toFixed(decimalPlaces)}`;
              if (diff !== 0.0 && this.isInternalReport) {
                value += `\n(${diff.toFixed(decimalPlaces)})`;
              }

              values.push({
                name: `Value${index + 1}`,
                value,
              });
            }
          }

          const { tolerance1Sign, tolerance2Sign } =
            this.getToleranceType(item);

          const row = {
            label: item.name,
            description: item.description ? item.description : '-',
            values,
            expectedValue: `${parseFloat(item.expectedValue).toFixed(
              decimalPlaces
            )}`,
            tolerance1: `${tolerance1Sign}${parseFloat(
              item.positiveTolerance
            ).toFixed(decimalPlaces)}`,
            tolerance2: `${tolerance2Sign}${parseFloat(
              item.negativeTolerance
            ).toFixed(decimalPlaces)}`,
            unit: item.unit,
            lastModifiedBy: item.lastModifiedBy
              ? item.lastModifiedBy.fullname
              : '-',
            date: date ? date.toLocaleDateString([]) : '-',
          };

          data.push(row);
        }
      });
      return data;
    },

    parseRunoutData(task) {
      let data = [];
      if (!task.childConfig[0]) return data;

      const grupeByNumberOfPoints = {};
      task.childConfig[0].childConfig[0].childConfig.forEach((item, index) => {
        if (item.measurement_type !== 'runout') return;
        const name =
          task.childConfig[0].childConfig[1].childConfig[index].label;
        item.name = name;
        if (!grupeByNumberOfPoints[item.numberOfPoints]) {
          grupeByNumberOfPoints[item.numberOfPoints] = [item];
        } else {
          grupeByNumberOfPoints[item.numberOfPoints].push(item);
        }
      });

      const hasMultipleRunoutsTables =
        Object.keys(grupeByNumberOfPoints).length > 1;

      if (hasMultipleRunoutsTables) {
        data = {};
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [numberOfPoints, items] of Object.entries(
        grupeByNumberOfPoints
      )) {
        // Is needed for the length of the values
        let longgestInputsValues = 0;
        items.forEach((item) => {
          if (longgestInputsValues < item.inputValues.length)
            longgestInputsValues = item.inputValues.length;
        });

        // eslint-disable-next-line no-loop-func
        items.forEach((item) => {
          let decimalPlaces = item.decimalPlaces ? item.decimalPlaces : 4;
          item.inputValues.forEach((element) => {
            // Check if inputValue exists
            if (element && element.value) {
              const value = parseFloat(element.value);
              decimalPlaces = Math.max(
                decimalPlaces,
                this.getNumberOfDecimalPlaces(value)
              );
            }
          });
          let date = null;

          if (item.numberOfPoints === 0) {
            if (item.inputValues[0] && item.inputValues[0].bubbleModifierDate) {
              date = new Date(item.inputValues[0].bubbleModifierDate);
            }
            const row = {
              label: item.name,
              description: item.description ? item.description : '-',
              tir: item.inputValues[0]
                ? parseFloat(item.inputValues[0].value).toFixed(decimalPlaces)
                : '-',
              tolerance: item.tolerance
                ? `${parseFloat(item.tolerance).toFixed(decimalPlaces)}`
                : '-',
              unit: item.unit,
              position: item.inputValues[1] ? item.inputValues[1].value : '-',
              lastModifiedBy: item.lastModifiedBy
                ? item.lastModifiedBy.fullname
                : '-',
              date: date ? date.toLocaleDateString([]) : '-',
            };

            if (hasMultipleRunoutsTables) {
              if (!data[numberOfPoints]) {
                data[numberOfPoints] = [row];
              } else {
                data[numberOfPoints].push(row);
              }
            } else {
              data.push(row);
            }
          } else {
            const values = [];
            const incrementValue = Math.floor(360 / item.numberOfPoints);
            for (let index = 0; index < longgestInputsValues; index++) {
              const element = item.inputValues[index];
              if (!element) {
                values.push({
                  name: `${index * incrementValue} Degrees`,
                  value: '-',
                });
              } else {
                if (element.bubbleModifierDate) {
                  date = new Date(element.bubbleModifierDate);
                }
                values.push({
                  name: `${index * incrementValue} Degrees`,
                  value: `${parseFloat(element.value).toFixed(decimalPlaces)}`,
                });
              }
            }
            const row = {
              label: item.name,
              description: item.description ? item.description : '-',
              values,
              tolerance: item.tolerance
                ? `${parseFloat(item.tolerance).toFixed(decimalPlaces)}`
                : '-',
              unit: item.unit,
              lastModifiedBy: item.lastModifiedBy
                ? item.lastModifiedBy.fullname
                : '-',
              date: date ? date.toLocaleDateString([]) : '-',
            };

            if (hasMultipleRunoutsTables) {
              if (!data[numberOfPoints]) {
                data[numberOfPoints] = [row];
              } else {
                data[numberOfPoints].push(row);
              }
            } else {
              data.push(row);
            }
          }
        });
      }

      return data;
    },

    parseGenericData(task) {
      const data = [];
      if (!task.childConfig[0]) return data;
      task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
        if (item.measurement_type === 'generic') {
          let date = null;
          if (item.lastModifiedBy)
            date = new Date(item.lastModifiedBy.timestamp);

          let value = '-';
          if (item.inputValues[0] && item.inputValues[0].genericValue) {
            value = item.inputValues[0].genericValue;
            if (item.inputValues[0].bubbleModifierDate) {
              date = new Date(item.inputValues[0].bubbleModifierDate);
            }
          } else if (item.expectedValue) {
            value = item.expectedValue;
          }

          if (item.bubbleModifierDate) {
            date = new Date(item.bubbleModifierDate);
          }

          data.push({
            label: item.name,
            measurementName: item.generic_measurement_name
              ? item.generic_measurement_name
              : '-',
            value,
            description: item.description ? item.description : '-',
            lastModifiedBy: item.lastModifiedBy
              ? item.lastModifiedBy.fullname
              : '-',
            date: date ? date.toLocaleDateString([]) : '-',
          });
        }
      });
      return data;
    },

    parseVisualData(task) {
      const data = [];
      if (!task.childConfig[0]) return data;
      task.childConfig[0].childConfig[0].childConfig.forEach((item) => {
        if (
          item.childConfig &&
          item.childConfig[0].measurement_type === 'visual'
        ) {
          let date = null;
          if (item.lastModifiedBy)
            date = new Date(item.lastModifiedBy.timestamp);
          let isFine = true;

          if (item.childConfig[0].bubbleModifierDate) {
            date = new Date(item.childConfig[0].bubbleModifierDate);
          }
          item.childConfig[0].visualConditions.forEach((condition) => {
            if (condition.radioSelected) {
              isFine = false;
              data.push({
                label: item.name,
                condition: condition.name,
                value: condition.radioSelected,
                visualName: item.generic_measurement_name
                  ? item.generic_measurement_name
                  : '-',
                description: item.description ? item.description : '-',
                lastModifiedBy: item.lastModifiedBy
                  ? item.lastModifiedBy.fullname
                  : '-',
                date: date ? date.toLocaleDateString([]) : '-',
              });
            }
          });

          if (isFine) {
            data.push({
              label: item.name,
              condition: 'FINE',
              value: 'FINE',
              description: item.description ? item.description : '-',
              visualName: item.generic_measurement_name
                ? item.generic_measurement_name
                : '-',
              lastModifiedBy: item.lastModifiedBy
                ? item.lastModifiedBy.fullname
                : '-',
              date: date ? date.toLocaleDateString([]) : '-',
            });
          }
        }
      });
      return data;
    },

    getValueForRunouts(item) {
      if (item === null || item === undefined) {
        return '-';
      }
      return item.value;
    },

    getAttachmentsData(task) {
      if (task.taskType === 'diagram') {
        return this.getAttachemntsDiagram(task);
      }
      if (task.taskType === 'instruction') {
        return this.getAttachemntsInstruction(task);
      }
    },

    getAttachemntsDiagram(task) {
      const attachments = [];
      if (task.childConfig.length <= 0) return [];

      task.childConfig[0].childConfig.forEach((childL1, childL1Index) => {
        if (!childL1.childConfig) return;
        childL1.childConfig.map((childL2, childL2Index) => {
          if (!childL2.childConfig) return;
          const attach = {};
          let isFreeFinding = false;
          if (childL2.findingsByInspector) {
            attach.findingsByInspector = `Comments: ${childL2.findingsByInspector}`;
            attach.name = childL2.name;
          }
          childL2.childConfig.map((childL3, childL3Index) => {
            if (childL3.image && childL3.image.length > 0) {
              attach.images = [];
              childL3.image.forEach((img, imageIndex) => {
                attach.images.push({
                  imgName: img.name,
                  img: window.location.origin + img.path,
                  imgDescription: img.description && `${img.description}`,
                  // TODO: how to calculate image size?
                  imgDim: { width: 687, height: 516 },
                  childL1Index,
                  childL2Index,
                  childL3Index,
                  imageIndex,
                });
              });

              attach.name = childL2.name;
            }
            if (childL3.measurement_type === 'freefinding') {
              isFreeFinding = true;
            }
          });

          if (isFreeFinding) {
            attach.isFreeFinding = isFreeFinding;
          }

          if (attach.findingsByInspector || attach.images) {
            attachments.push(attach);
          }
        });
      });

      return attachments;
    },

    getAttachemntsInstruction(task) {
      const attachments = [];
      task.childConfig[0].childConfig.forEach((item, childIndex) => {
        const attachment = {};

        if (item.question) {
          attachment.question = item.question;
        }

        if (
          item.findingsByInspector !== '' &&
          item.findingsByInspector !== undefined
        ) {
          attachment.findingsByInspector = item.findingsByInspector;
        }

        if (item.image && item.image.length > 0) {
          attachment.images = [];
          item.image.forEach((image, imageIndex) => {
            attachment.images.push({
              imgName: image.name,
              img: window.location.origin + image.path,
              imgDescription: image.description && `${image.description}`,
              // TODO: how to calculate image size?
              imgDim: { width: 687, height: 516 },
              childIndex,
              imageIndex,
            });
          });
        }
        // attachment.name = item.label;

        if (
          // attachment.question !== undefined ||
          attachment.images !== undefined ||
          attachment.findingsByInspector !== undefined
        )
          attachments.push(attachment);
      });

      return attachments;
    },

    getNumberOfDecimalPlaces(value) {
      if (Math.floor(value) === value) {
        return 0;
      }
      const valueAsString = Math.abs(value).toString();
      const isScientificNotation = valueAsString.includes('e');
      if (isScientificNotation) {
        const valueSplitted = valueAsString.split('e');
        const exponent = Number(valueSplitted[1]);
        const hasDecimalPlaces = valueAsString.includes('.');
        if (hasDecimalPlaces) {
          // Handle values like 1.22e1, 1.33e-5
          return Math.max(0, valueSplitted[0].split('.')[1].length - exponent);
        }
        // Handle values like 1e4, 1e-9
        return Math.max(0, -exponent);
      }

      // Handle values like 1.123, 0.0000345
      const decimals = valueAsString.split('.')[1];
      return decimals ? decimals.length : 0;
    },
  },
};
