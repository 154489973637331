<template>
  <div class="dij-annotator">
    <div class="dij-annotator-toolbar">
      <div>
        <b-icon size="is-medium" icon="arrow-left" @click.native="cancel" />
      </div>
      <div class="dij-annotator-toolbar-middle-block">
        <b-icon
          size="is-medium"
          icon="pencil"
          :type="penMode ? 'is-info' : ''"
          @click.native="enablePenMode"
        />
        <b-icon
          size="is-medium"
          icon="cursor-move"
          :type="moveMode ? 'is-info' : ''"
          @click.native="enableMoveMode"
        />
        <b-icon
          size="is-medium"
          icon="format-text"
          :type="textMode ? 'is-info' : ''"
          @click.native="enableTextMode"
        />
        <b-icon size="is-medium" icon="undo" @click.native="handleRevert" />
      </div>
      <template v-if="nextCamera">
        <div>
          <b-icon
            class="new-label"
            size="is-medium"
            icon="camera-plus"
            @click.native="okCamera"
          />

          <b-icon size="is-medium" icon="check" @click.native="ok" />
        </div>
      </template>
      <template v-else>
        <div>
          <b-upload @input="onFileChange" class="new-label">
            <b-icon class="new-label" size="is-medium" icon="camera-plus" />
          </b-upload>
          <b-icon size="is-medium" icon="check" @click.native="ok" />
        </div>
      </template>
    </div>
    <div class="dij-annotator-canvas-container">
      <canvas ref="canvas"></canvas>
      <div class="dij-annotator-textfield" ref="textFieldContainer">
        <div>
          <b-input
            type="textarea"
            ref="textField"
            v-model="textFieldText"
          ></b-input>
          <b-field label="Font Size:">
            <b-numberinput
              v-model="textFieldFontSize"
              controls-alignment="right"
              controls-position="compact"
              controls-rounded
              size="is-small"
              :expanded="true"
              :min="1"
              :editable="false"
              :step="10"
            ></b-numberinput>
          </b-field>
        </div>

        <div class="dij-annotator-textfield-actions">
          <b-icon
            size="is-medium"
            icon="check"
            @click.native="handleTextFieldOk"
          />
          <b-icon
            size="is-medium"
            icon="close"
            @click.native="handleTextFieldCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Stage,
  Touch,
  Ticker,
  Shape,
  Point,
  Text,
  Bitmap,
  Container,
} from '@createjs/easeljs';

export default {
  name: 'image-annotator',
  props: ['image', 'nextCamera'],
  emits: ['cancel', 'ok', 'okandnew', 'file-uploaded'],
  data() {
    return {
      stage: null,
      drawingCanvas: null,
      oldPoint: null,
      oldMiddlePoint: null,
      penMode: false,
      moveMode: false,
      textMode: false,
      textFieldText: '',
      textFieldX: 0,
      textFieldY: 0,
      textFields: [],
      update: false,
      textFieldFontSize: 80,
      containerToRemove: null,
    };
  },
  mounted() {
    this.init(this.image);
  },
  methods: {
    init(image) {
      const { canvas } = this.$refs;
      canvas.width = image.width;
      canvas.height = image.height;

      if (image.width > image.height) {
        const availableWidth = window.innerWidth;
        const efectiveWidth = Math.min(image.width, availableWidth);
        canvas.style.width = `${efectiveWidth}px`;
        canvas.style.height = 'auto';
      } else {
        const availableHeight = window.innerHeight - 35; // 35px from the top bar
        const efectiveHeight = Math.min(image.height, availableHeight);
        canvas.style.height = `${efectiveHeight}px`;
        canvas.style.width = 'auto';
      }

      this.stage = new Stage(canvas);
      this.stage.autoClear = false;
      this.stage.enableDOMEvents(true);

      Touch.enable(this.stage);
      Ticker.framerate = 24;
      Ticker.addEventListener('tick', (event) => {
        if (this.update) {
          this.update = false; // only update once
          this.stage.update(event);
        }
      });

      const bitmap = new Bitmap(image);
      bitmap.x = 0;
      bitmap.y = 0;
      this.stage.addChild(bitmap);

      this.enablePenMode();

      this.drawingCanvas = new Shape();
      this.stage.addChild(this.drawingCanvas);
      this.stage.update();
    },
    cancel() {
      this.$emit('cancel');
    },
    ok() {
      const { canvas } = this.$refs;
      this.$emit('ok', canvas);
    },

    okCamera() {
      const { canvas } = this.$refs;

      this.$emit('okandnew', { image: canvas });
    },
    onFileChange(file) {
      const { canvas } = this.$refs;
      if (file) {
        this.$emit('okandnew', { image: canvas, file });
      }
    },
    enablePenMode() {
      if (!this.penMode) {
        this.disableTextMode();
        this.disableMoveMode();
        this.penMode = true;
        this.stage.addEventListener('stagemousedown', this.handlePenMouseDown);
        this.stage.addEventListener('stagemouseup', this.handlePenMouseUp);
      }
    },
    disablePenMove() {
      this.penMode = false;
      this.stage.removeEventListener('stagemouseup', this.handlePenMouseUp);
      this.stage.removeEventListener('stagemousedown', this.handlePenMouseDown);
    },
    handlePenMouseDown(event) {
      if (!event.primary) {
        return;
      }
      this.oldPoint = new Point(this.stage.mouseX, this.stage.mouseY);
      this.oldMiddlePoint = this.oldPoint.clone();
      this.stage.addEventListener('stagemousemove', this.handlePenMouseMove);
    },
    handlePenMouseMove(event) {
      if (!event.primary) {
        return;
      }
      const middlePoint = new Point(
        (this.oldPoint.x + this.stage.mouseX) / 2,
        (this.oldPoint.y + this.stage.mouseY) / 2
      );

      this.drawingCanvas.graphics
        .setStrokeStyle(15, 'round', 'round')
        .beginStroke('red')
        .moveTo(middlePoint.x, middlePoint.y)
        .curveTo(
          this.oldPoint.x,
          this.oldPoint.y,
          this.oldMiddlePoint.x,
          this.oldMiddlePoint.y
        );

      this.oldPoint.x = this.stage.mouseX;
      this.oldPoint.y = this.stage.mouseY;

      this.oldMiddlePoint.x = middlePoint.x;
      this.oldMiddlePoint.y = middlePoint.y;

      this.update = true;
    },
    handlePenMouseUp(event) {
      if (!event.primary) {
        return;
      }
      this.stage.removeEventListener('stagemousemove', this.handlePenMouseMove);
    },
    enableTextMode() {
      if (!this.textMode) {
        this.disablePenMove();
        this.disableMoveMode();
        this.textMode = true;

        this.stage.enableMouseOver(0);
        this.stage.mouseMoveOutside = false;

        this.stage.addEventListener('stagemousedown', this.handleTextMouseDown);

        this.textFields.forEach((textField) => (textField.cursor = 'pointer'));
      }
    },
    disableTextMode() {
      this.textMode = false;

      this.stage.enableMouseOver(10);
      this.stage.mouseMoveOutside = true;

      this.stage.removeEventListener(
        'stagemousedown',
        this.handleTextMouseDown
      );

      this.textFields.forEach((textField) => (textField.cursor = null));
    },
    handleTextMouseDown(event) {
      const { textFieldContainer } = this.$refs;
      textFieldContainer.style.display = 'flex';
      this.textFieldX = event.stageX;
      this.textFieldY = event.stageY;

      const { canvas } = this.$refs;

      textFieldContainer.style.top = `${Math.min(
        (this.textFieldY * canvas.clientHeight) / canvas.height,
        canvas.clientHeight - textFieldContainer.clientHeight
      )}px`;
      textFieldContainer.style.left = `${Math.min(
        (this.textFieldX * canvas.clientWidth) / canvas.width,
        canvas.clientWidth - textFieldContainer.clientWidth
      )}px`;

      const { textField } = this.$refs;
      textField.focus();

      this.stage.removeEventListener(
        'stagemousedown',
        this.handleTextMouseDown
      );
    },
    handleRevert() {
      this.disableTextMode();
      this.disableMoveMode();
      this.disablePenMove();

      this.init(this.image);

      this.oldPoint = null;
      this.oldMiddlePoint = null;

      this.enablePenMode();

      this.textFields = [];
    },
    handleTextFieldOk() {
      const { textFieldContainer } = this.$refs;
      textFieldContainer.style.display = 'none';
      const { containerToRemove } = this;

      if (containerToRemove) {
        containerToRemove.parent.removeChild(containerToRemove);
        this.containerToRemove = null;
      }

      const container = new Container();
      container.x = this.textFieldX;
      container.y = this.textFieldY;
      container.cursor = 'pointer';
      this.stage.addChild(container);

      const padding = 10;

      if (this.textFieldText && this.textFieldText.length === 0) {
        return;
      }

      const text = new Text(
        this.textFieldText,
        `${this.textFieldFontSize}px Siemens Roman`,
        '#ffffff'
      );
      text.x = padding;
      text.y = padding;

      const textBox = new Shape();
      textBox.x = 0;
      textBox.y = 0;

      textBox.graphics
        .beginFill('#000000')
        .drawRect(
          0,
          0,
          text.getBounds().width + padding * 2,
          text.getBounds().height + padding * 2
        );
      textBox.alpha = 0.5;

      container.width = text.getBounds().width + padding * 2;
      container.height = text.getBounds().height + padding * 2;
      container.addChild(textBox, text);
      this.update = true;

      container.on('pressmove', (pressMoveEvent) => {
        if (this.moveMode) {
          container.x = pressMoveEvent.stageX - container.width / 2;
          container.y = pressMoveEvent.stageY - container.height / 2;

          this.update = true;
        }
      });

      this.stage.addEventListener('stagemousedown', this.handleTextMouseDown);

      this.textFields.push(container);

      container.on('click', () => {
        if (this.textMode) {
          if (container.parent) {
            this.containerToRemove = container;
          }
          this.handleEditTextField(
            container.x,
            container.y,
            container.children[1].text
          );
        }
      });

      this.textFieldText = '';

      this.enableMoveMode();
    },

    handleEditTextField(containerX, containerY, textToEdit) {
      this.textFieldText = textToEdit;
      if (this.textFieldText && this.textFieldText.length === 0) {
        return;
      }
      const { textFieldContainer } = this.$refs;
      textFieldContainer.style.display = 'flex';
      this.textFieldX = containerX;
      this.textFieldY = containerY;

      const { canvas } = this.$refs;

      textFieldContainer.style.top = `${Math.min(
        (this.textFieldY * canvas.clientHeight) / canvas.height,
        canvas.clientHeight - textFieldContainer.clientHeight
      )}px`;
      textFieldContainer.style.left = `${Math.min(
        (this.textFieldX * canvas.clientWidth) / canvas.width,
        canvas.clientWidth - textFieldContainer.clientWidth
      )}px`;

      const { textField } = this.$refs;
      textField.focus();

      this.stage.removeEventListener(
        'stagemousedown',
        this.handleTextMouseDown
      );
    },

    handleTextFieldCancel() {
      const { textFieldContainer } = this.$refs;
      textFieldContainer.style.display = 'none';
      this.textFieldText = '';
      const { containerToRemove } = this;
      if (containerToRemove) {
        this.containerToRemove = null;
      }
      this.stage.addEventListener('stagemousedown', this.handleTextMouseDown);
      this.enableMoveMode();
    },

    enableMoveMode() {
      if (!this.moveMode) {
        this.disablePenMove();
        this.disableTextMode();
        this.moveMode = true;
      }
    },

    disableMoveMode() {
      this.moveMode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-label {
  padding-right: 12px;
}
.dij-annotator {
  width: 100%;
  height: 100%;
}
.dij-annotator-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  &-middle-block {
    display: flex;
    gap: 10px;
  }
}
.dij-annotator-textfield {
  position: absolute;
  background-color: lightgrey;
  padding: 10px;
  opacity: 0.9;
  gap: 10px;
  display: none;

  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
}
.dij-annotator-canvas-container {
  position: relative;
  width: 100%;
  height: calc(100% - 36px);
}
</style>
