<template>
  <div>
    <div class="avatar-container">
      <img class="avatar" :src="user.avatar" />
    </div>

    <div class="inputs-container-row">
      <b-field label="My Name">
        <b-input
          size="is-medium"
          placeholder="Username"
          icon="account"
          v-model="user.fullname"
        ></b-input>
      </b-field>

      <b-field label="Email">
        <b-input
          size="is-medium"
          icon="email-outline"
          :placeholder="user.email || user._id"
          disabled
        >
        </b-input>
      </b-field>
      <b-field class="second-field" label="Location">
        <b-input icon="city" size="is-medium" :placeholder="location" disabled>
        </b-input>
      </b-field>

      <b-field label="Phone Number">
        <b-input
          size="is-medium"
          placeholder="Phone Number"
          icon="phone"
          v-model="user.phone"
        ></b-input>
      </b-field>

      <div class="update-buttons">
        <div class="update-profile-button">
          <b-button
            @click="updateProfile"
            size="is-medium"
            class="submit-button"
            type="is-primary"
            >{{ $t('profile_page.updateAccount') }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['updateProfile'],
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    location() {
      const siteLocationObj = this.$store.state.sites.find(
        (item) => item._id === this.user?.location
      );
      return siteLocationObj?.siteName;
    },
  },
  methods: {
    updateProfile() {
      this.$emit('updateProfile');
    },
  },
};
</script>

<style scoped lang="scss">
.update-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.update-profile-button,
.update-password-button {
  .submit-button {
    color: $siemens-logo-color !important;
    background-color: white !important;
    inline-size: fit-content !important;

    &:hover {
      color: white !important;
      background-color: $siemens-logo-color !important;
    }
  }
}

.avatar-container {
  display: flex;
  justify-content: center;
}

.avatar {
  width: 80px;
  height: 80px;
  margin: 30px 0;
  border-radius: 80px;
  display: block;
  white-space: nowrap;
  background-color: rgba(37, 48, 92, 0.3);
}
</style>
