// eslint-disable-next-line no-unused-vars
const MODULE_NAME = 'WebsocketModule';

// eslint-disable-next-line import/prefer-default-export
export const SET_WEBSOCKET_STATUS = `${MODULE_NAME}/SET_WEBSOCKET_STATUS`;
export const SET_WEB_SOCKET_CONNECTION_ID = `${MODULE_NAME}/SET_WEB_SOCKET_CONNECTION_ID`;

export const WEBSOCKET_EVENT_ADD_SUBSCRIBER = `${MODULE_NAME}/WEBSOCKET_EVENT_ADD_SUBSCRIBER`;
export const WEBSOCKET_EVENT_REMOVE_SUBSCRIBER = `${MODULE_NAME}/WEBSOCKET_EVENT_REMOVE_SUBSCRIBER`;
export const WEBSOCKET_EVENT_PUBLISH_MESSAGE = `${MODULE_NAME}/WEBSOCKET_EVENT_PUBLISH_MESSAGE`;
