const ADMIN_ROLE = 'admin';

export default {
  methods: {
    validatePassword(password, username) {
      let message = '';
      const hasLowerCase = /[a-z]/.test(password);
      const hasUpperCase = /[A-Z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasMinLength = password.length >= 8;
      const hasNoUsernameParts = !password.includes(username);

      if (!hasLowerCase) {
        message = 'change_password_page.passwordLowerCase';
      }

      if (!hasUpperCase) {
        message = 'change_password_page.passwordUpperCase';
      }

      if (!hasNumber) {
        message = 'change_password_page.passwordNumber';
      }

      if (!hasMinLength) {
        message = 'change_password_page.passwordMinLength';
      }

      if (!hasNoUsernameParts) {
        message = 'change_password_page.passwordNoUsernameParts';
      }

      return {
        isValid:
          hasLowerCase &&
          hasUpperCase &&
          hasNumber &&
          hasMinLength &&
          hasNoUsernameParts,
        message,
      };
    },

    validateAdminAccess() {
      if (this.$store.state.currentUser.role !== ADMIN_ROLE) {
        this.$router.push('/view/dashboard');
      }
    },
  },
};
