<template>
  <b-modal
    :active="true"
    aria-role="dialog"
    aria-modal
    trap-focus
    scroll="keep"
    full-screen
    class="dij-modal-dynamic-width"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <div>
          {{ $t('repair_assessment') }}
        </div>
        <div class="dij-ra-status">
          <b-button
            :loading="waitingForRead"
            icon-left="refresh"
            @click="refresh"
          ></b-button>
          <b-button
            class="dij-ra-save-status"
            :loading="waitingForWrite"
            :type="waitingForWrite ? 'is-warning' : 'is-success'"
            :icon-left="waitingForWrite ? '' : 'check-circle'"
            >Saved</b-button
          >
        </div>
      </header>
      <div class="dij-ra-header">
        <div class="dij-ra-action-header">
          <b-button
            icon-left="seal"
            :type="isPreAssemblyOk() ? 'is-success' : 'is-danger'"
            >{{ $t('pre_assembly_audit') }}</b-button
          >
          <b-button
            icon-left="download"
            :type="'is-success'"
            @click="exportRepairAssessment"
            >{{ $t('donwload_rat') }}</b-button
          >
          <b-button icon-left="upload" @click="$refs.importAdd.click()"
            >Upload RAT</b-button
          >
          <input
            ref="importAdd"
            type="file"
            class="hidden"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="handleRepairAssessmentImportAdd"
          />
        </div>

        <b-notification v-if="showUploadProgress" :closable="false">
          Uploading {{ uploaded }}/{{ total }}
        </b-notification>
        <b-input
          icon="magnify"
          :placeholder="$t('search')"
          @input="searchRepairAssessment"
        />
      </div>
      <section class="modal-card-body">
        <div class="flex-container">
          <b-tabs type="is-boxed" :expanded="false" v-model="activeTab">
            <b-tab-item
              label="Major Components"
              icon="engine"
              class="b-tab-item-scroll"
            >
              <repair-assessment-detail
                ref="repairAssessment"
                v-model="filteredMajorRepairAssessment"
                :read-only="readOnly"
                :tool="tool"
                @input="onInput"
              />
            </b-tab-item>
            <b-tab-item
              label="Minor Components"
              icon="screw-flat-top"
              class="b-tab-item-scroll"
            >
              <repair-assessment-detail
                ref="repairAssessmentMinor"
                v-model="filteredMinorRepairAssessment"
                :read-only="readOnly"
                :tool="tool"
                :isMinorComponents="true"
                @input="onInput"
              />
            </b-tab-item>
          </b-tabs>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="dij-ra-left-actions">
          <b-button
            type="is-primary"
            icon-right="plus"
            v-if="!readOnly"
            @click="addRow"
          />
        </div>
        <div class="dij-ra-rigth-actions">
          <b-button @click="close">
            {{ $t('close') }}
          </b-button>
        </div>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import { v4 } from 'uuid';
import RepairAssessmentDetail from './RepairAssessmentDetail';
import RepairAssessmentMixin from '../../mixins/RepairAssessmentMixin';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../store/websocket/actions/actionTypes';

const uuidv4 = v4;

export default {
  name: 'repair-assessment-detail-dialog',
  props: ['readOnly', 'tool'],
  emits: ['close'],
  mixins: [RepairAssessmentMixin],
  data() {
    return {
      repairAssessment: [],
      filteredRepairAssessment: [],
      searchText: '',
      showUploadProgress: false,
      uploaded: 0,
      total: 0,
      activeTab: 0,
    };
  },
  components: { RepairAssessmentDetail },
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
    jobs() {
      return this.$store.state.jobs;
    },
    waitingForRead() {
      return this.$store.state.waitingForRead;
    },
    waitingForWrite() {
      return this.$store.state.waitingForWrite;
    },
    filteredMinorRepairAssessment: {
      get() {
        return this.filteredRepairAssessment.filter((row) => row.isMinor);
      },
      set(value) {
        this.filteredRepairAssessment = [
          ...this.filteredRepairAssessment,
          value,
        ];
      },
    },
    filteredMajorRepairAssessment: {
      get() {
        return this.filteredRepairAssessment.filter((row) => !row.isMinor);
      },
      set(value) {
        this.filteredRepairAssessment = [
          ...this.filteredRepairAssessment,
          value,
        ];
      },
    },
  },
  async created() {
    const { id } = this.$route.params;
    await this.$store.dispatch('getJob', id);
    this.repairAssessment = [];
    if (this.job.repairAssessment) {
      this.job.repairAssessment.forEach((element) => {
        if (element.id) {
          this.repairAssessment.push({ ...element });
        } else {
          this.repairAssessment.push({ id: uuidv4(), ...element });
        }
      });
      this.filteredRepairAssessment = this.repairAssessment;
    }

    const wsMappings = {
      addRepairAssessmentRowResponse: this.refresh,
      updateRepairAssessmentRowResponse: this.refresh,
      deleteRepairAssessmentResponse: this.refresh,
      deleteRepairAssessmentRowResponse: this.refresh,
      moveRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentRowResponse: this.refresh,
      unlockRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentResponse: this.refresh,
      unlockRepairAssessmentResponse: this.refresh,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  beforeDestroy() {
    const wsMappings = {
      addRepairAssessmentRowResponse: this.refresh,
      updateRepairAssessmentRowResponse: this.refresh,
      deleteRepairAssessmentResponse: this.refresh,
      deleteRepairAssessmentRowResponse: this.refresh,
      moveRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentRowResponse: this.refresh,
      unlockRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentResponse: this.refresh,
      unlockRepairAssessmentResponse: this.refresh,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  methods: {
    onInput(value) {
      this.repairAssessment = [...this.repairAssessment, value];
    },
    close() {
      if (!this.readOnly) {
        if (!this.waitingForWrite) {
          this.$store
            .dispatch('unlockRepairAssessment', {
              job: this.job,
              tool: this.tool,
            })
            .then((repairAssessment) => {
              this.job.repairAssessment = repairAssessment;
            });
        }

        this.job.repairAssessment = this.repairAssessment;
      }
      this.$emit('close');
    },

    searchRepairAssessment(text) {
      if (text) {
        const lowerCaseText = text.toLowerCase();
        this.filteredRepairAssessment = this.repairAssessment.filter(
          (element) => {
            return (
              (element.assembly &&
                element.assembly
                  .toString()
                  .toLowerCase()
                  .includes(lowerCaseText)) ||
              (element.itemNumber &&
                element.itemNumber
                  .toString()
                  .toLowerCase()
                  .includes(lowerCaseText)) ||
              (element.partName &&
                element.partName
                  .toString()
                  .toLowerCase()
                  .includes(lowerCaseText)) ||
              (element.partNumber &&
                element.partNumber
                  .toString()
                  .toLowerCase()
                  .includes(lowerCaseText))
            );
          }
        );
      } else {
        this.filteredRepairAssessment = this.repairAssessment;
      }
    },

    async addRow() {
      if (this.activeTab === 0) {
        this.$refs.repairAssessment.addRow({ isMinor: false });
      }

      if (this.activeTab === 1) {
        this.$refs.repairAssessmentMinor.addRow({ isMinor: true });
      }
    },

    refresh() {
      console.log('REFRESH');
      this.$store
        .dispatch('getRepairAssessment', this.job)
        .then((repairAssessment) => {
          console.log('Responses', repairAssessment);
          this.repairAssessment = [...repairAssessment];
          this.filteredRepairAssessment = [...repairAssessment];
        });
    },

    isPreAssemblyOk() {
      return this.repairAssessment.every((element) =>
        this.isQualityControlGreen(element)
      );
    },

    isQualityControlGreen(item) {
      return (
        this.$refs.repairAssessment &&
        this.$refs.repairAssessment.getQualityControlType(item) === 'is-success'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-ra-action-header {
  display: flex;
  gap: 10px;
}

.tab-content {
  height: 100% !important;
}
.b-tab-item-scroll {
  height: 100%;
}
.flex-container {
  display: flex;
  height: 100%;
  width: 100%;
}

::v-deep div.animation-content.modal-content {
  display: flex;
  flex-direction: column;
}
// Set max width and correct font-size
div.dij-modal-dynamic-width {
  font-size: 16px;
}
// Set max heigth
section.modal-card-body {
  flex: 1 0 0;
  padding: 0px;
}
.dij-ra-rigth-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
// hide modal close button
::v-deep button.modal-close.is-large {
  display: none;
}
.dij-ra-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: white;
  padding: 10px;

  & > div.control {
    width: 250px;
  }
}

// Make padding smaller for header and footer
.modal-card-head,
.modal-card-foot {
  padding: 10px;
}

.modal-card-head {
  display: flex;
  justify-content: space-between;
}
.dij-ra-status {
  display: flex;
  gap: 10px;

  .dij-ra-save-status {
    border-radius: 0;
    cursor: unset;
    background: white;
    color: green;
  }
}
</style>

<style scoped>
.b-tabs {
  width: 100%;
}
.tab-content {
  height: calc(100% - 43px);
  padding: 0px;
}
</style>
