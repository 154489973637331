<template>
  <div class="tasks-container-invalid-token">
    <h4>Sorry, you are not allowed to see this page.</h4>
  </div>
</template>

<script>
export default {
  name: 'forbidden-page',
};
</script>

<style lang="scss">
.tasks-container-invalid-token {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 10% auto;

  .md-field {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }
}
</style>
