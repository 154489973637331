<template>
  <form>
    <h1>Upload image</h1>
    <input type="file" id="file" ref="file" @change="onFileSelected" />

    <!-- <button type="submit" @click="onUpload">Submit</button> -->
  </form>
</template>

<script>
import axios from '../../config/api';

export default {
  name: 'upload-image',

  data() {
    return {
      selectedFile: null,
      file: '',
    };
  },

  methods: {
    async onFileSelected(event) {
      const formData = new FormData();
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0];
      formData.append('image', this.selectedFile, this.selectedFile.name);

      const response = await this.getPresignedPostData(this.selectedFile);

      try {
        await this.uploadFileToS3(response, this.selectedFile);
        console.log('File was successfully uploaded!');
      } catch (e) {
        console.log('An error occurred!', e.message);
      }
    },

    /**
     * Retrieve pre-signed POST data from a dedicated API endpoint.
     * @param selectedFile
     * @returns {Promise<any>}
     */
    async getPresignedPostData(selectedFile) {
      return new Promise((resolve, reject) => {
        // const xhr = new XMLHttpRequest();

        // Set the proper URL here.
        // const url = 'http://localhost:3000/dij/image/presigned';
        const url =
          'https://jhj6qm3c5b.execute-api.eu-central-1.amazonaws.com/v1/dij/image/presigned';

        axios
          .post(
            url,
            JSON.stringify({
              name: selectedFile.name,
              type: selectedFile.type,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log('error', error);

            reject(error);
          });
      });
    },

    uploadFileToS3(presignedPostData, file) {
      /**
       * Upload file to S3 with previously received pre-signed POST data.
       * @param presignedPostData
       * @param file
       * @returns {Promise<any>}
       */

      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });

        formData.append('file', file);

        axios
          .post(presignedPostData.url, formData)
          .then((response) => {
            if (response.status === 204) {
              resolve();
            }

            reject(response.data);
          })
          .catch((error) => {
            console.log('error', error);

            reject(error);
          });
      });
    },
  },
  // test pipeline
};
</script>

<style></style>
