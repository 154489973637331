import client from '../../config/api';
import { LOCAL_SET_CURRENT_USER } from './mutations/mutationTypes';

function handleError(error) {
  throw error;
}

export default {
  async USERS_GET_CURRENT({ commit }) {
    try {
      const result = await client.get('/users/current');
      commit(LOCAL_SET_CURRENT_USER, result.data);
    } catch (error) {
      handleError(error);
    }
  },
};
