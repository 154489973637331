<template>
  <section id="table-inspection" v-if="tableData">
    <div class="task-table">
      TABLE - {{ tableData.question }} ({{ tableData.tableRow }} x
      {{ tableData.tableColumn }})
    </div>
    <div id="image-container">
      <ImageWithErrorFallback
        v-if="tableData.tableImg"
        :imageUrl="tableData.tableImg"
        class="table-image"
      />
    </div>
    <b-table :data="tableQuestionData.data" :striped="true">
      <template slot-scope="props">
        <b-table-column
          v-for="(column, index) in tableQuestionData.columns"
          :key="index"
          :label="column.label"
        >
          {{ props.row[column.field] }}
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import ImageWithErrorFallback from '../components/ImageWithErrorFallback';

export default {
  name: 'TableInspection',
  props: {
    tableData: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImageWithErrorFallback,
  },
  computed: {
    tableQuestionData() {
      const columns = [];
      const { tableData } = this;
      tableData.tableData[0].forEach((item, index) => {
        columns.push({
          field: index.toString(),
          label: item,
        });
      });
      const data = [];
      for (let i = 1; i < tableData.tableData.length; i++) {
        const row = tableData.tableData[i].map((item, index) => {
          const cell = {};
          cell[columns[index].field] = item;
          return cell;
        });
        data.push(
          row.reduce((prev, curr) => {
            return { ...prev, ...curr };
          })
        );
      }

      return {
        data,
        columns,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.task-table {
  width: 100%;
}

#image-container {
  display: flex;
  justify-content: center;
}

.table-image {
  border: #ccc 1px solid;
}
</style>
