import actions from './actions';
import mutations from './mutations';

const reportsModule = {
  namespaced: true,
  state() {
    return {
      excludedItems: [],
      enableExcludeItems: false,
      isInternalReport: true,
      reportView: {},
    };
  },
  actions,
  mutations,
};

export default reportsModule;
