const adminPageConstants = {
  adminPage: {
    tabs: {
      userList: 0,
      roleList: 1,
      siteList: 2,
      mteTypeList: 3,
    },
  },
  adminCookies: {
    admin_selected_tab: 'admin_selected_tab',
  },
};
export default adminPageConstants;
