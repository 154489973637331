<template>
  <!-- <div class="modal-switch-language"> -->
  <b-modal :active.sync="isComponentActive" :width="640" :height="400">
    <div class="container">
      <div class="notification">
        <div>
          <section>
            <h3 class="select-language-text">
              {{ $t('choose_language') }}
            </h3>
            <b-field>
              <b-select
                v-model="selectedLanguage"
                :placeholder="$t('language')"
                icon="earth"
                size="is-medium"
              >
                <option
                  v-for="language in languages"
                  :key="language.value"
                  :value="language.value"
                >
                  {{ language.title }}
                </option></b-select
              >
            </b-field>
          </section>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    isModalActive: Boolean,
  },
  data() {
    return {
      activeComponent: null,
      isPublic: true,
      languages: [
        {
          value: 'en',
          title: 'English',
        },
        {
          value: 'de',
          title: 'German',
        },
      ],
    };
  },

  computed: {
    isComponentActive: {
      get() {
        return this.activeComponent;
      },
      set(value) {
        this.activeComponent = value;
        this.$emit('isActiveSwitchLanguage', value);
      },
    },
    selectedLanguage: {
      get() {
        return null;
      },
      set(languageSelected) {
        const defaultLanguage = this.$cookies.get('lang');
        this.$cookies.set('lang', languageSelected);

        // Using this hack to refresh the page with the language selected
        if (defaultLanguage !== languageSelected) {
          /* Using this syntax to update the page
           if it's inside a polymer page (without 'view') */
          return location.reload();
        }
      },
    },
  },

  watch: {
    isModalActive(newVal) {
      this.activeComponent = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-switch-language {
  width: 640px;
  height: 400px;
}

.select-language-text {
  color: $siemens-logo-color;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 30px;
  justify-content: center;
}
</style>
