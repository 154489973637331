/* eslint-disable no-shadow */ /* eslint-disable eqeqeq */
<template>
  <CreatePageBase
    ref="createPageBase"
    :title="$t(`${TRANSLATION_ROOT}.create_new_user`)"
    :validForm="fieldsAreValid"
    @onSubmit="createUser"
  >
    <b-field
      v-for="(field, index) in fields"
      :key="index"
      :label="$t(`${TRANSLATION_ROOT}.${field.name}`)"
    >
      <b-input
        required
        :password-reveal="field.type === 'password'"
        :type="field.type"
        :icon="field.icon"
        :label="field.label"
        v-model.trim="field.value"
      ></b-input>
    </b-field>

    <b-field :label="$t('location')">
      <b-select
        icon="office-building-marker-outline"
        v-model="selectedSite"
        required
      >
        <option v-for="site in sites" :value="site._id" :key="site._id">
          {{ site.siteName }}
        </option>
      </b-select>
    </b-field>

    <b-field :label="$t(`${TRANSLATION_ROOT}.role`)">
      <b-select icon="wrench" v-model="selectedRole" required>
        <option
          v-for="availableRole in roles"
          :value="availableRole.rolename"
          :key="availableRole.rolename"
        >
          {{ availableRole.rolename }}
        </option>
      </b-select>
    </b-field>
  </CreatePageBase>
</template>

<script>
import CreatePageBase from './CreatePageBase';
import { USERS_CREATE_OKTA_USER } from '../../store/users/actionTypes';

import UserMixin from '../../mixins/UserMixin';

const TRANSLATION_ROOT = 'create_new_user_page';

export default {
  name: 'create-user',
  components: { CreatePageBase },
  mixins: [UserMixin],
  async mounted() {
    this.$refs.createPageBase.isLoading = true;

    await this.$store.dispatch('getSites');

    this.validateAdminAccess();
    this.$refs.createPageBase.submitBtnLabel = `${TRANSLATION_ROOT}.create_user`;

    this.$refs.createPageBase.isLoading = false;
  },
  data() {
    return {
      TRANSLATION_ROOT,
      fields: [
        {
          icon: 'account',
          type: '',
          label: 'first_name',
          name: 'firstName',
          value: '',
        },
        {
          icon: 'account',
          type: '',
          label: 'last_name',
          name: 'lastName',
          value: '',
        },
        {
          icon: 'email',
          type: 'email',
          label: 'email',
          name: 'email',
          value: '',
        },
        {
          icon: 'account-key',
          type: '',
          label: 'GID',
          name: 'gid',
          value: '',
        },
        {
          icon: 'lock',
          type: 'password',
          label: 'Password',
          name: 'password',
          value: '',
        },
      ],
      selectedRole: '',
      selectedSite: '',
    };
  },
  computed: {
    fieldsAreValid() {
      if (this.fields.some((field) => field.value === '' || !field))
        return false;
      if (this.selectedRole === '' || !this.selectedRole) return false;
      if (this.selectedSite === '' || !this.selectedSite) return false;
      return true;
    },
    roles() {
      return this.$store.state.userRoles;
    },
    sites() {
      return this.$store.state.sites;
    },
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: 'admin-root',
      });
    },
    validatePassword(password, username) {
      const hasLowerCase = /[a-z]/.test(password);
      const hasUpperCase = /[A-Z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasMinLength = password.length >= 8;
      const hasNoUsernameParts = !password.includes(username);

      return (
        hasLowerCase &&
        hasUpperCase &&
        hasNumber &&
        hasMinLength &&
        hasNoUsernameParts
      );
    },
    showSuccess() {
      this.$refs.createPageBase.successMessage = `${TRANSLATION_ROOT}.user_success`;
      this.$refs.createPageBase.showSuccess = true;
      window.scrollTo(0, 0);
    },
    showFail(message) {
      this.$refs.createPageBase.failedMessage = `${TRANSLATION_ROOT}.${message}`;
      this.$refs.createPageBase.showFail = true;
      window.scrollTo(0, 0);
    },
    async createUser() {
      if (this.fieldsAreValid) {
        try {
          const newUserPayload = {
            role: this.selectedRole,
            site: this.selectedSite,
          };
          this.fields.forEach((field) => {
            newUserPayload[field.name] = field.value;
          });

          if (
            !this.validatePassword(
              newUserPayload.password,
              newUserPayload.firstName
            )
          ) {
            this.showFail('password_validation');
            return;
          }

          this.$refs.createPageBase.isLoading = true;
          await this.$store.dispatch(USERS_CREATE_OKTA_USER, newUserPayload);
          this.showSuccess();

          setTimeout(() => {
            this.$router.push({
              name: 'admin-root',
            });
          }, 2000);
        } catch (err) {
          this.showFail('user_failed');
          this.$refs.createPageBase.isLoading = false;
        }
      } else {
        this.showFail('all_fields_required');
      }
    },
  },
};
</script>
