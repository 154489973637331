<template>
  <TemplateCopyfrom tool="publisher"> </TemplateCopyfrom>
</template>

<script>
import TemplateCopyfrom from '../layout/TemplateCopyfrom';

export default {
  name: 'publisher-copyfrom',
  components: {
    TemplateCopyfrom,
  },
};
</script>

<style></style>
