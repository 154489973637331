/* eslint-disable no-shadow */ /* eslint-disable eqeqeq */
<template>
  <div class="create-site-container">
    <div class="close-form" @click="cancelForm">
      <img src="/img/closebtn.png" />
    </div>
    <b-notification
      auto-close
      type="is-success"
      :active.sync="showSuccess"
      :duration="3000"
      aria-close-label="Close notification"
    >
      {{ successMessage }}
    </b-notification>
    <b-notification
      auto-close
      type="is-danger"
      :active.sync="showFail"
      :duration="5000"
      aria-close-label="Close notification"
    >
      {{ failedMessage }}
    </b-notification>
    <div class="create-site-form-container">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <h4>{{ $t(`${TRANSLATION_ROOT}.create_new_site`) }}</h4>

      <b-field
        v-for="(field, index) in fields"
        :key="index"
        :label="$t(`${TRANSLATION_ROOT}.${field.name}`)"
      >
        <b-input
          required
          :label="field.label"
          v-model.trim="field.value"
        ></b-input>
      </b-field>

      <div class="buttons-container">
        <button class="cancel-button" v-on:click="cancelForm">
          {{ $t('cancel') }}
        </button>

        <button
          @click="this.createSite"
          class="submit-button"
          :disabled="!this.fieldsAreValid || isLoading"
        >
          Create Site
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const TRANSLATION_ROOT = 'create_new_site_page';

export default {
  name: 'create-site',
  data() {
    return {
      TRANSLATION_ROOT,
      fields: [
        {
          label: 'site_name',
          name: 'siteName',
          value: '',
        },
        {
          label: 'city',
          name: 'city',
          value: '',
        },
        {
          label: 'state',
          name: 'state',
          value: '',
        },
        {
          label: 'country',
          name: 'country',
          value: '',
        },
      ],

      showSuccess: false,
      showFail: false,
      isLoading: false,
      successMessage: this.$t(`${TRANSLATION_ROOT}.site_success`),
      failedMessage: this.$t(`${TRANSLATION_ROOT}.site_failed`),
    };
  },

  methods: {
    cancelForm() {
      this.$router.push({
        name: 'admin-root',
      });
    },
    async createSite() {
      const newSite = {};
      this.fields.forEach((field) => {
        newSite[field.name] = field.value;
      });

      if (this.fieldsAreValid) {
        try {
          this.isLoading = true;
          await this.$store.dispatch('createSite', newSite);
          this.showSuccess = true;
          setTimeout(() => {
            this.$router.push({
              name: 'admin-root',
            });
            this.isLoading = false;
          }, 3000);
        } catch (err) {
          this.showFail = true;
        }
      } else {
        this.showFail = true;
      }
    },
  },
  computed: {
    fieldsAreValid() {
      if (this.fields.some((field) => field.value === '' || !field))
        return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-form {
  position: fixed;
  width: 16px;
  height: 16px;
  right: 0;
  margin-right: 5%;
}

.close-form:hover {
  cursor: pointer;
}

.create-site-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}
.create-site-form-container {
  margin: 8px auto;
  width: 50%;
  overflow: auto;
  padding: 10px;
  margin-top: 3%;
  display: flex;
  flex-direction: column;

  .md-field {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }

  [placeholder] {
    color: #777;
    font-size: 16px;
    font-family: 'Siemens Sans';
  }
}
.buttons-container {
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cancel-button {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;
}

.cancel-button:hover {
  cursor: pointer;
  font-family: 'Siemens Sans';
  font-size: 16px;
}
.submit-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #4a86e8;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.submit-button:hover {
  cursor: pointer;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
</style>
