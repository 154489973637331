import ChangePassword from '../components/Admin/ChangePassword';
import CreateRole from '../components/Admin/CreateRole';
import CreateSite from '../components/Admin/CreateSite';
import CreateUser from '../components/Admin/CreateUser';
import EditRole from '../components/Admin/EditRole';
import EditSite from '../components/Admin/EditSite';
import EditUserProfile from '../components/Admin/EditUserProfile';
import MteTypeEdit from '../components/Admin/MteTypeEdit';
import Admin from '../components/Admin/Template';

const adminRoutes = [
  {
    path: '/view/admin',
    name: 'admin-root',
    component: Admin,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/users/create',
    name: 'create-user',
    component: CreateUser,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/users/password/:id',
    name: 'change-user-password',
    component: ChangePassword,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/roles/create',
    name: 'create-role',
    component: CreateRole,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/sites/create',
    name: 'create-site',
    component: CreateSite,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/users/edit/:id',
    name: 'edit-user',
    props: true,
    component: EditUserProfile,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/sites/edit/:id',
    name: 'edit-site',
    props: true,
    component: EditSite,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/roles/edit/:id',
    name: 'edit-role',
    props: true,
    component: EditRole,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/mtetypes/create',
    name: 'mtetype-create',
    props: true,
    component: MteTypeEdit,
    meta: { authRequired: true },
  },
  {
    path: '/view/admin/mtetypes/edit/:id',
    name: 'mtetype-edit',
    props: true,
    component: MteTypeEdit,
    meta: { authRequired: true },
  },
];

export default adminRoutes;
