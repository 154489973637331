<template>
  <tasks-template ref="tasks" tool="inspector"> </tasks-template>
</template>

<script>
import TaskTemplate from '../ToolsTemplates/TaskPage';
import store from '../../store/index';

export default {
  components: {
    'tasks-template': TaskTemplate,
  },

  async beforeRouteEnter(to, {}, next) {
    const { id } = to.params;
    // const { state } = store;
    // Necessary until migration to vue
    // if (!state.job || state.job.id !== id) {
    await store.dispatch('getJob', id);
    // }
    next();
  },

  async beforeRouteLeave({}, {}, next) {
    await this.$refs.tasks.updateThumbnail();

    if (this.$refs.tasks.job.taskCount !== this.$refs.tasks.tasks.length) {
      this.$refs.tasks.job.taskCount = this.$refs.tasks.tasks.length;
      await this.$store.dispatch('updateJobSimple', this.$refs.tasks.job);
    }

    const waitForUpdate = setInterval(() => {
      if (!this.$store.state.waitingForWrite) {
        clearInterval(waitForUpdate);
        next();
      }
    }, 1000);
  },
};
</script>

<style></style>
