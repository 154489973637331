<template>
  <b-field>
    <b-select
      :disabled="disableSelectSubmitForApproval"
      v-model="selected"
      :placeholder="submitForApprovalSelectPlaceholder"
    >
      <option
        v-for="approver in approvers"
        :value="approver"
        :key="approver.Gid"
      >
        {{
          approvalStatus === 'requested'
            ? 'REQUESTED FOR APPROVAL'
            : approver.FullName
        }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
import jwt_decode from 'jwt-decode';

export default {
  name: 'select-approvers',
  props: {
    approvalStatus: String,
  },
  data() {
    return {
      externalClient: '',
      tokenData: {},
      readOnly: false,
      selected: null,
      showSubmit: false,
      loadingApprovers: false,
      approversError: '',
    };
  },

  async mounted() {
    const { token } = this.$route.query;
    if (token) {
      await this.checkExternalClient(token);

      if (this.externalClient.includes('OIL')) {
        await this.getOilApprovers();
      }
    }
  },
  computed: {
    token() {
      return this.$store.state.token;
    },

    approvers() {
      if (this.externalClient.includes('OIL')) {
        return this.$store.state.oilApprovers;
      }
      return [];
    },

    submitForApprovalSelectPlaceholder() {
      if (this.approvalStatus === 'requested') {
        return 'REQUESTED FOR APPROVAL';
      }
      if (this.approvalStatus === 'approved') {
        return 'APPROVED';
      }
      if (this.approversError) {
        return 'Failed to get approvers list';
      }
      if (this.loadingApprovers) {
        return 'Loading approvers list...';
      }

      return `Submit to ${this.externalClient}`;
    },

    disableSelectSubmitForApproval() {
      if (
        this.approvalStatus === 'requested' ||
        this.approvalStatus === 'approved' ||
        this.approversError !== '' ||
        this.loadingApprovers
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async selected(approver) {
      const objectHelper = {
        externalClient: this.externalClient,
        selectedApprover: approver,
      };

      if (approver.Gid) {
        this.$emit('selectedApprover', objectHelper);
      }
    },
    async loadingApprovers(loadingApprovers) {
      this.loadingApprovers = loadingApprovers;
    },
  },

  methods: {
    async checkExternalClient(token) {
      try {
        this.tokenData = await jwt_decode(token, process.env.JWT_SECRET);
        this.readOnly = this.tokenData.action === 'view';

        if (this.tokenData?.referral) {
          const externalClientName = this.tokenData.referral.split('_')[0];
          this.externalClient = externalClientName;
        }
      } catch (err) {
        console.error(err);
        this.$router.push('/logout');
      }
    },

    async getOilApprovers() {
      try {
        await this.$store.dispatch('getOilToken');

        this.loadingApprovers = true;

        await this.$store.dispatch(
          'getOilApprovers',
          this.$store.state.oilToken
        );

        this.loadingApprovers = false;
      } catch (err) {
        this.loadingApprovers = false;
        this.approversError = 'Could not fetch data from OIL';
      }
    },
  },
};
</script>

<style></style>
