<template>
  <div class="external-data-container">
    <div>
      <h1>EXTERNAL DATA</h1>
      <input type="file" id="file" ref="file" @change="onFileSelected" />
      <h1 v-if="errorUpload">File upload failed</h1>
    </div>
    <b-table :data="uploadFiles" :striped="true" :narrowed="true">
      <template slot-scope="props">
        <b-table-column label="ITEM">
          {{ props.index + 1 }}
        </b-table-column>

        <b-table-column field="displayName" label="File Name">
          <div @click="onDownloadFile(props.row.path)" id="file-name">
            {{ props.row.displayName }}
          </div>
        </b-table-column>

        <b-table-column label="Print to report">
          <b-checkbox
            v-if="canBePrinted(props.row.displayName)"
            @input="(e) => onCheckToPrint(e, props.row.uniqueFileName)"
            :value="isPrintToReport(props.row.printToReport)"
          />
          <b-checkbox
            v-else
            :disabled="true"
            title="This file type can not be printed to report"
          />
        </b-table-column>

        <b-table-column>
          <button @click="onDelete(props.index)">Delete</button>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { getDocument } from 'pdfjs-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'pdfjs-dist/webpack.mjs';

import { v4 } from 'uuid';
import DIJVueConfigurations from '../../../helpers/DIJVueConfigurations';
import client from '../../../config/api';

const SUPPORTED_FILE_TYPES = ['.jpeg', '.jpg', '.bmp', '.gif', '.png', '.pdf'];
const uuidv4 = v4;

export default {
  name: 'ExternalData',

  data() {
    return {
      columns: [
        {
          field: 'id',
          label: 'ID',
        },
      ],
      selectedFile: null,
      file: '',
      errorUpload: false,
      files: [],
    };
  },

  computed: {
    job() {
      return this.$store.state.currentJob;
    },
    site() {
      return this.$store.state.sites[0];
    },
    uploadFiles() {
      if (this.files.length > 0) return this.files;
      return this.job.uploadFile;
    },
  },

  methods: {
    async onFileSelected(event) {
      try {
        this.errorUpload = false;
        const file = event.target.files[0];
        this.selectedFile = file;
        const fileName = file.name;
        let base63ImagesArray = null;
        let fileJsonName = null;
        let filePathJson = null;

        const response = await client.post(
          `/job/${this.job._id}/presign-upload-file`,
          { fileName }
        );
        const { presignedPostData, path, uniqueFileName } = response.data;
        const dijConfigurations = new DIJVueConfigurations();
        await dijConfigurations.sendPresignedPostData(
          presignedPostData,
          file,
          file.name
        );

        if (file.type === 'application/pdf') {
          base63ImagesArray = await this.handlePDF(file);
          fileJsonName = `${uuidv4()}_json.txt`;
          const responsePdf = await client.post(
            `/job/${this.job._id}/presign-upload-file`,
            { fileName: fileJsonName }
          );

          const { presignedPostData: presignedPostDataJson, path: pathJson } =
            responsePdf.data;

          const blob = new Blob([JSON.stringify(base63ImagesArray)], {
            type: 'text/plain',
          });
          const fileJson = new File([blob], fileJsonName);
          filePathJson = pathJson;
          await dijConfigurations.sendPresignedPostData(
            presignedPostDataJson,
            fileJson,
            fileJsonName
          );
        }

        const payload = {
          displayName: file.name,
          filePath: path,
          uniqueFileName,
        };

        if (filePathJson) {
          payload.filePathJson = filePathJson;
        }

        await client.post(`/job/${this.job._id}/reportfile`, payload);
        console.log('File was successfully uploaded!');
        await this.$store.dispatch('getJob', this.job._id);
        this.$refs.file.value = null;
      } catch (error) {
        this.errorUpload = true;
        console.error(error);
      }
    },

    async onDelete(index) {
      await client.post(`/job/${this.job._id}/reportfile/${index}`);
      await this.$store.dispatch('getJob', this.job._id);
    },

    async onDownloadFile(path) {
      const dijConfigurations = new DIJVueConfigurations();
      const response = await client.post(
        `/job/${this.job._id}/reportfile-download-url`,
        { key: path, bucketName: dijConfigurations.bucket }
      );
      this.triggerDownload(response.data);
    },

    triggerDownload(downloadLink) {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = downloadLink;
      anchor.target = '_blank';

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },

    canBePrinted(fileName) {
      const fileExt = fileName
        .substring(fileName.lastIndexOf('.'), fileName.length)
        .toLowerCase();
      return SUPPORTED_FILE_TYPES.includes(fileExt);
    },

    async onCheckToPrint(e, uniqueFileName) {
      const { uploadFile } = this.job;

      const index = uploadFile.findIndex(
        (file) => file.uniqueFileName === uniqueFileName
      );
      uploadFile[index].printToReport = e;
      const { job } = this;
      job.uploadFile = uploadFile;

      await this.$store.dispatch('updateJobSimple', job);
    },

    isPrintToReport(printToReport) {
      if (printToReport) return true;
      return false;
    },

    // Very ugly workaround untill we move the logic to use pdf on backend
    async handlePDF(file) {
      const images = [];

      // Load the PDF document using a URL created from the file
      const loadingTask = getDocument(URL.createObjectURL(file));
      const pdf = await loadingTask.promise;

      // Loop through all the pages
      for (let i = 1; i <= pdf.numPages; i++) {
        // eslint-disable-next-line no-await-in-loop
        const page = await pdf.getPage(i);

        // Define the scale and viewport for the page
        const scale = 1; // Adjust the scale if needed
        const viewport = page.getViewport({ scale });

        // Prepare canvas using PDF page dimensions
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render the page into the canvas context
        const renderContext = {
          canvasContext: context,
          viewport,
        };
        // eslint-disable-next-line no-await-in-loop
        await page.render(renderContext).promise;

        // Convert the canvas content to an image and push to the images array
        const src = canvas.toDataURL();
        images.push(src);
      }
      return images;
    },
  },
};
</script>

<style>
.external-data-container {
  background-color: white;
  margin-top: 20px;
  padding: 10px;
  font-family: 'Siemens Roman';
  font-size: 18px;
}

#file-name {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(44, 44, 249);
}
</style>
