// eslint-disable-next-line import/no-unresolved
import * as formulajs from '@formulajs/formulajs';

export default {
  calculate: ({ dispatch }, options) => {
    // options.columnValue.type may be 'sum' or 'deviation', etc...
    return dispatch(options.columnValue.type, options);
  },
  sum: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = parseFloat(values[0], 10) + parseFloat(values[1], 10);
    return result;
  },
  sub: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = parseFloat(values[0], 10) - parseFloat(values[1], 10);
    return result;
  },

  subMul: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = parseFloat(values[0], 10) - 2 * parseFloat(values[1], 10);
    return result;
  },

  minClearance: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = 2 * (parseFloat(values[0], 10) + parseFloat(values[1], 10));
    return result;
  },
  maxClearance: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = 2 * parseFloat(values[0], 10);
    return result;
  },
  calcClearance: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = parseFloat(values[0], 10) - parseFloat(values[1], 10);
    return result;
  },
  deviation: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    if (parseFloat(values[0]) < parseFloat(values[1])) {
      return parseFloat(values[0], 10) - parseFloat(values[1], 10);
    }

    if (parseFloat(values[0]) > parseFloat(values[2])) {
      return parseFloat(values[0], 10) - parseFloat(values[2], 10);
    }
    return 0;
  },

  min: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = formulajs.MIN(values);
    return result || 0;
  },

  max: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    const result = formulajs.MAX(values);
    return result || 0;
  },

  ifEqual: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    if (values[0] === values[1]) {
      return values[2];
    }
    return values[3];
  },
  equal: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    return values[0];
  },

  ifEqualSub: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    if (values[0] === values[1]) {
      return values[2] - values[3];
    }
    return values[4];
  },

  highlightLine: async ({ dispatch }, options) => {
    const values = await dispatch('calcAux', options);

    if (values[0] === values[1] || values[1] === values[2]) {
      return 'Y';
    }
    return '';
  },

  reworkEquality: async ({ state, dispatch }, options) => {
    // if options have rows, use them instead of the state task rows
    let rows;
    if (options.rows) {
      rows = options.rows;
    } else {
      rows = state.task.rows;
    }
    const param1 = []; // difference first and second
    const param2 = []; // first stage
    const param3 = []; // last stage
    const param4 = []; // aux 2
    const stages = [];
    for (let i = 0; i < rows.length; i++) {
      // difference between first and second measurement param
      const param1Value = rows[i][options.columnValue.params[0]];
      // new_first_reference_stage param
      const param2Value = rows[i][options.columnValue.params[1]];
      // new_last_reference_stage param
      const param3Value = rows[i][options.columnValue.params[2]];
      // aux_2 param
      const param4Value = rows[i][options.columnValue.params[6]];
      if (typeof param1Value === 'object') {
        // eslint-disable-next-line no-await-in-loop
        const result = await dispatch('calculate', {
          rows,
          rowIndex: i,
          columnValue: param1Value,
        });
        param1.push(result);
      } else {
        param1.push(0);
      }

      if (typeof param2Value === 'object') {
        // eslint-disable-next-line no-await-in-loop
        const result = await dispatch('calculate', {
          rows,
          rowIndex: i,
          columnValue: param2Value,
        });
        param2.push(result);
      } else {
        param2.push(0);
      }

      if (typeof param3Value === 'object') {
        // eslint-disable-next-line no-await-in-loop
        const result = await dispatch('calculate', {
          rows,
          rowIndex: i,
          columnValue: param3Value,
        });
        param3.push(result);
      } else {
        param3.push(0);
      }

      if (typeof param4Value === 'object') {
        // eslint-disable-next-line no-await-in-loop
        const result = await dispatch('calculate', {
          rows,
          rowIndex: i,
          columnValue: param4Value,
        });
        param4.push(result);
      } else {
        param4.push(0);
      }

      // param3 - array of stages
      const param5Value = rows[i].stage; // stage
      stages.push(param5Value);
    }

    let reworkFront;
    let reworkRear;
    param4.forEach((stage, key) => {
      if (stage === param2[key]) {
        reworkFront = param1[key];
      }
      if (stage === param3[key]) {
        reworkRear = param1[key];
      }
    });

    const values = await dispatch('calcAux', options);

    let differenzStageAux1;
    if (values[2] - values[1] === 0) {
      differenzStageAux1 = 1;
    } else {
      differenzStageAux1 = values[2] - values[1];
    }

    // change this calculations
    const differenzStage = (reworkFront - reworkRear) / differenzStageAux1;
    const reworkRearLine = values[4];
    const reworkFrontLine =
      reworkFront + differenzStage * (param2[0] - values[3]);
    const differenzStageLine = reworkRearLine + reworkFrontLine;
    const result = formulajs.MIN([differenzStageLine, values[5]]) + 0.1;
    return result;
  },

  maxReferenceStage: async ({ state, dispatch }, options) => {
    // if options have rows, use them instead of the state task rows
    let rows;
    if (options.rows) {
      rows = options.rows;
    } else {
      rows = state.task.rows;
    }
    const param1 = [];
    const param2 = [];
    for (let i = 0; i < rows.length; i++) {
      // param1
      const param1Value = rows[i][options.columnValue.params[0]];
      // eslint-disable-next-line no-await-in-loop
      let result = await dispatch('multipleParamsAux', {
        rows,
        paramValue: param1Value,
        rowIndex: i,
      });
      param1.push(result);

      // param2
      const param2Value = rows[i][options.columnValue.params[1]];
      // eslint-disable-next-line no-await-in-loop
      result = await dispatch('multipleParamsAux', {
        rows,
        paramValue: param2Value,
        rowIndex: i,
      });
      param2.push(result);
    }

    if (formulajs.MAX(param1) === formulajs.MAX(param2)) {
      return formulajs.MAX(param1);
    }
    return formulajs.MAX(param2);
  },

  minReferenceStage: async ({ state, dispatch }, options) => {
    // if options have rows, use them instead of the state task rows
    let rows;
    if (options.rows) {
      rows = options.rows;
    } else {
      rows = state.task.rows;
    }
    const param1 = [];
    const param2 = [];
    for (let i = 0; i < rows.length; i++) {
      // param1
      const param1Value = rows[i][options.columnValue.params[0]];
      // eslint-disable-next-line no-await-in-loop
      let result = await dispatch('multipleParamsAux', {
        rows,
        paramValue: param1Value,
        rowIndex: i,
      });
      param1.push(result);

      // param2
      const param2Value = rows[i][options.columnValue.params[1]];
      // eslint-disable-next-line no-await-in-loop
      result = await dispatch('multipleParamsAux', {
        rows,
        paramValue: param2Value,
        rowIndex: i,
      });
      param2.push(result);
    }

    if (formulajs.MIN(param1) === formulajs.MIN(param2)) {
      return formulajs.MIN(param1);
    }
    return formulajs.MIN(param2);
  },

  lookUp: async ({ dispatch }, options) => {
    const param1 = [];
    const data = {
      id: options.columnValue.taskRef.id,
    };
    const newTask = await dispatch('getJobTaskInState', data);
    for (let i = 0; i < newTask.rows.length; i++) {
      const param1Value =
        newTask.rows[i][options.columnValue.taskRef.params[0]]; // param 0 from ref
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch('multipleParamsAux', {
        rows: newTask.rows,
        paramValue: param1Value,
        rowIndex: i,
      });
      param1.push(result);
    }
    const values = await dispatch('calcAux', options);
    const stageNumberInColumn = param1.includes(values[0]);
    if (stageNumberInColumn) {
      return 'FALSE';
    }
    return 'TRUE';
  },

  getJobTaskInState: ({ state }, { id }) => {
    const index = state.jobTasks.findIndex((task) => task._id === id);
    if (index > -1) {
      return state.jobTasks[index];
    }
    return {};
  },

  multipleParamsAux: async ({ dispatch }, options) => {
    const { paramValue, rowIndex, rows } = options;
    const param = [];
    if (!paramValue) {
      param.push(0);
    } else if (typeof paramValue === 'object') {
      const newOptions = {
        rows,
        rowIndex,
        columnValue: paramValue,
      };
      param.push(await dispatch('calculate', newOptions));
    } else {
      param.push(parseFloat(paramValue, 10));
    }
    return param[0];
  },
  /**
   * Where all the procedures to get the calculations values happen
   * @param {Object} options {rowIndex, columnValue, rows?}
   */
  calcAux: async ({ dispatch, state }, options) => {
    // params are the name of the fields to be calculated
    const { params } = options.columnValue;
    // the index of the row where the data is
    const { rowIndex } = options;
    const values = [];
    let rows;

    // if options have rows, use them instead of the state task rows
    if (options.rows) {
      rows = options.rows;
    } else {
      rows = state.task.rows;
    }

    // iterate through the params to transform them into actual values
    for (let i = 0; i < params.length; i++) {
      const param = params[i];

      // rows to be calculated
      let calcRows = rows;

      if (options.columnValue.taskRef) {
        let taskRefId = null;
        let refIndex = null;

        // check if taskRef is an array
        if (Array.isArray(options.columnValue.taskRef)) {
          refIndex = options.columnValue.taskRef.findIndex((ref) =>
            ref.params.includes(param)
          );
          // if param is found, save refId
          if (refIndex > -1) {
            taskRefId = options.columnValue.taskRef[refIndex].id;
          }

          // if our param is from the taskRef
        } else if (options.columnValue.taskRef.params.includes(param)) {
          taskRefId = options.columnValue.taskRef.id;
        }

        if (taskRefId) {
          // get the task from ref and use its rows
          const data = {
            id: taskRefId,
          };
          // eslint-disable-next-line no-await-in-loop
          const newTask = await dispatch('getJobTaskInState', data);

          // replace calcRows so we can use the rows from the ref task
          calcRows = newTask.rows;
        }
      }

      let value;

      const { type } = options.columnValue;
      if (
        type === 'lookup' ||
        type === 'minReferenceStage' ||
        type === 'maxReferenceStage'
      ) {
        value = [];
      }
      // get the value from the param name
      if (
        typeof param === 'string' &&
        calcRows &&
        (calcRows[rowIndex][param] || calcRows[rowIndex][param] === '')
      ) {
        value = calcRows[rowIndex][param];
      } else {
        // if param is not a field name, return its content instead
        value = param;
      }

      // if the value is other calculation or ref
      if (typeof value === 'object') {
        const newOptions = {
          rows: calcRows,
          rowIndex,
          columnValue: value,
        };

        // when there is ref, send the newTask rows so it can be calculated
        // with the correct task
        if (options.columnValue.taskRef) {
          newOptions.rows = calcRows;
        }
        // eslint-disable-next-line no-await-in-loop
        value = await dispatch('calculate', newOptions);
      }
      if (isNaN(parseFloat(value))) {
        values.push(value);
      } else {
        values.push(parseFloat(value));
      }
    }

    return values;
  },
};
