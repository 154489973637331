export default {
  getCurrentEpoch() {
    const nowDate = new Date();
    // Remove the timezone/DST offset to get UTC and convert to seconds
    const nowEpoch = Math.round(
      (nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000) / 1000
    );
    return nowEpoch;
  },
};
