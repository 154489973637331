<template>
  <div>
    <section>
      <b-notification
        auto-close
        type="is-danger"
        :active.sync="showNotEditableMessage"
        :duration="3000"
        aria-close-label="Close notification"
      >
        Sorry, you cannot change default roles.
      </b-notification>
      <b-table
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="role.name"
        :row-class="(row, index) => 'table-list-row'"
        :data="formattedRoles"
        :hoverable="true"
        :paginated="true"
        :per-page="10"
        @click="handleOpenEditRole"
      >
        <template slot-scope="props">
          <b-table-column width="60">
            <img src="/img/icons/user.png" />
          </b-table-column>

          <b-table-column
            field="rolename"
            label="Role Name"
            sortable
            searchable
          >
            <template #searchable="props">
              <div v-if="isSearchActive">
                <b-input
                  v-model="props.filters['rolename']"
                  placeholder="Filter"
                  size="is-small"
                />
              </div>
            </template>
            {{ props.row.rolename }}
          </b-table-column>

          <b-table-column
            field="toollist"
            label="Permitted Tools"
            sortable
            searchable
          >
            <template #searchable="props">
              <div v-if="isSearchActive">
                <b-input
                  v-model="props.filters['toollist']"
                  placeholder="Filter"
                  size="is-small"
                />
              </div>
            </template>
            {{ props.row.toollist }}
          </b-table-column>

          <b-table-column
            field="date"
            label="Date Modified"
            sortable
            searchable
          >
            <template #searchable="props">
              <div v-if="isSearchActive">
                <b-input
                  v-model="props.filters['date']"
                  placeholder="Filter"
                  size="is-small"
                />
              </div>
            </template>
            {{ props.row.date }}
          </b-table-column>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>
import adminPageConstants from '../../constants/adminPageConstants';

export default {
  name: 'role-list',

  props: {
    search: String,
  },

  data() {
    return {
      showNotEditableMessage: false,
      isActive: false,
      formattedRoles: [],
      isSearchActive: false,
    };
  },

  computed: {
    roles() {
      return this.$store.state.userRoles;
    },
  },

  watch: {
    search() {},
  },

  async mounted() {
    const parsedRoles = JSON.parse(JSON.stringify(this.roles));
    if (Object.keys(parsedRoles).length < 1) {
      await this.$store.dispatch('getUserRoles');
    }
  },

  methods: {
    async getRoles(query) {
      try {
        await this.$store.dispatch('getUserRoles', query);
        if (query && this.search) {
          this.isSearchActive = true;
        } else {
          this.isSearchActive = false;
        }

        this.formatRoles();
      } catch (err) {
        console.error('error when trying to get roles: ', err);
      }
    },

    formatRoles() {
      this.formattedRoles = this.roles.map((role) => {
        role.toollist = role.toollist.join(', ');
        role.date = new Date(role.timestamp).toLocaleDateString();

        return role;
      });
    },

    handleOpenEditRole(role) {
      if (!role.editable) {
        this.showNotEditableMessage = true;
      } else {
        this.$cookies.set(
          adminPageConstants.adminCookies.admin_selected_tab,
          adminPageConstants.adminPage.tabs.roleList
        );
        this.$router.push({
          name: 'edit-role',
          params: { role, id: role._id },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.notification.is-danger {
  width: 90%;
  margin-top: 20px;
  margin: auto;
}
</style>
