import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const documentsModule = {
  namespaced: true,
  state() {
    return {
      documents: [
        // {
        //   name: 'data1',
        //   type: 'folder',
        //   id: 'data1',
        //   hash: 'data1',
        // },
        // {
        //   name: 'data10',
        //   type: 'folder',
        //   id: 'data10',
        //   hash: 'data10',
        // },
        // {
        //   name: 'data5',
        //   type: 'file',
        //   id: 'data5',
        //   hash: 'data5',
        // },
      ],
    };
  },
  actions,
  getters,
  mutations,
};

export default documentsModule;
