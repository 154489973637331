import DateTimeHelper from './DateTimeHelper';

export default {
  isTaskAvailable(task, user) {
    // The task is available if any of the following rule apply:
    //   There is no modifier
    //   There is no modify timeout
    //   There is a modify timeout and it has expired
    //   There is a modifier that corresponds to the current user and the modify timeout has not expired
    return (
      !task.modifier ||
      !task.modifyTimeout ||
      task.modifyTimeout < DateTimeHelper.getCurrentEpoch() ||
      task.modifier._id === user._id
    );
  },
};
