<template>
  <div class="container">
    <img
      class="introLogoPortrait"
      id="portraitTabletLogo"
      src="/img/dij-tablet-portrait.png"
      alt="logo-tablet"
    />
    <div class="message">(404) Sorry, this page does not exist.</div>
    <div class="message-link">
      Click <a href="/signin"> here </a> to return to the home page.
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      // this.$router.push('/dashboard');
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin-top: 15% !important;
}
img {
  width: 170px;
  margin: auto;
}
.message {
  margin: auto;
  margin-top: 30px;
  font-size: 35px;
  font-family: 'Siemens Roman';
  display: flex;
}
.message-link {
  margin: auto;
  display: flex;
  a {
    color: $page404-link-color !important;
    margin: 0px 4px 0px 4px;
  }
}
</style>
