<template>
  <b-modal
    :active="true"
    aria-role="dialog"
    aria-modal
    has-modal-card
    trap-focus
    scroll="keep"
    full-screen
    class="dij-modal-dynamic-width"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <div>
          {{ $t('repair_assessment') }}
        </div>
        <div class="dij-ra-status">
          <b-button
            :loading="waitingForRead"
            icon-left="refresh"
            @click="refresh"
          ></b-button>
          <b-button
            class="dij-ra-save-status"
            :loading="waitingForWrite"
            :type="waitingForWrite ? 'is-warning' : 'is-success'"
            :icon-left="waitingForWrite ? '' : 'check-circle'"
            >Saved</b-button
          >
        </div>
      </header>
      <section class="modal-card-body">
        <div class="flex-container">
          <div class="upper-buttons">
            <template v-if="isRepairAssessmentTemplateSearchVisible">
              <b-input
                placeholder="Search..."
                type="search"
                icon="magnify"
                v-model="repairAssessmentTemplateSearchString"
                @keyup.native="handleRepairAssessmentTemplateSearch"
              >
              </b-input>
            </template>
            <template v-else>
              <b-button
                icon-left="drag-variant"
                :type="moveMode ? 'is-success' : ''"
                @click="handleMove"
              >
                {{ $t('move') }}
              </b-button>
              <b-button
                icon-left="delete"
                type="is-danger"
                :disabled="moveMode"
                @click="handleDeleteAll"
              >
                {{ $t('delete_all') }}
              </b-button>
              <input
                ref="importAdd"
                type="file"
                class="hidden"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="handleRepairAssessmentImportAdd"
              />
              <b-dropdown
                aria-role="list"
                v-if="!readOnly"
                :disabled="moveMode"
              >
                <template #trigger="{ active }">
                  <b-button
                    icon-left="upload"
                    type="is-info"
                    :label="$t('import')"
                    :icon-right="active ? 'menu-up' : 'menu-down'"
                  />
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  @click="showRepairAssessmentTemplateSearch"
                  >{{ $t('import_editor_template') }}</b-dropdown-item
                >
                <b-dropdown-item
                  aria-role="listitem"
                  @click="$refs.importAdd.click()"
                  >{{ $t('import_add_excel_file') }}</b-dropdown-item
                >
              </b-dropdown>
              <b-button
                icon-left="download"
                type="is-info"
                :disabled="moveMode"
                @click="handleRepairAssessmentDownload"
              >
                {{ $t('download_excel_file') }}
              </b-button>
            </template>
          </div>
          <div class="dij-ra-content">
            <b-table
              key="ra_template_search_table"
              detailed
              :data="templates"
              v-if="isRepairAssessmentTemplateSearchVisible"
              :selected.sync="selectedTemplate"
            >
              <template v-slot="props">
                <b-table-column
                  field="product_line"
                  :label="$t('product_line')"
                >
                  {{ props.row.product_line }}
                </b-table-column>

                <b-table-column field="title" :label="$t('title')">
                  {{ props.row.title }}
                </b-table-column>

                <b-table-column
                  field="original_oem"
                  :label="$t('original_OEM')"
                >
                  {{ props.row.original_oem }}
                </b-table-column>

                <b-table-column field="frame_type" :label="$t('frame_type')">
                  {{ props.row.frame_type }}
                </b-table-column>

                <b-table-column field="model" :label="$t('model')">
                  {{ props.row.model }}
                </b-table-column>

                <b-table-column field="part_number" :label="$t('part_number')">
                  {{ props.row.part_number }}
                </b-table-column>

                <b-table-column field="location" :label="$t('location')">
                  {{ props.row.location }}
                </b-table-column>

                <b-table-column field="created_by" :label="$t('created_by')">
                  {{ props.row.created_by }}
                </b-table-column>

                <b-table-column field="date" :label="$t('date')">
                  {{ props.row.date }}
                </b-table-column>
              </template>
              <template #empty>
                <div class="dij-ra-table-loading">
                  <b-loading
                    :is-full-page="false"
                    :can-cancel="false"
                    :active="true"
                  ></b-loading>
                </div>
              </template>
              <template #detail="props">
                <div class="dij-ra-details">
                  <repair-assessment
                    v-model="props.row.repairAssessment"
                    :read-only="true"
                    :tool="tool"
                  />
                </div>
              </template>
            </b-table>
            <b-tabs type="is-boxed" :expanded="false" v-model="activeTab">
              <b-tab-item
                label="Major Components"
                icon="engine"
                class="b-tab-item-scroll"
              >
                <repair-assessment
                  ref="repairAssessment"
                  v-if="!isRepairAssessmentTemplateSearchVisible"
                  v-model="repairAssessment"
                  :read-only="readOnly"
                  :tool="tool"
                  :move-mode="moveMode"
                />
              </b-tab-item>
              <b-tab-item
                label="Minor Components"
                icon="screw-flat-top"
                class="b-tab-item-scroll"
              >
                <repair-assessment
                  ref="repairAssessment"
                  v-if="!isRepairAssessmentTemplateSearchVisible"
                  v-model="repairAssessmentMinor"
                  :isMinorComponents="true"
                  :read-only="readOnly"
                  :tool="tool"
                  :move-mode="moveMode"
                />
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot dij-actions">
        <template v-if="isRepairAssessmentTemplateSearchVisible">
          <b-button @click="hideRepairAssessmentTemplateSearch">
            {{ $t('back') }}
          </b-button>
          <b-button
            type="is-primary"
            :disabled="!isTemplateSelected()"
            @click="handleImportAdd"
          >
            {{ $t('import_add') }}
          </b-button>
        </template>
        <template v-if="!isRepairAssessmentTemplateSearchVisible">
          <div class="dij-ra-left-actions">
            <b-button
              type="is-primary"
              icon-right="plus"
              v-if="!readOnly"
              @click="addRow"
            />
          </div>
          <div class="dij-ra-right-actions">
            <b-button @click="close">
              {{ $t('close') }}
            </b-button>
          </div>
        </template>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import * as xlsx from 'xlsx';
import { v4 } from 'uuid';
import RepairAssessment from './RepairAssessment';
import RepairAssessmentMixin from '../../mixins/RepairAssessmentMixin';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../store/websocket/actions/actionTypes';

const uuidv4 = v4;

export default {
  name: 'repair-assessment-dialog',
  props: ['readOnly', 'tool'],
  emits: ['close'],
  mixins: [RepairAssessmentMixin],
  data() {
    return {
      repairAssessment: [],
      repairAssessmentMinor: [],
      repairAssessmentTemplateSearchString: '',
      isRepairAssessmentTemplateSearchVisible: false,
      templates: [],
      selectedTemplate: null,
      moveMode: false,
      activeTab: 0,
    };
  },
  components: { RepairAssessment },
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
    jobs() {
      return this.$store.state.jobs;
    },
    waitingForRead() {
      return this.$store.state.waitingForRead;
    },
    waitingForWrite() {
      return this.$store.state.waitingForWrite;
    },
  },
  async created() {
    this.repairAssessment = [];
    this.repairAssessmentMinor = [];
    if (this.job.repairAssessment) {
      this.job.repairAssessment.forEach((element) => {
        if (element.isMinor) {
          if (element.id) {
            this.repairAssessmentMinor.push({ ...element });
          } else {
            this.repairAssessmentMinor.push({ id: uuidv4(), ...element });
          }
        } else if (element.id) {
          this.repairAssessment.push({ ...element });
        } else {
          this.repairAssessment.push({ id: uuidv4(), ...element });
        }
      });
    }
    const wsMappings = {
      addRepairAssessmentRowResponse: this.refresh,
      updateRepairAssessmentRowResponse: this.refresh,
      deleteRepairAssessmentResponse: this.refresh,
      deleteRepairAssessmentRowResponse: this.refresh,
      moveRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentRowResponse: this.refresh,
      unlockRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentResponse: this.refresh,
      unlockRepairAssessmentResponse: this.refresh,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  beforeDestroy() {
    const wsMappings = {
      addRepairAssessmentRowResponse: this.refresh,
      updateRepairAssessmentRowResponse: this.refresh,
      deleteRepairAssessmentResponse: this.refresh,
      deleteRepairAssessmentRowResponse: this.refresh,
      moveRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentRowResponse: this.refresh,
      unlockRepairAssessmentRowResponse: this.refresh,
      // lockRepairAssessmentResponse: this.refresh,
      unlockRepairAssessmentResponse: this.refresh,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  methods: {
    refresh() {
      this.$store
        .dispatch('getRepairAssessment', this.job)
        .then((repairAssessment) => {
          const majorRAT = repairAssessment.filter((ra) => !ra.isMinor);
          this.repairAssessment = [...majorRAT];
          const minorRAT = repairAssessment.filter((ra) => ra.isMinor);
          this.repairAssessmentMinor = [...minorRAT];
        });
    },
    handleMove() {
      if (this.moveMode) {
        this.$store
          .dispatch('unlockRepairAssessment', {
            job: this.job,
            tool: this.tool,
          })
          .then(() => {
            this.moveMode = !this.moveMode;
          });
      } else {
        this.$store
          .dispatch('lockRepairAssessment', { job: this.job, tool: this.tool })
          .then(() => {
            this.moveMode = !this.moveMode;
          })
          .catch((error) => {
            this.$buefy.toast.open(error.response.data.message);
          });
      }
    },
    handleDeleteAll() {
      this.$buefy.dialog.confirm({
        message: this.$t('delete_all_message'),
        onConfirm: () => {
          this.$store
            .dispatch('deleteRepairAssessment', {
              job: this.job,
              tool: this.tool,
            })
            .then((this.repairAssessment = []));
        },
      });
    },

    readRepairAssessmentFile(binary) {
      const workbook = xlsx.read(binary, {
        type: 'binary',
      });

      const workSheetJson = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );

      const headers = workSheetJson[0].map((header) => header.toLowerCase());
      workSheetJson.splice(0, 1);

      const levelLocation = headers.indexOf('level');
      const assemblyLocation = headers.indexOf('assembly');
      const itemNumberLocation = headers.indexOf('item number');
      const partNameLocation = headers.indexOf('part name');
      const partNumberLocation = headers.indexOf('part number');
      const quantityLocation = headers.indexOf('quantity');

      let validHeader = false;
      if (this.tool.includes('editor')) {
        validHeader =
          levelLocation !== -1 &&
          assemblyLocation !== -1 &&
          itemNumberLocation !== -1 &&
          partNameLocation !== -1 &&
          quantityLocation !== -1;
      } else {
        validHeader =
          levelLocation !== -1 &&
          assemblyLocation !== -1 &&
          itemNumberLocation !== -1 &&
          partNameLocation !== -1 &&
          partNumberLocation !== -1 &&
          quantityLocation !== -1;
      }

      if (!validHeader) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('repair_assessment_excel_import_failed'),
          type: 'is-danger',
        });
        return;
      }

      const repairAssessment = [];

      workSheetJson.forEach((row) => {
        const emptyRow =
          !row[levelLocation] &&
          !row[assemblyLocation] &&
          !row[itemNumberLocation] &&
          !row[partNameLocation] &&
          !row[partNumberLocation] &&
          !row[quantityLocation];
        if (!emptyRow) {
          repairAssessment.push({
            id: uuidv4(),
            level: isNaN(row[levelLocation]) ? 0 : row[levelLocation],
            assembly: row[assemblyLocation],
            itemNumber: row[itemNumberLocation],
            partName: row[partNameLocation],
            partNumber: row[partNumberLocation],
            quantity: isNaN(row[quantityLocation]) ? 0 : row[quantityLocation],
          });
        }
      });

      return repairAssessment;
    },
    handleRepairAssessmentImportAdd(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (onloadEvent) => {
        const importedRepairAssessment = this.readRepairAssessmentFile(
          onloadEvent.target.result
        );

        if (!importedRepairAssessment) {
          return;
        }

        const importedRows = [];
        importedRepairAssessment.forEach((row) => {
          importedRows.push(row);
        });

        this.$store
          .dispatch('addRepairAssessmentRow', {
            job: this.job,
            rows: importedRows,
            tool: this.tool,
          })
          .then(() => {
            this.refresh();
          });
      };
      fileReader.readAsBinaryString(file);
      // Clear the selected file so the user can reload the same file
      event.target.value = null;
    },
    handleRepairAssessmentDownload() {
      const isEditor = this.tool.includes('editor');
      const workSheetJson = [];

      // Empty template file
      if (isEditor) {
        workSheetJson.push({
          Level: '',
          Assembly: '',
          'Item Number': '',
          'Part Name': '',
          Quantity: '',
        });
      } else {
        workSheetJson.push({
          Level: '',
          Assembly: '',
          'Item Number': '',
          'Part Name': '',
          'Part Number': '',
          Quantity: '',
        });
      }

      const workbook = xlsx.utils.book_new();
      let sheetHeader;
      if (isEditor) {
        sheetHeader = {
          header: ['Level', 'Assembly', 'Item Number', 'Part Name', 'Quantity'],
        };
      } else {
        sheetHeader = {
          header: [
            'Level',
            'Assembly',
            'Item Number',
            'Part Name',
            'Part Number',
            'Quantity',
          ],
        };
      }
      const sheet = xlsx.utils.json_to_sheet(workSheetJson, sheetHeader);
      xlsx.utils.book_append_sheet(workbook, sheet, 'Repair Assessement');

      const now = new Date();
      const datetimeString = `${now.getFullYear()}${now.getMonth()}${now.getDate()}${now.getHours()}${now.getMinutes()}`;

      xlsx.writeFile(
        workbook,
        `Repair Assessment - ${this.job.title} - ${datetimeString}.xlsx`
      );
    },
    async showRepairAssessmentTemplateSearch() {
      this.isRepairAssessmentTemplateSearchVisible = true;

      const query = {
        type: 'editor',
        limit: this.limit,
        search: [this.repairAssessmentTemplateSearchString],
        repairAssessment: true,
        // lastKey: this.jobsLastKey,
      };

      await this.$store.dispatch('getJobs', query);

      this.templates = [];
      for (let i = 0; i < this.jobs.length; i++) {
        const job = this.jobs[i];
        if (job.repairAssessment.length !== 0) {
          const repairAssessment = [];
          job.repairAssessment.forEach((element) =>
            repairAssessment.push({ id: uuidv4(), ...element })
          );
          this.templates.push({
            id: job._id,
            title: job.title,
            product_line: this.getAndFormatDropValues(job.workOrderName),
            original_oem: this.getAndFormatDropValues(job.brand),
            frame_type: this.getAndFormatDropValues(job.frameType),
            model: this.getAndFormatDropValues(job.model),
            part_number: job.userEnteredPartNumber,
            location: this.getJobLocation(job),
            created_by: this.getCreatedBy(job),
            date: this.getDateString(job),
            repairAssessment,
          });
        }
      }
    },
    hideRepairAssessmentTemplateSearch() {
      this.isRepairAssessmentTemplateSearchVisible = false;
      this.selectedTemplate = null;
    },
    isTemplateSelected() {
      return this.selectedTemplate !== null;
    },
    cancelRepairAssessmentChange() {
      this.repairAssessment = [];
      if (this.job.repairAssessment) {
        this.job.repairAssessment.forEach((element) =>
          this.repairAssessment.push(element)
        );
      }
      this.$emit('close');
    },
    handleRepairAssessmentTemplateSearch(event) {
      if (event.key === 'Enter') {
        this.selectedTemplate = null;
        this.showRepairAssessmentTemplateSearch();
      }
    },
    handleImportReplace() {
      this.repairAssessment = this.selectedTemplate.repairAssessment;

      this.hideRepairAssessmentTemplateSearch();
    },
    handleImportAdd() {
      this.$store
        .dispatch('addRepairAssessmentRow', {
          job: this.job,
          rows: this.selectedTemplate.repairAssessment,
          tool: this.tool,
        })
        .then((createdRows) => {
          createdRows.forEach((row) => {
            this.repairAssessment.push(row);
          });
        });

      this.hideRepairAssessmentTemplateSearch();
    },
    close() {
      if (!this.readOnly) {
        if (!this.waitingForWrite) {
          this.$store
            .dispatch('unlockRepairAssessment', {
              job: this.job,
              tool: this.tool,
            })
            .then((repairAssessment) => {
              this.job.repairAssessment = repairAssessment;
            });
        }

        this.job.repairAssessment = this.repairAssessment;
      }
      this.$emit('close');
    },
    async addRow() {
      await this.$refs.repairAssessment.addRow(this.activeTab);
      await this.refresh();
    },
    getAndFormatDropValues(dropType) {
      let namesFormated = [];
      if (typeof dropType === 'undefined' || dropType === null) return '';
      if (typeof dropType !== 'string' && dropType.length > 1) {
        dropType.forEach((type) => {
          namesFormated.push(type.text);
        });
      } else if (typeof dropType === 'string') {
        namesFormated = dropType;
      } else if (typeof dropType[0] !== 'undefined') {
        namesFormated = dropType[0].text;
      } else {
        namesFormated = '';
      }
      if (typeof namesFormated === 'string') {
        return namesFormated;
      }
      if (Array.isArray(namesFormated)) {
        return namesFormated.join(', ');
      }
      return '';
    },
    getJobLocation(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser.location) {
        const { location } = job.prodCreatedByUser;
        if (typeof location === 'object') {
          return job.prodCreatedByUser.location.siteName;
        }
        return this.$store.getters.siteName(location);
      }
      return '';
    },
    getCreatedBy(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser.fullname) {
        return job.prodCreatedByUser.fullname;
      }
      if (job.modifier && job.modifier.fullname) {
        return job.modifier.fullname;
      }
      return '';
    },
    getDateString(job) {
      let { date } = job;
      const { prodCreatedByUser } = job;
      if (!date) {
        date = prodCreatedByUser.timestamp;
      }
      if (
        !date ||
        (typeof date !== 'number' && Object.keys(date).length === 0)
      ) {
        date = new Date().getTime();
      }
      const dateObject = new Date(parseFloat(date));
      const dateString = `${
        dateObject.getMonth() + 1
      }-${dateObject.getDate()}-${dateObject.getFullYear()}`;

      return dateString;
    },
  },
};
</script>

<style lang="scss" scoped>
.b-tabs {
  width: 100% !important;
  height: 100% !important;
}

.b-tab-item-scroll {
  height: 100%;
}

.flex-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

::v-deep div.animation-content.modal-content {
  display: flex;
  flex-direction: column;
}
section.modal-card-body {
  overflow: hidden;
}
.upper-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.dij-ra-details {
  max-height: 30vh;
  overflow-y: auto;
  background-color: rgb(240, 240, 240);
}
.dij-ra-table-loading {
  position: relative;
  height: 100px;
}
.dij-ra-content {
  margin-left: 10px;
  margin-right: 10px;
  height: calc(100% - 50px);
}
.dij-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
// hide modal close button
::v-deep button.modal-close.is-large {
  display: none;
}
// Remove gap between button and dropdown
::v-deep div.dropdown-trigger {
  height: unset;
}
// Set max width and correct font-size
div.dij-modal-dynamic-width {
  font-size: 16px;
  ::v-deep div.animation-content {
    max-width: 100%;
  }
}
// Set max heigth
section.modal-card-body {
  max-height: 80vh;
  height: 80vh;
}
.dij-ra-right-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-card-head {
  display: flex;
  justify-content: space-between;
}
.dij-ra-status {
  display: flex;
  gap: 10px;

  .dij-ra-save-status {
    border-radius: 0;
    cursor: unset;
    background: white;
    color: green;
  }
}
</style>
<style>
.tab-content {
  height: 100% !important;
}
</style>
