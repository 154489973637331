/* eslint-disable no-shadow */ /* eslint-disable eqeqeq */
<template>
  <div class="container">
    <b-notification
      auto-close
      type="is-success"
      :active.sync="showSuccess"
      :duration="3000"
      aria-close-label="Close notification"
    >
      {{ successMessage }}
    </b-notification>
    <b-notification
      auto-close
      type="is-danger"
      :active.sync="showFail"
      :duration="5000"
      aria-close-label="Close notification"
    >
      {{ failedMessage }}
    </b-notification>
    <div class="close-form" @click="cancelForm">
      <img src="/img/closebtn.png" />
    </div>
    <div class="form-container">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <h4>Edit Role</h4>

      <div class="rolename-field">
        <b-field label="New role name">
          <b-input label="New Role Name" v-model="role.rolename"></b-input>
        </b-field>
      </div>

      <div class="select-tool-container">
        <h1 class="select-tools-title">Select Software Tools</h1>
        <ul class="tools">
          <div class="tools-list">
            <li v-for="tool in tools" :key="tool">
              <div class="cards-list">
                <div
                  :class="
                    selectedTools.includes(tool)
                      ? 'tool-card selected-tool'
                      : 'tool-card'
                  "
                  @click="handleSelectTool(tool)"
                >
                  <b-icon
                    v-if="selectedTools.includes(tool)"
                    class="check-icon"
                    icon="check"
                  ></b-icon>

                  <p
                    :class="
                      selectedTools.includes(tool)
                        ? 'tool-title selected-tool-title'
                        : 'tool-title'
                    "
                  >
                    {{ tool }}
                  </p>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>

      <div class="buttons-container">
        <b-button @click="deleteRole" outlined type="is-danger"
          >Delete</b-button
        >
        <b-button
          @click="this.updateRole"
          type="is-info"
          style="margin-left: 8px"
          >Update</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'edit-role',
  props: {
    role: Object,
  },
  data() {
    return {
      rolenameInvalid: false,
      selectedTools: [],
      showSuccess: false,
      showFail: false,
      isLoading: false,
      rolename: '',
      tools: ['admin', 'editor', 'publisher', 'inspector', 'report'],
      successMessage: 'Role updated successfully.',
      failedMessage: 'Failed to update role',
    };
  },

  computed: {
    roles() {
      return this.$store.state.userRoles;
    },
  },

  async mounted() {
    const parsedRoles = JSON.parse(JSON.stringify(this.roles));
    if (Object.keys(parsedRoles).length < 1) {
      await this.$store.dispatch('getUserRoles');
    }
    if (this.role.toollist) {
      this.selectedTools.push(
        ...this.role.toollist.split(',').map((item) => item.trim())
      );
    }
  },

  methods: {
    async updateRole() {
      try {
        this.role.toollist = this.selectedTools;

        await this.$store.dispatch('updateUserRole', this.role);

        this.showSuccess = true;
        setTimeout(() => {
          this.$router.push({
            name: 'admin-root',
          });
        }, 3000);
      } catch (err) {
        console.error(err);
        this.showFail = true;
      }
    },
    async deleteRole() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this role?',
        type: 'is-danger',
        hasIcon: true,
        icon: 'delete',
        confirmText: 'Delete',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('deleteUserRole', this.role);

            this.successMessage = 'Role deleted successfully.';
            this.showSuccess = true;
            setTimeout(() => {
              this.$router.push({
                name: 'admin-root',
              });
            }, 3000);
          } catch (err) {
            console.error(err);
            this.failedMessage = 'Failed to delete role.';
            this.showFail = true;
          }
        },
      });
    },
    cancelForm() {
      this.$router.push({
        name: 'admin-root',
      });
    },

    handleCancel() {
      if (this.step === 1) {
        this.step = 0;
      } else {
        this.cancelForm();
      }
    },

    handleSelectTool(tool) {
      if (this.selectedTools.includes(tool)) {
        const index = this.selectedTools.findIndex((item) => item === tool);
        this.selectedTools.splice(index, 1);
      } else {
        this.selectedTools.push(tool);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button.is-danger.is-outlined,
.button.is-info {
  width: 100px;
}
.rolename-field,
.select-tools-title {
  margin-top: 20px;
}

.rolename-field {
  width: 50%;
}

.select-tools-title {
  font-size: 16px;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
}
.form-container {
  margin: 8px auto;
  width: 100%;
  overflow: auto;
  padding: 10px;

  .label {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }

  [placeholder] {
    color: #777;
    font-size: 16px;
    font-family: 'Siemens Sans';
  }
}
.buttons-container {
  // margin-bottom: 5%;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 5%;
}
.cancel-button {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;
}
.cancel-button:hover {
  cursor: pointer;
}
.submit-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #4a86e8;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.submit-button:hover {
  cursor: pointer;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.tool-card {
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 120px;
  height: 55px;
  overflow: auto;
  margin: 0 14px 14px 0;
  border: 1px solid $standard-background-color;
  border-radius: 4px;
  color: $standard-background-color;
  cursor: pointer;

  .tool-title {
    overflow: auto;
    text-align: center;
    // line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    // margin-top: 18px;
  }
}
.selected-tool {
  border: 2px solid $icon-active !important;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.check-icon {
  color: $icon-active;
  margin: 5px;
}

.selected-tool-title {
  margin-top: 2%;
  color: $icon-active !important;
}

.tools-list {
  display: flex;
  margin-top: 1%;
}
</style>
