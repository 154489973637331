<template>
  <div>
    <repair-assessment-detail-dialog
      tool="report"
      :readOnly="false"
      v-if="repairAssessmentVisible"
      @close="hideRepairAssessment"
    />
    <button class="dij-ra-polymer-show" @click="showRepairAssessment" />
    <button class="dij-ra-polymer-hide" @click="hideRepairAssessment" />
    <button class="dij-ra-polymer-export" @click="exportRepairAssessment" />
    <tool-template tool="report" />
  </div>
</template>

<script>
import * as xlsx from 'xlsx';
import ToolTemplate from '../../components/ToolsTemplates/Template';
import RepairAssessmentDetailDialog from '../../components/layout/RepairAssessmentDetailDialog';

export default {
  name: 'report-template',
  components: {
    'tool-template': ToolTemplate,
    RepairAssessmentDetailDialog,
  },
  data() {
    return {
      repairAssessmentVisible: false,
    };
  },
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
  },
  methods: {
    showRepairAssessment() {
      this.repairAssessmentVisible = true;
    },
    hideRepairAssessment() {
      this.repairAssessmentVisible = false;

      const event = new Event('ra-update');
      document.dispatchEvent(event);
    },
    exportRepairAssessment() {
      const workSheetJson = [];

      const { repairAssessment } = this.job;

      repairAssessment.forEach((row) => {
        workSheetJson.push({
          Level: row.level,
          Assembly: row.assembly,
          'Item Number': row.itemNumber,
          'Part Name': row.partName,
          'Part Number': row.partNumber,
          Quantity: row.quantity,
          Visual: row.visual,
          Dim: row.dim,
          NDT: row.ndt,
          Reuse: row.reuse,
          Repair: row.repair,
          Replace: row.replace,
          Missing: row.missing,
          Received: row.received,
          Summary: row.summary,
          'Repair Scope': row.repairScope,
          'Estimated Hours': row.estimatedLabor,
          'Eng. Review': row.reviewed,
          'Client Rejected': row.clientReject,
          QC: this.getQuality(row),
        });
      });

      const workbook = xlsx.utils.book_new();
      const sheetHeader = {
        header: [
          'Level',
          'Assembly',
          'Item Number',
          'Part Name',
          'Part Number',
          'Quantity',
          'Visual',
          'Dim',
          'NDT',
          'Reuse',
          'Repair',
          'Replace',
          'Missing',
          'Received',
          'Summary',
          'Repair Scope',
          'Estimated Hours',
          'Eng. Review',
          'Client Rejected',
          'QC',
        ],
      };
      const sheet = xlsx.utils.json_to_sheet(workSheetJson, sheetHeader);
      xlsx.utils.book_append_sheet(workbook, sheet, 'Repair Assessement');

      const now = new Date();
      const datetimeString = `${now.getFullYear()}${now.getMonth()}${now.getDate()}${now.getHours()}${now.getMinutes()}`;

      xlsx.writeFile(
        workbook,
        `Repair Assessment - ${this.job.title} - ${datetimeString}.xlsx`
      );
    },
    shouldShowQuality(item) {
      return (
        (item.reviewed &&
          item.reuse + item.repair + item.replace + item.missing !== 0) ||
        item.qualityControlOverride
      );
    },

    isQualityGreen(item) {
      return (
        item.qualityControlOverride ||
        item.reuse === item.quantity ||
        (item.replace > 0 && item.received + item.reuse >= item.quantity)
      );
    },

    isQualityYellow(item) {
      return (
        !item.qualityControlOverride &&
        (!item.quantity ||
          item.quantity === 0 ||
          item.repair > 0 ||
          item.missing > 0 ||
          item.clientReject)
      );
    },

    getQuality(item) {
      if (this.shouldShowQuality(item)) {
        if (this.isQualityYellow(item)) {
          return 'yellow';
        }
        if (this.isQualityGreen(item)) {
          return 'green';
        }
        return 'red';
      }
      return 'N/A';
    },
  },
};
</script>

<style>
#dropdown {
  background: #ffffff;
  position: fixed;
  z-index: 103;
}

#zoomToolWrapper.input-report,
.zoomBubble.input-report {
  z-index: 4 !important;
}

div.modal.dij-ra-popup {
  display: none;
}
</style>
