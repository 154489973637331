import client from '../../../config/api';
import {
  LOCAL_SET_ISO_NAMES,
  LOCAL_SET_ISO_GRADES,
  LOCAL_SET_ISO_VALUES,
  LOCAL_CLEAR_ISO_GRADES,
} from '../mutations/mutationTypes';

export default {
  async GET_ISO_NAMES_ACTION({ commit }) {
    const result = await client.get('iso/name');
    commit(LOCAL_SET_ISO_NAMES, result.data);
  },
  async GET_ISO_GRADES_ACTION({ commit }, payload) {
    const result = await client.get(`iso/grade/${payload}`);
    commit(LOCAL_SET_ISO_GRADES, result.data);
  },
  async GET_ISO_VALUES_ACTION({ commit }, payload) {
    const result = await client.get('iso/values', { params: payload });
    commit(LOCAL_SET_ISO_VALUES, result.data);
  },
  async CLEAR_ISO_GRADES_ACTION({ commit }) {
    commit(LOCAL_CLEAR_ISO_GRADES);
  },
};
