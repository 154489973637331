<template>
  <create-folder :tool="module" />
</template>

<script>
import CreateFolder from '../ToolsTemplates/CreateFolder';

export default {
  components: {
    CreateFolder,
  },
  props: ['module'],
};
</script>
