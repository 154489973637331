/* eslint-disable no-shadow */ /* eslint-disable eqeqeq */
<template>
  <div class="container">
    <b-notification
      auto-close
      type="is-success"
      :active.sync="showSuccess"
      :duration="3000"
      aria-close-label="Close notification"
    >
      {{ successMessage }}
    </b-notification>
    <b-notification
      auto-close
      type="is-danger"
      :active.sync="showFail"
      :duration="5000"
      aria-close-label="Close notification"
    >
      {{ failedMessage }}
    </b-notification>
    <div class="form-container">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <h4>Create New Role</h4>

      <div class="rolename-field">
        <b-field label="New role name" v-if="step === 0">
          <!-- <label>New Role Name</label> -->
          <b-input
            :type="rolenameInvalid ? 'is-danger' : ''"
            required
            label="New Role Name"
            v-model="rolename"
          ></b-input>
        </b-field>
      </div>

      <div class="select-tool-container" v-if="step === 1">
        <h1 class="select-tools-title">Select Software Tools</h1>
        <ul class="tools">
          <div class="tools-list">
            <li v-for="tool in tools" :key="tool">
              <div class="cards-list">
                <div
                  :class="
                    selectedTools.includes(tool)
                      ? 'tool-card selected-tool'
                      : 'tool-card'
                  "
                  @click="handleSelectTool(tool)"
                >
                  <b-icon
                    v-if="selectedTools.includes(tool)"
                    class="check-icon"
                    icon="check"
                  ></b-icon>

                  <p
                    :class="
                      selectedTools.includes(tool)
                        ? 'tool-title selected-tool-title'
                        : 'tool-title'
                    "
                  >
                    {{ tool }}
                  </p>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>

      <div class="buttons-container">
        <button class="cancel-button" v-on:click="handleCancel">
          <b-icon
            v-if="step === 1"
            :icon="this.step === 1 ? 'arrow-left' : ''"
            size="is-small"
          ></b-icon>
          {{ this.step === 0 ? $t('cancel') : 'Back' }}
        </button>

        <button class="submit-button" v-on:click="handleStep">
          <b-icon
            v-if="step === 0"
            :icon="this.step === 0 ? 'arrow-right' : ''"
            size="is-small"
          ></b-icon>

          {{ this.step === 0 ? 'Next' : 'Create Role' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'create-role',
  data() {
    return {
      rolenameInvalid: false,
      selectedTools: [],
      step: 0,
      showSuccess: false,
      showFail: false,
      isLoading: false,
      rolename: '',
      tools: [
        'Admin Tool',
        'Editor Tool',
        'Publisher Tool',
        'Inspector Tool',
        'Report Tool',
      ],
      successMessage: 'Role created successfully.',
      failedMessage: 'Failed to create site. Please, try again.',
    };
  },

  methods: {
    cancelForm() {
      this.$router.push({
        name: 'admin-root',
      });
    },
    async handleStep() {
      if (this.step === 0 && this.rolename !== '') {
        this.step += 1;
      } else if (this.step === 0 && this.rolename === '') {
        this.rolenameInvalid = true;
      } else {
        const newRole = {
          toollist: this.selectedTools,
          rolename: this.rolename,
        };

        if (this.fieldsAreValid()) {
          try {
            // Using name as default (lowercased)
            newRole.toollist.forEach((tool, index) => {
              [newRole.toollist[index]] = tool.toLowerCase().split(' tool');
            });

            await this.$store.dispatch('createUserRole', newRole);

            this.successMessage = 'Role was successfully created.';
            this.showSuccess = true;
            setTimeout(() => {
              this.$router.push({
                name: 'admin-root',
              });
            }, 3000);
          } catch (err) {
            this.failedMessage = 'Role creation failed.';
            this.showFail = true;
          }
        } else {
          this.failedMessage = 'Please, fill in all the required the fields.';
          this.showFail = true;
        }
      }
    },

    fieldsAreValid() {
      const fields = [this.rolename, this.selectedTools];
      console.log(
        fields.some((field) => field === '' || !field || field.lenght < 1),
        this.rolename,
        this.selectedTools
      );

      if (fields.some((field) => field === '' || !field || field.lenght < 1))
        return false;
      return true;
    },

    handleCancel() {
      if (this.step === 1) {
        this.step = 0;
      } else {
        this.cancelForm();
      }
    },

    handleSelectTool(tool) {
      if (this.selectedTools.includes(tool)) {
        const index = this.selectedTools.findIndex((item) => item === tool);
        this.selectedTools.splice(index, 1);
      } else {
        this.selectedTools.push(tool);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rolename-field,
.select-tools-title {
  margin-top: 20px;
}

.rolename-field {
  width: 50%;
}

.select-tools-title {
  font-size: 16px;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
}
.form-container {
  margin: 8px auto;
  width: 100%;
  overflow: auto;
  padding: 10px;

  .label {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }

  [placeholder] {
    color: #777;
    font-size: 16px;
    font-family: 'Siemens Sans';
  }
}
.buttons-container {
  // margin-bottom: 5%;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 5%;
}
.cancel-button {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;
}
.cancel-button:hover {
  cursor: pointer;
}
.submit-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #4a86e8;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.submit-button:hover {
  cursor: pointer;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.tool-card {
  text-align: center;
  line-height: 120px;
  margin: auto;
  width: 136px;
  height: 136px;
  overflow: auto;
  margin: 0 14px 14px 0;
  border: 1px solid $standard-background-color;
  border-radius: 4px;
  color: $standard-background-color;
  cursor: pointer;

  .tool-title {
    overflow: auto;
    text-align: center;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    margin-top: 18px;
  }
}
.selected-tool {
  border: 2px solid $icon-active !important;
  display: flex;
  flex-direction: column;
}

.check-icon {
  color: $icon-active;
  margin: 5px;
}

.selected-tool-title {
  margin-top: 20%;
  color: $icon-active !important;
}

.tools-list {
  display: flex;
  margin-top: 5%;
}
</style>
