<template>
  <div class="dij-ab">
    <div class="dij-ab-header">
      <div class="dij-ab-header-title">
        {{ $t('announcement_banner.title') }}
      </div>
      {{ $t('announcement_banner.description') }}
    </div>
    <div>
      <b-field :label="$t('announcement_banner.status')">
        <b-tag v-if="isMessageEnabled" type="is-success">{{
          $t('announcement_banner.enabled_status')
        }}</b-tag>
        <b-tag v-else type="is-danger">{{
          $t('announcement_banner.disabled_status')
        }}</b-tag>
      </b-field>
      <b-field :label="$t('announcement_banner.message')">
        <div class="dij-message-container">{{ message }}</div>
      </b-field>
      <b-field grouped>
        <b-field :label="$t('announcement_banner.start_date')">
          <b-datetimepicker
            v-model="startDate"
            rounded
            icon="calendar-today"
            icon-right-clickable
            horizontal-time-picker
          />
        </b-field>
        <b-field
          :label="$t('announcement_banner.end_date')"
          :message="
            isEndDateInThePast ? $t('announcement_banner.end_date_error') : ''
          "
          :type="isEndDateInThePast ? 'is-danger' : ''"
        >
          <b-datetimepicker
            v-model="endDate"
            rounded
            icon="calendar-today"
            icon-right-clickable
            horizontal-time-picker
          />
        </b-field>
      </b-field>
    </div>
    <div class="dij-ab-footer">
      <b-button @click="clear()">{{
        $t('announcement_banner.clear_action')
      }}</b-button>
      <b-button @click="preview()">{{
        $t('announcement_banner.preview_action')
      }}</b-button>
      <b-button
        @click="save()"
        type="is-primary"
        :disabled="isEndDateInThePast"
        >{{ $t('announcement_banner.save_action') }}</b-button
      >
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  data() {
    return {
      message: '',
      startDate: null,
      endDate: null,
      isMessageEnabled: false,
      messageComponent: null,
    };
  },
  computed: {
    isEndDateInThePast() {
      return this.endDate < new Date();
    },
  },
  async created() {
    const messageData = await this.$store.dispatch(
      'getAnnouncementBanner',
      true
    );
    const now = new Date();
    this.message = messageData.message;
    if (this.message) {
      document.querySelector('div.ql-editor').innerHTML = this.message;
    }
    if (messageData.startDate) {
      this.startDate = messageData.startDate;
    } else {
      this.startDate = now;
    }
    if (messageData.endDate) {
      this.endDate = messageData.endDate;
    } else {
      this.endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1
      );
    }
    this.isMessageEnabled =
      this.message && this.startDate < now && this.endDate > now;
  },
  mounted() {
    this.messageComponent = new Quill('.dij-message-container', {
      modules: {
        toolbar: [['bold', 'italic', 'underline']],
      },
      theme: 'snow', // or 'bubble'
    });
  },
  methods: {
    clear() {
      this.$buefy.dialog.confirm({
        message: this.$t('announcement_banner.clear_question'),
        type: 'is-warning',
        hasIcon: true,
        icon: 'warning',
        confirmText: this.$t('announcement_banner.clear_action'),
        onConfirm: async () => {
          this.message = '';
          document.querySelector('div.ql-editor').innerHTML = '';
          this.messageStartDate = '';
          this.messageEndDate = '';
          this.$store.dispatch('saveAnnouncementBanner', {
            message: this.message,
            startDate: this.startDate,
            endDate: this.endDate,
          });
          this.isMessageEnabled = false;
        },
      });
    },
    preview() {
      this.message = document.querySelector('div.ql-editor').innerHTML;
      this.$store.dispatch('previewAnnouncementBanner', {
        message: this.message,
      });
    },
    save() {
      this.message = document.querySelector('div.ql-editor').innerHTML;
      this.$store.dispatch('saveAnnouncementBanner', {
        message: this.message,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      const now = new Date();
      this.isMessageEnabled =
        this.message !== '' && this.startDate < now && this.endDate > now;
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-ab {
  width: 800px;
  margin: 20px auto;
}
.dij-ab-header {
  margin-bottom: 20px;
}
.dij-ab-header-title {
  font-weight: bold;
  font-size: 20px;
}
.dij-ab-status-label ::v-deep .label {
  display: flex;
  gap: 10px;
}
.dij-ab-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.dij-message-container {
  background-color: white;
}
</style>
