import View from '../components/Folders/View';
import Create from '../components/Folders/Create';

const folderRoutes = [
  {
    path: '/view/:module/:id/folder/:folderId',
    name: 'folder-view',
    component: View,
    meta: { authRequired: true },
    props: true,
  },
  {
    path: '/view/:module/:id/folder/:folderId/:subfolderId',
    name: 'sub-folders-view',
    component: View,
    meta: { authRequired: true },
    props: true,
  },
  {
    path: '/view/:module/:id/create/folder',
    name: 'create-folder',
    component: Create,
    meta: { authRequired: true },
    props: true,
  },
  {
    path: '/view/:module/:id/create/folder/:folderId',
    name: 'create-subfolder',
    component: Create,
    meta: { authRequired: true },
    props: true,
  },
];

export default folderRoutes;
