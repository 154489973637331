<template>
  <InstructionViewPublisher
    v-if="isEditorOrPublisher"
    ref="instructionViewPublisher"
    :task="task"
  />
  <InstructionViewInspector
    v-else
    ref="instructionViewInspector"
    :task="task"
  />
</template>

<script>
import InstructionViewPublisher from './InstructionViewPublisher';
import InstructionViewInspector from './InstructionViewInspector';

export default {
  components: {
    InstructionViewPublisher,
    InstructionViewInspector,
  },
  computed: {
    isEditorOrPublisher() {
      return this.viewType === 'publisher' || this.viewType === 'editor';
    },
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  methods: {
    onSave() {
      if (this.isEditorOrPublisher) {
        this.$refs.instructionViewPublisher.forceSave();
      } else {
        this.$refs.instructionViewInspector.forceSave();
      }
    },
  },
};
</script>
