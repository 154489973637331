<template>
  <div v-if="invalidToken">
    <forbidden-page></forbidden-page>
  </div>
  <div v-else>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
      class="loading"
    ></b-loading>
    <div v-if="showTemplate">
      <div v-if="showPolymerTemplate">
        <!-- <common-links />
        <instruction-links />
        <diagram-links />
        <report-links /> -->
        <!-- <template v-if="tool === 'report'">
          <instruction-links />
          <diagram-links />
          <report-links />
        </template>
        <template v-else>
          <instruction-links v-if="task.taskType === 'instruction'" />
          <diagram-links v-if="task.taskType === 'diagram'" />
        </template> -->
        <div id="templateContainer"></div>
      </div>
      <table-template :tool="tool" v-if="showTableTemplate" />
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import ForbbidenPage from '../layout/ForbiddenPage';
import TableTemplate from './TableTemplate';
import langs from '../../../public/lang';

export default {
  name: 'tool-template',
  components: {
    'forbidden-page': ForbbidenPage,
    'table-template': TableTemplate,
  },
  data() {
    return {
      isLoading: true,
      tokenData: null,
      readOnly: false,
      invalidToken: false,
      showTemplate: true,
      showPolymerTemplate: false,
      showTableTemplate: false,
      job: {},
      arrayOfIds: [],
    };
  },
  props: {
    tool: String,
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    currentJob() {
      return this.$store.state.currentJob;
    },
    task() {
      return this.$store.state.task;
    },
    tasks() {
      return this.$store.state.tasks;
    },
    sites() {
      return this.$store.state.sites;
    },
  },
  async mounted() {
    this.setLangs();
    const { id, taskNumber } = this.$route.params;

    if (this.tool === 'report' && id.includes('&&')) {
      // If id has '&&' then it's more than one workOrder
      // Then get all the data from the workOrders and tasks
      // transform into array
      this.arrayOfIds = id.split('&&');

      await this.$store.dispatch('getJob', this.arrayOfIds[0]);
      this.job = this.currentJob;

      if (!this.job.childConfig || this.job.childConfig.length === 0) {
        this.job.childConfig[0] = {};
      }
      this.job.childConfig[0].childConfig = [];

      this.arrayOfIds.forEach(async (jobId) => {
        const tasks = await this.$store.dispatch('getJobTasks', {
          return: true,
          jobId,
          allTasks: true,
        });

        this.job.childConfig[0].childConfigHierarchy =
          this.buildTaskHierarchy(tasks);
        this.job.childConfig[0].childConfig = this.filterNonFolders(
          this.job.childConfig[0].childConfigHierarchy
        );
      });
    } else {
      await this.$store.dispatch('getJob', id);

      this.job = this.currentJob;
      if (this.job.type === 'externalProduct') {
        this.job.type = 'productLibrary';
      } else if (this.job.type === 'externalWorkOrder') {
        this.job.type = 'workViewerLibrary';
      }
      if (!this.job.childConfig || this.job.childConfig.length === 0) {
        this.job.childConfig[0] = {};
      }
      if (taskNumber) {
        await this.$store.dispatch('getTask', taskNumber);

        this.job.childConfig[0].childConfig = [this.task];

        if (this.task?.taskType === 'table') {
          this.showTableTemplate = true;
          return true;
        }
      } else if (this.tool.includes('report')) {
        await this.$store.dispatch('getJobTasks', {
          jobId: id,
          allTasks: true,
        });

        this.job.childConfig[0].childConfigHierarchy = this.buildTaskHierarchy(
          this.tasks
        );
        this.job.childConfig[0].childConfig = this.filterNonFolders(
          this.job.childConfig[0].childConfigHierarchy
        );
      }
    }
    this.showPolymerTemplate = true;
    // only set the task after vue component is fully rendered
    this.setLoadingEvent();

    // eslint-disable-next-line func-names
    this.$nextTick(async function () {
      this.setTask();
      this.checkCookie();

      const { token } = this.$route.query;

      if (token) {
        this.tokenData = await jwt_decode(token, process.env.JWT_SECRET);
        this.readOnly = this.tokenData.action === 'view';

        await this.checkToken(token);
        await this.hideIfToken();
        this.showTemplate = true;

        return true;
      }
    });
  },
  methods: {
    filterNonFolders(hierarchy) {
      const nonFolders = [];
      let index = 0;

      const traverseHierarchy = (task) => {
        if (task.taskType !== 'folder') {
          task.index = index++;
          nonFolders.push(task);
        }
        task.children.forEach((child) => {
          traverseHierarchy(child);
        });
      };

      hierarchy.forEach((task) => {
        traverseHierarchy(task);
      });

      return nonFolders;
    },
    buildTaskHierarchy(tasks) {
      const tasksMap = new Map();
      const hierarchy = [];

      // Sort tasks based on taskType and folderId
      tasks.sort((a, b) => {
        if (a.folderId && !b.folderId) {
          return 1; // Folder comes after root task
        }
        if (!a.folderId && b.folderId) {
          return -1; // Root task comes before folder
        }
        return 0; // Maintain the original order
      });

      tasks.forEach((task) => {
        task.children = [];
        tasksMap.set(task._id, task);
      });

      // Traverse the tasks and assign children to their parent folders
      tasks.forEach((task) => {
        if (task.folderId) {
          const parentTask = tasksMap.get(task.folderId);
          if (parentTask) {
            parentTask.children.push(task);
          }
        } else {
          hierarchy.push(task); // Task is at the root level
        }
      });

      return hierarchy;
    },

    setLangs() {
      document.languages = langs;
    },

    checkCookie() {
      if (this.$cookies.isKey('inspectorReadOnly') && !this.readOnly) {
        this.$cookies.remove('inspectorReadOnly');
      }
      if (this.$cookies.isKey('editorReadOnly') && !this.readOnly) {
        this.$cookies.remove('editorReadOnly');
      }
    },
    setTask() {
      if (Array.isArray(this.arrayOfIds) && this.arrayOfIds.length > 0) {
        if (!this.job.childConfig || this.job.childConfig.length === 0) {
          this.job.childConfig[0] = {};
        }

        const container = document.getElementById('templateContainer');
        const loginSession = document.createElement('login-session');
        const notifications = document.createElement('notification-messages');
        loginSession.user = this.user;
        loginSession.hidden = true;

        container.appendChild(loginSession);
        container.appendChild(notifications);

        const item = document.createElement('emf-productlist-item');

        container.appendChild(item);
        item.job = this.job;

        if (
          this.tool.includes('inspector') &&
          item.job.type !== 'workViewerLibrary'
        ) {
          item.job.type = 'workViewerLibrary';
        } else if (
          this.tool.includes('publisher') &&
          item.job.type !== 'workOrderLibrary'
        ) {
          item.job.type = 'workOrderLibrary';
        } else if (
          this.tool.includes('report') &&
          item.job.type !== 'reportLibrary'
        ) {
          item.job.type = 'reportLibrary';
        }
        // item.style.opacity = 0;
      } else {
        const { job } = this;
        const container = document.getElementById('templateContainer');
        const loginSession = document.createElement('login-session');
        const notifications = document.createElement('notification-messages');
        loginSession.user = this.user;
        loginSession.hidden = true;

        container.appendChild(loginSession);
        container.appendChild(notifications);

        if (this.tool === 'inspector') {
          const cameraApp = document.createElement('custom-camera-app');
          container.appendChild(cameraApp);
        }

        const item = document.createElement('emf-productlist-item');

        container.appendChild(item);
        item.job = job;

        if (
          this.tool.includes('inspector') &&
          item.job.type !== 'workViewerLibrary'
        ) {
          item.job.type = 'workViewerLibrary';
        } else if (
          this.tool.includes('publisher') &&
          item.job.type !== 'workOrderLibrary'
        ) {
          item.job.type = 'workOrderLibrary';
        } else if (
          this.tool.includes('report') &&
          item.job.type !== 'reportLibrary'
        ) {
          item.job.type = 'reportLibrary';
        }
        // item.style.opacity = 0;
      }
    },
    setLoadingEvent() {
      const myself = this;
      // listen to the new created event
      document.addEventListener(
        'vueLoading',
        // eslint-disable-next-line func-names
        function () {
          myself.isLoading = false;

          const event = new Event('templateLoaded');
          document.dispatchEvent(event);
        },
        false
      );
    },

    async checkToken(token) {
      try {
        this.tokenData = await jwt_decode(token, process.env.JWT_SECRET);
        this.readOnly = this.tokenData.action === 'view';
        if (
          (this.job?.approvalStatus === 'requested' ||
            this.job?.approvalStatus === 'approved') &&
          this?.tokenData?.tool !== 'inspector'
        ) {
          this.readOnly = true;
        }

        const currentTime = Date.now() / 1000;

        if (currentTime > this.tokenData.exp) {
          this.invalidToken = true;
        }

        if (this.tool === 'editor' && this.job.type !== 'productLibrary') {
          this.$router.push({
            name: '404',
          });
        }

        if (
          (this.tool === 'publisher' || this.tool === 'inspector') &&
          this.job.type !== 'workViewerLibrary' &&
          this.job.type !== 'workOrderLibrary'
        ) {
          this.$router.push({
            name: '404',
          });
        }
      } catch (err) {
        this.invalidToken = true;
      }
    },

    async hideIfToken() {
      if (!this.readOnly) {
        document.addEventListener(
          'templateLoaded',
          // eslint-disable-next-line func-names
          function () {
            const userNameDropdownElement =
              this.getElementsByClassName('dropdown-wrapper');
            const gotoProductListButton = this.getElementById('gotoProductlist')
              ? this.getElementById('gotoProductlist')
              : this.getElementById('goToProductlist');
            const elementsToHide = this.getElementsByClassName(
              'header-inner headerTool'
            );
            const goToTaskListElement = this.getElementById('goToTasklist');

            if (goToTaskListElement) goToTaskListElement.style.display = 'none';
            if (userNameDropdownElement[0])
              userNameDropdownElement[0].style.display = 'none';
            if (gotoProductListButton)
              gotoProductListButton.style.display = 'none';
            elementsToHide.forEach((element) => {
              element.style.display = 'none';
            });
          },
          false
        );
      }

      if (this.readOnly) {
        if (this.tool === 'inspector') {
          this.$cookies.set('inspectorReadOnly', true);
        } else if (this.tool === 'editor' || this.tool === 'publisher') {
          this.$cookies.set('editorReadOnly', true);
        }

        document.addEventListener(
          'templateLoaded',
          // eslint-disable-next-line func-names
          function () {
            const cancelButton = document.getElementById('cancelbutton');
            const saveButton = document.getElementById('saveButton');
            const sidebar = document.getElementById('sidebar');
            const deleteButton =
              document.getElementsByClassName('deleteButtonClass');
            const deleteButtonInstruction =
              document.getElementsByClassName('deleteButton');
            const addImageOption = document.getElementById('addImageOption');

            const orderButton =
              document.getElementsByClassName('orderButtonElement');
            const goToTaskListElement = this.getElementById('goToTasklist');
            /* INSPECTOR */
            const inspectorAddBtnElement =
              this.getElementById('inspectorAddBtn');

            const signOffBtn = this.getElementById('signOffBtn');
            /* INSPECTOR */

            const gotoProductListButton =
              this.getElementById('gotoProductlist') ||
              this.getElementById('goToProductlist');

            // prevent clicking on instruction tasks and diagram
            // hide buttons and actions
            if (inspectorAddBtnElement)
              inspectorAddBtnElement.style.display = 'none';
            if (goToTaskListElement) goToTaskListElement.style.display = 'none';
            if (deleteButton[0]) deleteButton[0].style.display = 'none';
            if (deleteButtonInstruction[0])
              deleteButtonInstruction[0].style.display = 'none';
            if (cancelButton) cancelButton.style = 'display: none';
            if (saveButton) saveButton.style = 'display: none';
            if (sidebar) sidebar.style = 'display: none';
            if (gotoProductListButton)
              gotoProductListButton.style.display = 'none';
            if (addImageOption) addImageOption.style.display = 'none';
            if (orderButton[0]) orderButton[0].style.display = 'none';
            if (signOffBtn) signOffBtn.style.display = 'none';
          },
          false
        );
      }
    },
  },
};
</script>

<style lang="css">
emf-tasklist {
  top: 50px !important;
}

emf-reportlist {
  top: 50px !important;
  position: fixed !important;
  height: 95% !important;
}

emf-reportlist .container {
  max-width: inherit !important;
}

emf-reportlist .title {
  margin-bottom: 0 !important;
}

emf-diagrambuilder {
  position: absolute !important;
}
canvas {
  max-width: unset !important;
}
pre {
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
}
.loading {
  background-color: white;
  top: 50px !important;
}
</style>
