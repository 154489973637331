<template>
  <div class="edit-user-container">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-notification
      v-if="user.isPendingApproval"
      class="pending-approval-notification"
      type="is-info"
      :closable="false"
    >
      {{ $t('edit_user_profile.pending_approval_message') }}
    </b-notification>
    <b-notification
      v-if="user.isRejected"
      class="pending-approval-notification"
      type="is-info"
      :closable="false"
    >
      {{ $t('edit_user_profile.rejected_message') }}
    </b-notification>

    <h4>{{ $t('edit_user_profile.title') }}</h4>

    <div class="edit-user-form-container">
      <!-- <div class="column"> -->
      <!-- </div> -->
      <div class="column">
        <div class="row">
          <img class="avatar" src="/img/icons/user.png" />
        </div>
        <b-field :label="$t('edit_user_profile.full_name')">
          <b-input v-model="user.fullname"></b-input>
        </b-field>
        <b-field :label="$t('edit_user_profile.email')">
          <b-input v-model="user.email"></b-input>
        </b-field>
        <b-field :label="$t('edit_user_profile.primary_location')">
          <b-select
            v-model="user.location"
            :placeholder="userLocationName"
            @input="updateLocation"
          >
            <option v-for="site in sites" :value="site._id" :key="site._id">
              {{ site.siteName }}
            </option>
          </b-select>
        </b-field>
        <b-field :label="$t('edit_user_profile.secondary_locations')">
          <b-taginput
            v-model="user.secondaryLocations"
            :data="filteredSites"
            field="siteName"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            @typing="getFilteredSitesTags"
          >
          </b-taginput>
        </b-field>
        <b-field :label="$t('edit_user_profile.phone_number')">
          <b-input v-model="user.phone"></b-input>
        </b-field>
      </div>

      <div class="column">
        <div class="row img-space"></div>
        <b-field :label="$t('edit_user_profile.role')">
          <b-select
            v-model="newUserRole"
            :placeholder="
              parseInt(this.user.privil, 10) === 1
                ? $t('edit_user_profile.power_admin_role')
                : this.user.role
                ? this.user.role
                : $t('edit_user_profile.placeholder_role')
            "
          >
            <option v-if="privil" :value="'power'">
              {{ $t('edit_user_profile.power_admin_role') }}
            </option>
            <option v-for="role in roles" :value="role._id" :key="role._id">
              {{ role.rolename }}
            </option>
          </b-select>
        </b-field>

        <label class="label">{{ $t('edit_user_profile.ownership') }}</label>
        <div class="ownership">
          <b-loading :is-full-page="false" :active="waitingForRead"></b-loading>
          <p>
            {{
              $t('edit_user_profile.user_owned_template_count', {
                count: userOwnedTemplatesCount,
              })
            }}
          </p>
          <p>
            {{
              $t('edit_user_profile.user_owned_job_count', {
                count: userOwnedJobsCount,
              })
            }}
          </p>

          <b-button
            @click="isReassignActive = !isReassignActive"
            type="is-primary"
            v-if="!isReassignActive"
            >{{ $t('edit_user_profile.reassign_jobs') }}</b-button
          >

          <reassign-job
            @siteSelected="handleSiteSelected($event)"
            @userSelected="handleUserSelected($event)"
            :isReassignJobsActive="isReassignActive"
            :currentOwner="user"
          ></reassign-job>

          <b-button
            type="is-info-light"
            v-if="isReassignActive"
            @click="
              () => {
                isReassignActive = false;
              }
            "
          >
            {{ $t('edit_user_profile.reassign_jobs_cancel') }}
          </b-button>
          <b-button
            class="reassign-jobs-confirm-admin"
            type="is-success"
            v-if="
              isReassignActive &&
              isSiteReassignSelected &&
              isUserReassignSelected
            "
            @click="handleSubmitReassign"
          >
            {{ $t('reassign_jobs') }}
          </b-button>
        </div>

        <b-field :label="$t('edit_user_profile.additional_permissions')">
          <b-checkbox v-model="user.engineerReview">{{
            $t('edit_user_profile.enginner_review_permission')
          }}</b-checkbox>
        </b-field>
      </div>
    </div>

    <div class="actions">
      <template v-if="user.isPendingApproval || user.isRejected">
        <b-button @click="handleApproveUser" type="is-success">{{
          $t('edit_user_profile.approve_button')
        }}</b-button>
        <b-button
          v-if="!user.isRejected"
          @click="handleRejectUser"
          type="is-danger"
          >{{ $t('edit_user_profile.reject_button') }}</b-button
        >
      </template>
      <template v-else>
        <b-button @click="deleteUser" type="is-danger">{{
          $t('edit_user_profile.delete_button')
        }}</b-button>
        <b-button @click="updateUserProfile" type="is-primary">{{
          $t('edit_user_profile.update_button')
        }}</b-button>
      </template>
      <b-button @click="handleClose">{{
        $t('edit_user_profile.cancel_button')
      }}</b-button>
    </div>
  </div>
</template>

<script>
import ReassignJob from '../layout/ReassignJob';
import store from '../../store/index';

export default {
  name: 'edit-user-profile',
  components: {
    'reassign-job': ReassignJob,
  },
  data() {
    return {
      isNewPasswordActive: false,
      newUserRole: null,
      userLocationName: '',
      privil: false,
      isReassignJobsActive: false,
      selectedSite: null,
      selectedUser: null,
      isReassignActive: false,
      isSiteReassignSelected: false,
      isUserReassignSelected: false,
      userToReassign: null,
      isLoading: false,
      user: null,
      filteredSites: null,
    };
  },
  computed: {
    sites() {
      return this.$store.state.sites;
    },
    sitesWithoutPrimaryLocation() {
      const sitesWithoutPrimaryLocation = [...this.sites];
      const indexOfPrimaryLocation = sitesWithoutPrimaryLocation.filter(
        (site) => site._id !== this.user.location
      );
      return indexOfPrimaryLocation;
    },
    roles() {
      return this.$store.state.userRoles;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    userOwnedTemplatesCount() {
      return this.$store.state.userOwnedTemplatesCount;
    },
    userOwnedJobsCount() {
      return this.$store.state.userOwnedJobsCount;
    },
    waitingForRead() {
      return this.$store.state.waitingForRead;
    },
    userLocationNames() {
      const secondaryLocations =
        this.user.secondaryLocations.map((location) => location._id) || [];
      return [this.user.location, ...secondaryLocations].map(
        (location) => this.sites.find((site) => site._id === location).siteName
      );
    },
  },

  watch: {
    newUserRole(newValue) {
      if (newValue === 'power') {
        this.user.role = 'admin';
        this.user.privil = 1;
      } else {
        const chosenRole = this.roles.find((role) => role._id === newValue);
        this.user.role = chosenRole.rolename;
        this.user.privil = 0;
      }
    },
  },

  async beforeRouteEnter(to, {}, next) {
    const { id } = to.params;
    const { state } = store;

    await store.dispatch('getUserProfile', id);

    if (state.sites.length === 0) {
      await store.dispatch('getSites');
    }

    store.dispatch('getUserRoles');

    store.dispatch('countUserOwnedTemplates', id);
    store.dispatch('countUserOwnedJobs', id);

    next();
  },

  async created() {
    this.user = { ...this.$store.state.user };
    if (this.user.secondaryLocations) {
      this.user.secondaryLocations = this.user.secondaryLocations.map(
        (locationId) => this.sites.find((site) => site._id === locationId)
      );
    }

    if (this.currentUser) {
      this.privil = Boolean(parseInt(this.currentUser.privil, 10));
    }

    this.updateLocation();

    this.filteredSites = this.sitesWithoutPrimaryLocation;
  },
  methods: {
    updateLocation() {
      if (this.user.secondaryLocations) {
        this.user.secondaryLocations = this.user.secondaryLocations.filter(
          (location) => location._id !== this.user.location
        );
      }
      const site = this.sites.find(
        (siteToFind) => siteToFind._id === this.user.location
      );
      this.userLocationName = site.locationName;
    },
    handleClose() {
      this.$router.push({
        name: 'admin-root',
      });
    },

    async updateUserProfile() {
      const userToUpdate = { ...this.user };
      if (userToUpdate.secondaryLocations) {
        userToUpdate.secondaryLocations = this.user.secondaryLocations.map(
          (location) => location._id
        );
      }

      await this.$store.dispatch('updateUser', userToUpdate);

      this.$store.dispatch(
        'setSuccessMessage',
        this.$t('edit_user_profile.update_success_message')
      );
    },

    async deleteUser() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this user?',
        type: 'is-danger',
        hasIcon: true,
        icon: 'delete',
        confirmText: 'Delete',
        onConfirm: async () => {
          this.isLoading = true;
          await this.$store.dispatch('deleteUser', this.user);

          this.$store.dispatch(
            'setSuccessMessage',
            this.$t('edit_user_profile.delete_success_message')
          );

          setTimeout(() => {
            this.$router.push({
              name: 'admin-root',
            });
          }, 3000);
        },
      });
    },

    async reassignUserJobs() {
      if (!this.isReassignJobsActive) {
        this.isReassignJobsActive = true;
      } else {
        this.isReassignJobsActive = false;
      }
    },

    handleSiteSelected() {
      this.isSiteReassignSelected = true;
    },

    handleUserSelected(user) {
      this.isUserReassignSelected = true;
      this.userToReassign = user;
    },

    async handleSubmitReassign() {
      this.isLoading = true;
      const DTO = {
        newOwner: this.userToReassign,
        oldOwner: this.user,
      };
      try {
        await this.$store.dispatch('reassignJobsByUser', DTO);

        this.isLoading = false;

        this.$store.dispatch(
          'setSuccessMessage',
          this.$t('edit_user_profile.job_reassignment_success_message')
        );
      } catch (err) {
        console.error('reassign job error inside admin page', err);
        this.isLoading = false;

        this.$store.dispatch(
          'setErrorMessage',
          this.$t('edit_user_profile.job_reassignment_fail_message')
        );
      }
    },

    async handleApproveUser() {
      const name = this.user.fullname;
      const { role } = this.user;
      const locations = this.userLocationNames;
      this.$buefy.dialog.confirm({
        message: this.$t('edit_user_profile.approve_question', {
          name,
          role,
          locations,
        }),
        type: 'is-success',
        hasIcon: true,
        icon: 'check-decagram',
        confirmText: this.$t('edit_user_profile.approve_button'),
        onConfirm: async () => {
          this.isLoading = true;

          const userToUpdate = { ...this.user };
          if (userToUpdate.secondaryLocations) {
            userToUpdate.secondaryLocations = this.user.secondaryLocations.map(
              (location) => location._id
            );
          }

          await this.$store.dispatch('approveUser', userToUpdate);

          this.$store.dispatch(
            'setSuccessMessage',
            this.$t('edit_user_profile.approve_success_message')
          );

          setTimeout(() => {
            this.$router.push({
              name: 'admin-root',
            });
          }, 3000);
        },
      });
    },

    async handleRejectUser() {
      const name = this.user.fullname;
      this.$buefy.dialog.confirm({
        message: this.$t('edit_user_profile.reject_question', { name }),
        type: 'is-danger',
        hasIcon: true,
        icon: 'delete',
        confirmText: this.$t('edit_user_profile.reject_button'),
        onConfirm: async () => {
          this.isLoading = true;
          await this.$store.dispatch('rejectUser', this.user);

          this.$store.dispatch(
            'setSuccessMessage',
            this.$t('edit_user_profile.reject_success_message')
          );

          setTimeout(() => {
            this.$router.push({
              name: 'admin-root',
            });
          }, 3000);
        },
      });
    },

    getFilteredSitesTags(text) {
      this.filteredSites = this.sitesWithoutPrimaryLocation.filter(
        (location) => {
          return (
            location.siteName
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        }
      );
    },
  },
};
</script>

<style lang="scss">
.img-space {
  margin-top: 142px;
}
.avatar {
  width: 80px;
  height: 80px;
  margin: 30px 0;
  margin-left: 8px;
  border-radius: 80px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  background-color: rgba(37, 48, 92, 0.3);
}

.edit-user-container {
  h4 {
    margin-left: 5%;
    margin-top: 2%;
    font-weight: bold;
    color: #333333;
    font-size: 20px;
  }
}

.edit-user-form-container {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: auto;
}

.first-column {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}
select,
.select,
.is-empty {
  width: 100%;
}

.actions {
  display: flex;
  gap: 10px;
  right: 0;
  bottom: 0;
  position: absolute;
  margin-right: 10%;
  margin-bottom: 5%;
}

.reassign-jobs-dropdown-admin {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  .reassign-jobs-users {
    margin-left: 8px;
  }
}

.reassign-jobs-confirm-admin {
  margin-left: 8px;
}

.ownership {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
}

.notification.pending-approval-notification {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}
</style>
