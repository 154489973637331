<template>
  <template-list :type="'publisher'" :columnsData="columnsData" />
</template>
<script>
import TemplateList from '../layout/TemplateList';
import store from '../../store/index';

export default {
  name: 'publisher-list',
  components: {
    TemplateList,
  },
  data() {
    return {
      columnsData: [
        'title',
        'original_oem',
        'frame_type',
        'model',
        'part_number',
        'customer',
        'project_number',
        'serial_number',
        'work_order_number',
        'sales_force_opportunity_number',
        'executing_site',
      ],
      prevRoute: null,
    };
  },
  async beforeRouteEnter({}, from, next) {
    const { state } = store;

    const urlPathSplitted = from.path.split('/');

    if (urlPathSplitted.length === 4) {
      const taskId = urlPathSplitted[3];
      // Check if string is a valid uuid v4
      const uuidV4Pattern =
        /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
      const jobIdValid = taskId.match(uuidV4Pattern);
      if (jobIdValid) {
        // Since the diagram and instruction components are made in Polymer
        // The store is not called when using persistence APIs
        // Because of that we reload the job when a user comes out of the tasks page
        // And update the list of jobs if necessary
        await store.dispatch('processJobChanges', jobIdValid);
      }
    }

    state.job = null;

    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>
