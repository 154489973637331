import { render, staticRenderFns } from "./DiagramView.vue?vue&type=template&id=8c3c5190&scoped=true"
import script from "./DiagramView.vue?vue&type=script&lang=js"
export * from "./DiagramView.vue?vue&type=script&lang=js"
import style0 from "./DiagramView.vue?vue&type=style&index=0&id=8c3c5190&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c3c5190",
  null
  
)

export default component.exports