var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"header-logo-outer"},[_c('router-link',{staticClass:"header-logo",attrs:{"to":_vm.tokenExists ? this.$router.currentRoute : '/view/dashboard'}},[_c('img',{staticStyle:{"width":"inherit"},attrs:{"src":"/img/siemens-energy-logo.png","alt":"","srcset":""}})])],1),_c('div',{staticClass:"header-inner header-current-tool"},[_vm._v(" DIJ - "+_vm._s(_vm.toolName ? _vm.toolName : 'Digital Inspection Jacket')+" ")]),(_vm.isAdminVisible && !_vm.isDashboard && !this.$route.query.token)?_c('router-link',{attrs:{"to":"/view/admin"}},[_c('div',{staticClass:"header-inner headerTool"},[_vm._v(_vm._s(_vm.$t('admin')))])]):_vm._e(),(_vm.isEditorVisible && !_vm.isDashboard && !this.$route.query.token)?_c('router-link',{attrs:{"to":"/view/editor"}},[_c('div',{staticClass:"header-inner headerTool"},[_vm._v(_vm._s(_vm.$t('editor')))])]):_vm._e(),(_vm.isPublisherVisible && !_vm.isDashboard && !this.$route.query.token)?_c('router-link',{attrs:{"to":"/view/publisher"}},[_c('div',{staticClass:"header-inner headerTool"},[_vm._v(_vm._s(_vm.$t('publisher')))])]):_vm._e(),(
      _vm.toolName !== 'Inspector' &&
      !_vm.tokenExists &&
      !_vm.isDashboard &&
      this.userAllowedTools.some((tool) => tool === 'inspector') &&
      !this.$route.query.token
    )?_c('router-link',{attrs:{"to":"/view/inspector"}},[_c('div',{staticClass:"header-inner headerTool"},[_vm._v(_vm._s(_vm.$t('inspector')))])]):_vm._e(),(_vm.isReportVisible && !_vm.isDashboard && !this.$route.query.token)?_c('router-link',{attrs:{"to":"/view/report"}},[(!_vm.tokenExists && !_vm.isDashboard)?_c('div',{staticClass:"header-inner headerTool"},[_vm._v(" "+_vm._s(_vm.$t('report'))+" ")]):_vm._e()]):_vm._e(),(_vm.isToolEditor)?_c('submit-for-approval'):_vm._e(),(!_vm.tokenExists)?_c('HeaderMenu'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }