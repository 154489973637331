<template>
  <section>
    <b-notification
      auto-close
      type="is-success"
      :active.sync="showSuccess"
      :duration="3000"
      aria-close-label="Close notification"
    >
      {{ $t('mte_type_delete_success') }}
    </b-notification>
    <b-notification
      auto-close
      type="is-danger"
      :active.sync="showFail"
      :duration="5000"
      aria-close-label="Close notification"
    >
      {{ $t('mte_type_delete_fail') }}
    </b-notification>
    <b-table class="dij-admin-mtetype-table" :data="mteTypes">
      <template slot-scope="props">
        <b-table-column field="name" :label="$t('name')">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          field="needsMaster"
          :label="$t('needs_master')"
          centered
        >
          <b-icon
            :icon="props.row.needsMaster ? 'check' : 'close'"
            :type="props.row.needsMaster ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column field="isMaster" :label="$t('is_master')" centered>
          <b-icon
            :icon="props.row.isMaster ? 'check' : 'close'"
            :type="props.row.isMaster ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          :label="$t('actions')"
          class="dij-admin-mtetype-table-row-actions"
          width="40"
          centered
        >
          <b-icon icon="pencil" @click.native="editMteType(props.row)" />
          <b-icon
            icon="delete"
            type="is-danger"
            @click.native="deleteMteType(props.row)"
          />
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import adminPageConstants from '../../constants/adminPageConstants';

export default {
  data() {
    return {
      mteTypes: [],
      showSuccess: false,
      showFail: false,
    };
  },

  created() {
    this.getMteTypes();
  },
  methods: {
    async getMteTypes(query) {
      this.mteTypes = this.$store.state.mteTypes;
      if (this.mteTypes.length === 0) {
        await this.$store.dispatch('getMteTypes');
      }
      this.mteTypes = this.$store.state.mteTypes;
      if (query && query.search) {
        this.mteTypes = this.mteTypes.filter((mteType) => {
          const mteTypename = mteType.name.toLowerCase();
          const querysearch = query.search.toLowerCase();
          if (mteTypename.includes(querysearch)) {
            return mteType.name;
          }
        });
      }
    },
    editMteType(mteType) {
      this.$cookies.set(
        adminPageConstants.adminCookies.admin_selected_tab,
        adminPageConstants.adminPage.tabs.mteTypeList
      );
      this.$router.push({
        name: 'mtetype-edit',
        params: { id: mteType._id },
      });
    },
    deleteMteType(mteType) {
      this.$buefy.dialog.confirm({
        message: this.$t('mte_type_delete_question'),
        type: 'is-danger',
        hasIcon: true,
        icon: 'delete',
        confirmText: 'Delete',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('deleteMteType', mteType);

            this.showSuccess = true;
          } catch (err) {
            this.$buefy.notification.open({
              message: this.$t('mte_type_delete_fail'),
              type: 'is-success',
            });
            this.showFail = true;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-admin-mtetype-table {
  width: 90%;
  margin: 20px auto;
}
.dij-admin-mtetype-table-row-actions {
  display: flex;
  gap: 10px;

  & > * {
    cursor: pointer;
  }
}
</style>
