<template>
  <section id="collapse-container" v-if="attachmentData.length > 0">
    <b-collapse
      class="card"
      animation="slide"
      aria-id="collapse-container"
      :open="true"
    >
      <template #trigger="props">
        <div
          id="collapse-container-title"
          role="button"
          aria-controls="collapse-container"
          :aria-expanded="props.open"
        >
          <p class="card-header-title">
            {{ isFreeFinding ? 'FREE FINDING' : 'ATTACHMENTS' }}
            (<span>{{ totalNumberOfImages.total }} pictures </span>
            <span v-if="totalNumberOfImages.excluded > 0">
              &nbsp; / {{ totalNumberOfImages.excluded }} excluded </span
            >)
          </p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content" v-if="attachmentData.length > 0">
        <div class="content">
          <div v-for="(att, index) in attachmentData" class="content-attchment">
            <div class="title-question" v-if="att.question">
              Question: {{ att.question }}
            </div>
            <div class="label-images" v-if="att.label">
              Label {{ att.label }}
            </div>
            <div class="title-comment" v-if="att.description">
              Description: {{ att.description }}
            </div>
            <div class="title-comment" v-if="att.comment">
              {{ att.comment }}
            </div>
            <div v-for="(image, i) in att.images" class="content-attchment">
              <div
                :class="
                  i == att.images.length - 1
                    ? ' container-image underline-border'
                    : 'container-image'
                "
              >
                <div
                  :class="
                    isImageExclude(image.src) ? 'element excluded' : 'element'
                  "
                >
                  <b-checkbox
                    @input="(e) => onImageToExclude(e, image.src)"
                    :value="isImageExclude(image.src)"
                    v-if="enableExcludeItems"
                  />
                  <span class="description-name">Name:</span>
                  {{ image.name }}
                  <span @click="editImageName(image, att)">
                    <b-icon
                      custom-size="mdi-24px"
                      icon="pencil"
                      class="edit-icon"
                      type="is-info"
                    />
                  </span>
                </div>

                <div class="element">
                  <span class="description-name">Description:</span>
                  <span v-if="image.description">{{ image.description }}</span>

                  <span @click="editImageDescription(image, att)">
                    <b-icon
                      custom-size="mdi-24px"
                      icon="pencil"
                      class="edit-icon"
                      type="is-info"
                    />
                  </span>
                </div>

                <ImageWithErrorFallback
                  :imageUrl="getImageUrl(image)"
                  v-if="
                    !isImageExclude(image?.src || image?.url || image?.path)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import ReportDataParserMixin from '../../../mixins/Report/ReportDataParserMixin';
import ExcludeItemsMixin from '../../../mixins/Report/ExcludeItemsMixin';
import ImageWithErrorFallback from './ImageWithErrorFallback';
import { REPORTS_UPDATE_ATTACHMENT_IMAGE } from '../../../store/reports/actions/actionTypes';

export default {
  name: 'Attachments',
  mixins: [ReportDataParserMixin, ExcludeItemsMixin],
  components: { ImageWithErrorFallback },
  props: {
    taskProp: {
      type: Object,
      required: true,
    },

    isFreeFinding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      task: null,
    };
  },

  created() {
    this.task = this.taskProp;
  },

  watch: {
    taskProp(newVal) {
      this.task = newVal;
    },
  },

  computed: {
    attachmentData() {
      return this.task.attachmentData.filter((att) => {
        if (this.isFreeFinding) {
          if (att.isFreeFinding) return att;
        } else if (!att.isFreeFinding) return att;
      });
    },
    totalNumberOfImages() {
      let total = 0;
      let excluded = 0;
      this.attachmentData.forEach((att) => {
        if (att.images) {
          total += att.images.length;
          att.images.forEach((img) => {
            if (this.isImageExclude(img.src)) {
              excluded += 1;
            }
          });
        }
      });
      return { total, excluded };
    },
  },
  methods: {
    editImageName(image, att) {
      this.$buefy.dialog.prompt({
        message: 'Change image name:',
        inputAttrs: {
          placeholder: 'Type here...',
          type: 'text',
          required: false,
          value: image.name,
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.onConfirmImageName(image, value, att);
        },
      });
    },

    editImageDescription(image, att) {
      this.$buefy.dialog.prompt({
        message: 'Change image description:',
        inputAttrs: {
          placeholder: 'Type here...',
          type: 'text',
          required: false,
          value: image.description,
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.onConfirmImageDescription(image, value, att);
        },
      });
    },

    async onConfirmImageName(image, value, att) {
      this.task.attachmentData.forEach((images) => {
        images.images.forEach((img) => {
          const imgUrl = img.url || img.path || img.src || undefined;
          const imageUrl = image.url || image.path || image.src || undefined;

          if (imgUrl === imageUrl) {
            img.name = value;
          }
        });
      });

      this.task.attachmentData = [...this.task.attachmentData];

      await this.$store.dispatch(REPORTS_UPDATE_ATTACHMENT_IMAGE, {
        bubbleId: att.bubbleId,
        imageUrl: image.url || image.path || image.src || undefined,
        imageName: value,
      });
    },

    async onConfirmImageDescription(image, value, att) {
      this.task.attachmentData.forEach((images) => {
        images.images.forEach((img) => {
          const imgUrl = img.url || img.path || img.src || undefined;
          const imageUrl = image.url || image.path || image.src || undefined;
          if (imgUrl === imageUrl) {
            img.description = value;
          }
        });
      });

      this.task.attachmentData = [...this.task.attachmentData];

      await this.$store.dispatch(REPORTS_UPDATE_ATTACHMENT_IMAGE, {
        bubbleId: att.bubbleId,
        imageUrl: image.url || image.path || image.src || undefined,
        imgDescription: value,
      });
    },

    getImageUrl(image) {
      const url = image.url || image.path || image.src || undefined;

      // For local env we need to point to the local s3
      if (window.location.host.includes('localhost')) {
        return `http://localhost:4566/images${url}`;
      }

      return url;
    },
  },
};
</script>

<style scoped lang="scss">
#collapse-container {
  margin-top: 20px;
}

#collapse-container-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#collapse-container-title:hover {
  background-color: #ddd;
}

.title-question {
  font-size: 20px;
  font-weight: bold;
}

.content-attchment {
  width: 100%;
  padding: 5px;
}

.underline-border {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.container-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.element {
  margin: 5px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label-images {
  font-size: 20px;
  font-family: 'Siemens Roman';
}

.title-comment {
  font-size: 20px;
  font-family: 'Siemens Roman';
  margin-left: 10px;
}
.excluded {
  text-decoration: line-through;
}

.card-header-title {
  span {
    font-size: 12px;
  }
}

.content {
  max-height: 600px;
  overflow-y: auto;
}

.description-name {
  font-weight: bold;
  margin-right: 5px;
}

.edit-icon {
  cursor: pointer;
}
</style>
