import { render, staticRenderFns } from "./AddOrderInfoPage.vue?vue&type=template&id=22a06a17&scoped=true"
import script from "./AddOrderInfoPage.vue?vue&type=script&lang=js"
export * from "./AddOrderInfoPage.vue?vue&type=script&lang=js"
import style0 from "./AddOrderInfoPage.vue?vue&type=style&index=0&id=22a06a17&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a06a17",
  null
  
)

export default component.exports