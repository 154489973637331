<template>
  <div class="container">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <div class="form-container">
      <div style="margin-bottom: 50px" v-if="false">
        <h4>
          {{ $t('create_folder.create_new_folder') }}
        </h4>
        <b-button @click="createPart" type="is-text"
          >Configure New Part</b-button
        >
      </div>
      <md-field>
        <label> {{ $t('create_folder.folder_name') }} </label>
        <md-input
          required
          v-model="folderObject.taskName"
          :disabled="folder && folder.isPart"
        ></md-input>
      </md-field>

      <template v-if="folder && folder.isPart">
        <md-field
          v-if="folder.taskName.includes('GBC') || folder.taskName === 'Rotor'"
        >
          <label> Draw No (B-Plan) </label>
          <md-input v-model="folderObject.drawNoBplan"></md-input>
        </md-field>
        <md-field v-if="folder.taskName !== 'Rotor'">
          <label> Draw No </label>
          <md-input v-model="folderObject.drawNo1"></md-input>
        </md-field>
        <md-field v-if="folder.taskName !== 'Rotor'">
          <label> Draw No </label>
          <md-input v-model="folderObject.drawNo2"></md-input>
        </md-field>
        <md-field v-if="folder.taskName !== 'Rotor'">
          <label> Draw No </label>
          <md-input v-model="folderObject.drawNo3"></md-input>
        </md-field>
        <md-field
          v-if="!folder.taskName.includes('GBC') && folder.taskName !== 'Rotor'"
        >
          <label> Draw No </label>
          <md-input v-model="folderObject.drawNo4"></md-input>
        </md-field>

        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Draw No (Turbinenlaufer) </label>
          <md-input v-model="folderObject.drawNoTurbinenlaufer"></md-input>
        </md-field>

        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Draw No (Nutenplan) </label>
          <md-input v-model="folderObject.drawNoNutenplan"></md-input>
        </md-field>

        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Draw No (Wellenende vorne) </label>
          <md-input v-model="folderObject.drawNoWelleVorne"></md-input>
        </md-field>

        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Draw No (Wellenende hinten) </label>
          <md-input v-model="folderObject.drawNoWelleHinten"></md-input>
        </md-field>

        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Draw No (Einstromteil) </label>
          <md-input v-model="folderObject.drawNoEinstromteil"></md-input>
        </md-field>

        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Draw No (Abstromteil) </label>
          <md-input v-model="folderObject.drawNoAbstromteil"></md-input>
        </md-field>

        <!-- **************************************** -->
        <md-field v-if="folder.taskName === 'Rotor'">
          <label> Rotor Id </label>
          <md-input v-model="folderObject.rotorId"></md-input>
        </md-field>
        <md-field>
          <label> Parts List </label>
          <md-input v-model="folderObject.partList"></md-input>
        </md-field>
        <md-field>
          <label> Hallmark </label>
          <md-input v-model="folderObject.hallmark"></md-input>
        </md-field>
      </template>

      <md-field v-for="field in fields" :key="field.id">
        <label> {{ field.label }} </label>
        <md-input v-model="folderObject[field.id]"></md-input>
        <div @click="deleteField(field)">
          <md-icon class="delete-field">delete</md-icon>
        </div>
      </md-field>
    </div>
    <div class="buttons-container">
      <button class="cancel-button" @click="cancel">
        {{ $t('cancel') }}
      </button>
      <button v-if="false" class="submit-button" @click="addField">
        {{ $t('add_field') }}
      </button>
      <button
        class="submit-button"
        :disabled="folderObject.taskName === '' || creating"
        @click="save"
      >
        {{ $t('done') }}
      </button>
      <b-loading
        :is-full-page="false"
        :active.sync="creating"
        :can-cancel="false"
      ></b-loading>
    </div>

    <b-notification
      class="notification-message"
      v-if="notificationMessage"
      :type="notificationType"
      aria-close-label="Close notification"
      role="alert"
    >
      {{ notificationMessage }}
    </b-notification>
  </div>
</template>

<script>
import FoldersManagerMixin from '../../mixins/FoldersManagerMixin';
import KeyHandlerMixin from '../../mixins/KeyHandlerMixin';

export default {
  name: 'create-folder',
  props: {
    tool: String,
  },
  mixins: [FoldersManagerMixin, KeyHandlerMixin],
  data() {
    return {
      creating: false,
      fields: [],
      folderObject: {
        taskName: '',
      },
      notificationType: 'is-success', // 'is-danger'
      notificationMessage: '',
    };
  },
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
    tasks() {
      return this.$store.state.tasks;
    },
  },

  async mounted() {
    if (Object.keys(this.job).length === 0) {
      const { id } = this.$route.params;
      await this.$store.dispatch('getJob', id);
    }

    if (this.$route.query.edit) {
      let folderObj = null;
      if (this.folderId) {
        folderObj = await this.getFolder;
      } else if (this.subfolderId) {
        folderObj = await this.getSubfolder;
      }

      if (folderObj) {
        this.fields = folderObj.fields || this.fields;
        Object.assign(this.folderObject, folderObj);
      }
    }
    this.onSaveCallback = {
      callback: this.save,
      isSave: true,
    };
    this.onCancelCallback = {
      callback: this.cancel,
      isCancel: true,
    };
  },

  methods: {
    deleteField(field) {
      this.$buefy.dialog.confirm({
        title: 'DELETE',
        message: `Delete field ${field.label}?`,
        confirmText: 'Delete',
        type: 'is-dark',
        hasIcon: true,
        icon: 'delete',
        onConfirm: async () => {
          if (this.folderObject[field.id]) delete this.folderObject[field.id];
          const index = this.fields.findIndex((item) => item.id === field.id);
          if (index > -1) {
            this.fields.splice(index, 1);
          }
        },
      });
    },
    addField() {
      this.notificationMessage = '';
      this.$buefy.dialog.prompt({
        message: 'Name',
        trapFocus: true,
        onConfirm: (value) => {
          const field = {
            id: value.replace(/ /g, '_').toLowerCase(),
            label: value,
          };
          if (this.fields.includes(field)) {
            this.notificationType = 'is-danger';
            this.notificationMessage = 'Field already exists.';
            return false;
          }
          this.fields.push(field);
        },
      });
    },
    async cancel() {
      const routeParams = {
        path: `/view/${this.tool}/${this.job._id}`,
      };

      if (this.subfolderId) {
        const folder = await this.getSubfolder;
        routeParams.path += `/folder/${folder.folderId}`;
        routeParams.params = { folderId: folder.folderId };
      }

      if (this.folderId && !this.$route.query.edit) {
        routeParams.path += `/folder/${this.folderId}`;
        routeParams.params = { folderId: this.folderId };
      }

      if (this.$route.query.token) {
        routeParams.query = { token: this.$route.query.token };
      }
      this.$router.push(routeParams);
    },
    async save() {
      const folder = this.folderObject;
      folder.taskType = 'folder';

      if (this.folderId && !this.$route.query.edit) {
        folder.folderId = this.folderId;
        folder.folderLevel = this.folderObject.folderLevel
          ? this.folderObject.folderLevel + 1
          : 1;
      }
      folder.jobId = this.job._id;
      folder.fields = this.fields;
      if (!this.$route.query.folderId) {
        folder.taskOrder =
          Math.max(...this.$store.state.tasks.map((o) => o.taskOrder), 0) + 1;
      }

      try {
        if (this.$route.query.edit) {
          await this.$store.dispatch('updateTask', folder);
        } else {
          this.creating = true;
          await this.$store.dispatch('createNewTask', folder);
          this.creating = false;
        }
        return this.cancel();
      } catch (err) {
        console.error('Failed to create new task');
        return false;
      }
    },
    createPart() {
      if (this.$route.query.token) {
        const { token } = this.$route.query;

        this.$router.push({
          name: 'publisher-create-part',
          params: { id: this.job._id },
          query: { token },
        });
      } else {
        this.$router.push({
          name: 'publisher-create-part',
          params: { id: this.job._id },
        });
      }
    },
  },
};
</script>

<style scoped>
.delete-field {
  cursor: pointer;
}
</style>
