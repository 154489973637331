/* eslint-disable no-shadow */ /* eslint-disable eqeqeq */
<template>
  <CreatePageBase
    ref="createPageBase"
    :title="$t(`${TRANSLATION_ROOT}.change_user_password`)"
    :validForm="fieldsAreValid"
    @onSubmit="changeUserPassword"
  >
    <b-field
      v-for="(field, index) in fields"
      :key="index"
      :label="$t(`${TRANSLATION_ROOT}.${field.name}`)"
    >
      <b-input
        required
        :password-reveal="field.type === 'password'"
        :type="field.type"
        :icon="field.icon"
        :label="field.label"
        v-model.trim="field.value"
      ></b-input>
    </b-field>
  </CreatePageBase>
</template>

<script>
import CreatePageBase from './CreatePageBase';
import { USERS_SET_USER_PASSWORD } from '../../store/users/actionTypes';

import UserMixin from '../../mixins/UserMixin';

const TRANSLATION_ROOT = 'change_password_page';

export default {
  name: 'change-password',
  components: { CreatePageBase },
  mixins: [UserMixin],
  async mounted() {
    this.$refs.createPageBase.isLoading = true;

    this.validateAdminAccess();
    this.$refs.createPageBase.submitBtnLabel = `${TRANSLATION_ROOT}.change_password`;
    const { id } = this.$route.params;
    this.setEmail(id);

    this.$refs.createPageBase.isLoading = false;
  },
  data() {
    return {
      TRANSLATION_ROOT,
      fields: [
        {
          icon: 'email',
          type: 'email',
          label: 'email',
          name: 'email',
          value: '',
        },
        {
          icon: 'lock',
          type: 'password',
          label: 'New Password',
          name: 'password',
          value: '',
        },
        {
          icon: 'lock',
          type: 'password',
          label: 'Confirm New Password',
          name: 'confirmNewPassword',
          value: '',
        },
      ],
    };
  },
  computed: {
    fieldsAreValid() {
      if (this.fields.some((field) => field.value === '' || !field))
        return false;
      return true;
    },
  },
  methods: {
    setEmail(email) {
      this.fields.find((field) => field.name === 'email').value = email;
    },
    cancelForm() {
      this.$router.push({
        name: 'admin-root',
      });
    },
    async changeUserPassword() {
      if (this.fieldsAreValid) {
        try {
          const newUserPayload = {};
          this.fields.forEach((field) => {
            newUserPayload[field.name] = field.value;
          });

          if (newUserPayload.password !== newUserPayload.confirmNewPassword) {
            this.$refs.createPageBase.failedMessage = `${TRANSLATION_ROOT}.password_should_match`;
            this.$refs.createPageBase.showFail = true;
            return;
          }

          if (
            !this.validatePassword(
              newUserPayload.password,
              newUserPayload.email
            ).isValid
          ) {
            this.$refs.createPageBase.failedMessage = `${TRANSLATION_ROOT}.password_validation`;
            this.$refs.createPageBase.showFail = true;
            return;
          }

          this.$refs.createPageBase.isLoading = true;

          await this.$store.dispatch(USERS_SET_USER_PASSWORD, newUserPayload);

          this.$refs.createPageBase.successMessage = `${TRANSLATION_ROOT}.password_change_success`;
          this.$refs.createPageBase.showSuccess = true;

          setTimeout(() => {
            this.$router.push({
              name: 'admin-root',
            });
          }, 3000);
        } catch (err) {
          this.$refs.createPageBase.failedMessage = `${TRANSLATION_ROOT}.password_change_fail`;
          this.$refs.createPageBase.showFail = true;
          this.$refs.createPageBase.isLoading = false;
        }
      } else {
        this.$refs.createPageBase.failedMessage = `${TRANSLATION_ROOT}.all_fields_required`;
        this.$refs.createPageBase.showFail = true;
      }
    },
  },
};
</script>
