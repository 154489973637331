<template>
  <div class="location">
    <div class="location-div">
      <label class="location-label">{{ $t('locations') }}</label>
    </div>
    <div class="location-field">
      <md-field>
        <md-select
          v-model="selectedLocations"
          class="multi-select"
          multiple
          placeholder="All"
          @md-selected="updateLocations"
        >
          <md-option
            v-for="location in availableLocations"
            :value="location._id"
            :key="location._id"
          >
            {{ location.siteName }}</md-option
          >
        </md-select>
      </md-field>
    </div>
  </div>
</template>
<script>
import isEqual from 'lodash/isEqual';

export default {
  name: 'filter-location',
  emits: ['update'],
  props: ['locations'],
  data() {
    return { availableLocations: null, selectedLocations: [] };
  },
  computed: {
    sites() {
      return this.$store.state.sites;
    },
    user() {
      return this.$store.state.currentUser;
    },
  },
  async created() {
    // If sites aren't yet available, get them
    if (!this.sites || this.sites.length === 0) {
      await this.$store.dispatch('getSites');
    }

    const isSuperAdmin = parseInt(this.user.privil, 10) === 1;
    if (isSuperAdmin) {
      // All locations are available
      this.availableLocations = [...this.sites];
    } else {
      // All secondary locations are available
      this.availableLocations = this.sites.filter((site) => {
        return this.user.secondaryLocations.includes(site._id);
      });
    }
    // Sort the availableLocation list
    this.availableLocations.sort();

    // Move the primary location to the beginning of the availableLocations list
    const primaryLocation = this.sites.find(
      (site) => site._id === this.user.location
    );
    // Remove the primary location of the list
    this.availableLocations = this.availableLocations.filter(
      (userLocation) => userLocation !== primaryLocation
    );
    // Place on top of the list
    this.availableLocations.unshift(primaryLocation);

    this.selectedLocations = this.locations;
  },
  methods: {
    updateLocations() {
      if (!isEqual(this.locations, this.selectedLocations)) {
        this.$cookies.set(
          'locations',
          JSON.stringify(this.selectedLocations),
          60 * 60 * 8
        );
        this.$emit('update', this.selectedLocations);
      }
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.multi-select-label {
  color: #363636 !important;
  display: block;
  font-size: 1rem !important;
  font-weight: 600;
  margin-right: 10px;
}

.location {
  display: inline-flex;
  float: right;
  height: 100%;
  width: 100%;
}

.location-div {
  margin: auto;
  height: 100%;
  width: 100%;
}

.location-label {
  text-align: right;
  margin-right: 16px;
  margin-top: 16px;
  padding-right: 16px;
  height: 100%;
  width: 100%;
  color: #363636 !important;
  display: block;
  font-size: 1rem !important;
  font-weight: 600;
}

.location-select {
  margin: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.multi-select {
  margin: auto;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: -10px;
}
</style>
