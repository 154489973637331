<template>
  <template-list :type="'report'" :columnsData="columnsData" />
</template>
<script>
import TemplateList from '../layout/TemplateList';
import store from '../../store/index';

export default {
  name: 'report-list',
  components: {
    TemplateList,
  },
  data() {
    return {
      columnsData: [
        'title',
        'original_oem',
        'frame_type',
        'model',
        'part_number',
        'customer',
        'project_number',
        'serial_number',
        'work_order_number',
        'sales_force_opportunity_number',
        'executing_site',
      ],
      prevRoute: null,
    };
  },
  beforeRouteEnter({}, from, next) {
    const { state } = store;
    state.job = null;
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>
