import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const questionsModule = {
  namespaced: true,
  state() {
    return { questions: [] };
  },
  actions,
  getters,
  mutations,
};

export default questionsModule;
