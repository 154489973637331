import { REPORTS_ITEM_TO_EXCLUDE } from '../../store/reports/actions/actionTypes';

export default {
  computed: {
    enableExcludeItems() {
      return this.$store.state.ReportsModule.enableExcludeItems;
    },
    isExclude: {
      get() {
        return this.$store.state.ReportsModule.excludedItems.includes(this.id);
      },
      set() {},
    },
  },
  methods: {
    onItemToExclude(value) {
      const { id } = this;
      this.$store.dispatch(REPORTS_ITEM_TO_EXCLUDE, { value, id });
    },
    onImageToExclude(value, imagePath) {
      this.$store.dispatch(REPORTS_ITEM_TO_EXCLUDE, { value, id: imagePath });
    },
    isImageExclude(imagePath) {
      return this.$store.state.ReportsModule.excludedItems.includes(imagePath);
    },
    isTaskExclude(taskId) {
      return this.$store.state.ReportsModule.excludedItems.includes(taskId);
    },
    onTaskToExclude(value, taskId) {
      this.$store.dispatch(REPORTS_ITEM_TO_EXCLUDE, { value, id: taskId });
    },
  },
};
