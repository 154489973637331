<template>
  <b-modal :active.sync="showModal" @close="onClose">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Configure Calculation</p>
      </header>
      <section class="modal-card-body">
        <template v-for="(column, key) in columns">
          <button v-if="key !== cell.colPos" @click="columnSelected(column)">
            {{ column.label }}
          </button>
        </template>
        <div class="buttons">
          <b-field>
            <div>Select the operator:</div>
            <p class="control">
              <b-tooltip
                :label="$t('calculation.sum')"
                type="is-light"
                position="is-bottom"
              >
                <b-button class="button is-primary-outline" @click="sum">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip
                :label="$t('calculation.sub')"
                type="is-light"
                position="is-bottom"
              >
                <b-button class="button is-primary-outline" @click="sub">
                  <b-icon icon="minus"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip
                :label="$t('calculation.mult')"
                type="is-light"
                position="is-bottom"
              >
                <b-button class="button is-primary-outline" @click="mult">
                  <b-icon icon="close"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
          </b-field>
        </div>
        <div>
          <span> calculation here: {{ previewCalculation }} </span>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button class="button" type="button" @click="close"> Close </b-button>
        <b-button class="button" type="button" @click="save"> Save </b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      cell: {},
      previewCalculation: '',
      calculation: {
        type: 'calc',
        rows: [],
      },
    };
  },
  computed: {
    columns() {
      return this.$parent.columns;
    },
  },
  mounted() {},
  methods: {
    open() {
      // open the modal for new calculation
      // clear values
      this.cell = this.$parent.selectedCell;

      this.previewCalculation = '';
      this.calculation.rows = [];
      // open modal
      this.showModal = true;
      this.$parent.cellInUse = true; // tell the parent to 'block' the selected cell
    },
    close() {
      // called when clkicked on close button
      this.showModal = false;
      return this.onClose();
    },
    onClose() {
      // called when modal is closed (clicked outside, pressed 'Esc')
      this.$parent.cellInUse = false; // tell the parent to let go the selected cell
      this.$parent.selectedCell = {};
    },
    sum() {
      this.calculation.rows.push('+');
    },
    sub() {
      this.calculation.rows.push('-');
    },
    mult() {
      this.calculation.rows.push('*');
    },
    div() {
      this.calculation.rows.push('/');
    },
    columnSelected(column) {
      // add the selected column to be calculated
      const row = {
        ...column,
      };
      this.calculation.rows.push(row);
    },
    renderCalculation(rows) {
      // this will
      let count = '';
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (typeof row === 'string') {
          count += row;
        } else {
          const value = this.$parent.rows[this.cell.rowPos][row.field];
          count += value;
        }
      }
      this.previewCalculation = count;
    },
    save() {
      const { field } = this.$parent.columns[this.cell.colPos];
      this.$parent.rowsData.forEach((row, index) => {
        row[field] = this.calculation;
        this.$parent.calculateField(this.calculation.rows, index, field);
      });
      this.close();
    },
  },
  watch: {
    'calculation.rows': {
      handler(rows) {
        this.previewCalculation = '';
        return this.renderCalculation(rows);
      },
    },
  },
};
</script>
