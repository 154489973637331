<template>
  <div class="container">
    <div style="width: 100%">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <div class="title">
        <span> Copy From </span>
        <pre> > </pre>
        <span class="desc"> Copying tasks to "{{ job.title }}" </span>
      </div>
      <div class="btn-wrap" style="float: right">
        <button class="btn-outline-primary" @click="close">Cancel</button>
        <button class="btn-primary" @click="finish">Finish</button>
      </div>
    </div>
    <div v-if="copyFromPage !== 'editor'" class="btn-wrap">
      <button
        :class="
          currentTool === 'publisher' ? 'btn-primary' : 'btn-outline-primary'
        "
        style="width: 120px"
        @click="getPublisherTasks"
        :disabled="tableLoading"
      >
        Publisher Tasks
      </button>
      <button
        :class="
          currentTool === 'publisher' ? 'btn-outline-primary' : 'btn-primary'
        "
        @click="getEditorTasks"
        :disabled="tableLoading"
      >
        Editor Tasks
      </button>
    </div>
    <div class="search">
      <form v-on:submit.prevent="getSearchJobs">
        <b-field>
          <b-input
            placeholder="Search..."
            type="search"
            icon="magnify"
            icon-clickable
            @icon-click="getSearchJobs"
            class="search-input"
            v-model="search"
          >
          </b-input>
        </b-field>
      </form>
      <b-tooltip
        v-if="isSearchActive"
        label="The select all button will add 30 tasks (limit)"
        position="is-bottom"
      >
        <div
          v-if="isSearchActive && isSortActive && currentTool === 'editor'"
          @click="handleSelectEverything"
          class="select-everything"
        >
          <p>Select everything</p>
        </div>
      </b-tooltip>
      <md-field
        class="selectField"
        v-if="currentTool === 'publisher' && parseInt(user.privil) === 0"
      >
        <v-select
          placeholder="Select site"
          v-model="site"
          :options="sitesFiltered"
          class="selectProdLine"
          @input="siteSelected"
        ></v-select>
      </md-field>
    </div>
    <div class="limit-message">
      <b-message
        :active.sync="showLimitMessage"
        title="Warning"
        type="is-warning"
        aria-close-label="Close message"
      >
        {{ limitMessage }}
      </b-message>
    </div>
    <div class="grid-container">
      <div class="grid-item" ref="div-table">
        <b-table
          :data="updatedTableJobs"
          :hoverable="true"
          :loading="tableLoading"
          @click="onSelect"
          :row-class="isSelected"
          v-if="copyFromPage"
          :key="tableKey"
          @sort="handleSort"
        >
          <template slot-scope="props">
            <b-table-column label="Product Line" :searchable="isSearchActive">
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['workOrderName']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.workOrderName && props.row.workOrderName.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.workOrderName }}
              </p>
            </b-table-column>
            <b-table-column label="Title" :searchable="isSearchActive">
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['title']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row?.title && props.row?.title.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row?.title }}
              </p>
            </b-table-column>
            <b-table-column
              :searchable="isSearchActive"
              label="Work Order"
              :visible="copyFromPage !== 'editor' && currentTool !== 'editor'"
            >
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['workOrderNumber']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.workOrderNumber &&
                  props.row.workOrderNumber.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.workOrderNumber }}
              </p>
            </b-table-column>

            <b-table-column :searchable="isSearchActive" label="Original OEM">
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['brand']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.brand && props.row.brand.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.brand }}
              </p>
            </b-table-column>

            <b-table-column
              label="Frame Type"
              :visible="copyFromPage !== 'editor' && currentTool === 'editor'"
              :searchable="isSearchActive"
            >
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['frameType']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.frameType && props.row.frameType.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.frameType }}
              </p>
            </b-table-column>

            <b-table-column
              label="Model"
              :visible="copyFromPage !== 'editor' && currentTool === 'editor'"
              :searchable="isSearchActive"
            >
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['model']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.model && props.row.model.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.model }}
              </p></b-table-column
            >

            <b-table-column
              :visible="currentTool === 'editor'"
              label="Doc no."
              :sortable="isSearchActive"
              :custom-sort="customSortDocNumber"
              :searchable="isSearchActive"
            >
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['documentNumber']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>

              <template v-if="isSearchActive" v-slot:header="{ column }">
                <b-tooltip label="Sorting according to GMTL" append-to-body>
                  {{ column.label }}
                </b-tooltip>
              </template>

              <p
                :class="
                  props.row.documentNumber &&
                  props.row.documentNumber.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.documentNumber }}
              </p></b-table-column
            >

            <b-table-column
              :visible="currentTool === 'publisher'"
              label="Serial Number"
              :searchable="isSearchActive"
            >
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['serialNumber']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.serialNumber && props.row.serialNumber.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.serialNumber }}
              </p></b-table-column
            >

            <b-table-column label="Part no." :searchable="isSearchActive">
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['userEnteredPartNumber']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p
                :class="
                  props.row.userEnteredPartNumber &&
                  props.row.userEnteredPartNumber.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.userEnteredPartNumber }}
              </p>
            </b-table-column>
            <b-table-column
              v-if="currentTool === 'editor'"
              label="Location"
              :searchable="isSearchActive"
            >
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <div :v-if="isSearchActive">
                  <b-input
                    v-if="isSearchActive"
                    v-model="filterObject['location']"
                    placeholder="Filter"
                    size="is-small"
                  />
                </div>
              </template>
              <p :class="props.row.location > 40 ? 'text-column-limit' : ''">
                {{ props.row.location }}
              </p></b-table-column
            >

            <b-table-column label="Prod. Owner" :searchable="isSearchActive">
              <template
                v-if="isSearchActive"
                slot="searchable"
                slot-scope="props"
              >
                <b-input
                  v-if="isSearchActive"
                  v-model="filterObject['fullname']"
                  placeholder="Filter"
                  size="is-small"
                />
              </template>
              <p
                :class="
                  props.row.fullname && props.row.fullname.length > 40
                    ? 'text-column-limit'
                    : ''
                "
              >
                {{ props.row.fullname }}
              </p></b-table-column
            >

            <b-table-column label="Tasks count">
              {{ props.row.taskCount }}
              Tasks
            </b-table-column>
          </template>
        </b-table>
      </div>
      <div class="grid-item tasks">
        <div class="tasks-menu">
          <span>Tasks</span>
          <button
            class="btn-outline-primary"
            v-if="
              showTasks.length > 0 &&
              showTasks.length === showTasksActiveIds.length
            "
            @click="showTasksActiveIds = []"
          >
            Deselect All
          </button>
          <button class="btn-outline-primary" v-else @click="selectAll(false)">
            Select All
          </button>
          <button class="btn-outline-primary" @click="copyTask">
            Copy Task
          </button>
        </div>
        <div>
          <div
            :class="taskClass(task._id)"
            :key="task._id"
            v-for="task in showTasks"
            @click="clickTask(task)"
          >
            <div
              :title="task.folderTitle"
              class="folder-title"
              v-if="task.folderId !== ''"
            >
              {{ task.folderTitle }}
            </div>
            <div class="box-wrapper">
              <div class="task-box-name">
                <div :title="task.taskName" position="is-top">
                  <span>{{ task.taskName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item tasks">
        <div class="tasks-menu">
          <span>Selected Tasks</span>
          <button
            class="btn-outline-primary"
            v-if="
              selectedTasks.length > 0 &&
              selectedTasks.length === selectedTasksActiveIds.length
            "
            @click="selectedTasksActiveIds = []"
          >
            Deselect All
          </button>
          <button class="btn-outline-primary" v-else @click="selectAll(true)">
            Select All
          </button>
          <button class="btn-outline-primary" @click="removeTask">
            Remove Task
          </button>
        </div>
        <div>
          <b-notification
            auto-close
            type="is-danger"
            :active.sync="showDanger"
            :duration="3000"
            aria-close-label="Close notification"
          >
            {{ notificationMessage }}
          </b-notification>
          <div
            :class="taskClass(task._id, true)"
            :key="task._id"
            v-for="task in selectedTasks"
            @click="clickTask(task, true)"
          >
            <div :title="getSelectedTaskTitle(task)" class="folder-title">
              {{ getSelectedTaskTitle(task) }}
            </div>
            <div class="box-wrapper">
              <div class="task-box-name">
                {{ task.taskName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tool'],
  name: 'copyfrom',
  data() {
    return {
      site: {},
      sitesFiltered: [],
      selected: null,
      showTasks: [],
      showTasksActiveIds: [],
      selectedTasks: [],
      selectedTasksActiveIds: [],
      search: '',
      showDanger: false,
      notificationMessage: 'Duplicated Task',
      copyFromPage: '',
      currentTool: 'editor',
      tableLoading: false,
      skip: 0,
      limit: 15,
      isLoading: false,
      tableJobs: [],
      updatedTableJobs: [],
      isAsc: null,
      isSortActive: false,
      showLimitMessage: false,
      limitMessage: '',
      isSearchActive: false,
      filterObject: {},
      tableKey: 0,
    };
  },
  computed: {
    sites() {
      return this.$store.state.sites;
    },
    jobs() {
      return this.$store.state.jobs;
    },
    job() {
      return this.$store.state.currentJob;
    },
    tasks() {
      return this.$store.state.tasks;
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    tableJobs: {
      deep: true,
      handler(newValue) {
        if (!newValue || newValue.length === 0) {
          this.updatedTableJobs = [];
          this.tableKey++;
        } else {
          const jobs = newValue;

          const fieldsToFormat = [
            'workOrderName',
            'brand',
            'frameType',
            'model',
          ];
          jobs.map((job) => {
            job.location = this.getJobLocation(job);

            const keys = Object.keys(job);
            keys.forEach((key) => {
              if (fieldsToFormat.some((value) => value === key)) {
                job[key] = this.formatFields(job, key);
              }
            });

            job.fullname = job.prodCreatedByUser.fullname;
          });

          if (Object.keys(this.filterObject).length > 0) {
            this.updatedTableJobs = jobs.filter((row) => this.filterFunc(row));
          } else {
            this.updatedTableJobs = [...jobs];
          }
        }
      },
    },
    filterObject: {
      handler(newVal) {
        const keys = Object.keys(newVal);
        if (keys.length > 0) {
          const objectEntries = Object.entries(newVal);
          if (!objectEntries.some((key_value) => key_value[1] !== '')) {
            this.filterObject = {};
            this.$cookies.remove('templates_filter');
            this.tableKey++;
          } else {
            this.updatedTableJobs = this.tableJobs.filter((row) =>
              this.filterFunc(row)
            );

            this.$cookies.set('templates_filter', newVal);
          }
        } else {
          this.$cookies.remove('templates_filter');
          this.updatedTableJobs = this.tableJobs;
          this.tableKey++;
        }
      },
      deep: true,
    },
    search: {
      async handler(newValue, oldValue) {
        if (oldValue !== '' && newValue === '') {
          this.skip = 0;
          this.isSearchActive = false;
          this.filterObject = {};
          this.tableJobs = [];
          await this.getJobs();

          this.tableKey++;
        }
      },
    },
    currentTool: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.tableKey++;
      },
    },
  },
  async mounted() {
    this.copyFromPage = this.tool;
    const { id } = this.$route.params;
    if (Object.keys(this.job).length === 0 && id) {
      await this.$store.dispatch('getJob', id);
    }
    const divTable = this.$refs['div-table'];
    divTable.addEventListener('scroll', this.onScroll);
    await this.$store.dispatch('getSites');
    this.sites.forEach((site) => {
      const filtered = {
        label: site.siteName,
        id: site._id,
      };
      this.sitesFiltered.push(filtered);
      if (site._id === this.user.location) {
        this.site = filtered;
      }
    });

    return this.getJobs();
  },
  methods: {
    getSelectedTaskTitle(task) {
      const taskJob = this.tableJobs.find((job) => job._id === task.jobId);

      let selectedTaskTitle = '';

      if (taskJob) {
        selectedTaskTitle = `Job: ${taskJob.title} `;
      }

      if (task.folderTitle) {
        selectedTaskTitle += `\nFolder: ${task.folderTitle}`;
      }

      return selectedTaskTitle;
    },
    getJobLocation(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser.location) {
        const { location } = job.prodCreatedByUser;
        if (typeof location === 'object') {
          return job.prodCreatedByUser.location.siteName;
        }
        return this.$store.getters.siteName(location);
      }
      return '';
    },
    siteSelected() {
      this.skip = 0;
      this.tableJobs = [];
      return this.getJobs();
    },
    async getJobs() {
      if (this.tableLoading) return false;
      this.tableLoading = true;
      const search = {
        type: this.currentTool,
        search: this.search.split(','),
      };
      if (this.currentTool === 'publisher' && this.site?.id) {
        search.location = this.site.id;
      }
      const result = await this.$store.dispatch('getJobs', search);

      let startIndex;
      if (result.isNewQuery) {
        // Recreate the full table
        this.tableJobs = [];
        startIndex = 0;
      } else {
        // Just create the new list items
        startIndex = this.tableJobs.length;
      }

      for (let i = startIndex; i < this.jobs.length; i++) {
        const job = this.jobs[i];
        this.tableJobs.push(job);
      }

      this.tableLoading = false;
    },
    async getSearchJobs() {
      if (this.tableLoading) return false;
      this.skip = 0;
      if (this.search && this.search.length > 0) {
        this.isSearchActive = true;
        this.tableLoading = true;
        const search = {
          type: this.currentTool,
          search: this.search.split(','),
        };
        if (this.currentTool === 'publisher' && this.site?.id) {
          search.location = this.site.id;
        }
        await this.$store.dispatch('getJobs', search);
        this.tableJobs = [];
        if ((this.skip === 0 || this.skip) && this.limit) {
          this.tableJobs.push(
            ...this.jobs.slice(this.skip, this.limit + this.skip)
          );
        } else {
          this.tableJobs.push(...this.jobs.slice(this.skip));
        }
        this.tableLoading = false;
      } else {
        this.isSearchActive = false;
        this.tableJobs = [];
        return this.getJobs();
      }
    },
    getPublisherTasks() {
      this.currentTool = 'publisher';
      this.skip = 0;
      this.$store.commit('SET_JOBS_LASTKEY', null);
      this.tableJobs = [];
      return this.getJobs();
    },
    getEditorTasks() {
      this.currentTool = 'editor';
      this.skip = 0;
      this.$store.commit('SET_JOBS_LASTKEY', null);
      this.tableJobs = [];
      return this.getJobs();
    },
    async onSelect(item) {
      this.selected = item._id;
      this.isLoading = true;
      try {
        await this.$store.dispatch('getJobTasks', {
          jobId: item._id,
          allTasks: true,
        });
      } catch (error) {
        console.log('error', error);
        this.isLoading = false;
      }

      this.isLoading = false;
      this.showTasks = this.tasks.filter((task) => task.taskType !== 'folder');
      this.showTasks.forEach((showTask, i) => {
        if (showTask.folderId && showTask.folderId !== '') {
          const folderTask = this.tasks.filter((taskFilter) => {
            return taskFilter._id === showTask.folderId;
          })[0];
          if (folderTask) {
            this.showTasks[i].folderTitle = folderTask.taskName;
          }
        }
      });
      this.showTasksActiveIds = [];
    },
    isSelected(row) {
      if (row._id === this.selected) return 'table-list-row row-selected';
      return 'table-list-row';
    },
    taskClass(id, selected = false) {
      let className = 'task-preview';
      if (selected && this.selectedTasksActiveIds.includes(id)) {
        className += ' selected';
      } else if (!selected && this.showTasksActiveIds.includes(id)) {
        className += ' selected';
      }
      return className;
    },
    clickTask(task, selected = false) {
      if (selected) {
        if (this.selectedTasksActiveIds.includes(task._id)) {
          this.deselectTasks(task._id, true);
        } else {
          this.selectedTasksActiveIds.push(task._id);
        }
        return true;
      }
      if (this.showTasksActiveIds.includes(task._id)) {
        this.deselectTasks(task._id);
      } else {
        this.showTasksActiveIds.push(task._id);
      }
    },
    deselectTasks(id, selected = false) {
      if (selected) {
        const classIndex = this.selectedTasksActiveIds.indexOf(id);
        this.selectedTasksActiveIds.splice(classIndex, 1);
        return true;
      }
      const classIndex = this.showTasksActiveIds.indexOf(id);
      this.showTasksActiveIds.splice(classIndex, 1);
      return true;
    },
    getAndFormatDropValues(dropType) {
      let namesFormated = [];
      if (!dropType) return '';
      if (typeof dropType !== 'string' && dropType.length > 1) {
        dropType.forEach((type) => {
          namesFormated.push(type.text);
        });
      } else if (typeof dropType === 'string') {
        namesFormated = dropType;
      } else {
        namesFormated = dropType[0].text;
      }
      return typeof namesFormated === 'string'
        ? namesFormated
        : namesFormated.join(', ');
    },
    selectAll(selected = false) {
      if (selected) {
        this.selectedTasksActiveIds = [];
        this.selectedTasks.forEach((item) => {
          this.selectedTasksActiveIds.push(item._id);
        });
        return true;
      }
      this.showTasksActiveIds = [];
      this.showTasks.forEach((item) => {
        this.showTasksActiveIds.push(item._id);
      });
    },
    copyTask() {
      this.showTasks.forEach((task) => {
        if (this.showTasksActiveIds.includes(task._id)) {
          const taskIndex = this.selectedTasks.findIndex(
            (item) => item._id === task._id
          );
          if (taskIndex < 0) {
            this.selectedTasks.push(task);
          } else {
            let trimmedTaskName = task.taskName;
            if (task.taskName.length > 35) {
              trimmedTaskName = `${task.taskName.substring(0, 22)}...`;
            }
            this.notificationMessage = `Task '${trimmedTaskName}' is already selected`;
            this.showDanger = true;
          }
        }
      });
    },
    removeTask() {
      this.selectedTasksActiveIds.forEach((id) => {
        const taskIndex = this.selectedTasks.findIndex(
          (item) => item._id === id
        );
        if (taskIndex >= 0) {
          this.selectedTasks.splice(taskIndex, 1);
        }
      });
      this.selectedTasksActiveIds = [];
    },
    handleSort(field, order) {
      this.isSortActive = true;
      this.limit = 100;
      this.isAsc = order === 'asc';

      if (this.search) {
        this.tableJobs = this.jobs;
      }

      this.limit = 15;
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight - 1
      ) {
        this.getMoreJobs();
      }
    },

    getMoreJobs() {
      if (this.search) {
        this.skip += this.limit;
        if ((this.skip === 0 || this.skip) && this.limit) {
          this.tableJobs.push(
            ...this.jobs.slice(this.skip, this.limit + this.skip)
          );
        } else {
          this.tableJobs.push(...this.jobs.slice(this.skip));
        }
      } else if (this.jobsLastKey) {
        this.getJobs();
      }
    },
    async close(jobId) {
      const search = {
        type: this.copyFromPage === 'editor' ? 'editor' : 'publisher',
        search: [''],
      };
      await this.$store.dispatch('getJobs', search);

      if (jobId) {
        this.$router.push(`/view/${this.copyFromPage}/${jobId}`);
      } else {
        this.$router.push({
          name: `${this.copyFromPage}-list`,
        });
      }
    },
    async finish() {
      this.isLoading = true;

      const selectedTaskIds = this.selectedTasks.map((task) => {
        return {
          _id: task._id,
          jobId: task.jobId,
          folderId: task.folderId,
          folderTitle: task.folderTitle,
        };
      });

      let copyFromSuceeded = true;
      const copyFromProgress = this.$buefy.toast.open({
        message: `Initiating task copy process: 0 of ${selectedTaskIds.length} tasks completed`,
        type: 'is-info',
        duration: 500000,
        position: 'is-bottom',
      });

      try {
        for (let i = 0; i < selectedTaskIds.length; i++) {
          const task = selectedTaskIds[i];

          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('copyFromTask', {
            jobId: this.job._id,
            task,
          });
          copyFromProgress.message = `Task copy in progress: ${i + 1} of ${
            selectedTaskIds.length
          } tasks completed`;
        }

        copyFromProgress.close();
      } catch (error) {
        copyFromProgress.close();
        copyFromSuceeded = false;
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Something went wrong while copying from another job',
          type: 'is-danger',
        });
      }

      await this.close(this.job._id);

      this.isLoading = false;

      if (copyFromSuceeded) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Tasks copied successfully.',
          type: 'is-success',
          position: 'is-bottom',
        });
      }
    },

    async handleSelectEverything() {
      this.isLoading = true;
      this.getMoreJobs();

      const filterKeys = Object.keys(this.filterObject);

      let sortedJobs = [...this.jobs];
      if (
        filterKeys.some(
          (key) => this.filterObject[key] && this.filterObject[key] !== ''
        )
      )
        sortedJobs = [...this.updatedTableJobs];

      sortedJobs = sortedJobs.sort(this.customSortDocNumber);

      // const tasksSum = 0;
      let limitReached = false;
      for (let index = 0; index < sortedJobs.length; index++) {
        const job = sortedJobs[index];
        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('getJobTasks', {
          jobId: job._id,
          allTasks: true,
        });

        // Filtering tasks to the first step
        this.showTasks = this.tasks.filter(
          (taskToFind) =>
            taskToFind.taskType !== 'folder' &&
            taskToFind.childConfig &&
            Array.isArray(taskToFind.childConfig) &&
            taskToFind.childConfig.length > 0
        );
        this.showTasksActiveIds = [];

        // Adding tasks to selectedTasks list
        const tasksLimit = 30;
        // eslint-disable-next-line no-restricted-syntax
        for (const task of this.showTasks) {
          if (this.selectedTasks.length === tasksLimit) {
            this.showLimitMessage = true;

            // eslint-disable-next-line no-await-in-loop
            await this.$store.dispatch('getJob', task.jobId);
            this.selected = this.job._id;
            this.limitMessage = `Only ${tasksLimit} tasks were imported, the limit was reached. The last template to get tasks was: ${this.job.title}`;
            limitReached = true;
            break;
          }
          const taskIndex = this.selectedTasks.findIndex(
            (itemToFind) => itemToFind._id === task._id
          );
          if (taskIndex < 0) this.selectedTasks.push(task);
        }

        if (limitReached) break;
      }
      if (!limitReached) this.showTasks = [];
      this.isLoading = false;
    },

    customSortDocNumber(a, b, isAsc) {
      this.isSortDocNumberActive = true;

      if (this.isAsc !== null) isAsc = this.isAsc;

      const aDocumentNumber = a.documentNumber;
      const bDocumentNumber = b.documentNumber;

      const regexSearch1 = /^[a-zA-Z]{2}[0-9]{4}[a-zA-Z]{1}$/gm;
      const regexSearch2 = /^[a-zA-Z]{2}[0-9]{4}[a-zA-Z]{1}$/gm;

      if (isAsc) {
        // If one of the values has the format XX####X and the other not,
        // it will have the preference when sorting
        if (
          regexSearch1.test(aDocumentNumber) &&
          !regexSearch2.test(bDocumentNumber)
        ) {
          return -1;
        }

        regexSearch1.lastIndex = 0;
        regexSearch2.lastIndex = 0;
        if (
          !regexSearch1.test(aDocumentNumber) &&
          regexSearch2.test(bDocumentNumber)
        ) {
          return 1;
        }

        regexSearch1.lastIndex = 0;
        regexSearch2.lastIndex = 0;
        // If both of them have the format,
        // then check which one should show up first
        if (
          regexSearch1.test(aDocumentNumber) &&
          regexSearch2.test(bDocumentNumber)
        ) {
          const numbersFirst = parseInt(aDocumentNumber.split(/\D/)[2], 10);
          const numbersSecond = parseInt(bDocumentNumber.split(/\D/)[2], 10);

          if (numbersFirst > numbersSecond) return 1;
          if (numbersFirst < numbersSecond) return -1;
          return 0;
        }

        // If none of the values is on the format above, sort by alphanumeric
        return aDocumentNumber.localeCompare(bDocumentNumber, 'en', {
          numeric: true,
        });
      }

      // If one of the values has the format XX####X and the other not,
      // it will have the preference when sorting
      if (
        regexSearch1.test(aDocumentNumber) &&
        !regexSearch2.test(bDocumentNumber)
      ) {
        return -1;
      }

      regexSearch1.lastIndex = 0;
      regexSearch2.lastIndex = 0;
      if (
        !regexSearch1.test(aDocumentNumber) &&
        regexSearch2.test(bDocumentNumber)
      ) {
        return 1;
      }

      regexSearch1.lastIndex = 0;
      regexSearch2.lastIndex = 0;
      // If both of them have the format,
      // then check which one should show up first
      if (
        regexSearch1.test(aDocumentNumber) &&
        regexSearch2.test(bDocumentNumber)
      ) {
        const numbersFirst = parseInt(aDocumentNumber.split(/\D/)[2], 10);
        const numbersSecond = parseInt(bDocumentNumber.split(/\D/)[2], 10);

        if (numbersFirst > numbersSecond) return -1;
        if (numbersFirst < numbersSecond) return 1;

        return 0;
      }

      // If none of the values is on the format above, sort by alphanumeric
      const result = aDocumentNumber.localeCompare(bDocumentNumber, 'en', {
        numeric: true,
      });

      if (result === 1) return -1;
      if (result === -1) return 1;
      return result;
    },

    filterFunc(row) {
      // eslint-disable-next-line no-restricted-syntax
      const keys = Object.keys(this.filterObject);
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          let value = row[key];

          if (typeof value === 'object' && value.text) value = value.text;

          if (value == null) return false;
          if (Number.isInteger(value)) {
            if (value !== Number(this.filterObject[key])) return false;
          } else {
            const re = new RegExp(this.filterObject[key], 'i');
            if (typeof value === 'boolean') value = `${value}`;
            if (!value.match(re)) return false;
          }
        }
        return true;
      }
    },

    formatFields(job, field) {
      let namesFormated = [];
      if (!job[field]) return '';
      if (typeof job[field] !== 'string' && job[field].length > 1) {
        job[field].forEach((type) => {
          namesFormated.push(type.text);
        });
      } else if (typeof job[field] === 'string') {
        namesFormated = job[field];
      } else if (job[field][0]) {
        namesFormated = job[field][0].text ? job[field][0].text : job[field][0];
      } else {
        console.log(
          `Failed to extract text for job ${job} and field ${field} with data ${job[field]}`
        );
        namesFormated = '';
      }
      return typeof namesFormated === 'string'
        ? namesFormated
        : namesFormated.join(', ');
    },
  },
};
</script>
<style lang="scss" scoped>
$siemens-logo-color: #009999;
.container {
  margin: 50px;
  max-width: 100%;
}
.title {
  color: #777;
  margin: 0 0 50px 0;
  font-size: 24px;
  display: inline-flex;
  .desc {
    color: black;
    font-size: 15px;
    line-height: 1.7;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: 850px auto auto;
  .grid-item {
    padding-top: 30px;
    max-height: 530px;
    overflow: auto;
    font-size: 13px;
    &.tasks {
      position: relative;
      padding: 10px;
      margin-left: 10px;
      border-left: 1px solid gray;
      height: 500px;
    }
  }
}
.btn-outline-primary {
  width: 100px;
  margin: 5px;
  font-size: 13px;
}
table td,
table th {
  vertical-align: middle;
}

td.md-table-cell .md-table-cell-container {
  max-width: fit-content !important;
  padding: 0px !important;
}
.btn-primary {
  background-color: $siemens-logo-color;
}
.task-preview {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 0 20px 20px 0;

  color: #aaaaaa;
  cursor: pointer;
  position: relative;
  &.selected {
    border: 3px solid $siemens-logo-color;
    color: $siemens-logo-color;
  }
}
.box-wrapper {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  padding: 10px;
}
.btn-wrap {
  button {
    height: 30px;
    font-size: 14px;
    width: 100px;
  }
}
.search {
  height: 100%;
  width: 100%;
  margin: 5px 0;
  display: flex;
  .search-input input {
    border: 0px !important;
    background-color: #e0e0e0 !important;
    outline: none;
    box-shadow: none;
    width: 300px !important;
  }
  form {
    .field {
      margin-right: 10px;
    }
    width: fit-content;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
}
.selectField {
  max-width: 400px;
  display: inline-flex;
}

.select-everything {
  text-align: center;
  border: 1px solid $icon-active-purple;
  width: fit-content;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active-purple !important;
  }
}

.select-everything:hover {
  cursor: pointer;
  background-color: $icon-active-purple;
  border: 1px solid $icon-active-purple;

  p {
    color: white !important;
  }
}

.deselect-everything {
  margin-left: 10px;
  text-align: center;
  border: 1px solid $icon-active-purple;
  width: fit-content;

  p {
    margin: 4px;
    font-size: 14px;
    color: $icon-active-purple !important;
  }
}

.deselect-everything:hover {
  cursor: pointer;
  background-color: $icon-active-purple;
  border: 1px solid $icon-active-purple;

  p {
    color: white !important;
  }
}

.text-column-limit {
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 5;
  -moz-line-clamp: 5;
  -ms-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
}

.limit-message {
  width: 350px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
}

.text-column-limit {
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 5;
  -moz-line-clamp: 5;
  -ms-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
  transition: 0s overflow;
  transition: 0s display;

  &:hover {
    width: 160px;
    overflow: visible;
    display: table;
    transition-delay: 1s;
  }
}

.folder-title {
  position: absolute;
  bottom: 100%;
  max-height: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.tasks-menu {
  margin-bottom: 5%;
}
</style>
