<template>
  <div class="container">
    <div class="form-container">
      <h4 style="margin-bottom: 50px">
        {{ $t('create_task.create_new_task') }}
      </h4>
      <md-field>
        <label> {{ $t('create_task.task_name') }} </label>
        <md-input required v-model="name"></md-input>
      </md-field>
      <div class="select-type">
        <h5>{{ $t('create_task.select_software_tool') }}</h5>
        <button
          :class="'type-button' + (type === 'diagram' ? ' type-active' : '')"
          @click="type = 'diagram'"
        >
          {{ $t('create_task.diagram') }}
        </button>
        <button
          :class="
            'type-button' + (type === 'instruction' ? ' type-active' : '')
          "
          @click="type = 'instruction'"
        >
          {{ $t('create_task.instruction') }}
        </button>
        <button
          :class="'type-button' + (type === 'table' ? ' type-active' : '')"
          @click="type = 'table'"
          v-if="false"
        >
          {{ $t('create_task.worksheet') }}
        </button>
      </div>
    </div>
    <div class="buttons-container">
      <button class="cancel-button" @click="cancel">
        {{ $t('create_task.cancel') }}
      </button>
      <button
        class="submit-button"
        :disabled="name === '' || creatingTask"
        @click="done"
      >
        {{ $t('create_task.done') }}
      </button>
      <b-loading
        :is-full-page="false"
        :active.sync="creatingTask"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import FoldersManagerMixin from '../../mixins/FoldersManagerMixin';
import KeyHandlerMixin from '../../mixins/KeyHandlerMixin';

export default {
  name: 'tool-create-task',
  props: {
    tool: String,
  },
  data() {
    return {
      name: '',
      type: 'diagram',
      creatingTask: false,
    };
  },
  mixins: [FoldersManagerMixin, KeyHandlerMixin],
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
  },
  async mounted() {
    if (Object.keys(this.job).length === 0) {
      const { id } = this.$route.params;
      await this.$store.dispatch('getJob', id);
    }
    this.onSaveCallback = {
      callback: this.done,
      isSave: true,
    };
    this.onCancelCallback = {
      callback: this.cancel,
      isCancel: true,
    };
  },
  methods: {
    cancel() {
      const routeName = `${this.tool}-tasks`; // e.g: 'editor-tasks'
      if (this.$route.query.token) {
        const { token } = this.$route.query;
        if (this.$route.query.folderId) {
          return this.$router.push({
            path: `/view/${this.tool}/${this.job._id}/folder/${this.$route.query.folderId}`,
            query: { token },
          });
        }
        this.$router.push({
          name: routeName,
          params: { id: this.job._id },
          query: { token },
        });
      } else {
        if (this.$route.query.folderId) {
          return this.$router.push({
            path: `/view/${this.tool}/${this.job._id}/folder/${this.$route.query.folderId}`,
          });
        }
        this.$router.push({
          name: routeName,
          params: { id: this.job._id },
        });
      }
    },
    async done() {
      if (!this.name) return false;

      this.creatingTask = true;
      const allTasks = await this.$store.dispatch('getJobTasks', {
        jobId: this.job._id,
        allTasks: true,
        return: true,
      });
      const maxValueOfOrder = Math.max(...allTasks.map((o) => o.taskOrder), 0);
      let taskOrder = maxValueOfOrder + 1;
      if (this.folderId) {
        const folder = allTasks.find((item) => item._id === this.folderId);
        const tasksInFolder = allTasks.filter(
          (item) => item.folderId === this.folderId
        );
        taskOrder = tasksInFolder.length + folder.taskOrder + 1;
      }
      // create the task object
      let newTask = {
        taskType: this.type,
        taskName: this.name,
        jobId: this.job._id,
        taskOrder,
      };
      if (this.type !== 'table') {
        newTask = {
          childConfig: [],
          type: this.job.type,
          taskType: this.type,
          taskName: this.name,
          taskOrder,
          jobId: this.job._id,
          parent_name: this.job.name,
          workOrderName: this.job.workOrderName,
          workOrderNumber: this.job.workOrderNumber,
          partNumber: this.job.partNumber,
          userEnteredPartNumber: this.job.userEnteredPartNumber,
          model: this.job.model,
          frameType: this.job.frameType,
          title: this.job.title,
          brand: this.job.brand,
          documentNumber: this.job.documentNumber,
          revision: this.job.revision,
          projectInfoText: this.job.projectInfoText,
          customerInfoText: this.job.customerInfoText,
          completenessPercentage: 0,
          tagName: 'EMF-TASKLIST-ITEM',
          cssText: 'width: 220px; height: 220px;',
          classList: [],
        };
      }

      let taskId = '';
      try {
        if (this.folderId) {
          newTask.folderId = this.folderId;
          newTask.folderTitle = this.folderName;
        }
        const createdTask = await this.$store.dispatch(
          'createNewTask',
          newTask
        );
        taskId = createdTask.id;
      } catch (err) {
        console.error('Failed to get new TaskNumber');
        return false;
      }

      await this.$store.dispatch('updateTasksOrder', { jobId: this.job._id });
      // open the new task created
      const routerName = `${this.tool}-template`; // e.g: 'editor-template'
      setTimeout(() => {
        if (this.$route.query.token) {
          const { token } = this.$route.query;

          this.$router.push({
            name: routerName,
            params: { id: this.job._id, taskNumber: taskId },
            query: { token },
          });
        } else {
          this.$router.push({
            name: routerName,
            params: { id: this.job._id, taskNumber: taskId },
          });
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
button:not(:disabled) {
  cursor: pointer;
}
.select-type {
  color: #777;
  margin-top: 100px;
  margin-bottom: 30px;
}
.type-button {
  border: 1px solid #969696;
  color: #969696;
  width: 250px;
  height: 60px;
  font-size: 18px;
  margin: 0;
}
.type-active {
  border: 2px solid #1976d2;
  color: #1976d2;
}
.loading-div {
  max-width: 400px;
  max-height: 400px;
  position: relative;
}
</style>
