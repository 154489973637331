import InspectorFolders from '../components/Inspector/Folders';
import InspectorList from '../components/Inspector/List';
import InspectorTasks from '../components/Inspector/Tasks';
import InspectorTemplate from '../components/Inspector/Template';
import InspectorTaskView from '../modules/Inspector/views/TaskView';

const inspectorRoutes = [
  {
    path: '/view/inspector',
    name: 'inspector-list',
    component: InspectorList,
    meta: { authRequired: true },
  },
  {
    path: '/view/inspector/:id',
    name: 'inspector-tasks',
    component: InspectorTasks,
    meta: { authRequired: true },
  },
  {
    path: '/view/inspector/:id/folder/:folderId',
    name: 'inspector-folders',
    component: InspectorFolders,
    meta: { authRequired: true },
  },
  {
    path: '/view/inspector/:id/:taskNumber',
    name: 'inspector-template',
    component: InspectorTaskView,
    meta: { authRequired: true, needsTaskLock: true },
  },
  {
    path: '/view/inspector/pol/:id/:taskNumber',
    name: 'inspector-template',
    component: InspectorTemplate,
    meta: { authRequired: true, needsTaskLock: true, refreshToken: true },
  },
];

export default inspectorRoutes;
