<template></template>

<script>
import { handleRedirect } from '../../services/auth';

export default {
  async created() {
    // reset template list cookies
    this.$cookies.remove('editor_page');
    this.$cookies.remove('report_page');
    this.$cookies.remove('inspector_page');
    this.$cookies.remove('publisher_page');

    this.$cookies.remove('editor_page_lastKey');
    this.$cookies.remove('report_page_lastKey');
    this.$cookies.remove('publisher_page_lastKey');
    this.$cookies.remove('inspector_page_lastKey');

    await handleRedirect();
  },
};
</script>

<style></style>
