<template>
  <div
    class="dij-diagrambuilder-inspections"
    ref="measurementListContainer"
    @scroll="handleScroll"
  >
    <template v-if="inspections[selectedInspectionIndex]">
      <inspection-keypad
        ref="keypad"
        v-model="inspections[selectedInspectionIndex]"
        @next="nextMeasurement"
        @done="saveInspectionByInspectionNumber"
        @deleteInspection="deleteInspection"
        @forceSaveInspection="forceSaveInspection"
      />
    </template>
    <template v-else>
      <div v-for="(inspection, index) in inspections" :key="inspection._id">
        <template
          v-if="
            inspection.type !== 'text' &&
            inspection.type !== 'arrow' &&
            inspection.type !== 'line'
          "
        >
          <div
            class="dij-diagrambuilder-inspection"
            :style="[
              inspection.isLocked,
              {
                color: inspection.isLocked ? 'lightgray' : 'black',
              },
            ]"
            @click="selectInspection(inspection)"
          >
            <div
              class="dij-diagrambuilder-inspection-number"
              @click="onInspectionNumberClick(inspection)"
            >
              <b-icon
                v-if="inspection.isLocked"
                icon="account-lock"
                class="locked-icon"
                :title="`Currently this bubble is being modified by ${inspection.isLockedBy}`"
              />
              <p>{{ inspection.number }}</p>
            </div>
            <div class="dij-diagrambuilder-inspection-data">
              <p class="dij-diagrambuilder-inspection-type">
                {{ $t(inspection.type) }}
                <template v-if="shouldShowVisualInspectionFine(inspection)">
                  <span style="color: green">- Fine</span>
                </template>
              </p>
              <template v-if="tool === 'inspector'">
                <template v-if="inspection.type === 'visual'">
                  <div>
                    {{ $t('rubbed') }}:
                    {{
                      inspection.measurements.length !== 0 &&
                      inspection.measurements[0].value &&
                      inspection.measurements[0].value !== 'notCompleted'
                        ? $t(inspection.measurements[0].value)
                        : '--'
                    }}
                  </div>
                  <div>
                    {{ $t('damaged') }}:
                    {{
                      inspection.measurements.length !== 0 &&
                      inspection.measurements[1].value &&
                      inspection.measurements[1].value !== 'notCompleted'
                        ? $t(inspection.measurements[1].value)
                        : '--'
                    }}
                  </div>
                  <div>
                    {{ $t('pitted') }}:
                    {{
                      inspection.measurements.length !== 0 &&
                      inspection.measurements[2].value &&
                      inspection.measurements[2].value !== 'notCompleted'
                        ? $t(inspection.measurements[2].value)
                        : '--'
                    }}
                  </div>
                  <div>
                    {{ $t('eroded') }}:
                    {{
                      inspection.measurements.length !== 0 &&
                      inspection.measurements[3].value &&
                      inspection.measurements[3].value !== 'notCompleted'
                        ? $t(inspection.measurements[3].value)
                        : '--'
                    }}
                  </div>
                </template>
                <template v-else>
                  <template
                    v-if="
                      inspection.type !== 'visual' &&
                      inspection.type === 'runout' &&
                      !inspection.multipleMeasurements
                    "
                  >
                    <div class="runout-one-measurement">
                      <div
                        :style="
                          isMeasurementOk(inspection, 0)
                            ? 'color:green'
                            : inspection.measurements &&
                              inspection.measurements[0] &&
                              inspection.measurements[0].value === ''
                            ? 'color: black'
                            : 'color:red'
                        "
                      >
                        {{ $t('tir') }}:
                        {{
                          inspection.measurements.length !== 0 &&
                          inspection.measurements[0] &&
                          inspection.measurements[0].value
                            ? inspection.measurements[0].value
                            : '--'
                        }}
                      </div>
                      <div
                        :style="
                          isMeasurementOk(inspection, 0)
                            ? 'color:green'
                            : inspection.measurements &&
                              inspection.measurements[0] &&
                              inspection.measurements[0].value === ''
                            ? 'color: black'
                            : 'color:red'
                        "
                      >
                        Pos :
                        {{
                          inspection.measurements.length !== 0 &&
                          inspection.measurements[1] &&
                          inspection.measurements[1].value !== ''
                            ? inspection.measurements[1].value
                            : '--'
                        }}
                        deg
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-for="number in inspection.measurementCount"
                      :key="number"
                    >
                      <template v-if="inspection.type !== 'freefinding'">
                        <template v-if="inspection.type === 'generic'">
                          <span
                            class="generic-label"
                            :title="inspection.measurementName"
                          >
                            {{ inspection.measurementName }}
                          </span>
                          <span
                            class="generic-label"
                            :title="inspection.measurements[number - 1].value"
                          >
                            {{
                              inspection.measurements[number - 1].value !== ''
                                ? inspection.measurements[number - 1].value
                                : '--'
                            }}</span
                          >
                        </template>
                        <template v-else>
                          <div
                            :style="
                              isMeasurementOk(inspection, number - 1)
                                ? 'color:green'
                                : isInspectionMeasurementValid(
                                    inspection,
                                    number - 1
                                  )
                                ? 'color: black'
                                : 'color:red'
                            "
                          >
                            Pos{{ number }} :
                            {{
                              inspection.measurements &&
                              inspection.measurements[number - 1] &&
                              inspection.measurements[number - 1].value
                                ? inspection.measurements[number - 1].value
                                : '--'
                            }}
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                <template v-if="showExpectedValue(inspection)">
                  <b-field
                    :class="{ hidden: !inspection.hasDescription }"
                    :label="$t('marker_description')"
                    class="dij-diagrambuilder-inspection-tolerance"
                  >
                    <b-input
                      :disabled="inspection.isLocked"
                      type="text"
                      v-model="inspection.description"
                      @blur="onBlur(inspection)"
                  /></b-field>
                  <b-field :label="$t('expected_value')">
                    <b-input
                      type="number"
                      step="any"
                      :disabled="isTaskCompleted || inspection.isLocked"
                      v-model="inspection.expectedValue"
                      @input="updateToleranceDecimalPlaces(inspection)"
                      @blur="onBlur(inspection)"
                  /></b-field>
                  <div class="dij-diagrambuilder-inspection-tolerances">
                    <b-field
                      :label="
                        inspection.toleranceType == 'normal' ||
                        inspection.toleranceType == 'positive'
                          ? $t('positive_tolerance')
                          : $t('negative_tolerance')
                      "
                    >
                      <b-input
                        type="number"
                        step="any"
                        :disabled="isTaskCompleted || inspection.isLocked"
                        v-model="inspection.positiveTolerance"
                        @blur="onBlur(inspection)"
                      />
                    </b-field>
                    <b-field
                      :label="
                        inspection.toleranceType == 'normal' ||
                        inspection.toleranceType == 'negative'
                          ? $t('negative_tolerance')
                          : $t('positive_tolerance')
                      "
                    >
                      <b-input
                        type="number"
                        step="any"
                        :disabled="isTaskCompleted || inspection.isLocked"
                        v-model="inspection.negativeTolerance"
                        @blur="onBlur(inspection)"
                      />
                    </b-field>
                  </div>
                </template>
                <template v-else-if="showTolerance(inspection)">
                  <b-field
                    :class="{ hidden: !inspection.hasDescription }"
                    :label="$t('marker_description')"
                    class="dij-diagrambuilder-inspection-tolerance"
                  >
                    <b-input
                      :disabled="inspection.isLocked"
                      type="text"
                      v-model="inspection.description"
                      @blur="onBlur(inspection)"
                  /></b-field>
                  <b-field
                    :label="$t('tolerance')"
                    class="dij-diagrambuilder-inspection-tolerance"
                  >
                    <b-input
                      type="number"
                      step="any"
                      :disabled="isTaskCompleted || inspection.isLocked"
                      v-model="inspection.tolerance"
                      @blur="onBlur(inspection)"
                  /></b-field>
                </template>
                <template v-else>
                  <template v-if="inspection.type === 'generic'">
                    <b-field
                      :label="$t('generic_name')"
                      class="dij-diagrambuilder-inspection-tolerance"
                    >
                      <b-input
                        type="text"
                        :disabled="isTaskCompleted || inspection.isLocked"
                        v-model="inspection.measurementName"
                        @blur="onBlur(inspection)"
                    /></b-field>
                  </template>
                  <b-field
                    :class="{ hidden: !inspection.hasDescription }"
                    :label="$t('marker_description')"
                    class="dij-diagrambuilder-inspection-tolerance"
                  >
                    <b-input
                      :disabled="inspection.isLocked"
                      type="text"
                      v-model="inspection.description"
                      @blur="onBlur(inspection)"
                  /></b-field>
                  <!-- Visual or Freefinding -->
                </template>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import InspectionKeypad from './InspectionKeypad';
import { UPDATE_BUBBLE } from '../../store/bubbles/actions/actionTypes';
import { GET_CURRENT_TASK } from '../../store/tasks/getters/getterTypes';
import { GET_CURRENT_JOB } from '../../store/jobs/getters/getterTypes';
import BubbleDTO from '../../shared/dtos/BubbleDTO';

export default {
  components: { InspectionKeypad },
  name: 'inspection-list',
  props: ['value', 'tool'],
  data() {
    return {
      selectedInspectionIndex: null,
      scrollValue: 0,
    };
  },
  computed: {
    inspections: {
      get() {
        const sortedValue = this.value
          .slice()
          .sort((a, b) => a.number - b.number);
        return sortedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    job() {
      return this.$store.getters[GET_CURRENT_JOB];
    },
    task() {
      return this.$store.state.task && this.$store.state.task._id
        ? this.$store.state.task
        : this.$store.getters[GET_CURRENT_TASK];
    },
    isTaskCompleted() {
      return this.task.workStatusCode
        ? this.task.workStatusCode.toString() === '2' && this.job.jobCompleted
        : false;
    },
  },
  methods: {
    countDecimalPlaces(value) {
      if (!value) {
        return 0;
      }

      const numString = typeof value === 'number' ? value.toString() : value;

      const parts = numString.split('.');

      if (parts.length === 2) {
        return parts[1].length;
      }

      return 0;
    },
    adjustToleranceValue(inspection) {
      const numberOfDecimals = this.countDecimalPlaces(
        inspection.expectedValue
      );

      const adjustTolerance = (tolerance) => {
        return tolerance !== undefined
          ? parseFloat(tolerance || 0).toFixed(numberOfDecimals)
          : tolerance;
      };

      inspection.positiveTolerance = adjustTolerance(
        inspection.positiveTolerance
      );
      inspection.negativeTolerance = adjustTolerance(
        inspection.negativeTolerance
      );
    },

    handleScroll() {
      if (this.$refs.measurementListContainer.scrollTop !== 0) {
        this.scrollValue = this.$refs.measurementListContainer.scrollTop;
      }
    },
    isInspectionMeasurementValid(inspection, measurementIndex) {
      if (measurementIndex > inspection.measurements.length) {
        console.error(
          `Inspection ${inspection._id} has measurement count${inspection.measurementCount} but measurements are ${inspection.measurements.length}`
        );
        return false;
      }

      return (
        inspection.measurements &&
        inspection.measurements[measurementIndex] &&
        inspection.measurements[measurementIndex].value === ''
      );
    },
    shouldShowVisualInspectionFine(inspection) {
      return (
        this.tool === 'inspector' &&
        inspection.type === 'visual' &&
        inspection.measurements
          .slice(0, 4)
          .every((measurement) => measurement.value === '')
      );
    },
    onInspectionNumberClick(inspection) {
      if (this.tool !== 'inspector') {
        this.$parent.focusOnMarker(inspection);
      }
    },
    isMeasurementOk(inspection, measurementNumber) {
      return this.$parent.isMeasurementOk(inspection, measurementNumber);
    },
    onBlur(inspection) {
      this.adjustToleranceValue(inspection);

      this.saveInspection(inspection);
    },
    showExpectedValue(inspection) {
      return inspection.type === 'diameter' || inspection.type === 'length';
    },
    showTolerance(inspection) {
      return inspection.type === 'runout';
    },
    updateToleranceDecimalPlaces(inspection) {
      if (inspection.expectedValue) {
        const expectedValueSplitted = inspection.expectedValue
          .toString()
          .split('.');
        const numberOfDecimalPlaces =
          expectedValueSplitted.length === 1
            ? 0
            : expectedValueSplitted[1].length;

        inspection.positiveTolerance = parseFloat(
          inspection.positiveTolerance,
          10
        ).toFixed(numberOfDecimalPlaces);
        inspection.negativeTolerance = parseFloat(
          inspection.negativeTolerance,
          10
        ).toFixed(numberOfDecimalPlaces);
      }
    },
    isSelectionAllowed() {
      return this.tool === 'inspector' && !this.isTaskCompleted;
    },
    selectInspection(selectedInspection) {
      if (!this.isSelectionAllowed() || selectedInspection.isLocked) {
        return;
      }
      const index = this.inspections.findIndex(
        (inspection) => inspection._id === selectedInspection._id
      );
      if (this.selectedInspectionIndex !== null) {
        this.$parent.blurFromMarker(
          this.inspections[this.selectedInspectionIndex]
        );
      }

      // Check if the selected inspection exists in the list
      if (index < 0) return;
      this.selectedInspectionIndex = index;
      this.$parent.showMultipleMeasurementSelector(
        this.inspections[this.selectedInspectionIndex]
      );
      this.$parent.focusOnMarker(
        this.inspections[this.selectedInspectionIndex]
      );

      if (this.$refs.keypad) {
        this.$refs.keypad.refreshUI();
      }
    },
    nextMeasurement() {
      this.$parent.showMultipleMeasurementSelector(
        this.inspections[this.selectedInspectionIndex]
      );
      this.$parent.remakeMarker(this.inspections[this.selectedInspectionIndex]);
    },
    saveInspectionByInspectionNumber() {
      this.saveInspection(this.inspections[this.selectedInspectionIndex]);

      this.$parent.blurFromMarker(
        this.inspections[this.selectedInspectionIndex]
      );
      this.$parent.remakeMarker(this.inspections[this.selectedInspectionIndex]);

      this.selectedInspectionIndex = null;

      setTimeout(() => {
        this.$refs.measurementListContainer.scrollTop = this.scrollValue;
      }, 0);
    },
    forceSaveInspection() {
      this.saveInspection(this.inspections[this.selectedInspectionIndex]);
    },
    saveInspection(inspection) {
      if (!inspection) {
        console.log('Cannot save invalid inspection');
        return;
      }
      const isInspector = this.tool === 'inspector';
      this.$store.dispatch(UPDATE_BUBBLE, {
        ...new BubbleDTO(inspection),
        isInspector,
      });
    },
    deleteInspection() {
      this.$parent.blurFromMarker(
        this.inspections[this.selectedInspectionIndex]
      );

      this.$parent.deleteInspection();

      this.selectedInspectionIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-diagrambuilder-inspections {
  position: absolute;
  right: 0;
  width: 225px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.dij-diagrambuilder-inspection {
  display: flex;
  justify-content: center;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 5px;
}
.dij-diagrambuilder-inspection-number {
  padding-top: 20px;
  flex: 0 0 30%;
  p {
    font-size: 200%;
    font-weight: bold;
    text-align: center;
  }
}

.locked-icon {
  color: red;
}

.dij-diagrambuilder-inspection-number:hover {
  cursor: pointer;
}
.dij-diagrambuilder-inspection-data {
  flex: 0 0 70%;
}
.dij-diagrambuilder-inspection-type {
  font-weight: bold;
  font-size: 110%;
}
.dij-diagrambuilder-inspection-tolerances {
  display: flex;
  gap: 3px;
}
.dij-diagrambuilder-inspection-tolerance {
  margin-bottom: 0.75rem;
}
// lower the text size for label and fields
.field ::v-deep label,
.control ::v-deep input {
  font-size: 75%;
}

.runout-one-measurement {
  display: flex;
  flex-direction: column;
}

.generic-label {
  display: block;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
