<template>
  <div>
    <b-modal
      :active="true"
      aria-role="dialog"
      aria-modal
      trap-focus
      scroll="keep"
      full-screen
      class="dij-modal-dynamic-width"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <div>
            {{ $t('mte_management') }}
          </div>
        </header>
        <div class="dij-mte-header">
          <b-button icon-left="camera" @click="toggleCamera">
            <span v-if="!isQrCodeReaderVisible">
              {{ $t('add_using_camera') }}
            </span>
            <span v-if="isQrCodeReaderVisible">
              {{ $t('close_camera') }}
            </span>
          </b-button>
          <div>
            <b-button icon-left="plus" @click="add">{{
              $t('add_manual_entry')
            }}</b-button>
          </div>
        </div>
        <section class="modal-card-body">
          <div class="camera">
            <QRCodeReaderVue
              v-if="isQrCodeReaderVisible"
              @data="parseQrCodeData"
            />
          </div>
          <div class="table">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('id') }}</th>
                  <th>{{ $t('type') }}</th>
                  <th>{{ $t('due_date') }}</th>
                  <th>{{ $t('master') }}</th>
                  <th>{{ $t('created_by') }}</th>
                  <th>{{ $t('created_at') }}</th>
                  <th>{{ $t('actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(mte, index) in mtes" :key="index">
                  <template v-if="itemsBeingEdited[mte.uid]">
                    <td>
                      <b-field
                        :type="
                          isIdEmpty(mte) || isIdDuplicated(mte)
                            ? 'is-danger'
                            : undefined
                        "
                        :message="
                          isIdEmpty(mte)
                            ? $t('mandatory_error_message', { field: $t('id') })
                            : isIdDuplicated(mte)
                            ? $t('unique_error_message', { field: $t('id') })
                            : undefined
                        "
                      >
                        <b-input v-model="mte.id" />
                      </b-field>
                    </td>
                    <td>
                      <b-field
                        :type="isTypeEmpty(mte) ? 'is-danger' : undefined"
                        :message="
                          isTypeEmpty(mte)
                            ? $t('mandatory_error_message', {
                                field: $t('type'),
                              })
                            : undefined
                        "
                      >
                        <b-select v-model="mte.type">
                          <optgroup :label="$t('masters')">
                            <option
                              v-for="mteType in masterMteTypes"
                              :value="mteType"
                              :key="mteType._id"
                            >
                              {{ mteType.name }}
                            </option>
                          </optgroup>
                          <optgroup :label="$t('non_masters')">
                            <option
                              v-for="mteType in nonMasterMteTypes"
                              :value="mteType"
                              :key="mteType._id"
                            >
                              {{ mteType.name }}
                            </option>
                          </optgroup>
                        </b-select>
                      </b-field>
                    </td>
                    <td>
                      <b-field
                        :type="
                          isDueDateEmptyOrInThePast(mte)
                            ? 'is-danger'
                            : undefined
                        "
                        :message="
                          isDueDateEmptyOrInThePast(mte)
                            ? $t(
                                'mandatory_date_must_not_be_in_past_error_message',
                                { field: $t('due_date') }
                              )
                            : undefined
                        "
                      >
                        <b-datepicker
                          v-model="mte.dueDate"
                          icon="calendar-today"
                          :min-date="minDate"
                          :years-range="[0, 10]"
                        />
                      </b-field>
                    </td>
                    <td>
                      <template v-if="mte.type && mte.type.needsMaster">
                        <b-field
                          :type="
                            isMasterNeededAndEmpty(mte)
                              ? 'is-danger'
                              : undefined
                          "
                          :message="
                            isMasterNeededAndEmpty(mte)
                              ? $t('mandatory_error_message', {
                                  field: $t('master'),
                                })
                              : undefined
                          "
                        >
                          <b-select v-model="mte.master">
                            <option :value="null">{{ $t('none') }}</option>
                            <option
                              v-for="masterMte in masterMtes"
                              :value="masterMte"
                              :key="masterMte.id"
                            >
                              {{ masterMte.id }} -
                              {{ masterMte.type.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </template>
                      <template v-else>
                        {{ $t('none') }}
                      </template>
                    </td>
                    <td>{{ mte.createdBy }}</td>
                    <td>
                      {{ getDateString(mte.createdAt) }}
                    </td>
                    <td class="dij-mte-actions">
                      <b-button @click="cancel(mte)">{{
                        $t('cancel')
                      }}</b-button>
                      <b-button
                        :disabled="!isSaveAllowed(mte)"
                        type="is-primary"
                        @click="save(mte)"
                        >{{ $t('save') }}</b-button
                      >
                    </td>
                  </template>
                  <template v-else>
                    <td>{{ mte.id }}</td>
                    <td>{{ mte.type.name }}</td>
                    <td>
                      {{ getDateString(mte.dueDate) }}
                    </td>
                    <td>
                      <template v-if="mte.master">
                        {{ mte.master.id }} - {{ mte.master.type.name }}
                      </template>
                      <template v-else>
                        {{ $t('none') }}
                      </template>
                    </td>
                    <td>{{ mte.createdBy }}</td>
                    <td>
                      {{ getDateString(mte.createdAt) }}
                    </td>
                    <td class="dij-mte-actions">
                      <b-button @click="edit(mte)">{{ $t('edit') }}</b-button>
                      <b-button
                        :disabled="!isRemoveAllowed(mte)"
                        @click="remove(mte)"
                        >{{ $t('delete') }}</b-button
                      >
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer class="modal-card-foot dij-mte-actions">
          <b-button @click="close">
            {{ $t('close') }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { v4 } from 'uuid';
import QRCodeReaderVue from './QRCodeReader';
import { GET_CURRENT_TASK } from '../../store/tasks/getters/getterTypes';

const uuidv4 = v4;

export default {
  emits: ['close'],
  data() {
    const today = new Date();
    return {
      mtes: [],
      isQrCodeReaderVisible: false,
      mteMasterTypes: [],
      needsMasterTypes: [],
      itemsBeingEdited: {},
      itemsBackup: {},
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    task() {
      return this.$store.state.task && this.$store.state.task._id
        ? this.$store.state.task
        : this.$store.getters[GET_CURRENT_TASK];
    },

    mteTypes() {
      return this.$store.state.mteTypes;
    },
    masterMteTypes() {
      return this.mteTypes
        .filter((mteType) => mteType.isMaster)
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },
    nonMasterMteTypes() {
      return this.mteTypes
        .filter((mteType) => !mteType.isMaster)
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    },
    masterMtes() {
      return this.mtes.filter((mte) =>
        this.mteMasterTypes.some((mteType) => mte.type === mteType)
      );
    },
    dateWithoutHours() {
      const nowDate = new Date();
      const nowDateWithoutHours = new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate()
      );
      return nowDateWithoutHours;
    },
  },
  async created() {
    this.mteMasterTypes = await this.$store.dispatch('getMteMasterTypes');
    this.needsMasterType = await this.$store.dispatch('getMteNeedsMasterTypes');
    // Detach from the original list
    const originalMtes = this.task.mtes;
    if (originalMtes) {
      this.mtes = [...originalMtes];
      this.mtes = this.mtes.map((mte) => {
        return { ...mte };
      });
      this.mtes.forEach((mte) => {
        mte.type = this.mteTypes.find((mteType) => mteType._id === mte.type);
        if (mte.master) {
          mte.master = this.mtes.find((item) => item.id === mte.master);
        }
        mte.dueDate = this.convertToDate(mte.dueDate);
        mte.createdAt = this.convertToDate(mte.createdAt);
        mte.uid = uuidv4();
      });
    }
  },
  components: {
    QRCodeReaderVue,
  },
  methods: {
    isRemoveAllowed(item) {
      return (
        !item.type.isMaster || this.mtes.every((mte) => mte.master !== item)
      );
    },
    edit(mte) {
      this.itemsBeingEdited[mte.uid] = mte;
      this.itemsBackup[mte.uid] = { ...mte };

      this.itemsBeingEdited = { ...this.itemsBeingEdited };
    },
    async remove(mte) {
      await this.$store.dispatch('deleteMte', { task: this.task, mte });
      const indexToDelete = this.mtes.findIndex((item) => item === mte);
      this.mtes.splice(indexToDelete, 1);
    },
    cancel(mte) {
      const itemsBackup = this.itemsBackup[mte.uid];
      if (itemsBackup) {
        // Updating item - Revert changes
        Object.keys(itemsBackup).forEach((key) => {
          this.itemsBeingEdited[mte.uid] = itemsBackup[key];
        });
      } else {
        // Creating item - Remove from list
        this.mtes.splice(this.mtes.length - 1, 1);
      }
      delete this.itemsBeingEdited[mte.uid];
      delete this.itemsBackup[mte.uid];
    },
    async save(mte) {
      const itemToSave = { ...mte };
      itemToSave.type = itemToSave.type._id;
      if (itemToSave.master) {
        itemToSave.master = itemToSave.master.id;
      }
      itemToSave.dueDate = this.convertToEpoch(itemToSave.dueDate);
      itemToSave.createdAt = this.convertToEpoch(itemToSave.createdAt);
      delete itemToSave.uid;

      const isNew = !this.itemsBackup[mte.uid];
      if (isNew) {
        await this.$store.dispatch('createMte', {
          task: this.task,
          mte: itemToSave,
        });
      } else {
        await this.$store.dispatch('updateMte', {
          task: this.task,
          mte: itemToSave,
        });
      }

      delete this.itemsBeingEdited[mte.uid];
      delete this.itemsBackup[mte.uid];

      this.itemsBeingEdited = { ...this.itemsBeingEdited };
    },
    toggleCamera() {
      this.isQrCodeReaderVisible = !this.isQrCodeReaderVisible;
    },
    close() {
      this.$emit('close');
    },
    addNewToolQrCode(newTool) {
      const newType = this.toolTypes.filter((toolType) => {
        return toolType.name === newTool.type;
      });
      if (newType) {
        [newTool.type] = newType;
      }
      this.addNewTool(newTool);
    },
    add() {
      const newMte = {
        uid: uuidv4(),
        id: null,
        type: null,
        dueDate: null,
        master: null,
        createdBy: this.user.fullname,
        createdAt: new Date(),
      };

      this.mtes.push(newMte);

      this.itemsBeingEdited[newMte.uid] = newMte;

      return newMte;
    },
    convertToEpoch(date) {
      return Math.round(
        (date.getTime() + date.getTimezoneOffset() * 60 * 1000) / 1000
      );
    },
    convertToDate(epoch) {
      const date = new Date();
      date.setTime(epoch * 1000 - date.getTimezoneOffset() * 60 * 1000);
      return date;
    },
    getDateString(date) {
      return date.toLocaleDateString();
    },

    isIdEmpty(item) {
      return !item.id;
    },
    isIdDuplicated(item) {
      return this.mtes.some((mte) => mte !== item && mte.id === item.id);
    },
    isTypeEmpty(item) {
      return !item.type;
    },
    isDueDateEmptyOrInThePast(item) {
      return !item.dueDate || this.dateWithoutHours > item.dueDate;
    },
    isMasterNeededAndEmpty(item) {
      return (
        !this.isTypeEmpty(item) &&
        item.type.needsMaster &&
        (!item.master || !item.master.type.isMaster)
      );
    },
    hasMasterAndMasterIsBeingEdited(item) {
      return item.master && this.itemsBeingEdited[item.master.uid];
    },
    isSaveAllowed(item) {
      return (
        !this.isIdEmpty(item) &&
        !this.isIdDuplicated(item) &&
        !this.isTypeEmpty(item) &&
        !this.isDueDateEmptyOrInThePast(item) &&
        !this.isMasterNeededAndEmpty(item) &&
        !this.hasMasterAndMasterIsBeingEdited(item)
      );
    },
    parseQrCodeData(data) {
      const newMte = this.add();
      newMte.id = data;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep div.animation-content.modal-content {
  display: flex;
  flex-direction: column;
}
// Set max width and correct font-size
div.dij-modal-dynamic-width {
  font-size: 16px;
}
// Set max heigth
section.modal-card-body {
  flex: 1 0 0;
  padding: 8px;
}
// hide modal close button
::v-deep button.modal-close.is-large {
  display: none;
}
.dij-mte-header {
  display: flex;
  justify-content: flex-end;
  position: relative;
  background-color: white;
  padding: 10px;
  gap: 10px;

  & > div.control {
    width: 250px;
  }
}

// Make padding smaller for header and footer
.modal-card-head,
.modal-card-foot {
  padding: 10px;
}

.modal-card-head {
  display: flex;
  justify-content: space-between;
}

.camera {
  width: 40%;
}

table {
  width: 100%;
  height: 80%;
  border-collapse: collapse;
  text-align: left;
  position: relative;
}

th,
td {
  padding: 0.25rem;
}

.table.body {
  width: 100%;
}

.error-text {
  position: relative;
  color: red;
  text-align: center;
  padding: 10px;
  top: 7px;
}

.error-date {
  position: relative;
  color: red;
  padding: 10px;
}

.dij-mte-actions {
  display: flex;
  gap: 10px;
}

.dij-mte-actions {
  justify-content: flex-end;
}
</style>
