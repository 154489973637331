<template>
  <tasks-template tool="inspector"> </tasks-template>
</template>

<script>
import TaskTemplate from '../ToolsTemplates/TaskPage';
import store from '../../store/index';

export default {
  components: {
    'tasks-template': TaskTemplate,
  },

  async beforeRouteEnter(to, {}, next) {
    const { id } = to.params;
    const { state } = store;
    if (!state.job || state.job.id !== id) {
      await store.dispatch('getJob', id);
    }
    next();
  },
};
</script>

<style></style>
