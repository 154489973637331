<template></template>

<script>
import { logout } from '../../services/auth';

export default {
  async mounted() {
    sessionStorage.clear();
    localStorage.removeItem('path');
    this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
    this.$cookies.set('switch-account', true);
    await logout();
  },
};
</script>

<style></style>
