<template>
  <div class="review-container">
    <h1 class="review-title">{{ $t('review') }}</h1>

    <div class="review-head-card">
      <div class="row">
        <div class="row-title">{{ $t('work_order_number') }}</div>
        <div class="row-value">
          {{
            orderCreationFields.workOrderNumber
              ? orderCreationFields.workOrderNumber
              : ''
          }}
        </div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('title') }}</div>
        <div class="row-value">
          {{ orderCreationFields.title ? orderCreationFields.title : '' }}
        </div>
      </div>
      <div v-if="orderCreationFields.originalOEMNumber" class="row">
        <div class="row-title">{{ $t('original_oem_number') }}</div>
        <div class="row-value">
          {{
            orderCreationFields.originalOEMNumber
              ? orderCreationFields.originalOEMNumber
              : ''
          }}
        </div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('serial_number') }}</div>
        <div class="row-value">{{ orderCreationFields.serialNumber }}</div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('product_line') }}</div>
        <div class="row-value">
          {{
            orderCreationFields.workOrderName
              ? orderCreationFields.workOrderName
              : ''
          }}
        </div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('original_OEM') }}</div>
        <div class="row-value">
          {{ orderCreationFields.brand ? orderCreationFields.brand : '' }}
        </div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('model') }}</div>
        <div class="row-value">
          {{ orderCreationFields.model ? orderCreationFields.model : '' }}
        </div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('frame_type') }}</div>
        <div class="row-value">
          {{
            orderCreationFields.frameType ? orderCreationFields.frameType : ''
          }}
        </div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('part_number') }}</div>
        <div class="row-value">
          {{
            orderCreationFields.userEnteredPartNumber
              ? orderCreationFields.userEnteredPartNumber
              : ''
          }}
        </div>
      </div>
      <div v-if="orderCreationFields.customerInfoText" class="row">
        <div class="row-title">{{ $t('customer') }}</div>
        <div class="row-value">{{ orderCreationFields.customerInfoText }}</div>
      </div>
      <div v-if="orderCreationFields.salesForceNumber" class="row">
        <div class="row-title">Sales Force Opportunity Number</div>
        <div class="row-value">{{ orderCreationFields.salesForceNumber }}</div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('executing_site') }}</div>
        <div class="row-value">{{ executingSite }}</div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('sites') }}</div>
        <div class="row-value">{{ secondarySites }}</div>
      </div>
    </div>

    <div
      v-for="(object, index) in selectedTasks"
      :key="index"
      class="review-tasks-chosen"
    >
      <div class="row">
        <div class="row-title">{{ $t('title') }}</div>
        <div class="row-value">{{ object.title }}</div>
      </div>
      <div class="row">
        <div class="row-title">{{ $t('products') }}</div>
        <div class="row-value">{{ object.products }}</div>
      </div>
      <div class="row">
        <div class="row-value">{{ object.tasks.join(',') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'publisher-review-workorder',
  props: {
    orderCreationFields: Object,
    selectedTasks: Array,
    siteNames: Array,
  },
  data() {
    return {};
  },

  async mounted() {
    this.formatProductLine();
    this.formatOEM();
    this.formatModel();
    this.formatFrameType();
  },
  computed: {
    jobs() {
      return this.$store.state.jobs;
    },
    executingSite() {
      return this.siteNames[0];
    },
    secondarySites() {
      const [, ...secondarySites] = this.siteNames;
      if (secondarySites.length === 0) {
        return this.$t('na');
      }
      return secondarySites.join(', ');
    },
  },
  watch: {
    selectedTasks(newValues) {
      this.selectedTasks = newValues;
    },
  },
  methods: {
    formatProductLine() {
      // Keeping arrays check in the case if change ability to use multiple fleet data
      if (
        Array.isArray(this.orderCreationFields.workOrderName) &&
        this.orderCreationFields.length > 1
      ) {
        let formattedProductLine;
        for (
          let i = 0;
          i < this.orderCreationFields.workOrderName.length;
          i++
        ) {
          if (i === 0 && this.orderCreationFields.workOrderName[i].text) {
            formattedProductLine =
              this.orderCreationFields.workOrderName[i].text;
          } else if (i === 0 && this.orderCreationFields.workOrderName[i]) {
            formattedProductLine = this.orderCreationFields.workOrderName[i];
          } else if (this.orderCreationFields.workOrderName[i].text) {
            formattedProductLine = formattedProductLine.concat(
              ', ',
              this.orderCreationFields.workOrderName[i].text
            );
          } else if (!this.orderCreationFields.workOrderName[i].text) {
            formattedProductLine = formattedProductLine.concat(
              ', ',
              this.orderCreationFields.workOrderName[i]
            );
          }
        }

        this.orderCreationFields.workOrderName = formattedProductLine;
      } else if (
        this.orderCreationFields.workOrderName &&
        this.orderCreationFields.workOrderName.length === 1
      ) {
        this.orderCreationFields.workOrderName =
          this.orderCreationFields.workOrderName[0].text;
      }
    },
    formatOEM() {
      if (
        Array.isArray(this.orderCreationFields.brand) &&
        this.orderCreationFields.brand.length > 1
      ) {
        let formattedOEM;
        for (let i = 0; i < this.orderCreationFields.brand.length; i++) {
          if (i === 0 && this.orderCreationFields.brand[i].text) {
            formattedOEM = this.orderCreationFields.brand[i].text;
          } else if (i === 0 && this.orderCreationFields.brand[i]) {
            formattedOEM = this.orderCreationFields.brand[i];
          } else if (this.orderCreationFields.brand[i].text) {
            formattedOEM = formattedOEM.concat(
              ', ',
              this.orderCreationFields.brand[i].text
            );
          } else if (!this.orderCreationFields.brand[i].text) {
            formattedOEM = formattedOEM.concat(
              ', ',
              this.orderCreationFields.brand[i]
            );
          }
        }

        this.orderCreationFields.brand = formattedOEM;
      } else if (
        this.orderCreationFields.brand &&
        this.orderCreationFields.brand.length === 1
      ) {
        this.orderCreationFields.brand = this.orderCreationFields.brand[0].text;
      }
    },
    formatModel() {
      if (Array.isArray(this.orderCreationFields.model)) {
        let formattedModel;
        this.orderCreationFields.model.forEach((model, index) => {
          if (index === 0 && model.text) {
            formattedModel = model.text;
          } else if (index === 0 && model) {
            formattedModel = model;
          } else if (model.text) {
            formattedModel = formattedModel.concat(', ', model.text);
          } else if (!model.text) {
            formattedModel = formattedModel.concat(', ', model);
          }
        });

        this.orderCreationFields.model = formattedModel;
      } else if (
        this.orderCreationFields.model &&
        this.orderCreationFields.model.length === 1
      ) {
        this.orderCreationFields.model = this.orderCreationFields.model[0].text;
      }
    },

    formatFrameType() {
      if (Array.isArray(this.orderCreationFields.frameType)) {
        let formattedFrameType;
        this.orderCreationFields.frameType.forEach((frameType, index) => {
          if (index === 0 && frameType.text) {
            formattedFrameType = frameType.text;
          } else if (index === 0 && frameType) {
            formattedFrameType = frameType;
          } else if (frameType.text) {
            formattedFrameType = formattedFrameType.concat(
              ', ',
              frameType.text
            );
          } else if (!frameType.text) {
            formattedFrameType = formattedFrameType.concat(', ', frameType);
          }
        });

        this.orderCreationFields.frameType = formattedFrameType;
      } else if (
        this.orderCreationFields.frameType &&
        this.orderCreationFields.frameType.length === 1
      ) {
        this.orderCreationFields.frameType =
          this.orderCreationFields.frameType[0].text;
      }
    },
  },
};
</script>

<style lang="scss">
.review-container {
  display: flex;
  overflow: auto;
  width: 100% !important;
  height: 70vh;
  flex-direction: column;
}

.review-title {
  color: $standard-text-color-publisher;
  font-size: 24px;
}

.review-head-card {
  background-color: #e1e1e1;
  border-bottom: 1px solid #cbcbcb;
  padding: 6px 12px;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row-title {
  color: #000 !important;
  font-weight: bold;
}

.row-value {
  color: #000 !important;
  margin-right: 50%;
}

.review-tasks-chosen {
  border-bottom: 1px solid #d6d6d6;
  padding: 6px 12px;
  width: 100%;
  .row-title,
  .row-value {
    color: #aaa !important;
    padding-top: 6px;
  }
}
</style>
