export default {
  SET_ISO_NAMES: (state, isoNames) => {
    state.isoNames = isoNames;
  },
  SET_ISO_GRADES: (state, isoGrades) => {
    state.isoGrades = isoGrades;
  },
  SET_ISO_VALUES: (state, isoValues) => {
    state.isoValues = isoValues;
  },
  CLEAR_ISO_VALUES: (state) => {
    state.isoGrades = [];
  },
};
