<template>
  <div :class="isExclude ? 'label-check excluded' : 'label-check'">
    {{ label }}
    <b-checkbox
      v-if="showCheck"
      class="checkbox"
      @input="onItemToExclude"
      v-model="isExclude"
    />
  </div>
</template>

<script>
import { REPORTS_ITEM_TO_EXCLUDE } from '../../../store/reports/actions/actionTypes';

export default {
  name: 'LabelInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    itemToExcludeId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  methods: {
    onItemToExclude(value) {
      const { itemToExcludeId } = this;
      this.$store.dispatch(REPORTS_ITEM_TO_EXCLUDE, {
        value,
        id: itemToExcludeId,
      });
    },
  },

  computed: {
    showCheck() {
      return this.$store.state.ReportsModule.enableExcludeItems;
    },
    isExclude: {
      get() {
        return this.$store.state.ReportsModule.excludedItems.includes(
          this.itemToExcludeId
        );
      },
      set() {},
    },
  },
};
</script>

<style lang="scss" scoped>
.label-check {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.checkbox {
  margin-left: 5px;
}

.excluded {
  text-decoration: line-through;
}
</style>
