export default {
  SET_WEBSOCKET_STATUS: (state, status) => {
    state.websocketStatus = status;
  },

  SET_WEB_SOCKET_CONNECTION_ID: (state, connectionId) => {
    state.websocketConnectionId = connectionId;
  },

  WEBSOCKET_EVENT_ADD_SUBSCRIBER(state, { action, callback, once }) {
    console.log('Subscribing to', action);
    if (!state.eventSubscribers.has(action)) {
      state.eventSubscribers.set(action, new Set());
    }

    const subscriber = { callback, once };
    state.eventSubscribers.get(action).add(subscriber);
  },

  // Removes a subscriber from the action-based subscriber list
  WEBSOCKET_EVENT_REMOVE_SUBSCRIBER(state, { action, callback }) {
    console.log('unsubscribing to', action);

    if (state.eventSubscribers.has(action)) {
      state.eventSubscribers.get(action).delete(callback);
    }
  },

  // Notifies all subscribers of a given action with the payload
  WEBSOCKET_EVENT_PUBLISH_MESSAGE(state, { action, data }) {
    if (state.eventSubscribers.has(action)) {
      const subscribers = state.eventSubscribers.get(action);
      console.log(subscribers);
      subscribers.forEach((subscriber) => {
        subscriber.callback(data);

        // Remove the subscriber if `once` is true
        if (subscriber.once) {
          subscribers.delete(subscriber);
        }
      });
    }
  },
};
