import client from '../../../config/api';
import QuestionDTO from '../../../shared/dtos/QuestionDTO';
import SET_LOADING_STATE from '../../loading/mutations/mutationTypes';
import { SEND_WEBSOCKET_MESSAGE } from '../../websocket/actions/actionTypes';
import {
  LOCAL_SET_QUESTIONS,
  REMOVE_QUESTION,
} from '../mutations/mutationTypes';

export default {
  async LOCK_QUESTION({ dispatch }, payload) {
    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'questionLock', data: payload },
      { root: true }
    );
  },
  async UNLOCK_QUESTION({ dispatch }, payload) {
    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'questionUnlock', data: payload },
      { root: true }
    );
  },
  async SAVE_QUESTIONS(_1, payload) {
    const { taskId, data } = payload;
    return client.post(`/questions/${taskId}`, data);
  },
  async GET_QUESTIONS({ commit }, taskId) {
    const result = await client.get(`/questions/${taskId}`);
    commit(LOCAL_SET_QUESTIONS, result.data);
  },
  async DELETE_QUESTION({ commit, dispatch }, questionId) {
    if (!questionId) {
      return;
    }

    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'questionDelete', data: { questionId } },
      { root: true }
    );

    commit(REMOVE_QUESTION, questionId, { root: true });
  },
  async CREATE_QUESTION({ commit, dispatch }, data) {
    const { taskId, question } = data;
    if (!taskId || !question) {
      console.log('Missing requred params for request');
      return;
    }
    const questionDto = new QuestionDTO(question);
    commit(SET_LOADING_STATE, { value: true }, { root: true });

    const payload = {
      ...questionDto,
      taskId,
    };

    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'questionCreate', data: payload },
      { root: true }
    );

    commit(SET_LOADING_STATE, { value: false }, { root: true });
  },
  async CREATE_QUESTIONS({ commit }, data) {
    console.log('CREATE_QUESTIONS', data);
    const { taskId, questions } = data;
    if (!taskId || questions.length === 0) {
      console.log('Missing requred params for request');
      return;
    }

    const questionsDTO = questions.map((question) => new QuestionDTO(question));

    commit(SET_LOADING_STATE, { value: true }, { root: true });
    const response = await client.post(`/questions/${taskId}`, questionsDTO);
    commit(SET_LOADING_STATE, { value: false }, { root: true });
    return response.data;
  },
  async UPDATE_QUESTION({ dispatch }, data) {
    const { _id } = data;
    if (!_id) {
      console.log('Missing requred params for request _id');
      return;
    }
    const questionDto = new QuestionDTO(data);
    questionDto.lockedByWS = '';
    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'questionUpdate', data: questionDto },
      { root: true }
    );
  },
};
