<template>
  <div id="container-findings">
    <div id="title">FINDINGS</div>
    <b-field label="By Inspector" :class="'inspector-input'">
      <b-input type="textarea" readonly v-model="task.taskComment"></b-input>
    </b-field>
    <b-field label="By Reporter" :class="'inspector-input'">
      <b-input
        type="textarea"
        v-model="task.reportNote"
        v-on:blur="onUpdateJobReport"
        placeholder="Please enter your findings here..."
        :disabled="isLocked"
        :title="
          isLocked
            ? $t('task_locked_by_user', {
                user: task.modifier.fullname,
                date: new Date(task.modifyTimeout * 1000),
              })
            : undefined
        "
      ></b-input>
    </b-field>
  </div>
</template>

<script>
import LockHelper from '../../../helpers/LockHelper';

export default {
  props: {
    taskProp: {
      type: Object,
      required: true,
    },
  },
  name: 'Findings',
  computed: {
    task() {
      return this.taskProp;
    },
  },

  data() {
    return { persistedReportNode: null, isLocked: false };
  },
  created() {
    this.persistedReportNode = this.task.reportNote;
    this.isLocked = !LockHelper.isTaskAvailable(
      this.task,
      this.$store.state.currentUser
    );
  },
  methods: {
    async onUpdateJobReport() {
      try {
        await this.$store.dispatch('updateTaskReportNote', {
          taskId: this.task._id,
          reportNote: this.task.reportNote,
        });
        this.persistedReportNode = this.task.reportNote;
      } catch (e) {
        this.$store.dispatch('setErrorMessage', e.message);
        this.task.modifier = e.data.modifier;
        this.task.modifyTimeout = e.data.modifyTimeout;
        this.isLocked = true;
        this.task.reportNote = this.persistedReportNode;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#container-findings {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
  padding: 10px;
}
#title {
  font-family: 'Siemens Sans';
  font-size: 18px;
}

.inspector-input {
  margin-top: 30px;
}
</style>
