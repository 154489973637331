<template>
  <img :src="imageUrl" v-bind="$props" @error="errorImage" />
</template>

<script>
export default {
  name: 'ImageWithErrorFallback',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    errorImage(e) {
      e.target.src = '/img/icons/gallery_icon.png';
    },
  },
};
</script>

<style></style>
