const state = {
  announcementBannerDataTimeout: 0,
  announcementBannerVisible: true,
  announcementBannerMessage: null,
  announcementBannerStartDate: null,
  announcementBannerEndDate: null,

  infoMessage: null,
  successMessage: null,
  warningMessage: null,
  errorMessage: null,

  currentUser: null,
  currentUserAllowedTools: [],

  surrogateUser: null,

  user: {},
  userRoles: [],
  users: [],
  usersPendingApproval: [],
  usersLoggedInToday: [],
  usersRejected: [],
  userOwnedTemplatesCount: 0,
  userOwnedJobsCount: 0,

  // Editor Creation
  productListName: [],
  originalOEMBrandList: [],
  frameTypeList: [],
  modelList: [],

  // Jobs
  jobs: [],
  currentJob: {},
  jobErrors: [],
  totalJobs: 0,
  jobsLastKey: null,
  // The query of jobs in cache
  jobsQuery: null,

  // Stand alone Tasks
  task: {},
  jobTasks: [],

  // Tasks related to jobs
  tasks: [],
  currentTasks: [],

  // All task for the current job
  allTasks: [],

  // Instruction inside task
  currentTask: {},

  // Sites - Users' location
  sites: [],

  // Connection between OIL and DIJ
  oilToken: '',
  oilApprovers: [],

  // Approval templates
  approvalList: [],
  approvalError: {},

  // Part Number names
  partLevels: [],

  // Visual conditions
  visualConditions: [],

  // Current Application Version Number
  versionNumber: '2.2.1.1',

  // Waiting flags
  waitingForRead: false,
  waitingForWrite: false,

  // Deleted lists
  deletedJobs: [],
  deletedJobsLastKey: null,
  deletedJobsQuery: null,
  deletedTasks: [],

  // MteTypes
  mteTypes: [],
  mteType: null,

  // Locked tasks ids
  lockedTaskIds: [],
};

export default state;
