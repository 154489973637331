export default {
  LOCAL_SET_DOCUMENTS: (state, documents) => {
    state.documents = documents;
  },

  LOCAL_ADD_DOCUMENT: (state, document) => {
    state.documents.push(document);
  },

  LOCAL_RENAME_DOCUMENT: (state, { id, newName }) => {
    const index = state.documents.findIndex((d) => d.id === id);
    if (index >= 0) {
      state.documents[index].name = newName;
    }
  },

  LOCAL_DELETE_DOCUMENT: (state, ids) => {
    state.documents = state.documents.filter((d) => !ids.includes(d.id));
  },

  LOCAL_MOVE_DOCUMENTS: (state, { srcids, destid }) => {
    const srcDocuments = state.documents.filter((d) => srcids.includes(d.id));
    srcDocuments.forEach((d) => {
      d.folderId = destid;
    });
  },
};
