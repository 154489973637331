<template>
  <div class="deviation-container">
    <header class="modal-card-head">
      <div class="columns">
        <div class="column">
          <b-field label="Unit Serial:">
            <b-input v-model="job.serialNumber" :disabled="true" />
          </b-field>
        </div>
        <div class="column dij-deviation-radio">
          <b-radio
            v-model="radio"
            name="radio"
            :native-value="'review_only'"
            :disabled="isReadonly"
            @input="save"
          >
            Review only
          </b-radio>
          <b-radio
            v-model="radio"
            name="radio"
            :native-value="'action_required'"
            :disabled="isReadonly"
            @input="save"
          >
            Action required
          </b-radio>
        </div>
      </div>
    </header>
    <b-table :data="deviationRows">
      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
      <template slot-scope="props">
        <template v-if="props.row.id === editFieldId">
          <b-table-column field="assemblyBOM" label="Assembly BOM">
            <b-input v-model="props.row.assemblyBOM" />
          </b-table-column>

          <b-table-column field="assemblyRef" label="Assembly REF">
            <b-input v-model="props.row.assemblyRef" />
          </b-table-column>

          <b-table-column field="itemNumber" label="Item #">
            <b-input v-model="props.row.itemNumber" />
          </b-table-column>

          <b-table-column field="originalPn" label="Original PN">
            <b-input v-model="props.row.originalPn" />
          </b-table-column>

          <b-table-column field="description" label="Description">
            <b-input v-model="props.row.description" type="textarea" />
          </b-table-column>
          <b-table-column label="Actions">
            <b-button @click="save" type="is-success">
              {{ $t('save') }}
            </b-button>
          </b-table-column>
        </template>

        <template v-else>
          <b-table-column field="assemblyBOM" label="Assembly BOM">
            {{ props.row.assemblyBOM }}
          </b-table-column>

          <b-table-column field="assemblyRef" label="Assembly REF">
            {{ props.row.assemblyRef }}
          </b-table-column>

          <b-table-column field="itemNumber" label="Item #">
            {{ props.row.itemNumber }}
          </b-table-column>

          <b-table-column field="originalPn" label="Original PN">
            {{ props.row.originalPn }}
          </b-table-column>

          <b-table-column field="description" label="Description">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Actions" v-if="!isReadonly">
            <b-button
              type="is-info"
              icon-left="pencil"
              @click="editRow(props.row.id)"
              >Edit</b-button
            >
            <b-button
              style="margin-left: 10px"
              type="is-danger"
              icon-right="delete"
              @click="deleteRow(props.row.id)"
            />
          </b-table-column>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import { v4 } from 'uuid';

const uuidv4 = v4;

export default {
  name: 'DeviationReport',
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    job() {
      return this.$store.state.currentJob;
    },

    deviationRows() {
      if (!this.job.deviationReport) return [...this.newRows];
      return [...this.job.deviationReport.rows, ...this.newRows];
    },
  },

  watch: {
    job() {
      this.newRows = [];
    },
  },

  data() {
    return {
      assemblyBOM: '',
      assemblyRef: '',
      itemNumber: '',
      originalPn: '',
      description: '',
      editFieldId: null,
      newRows: [],
      serialNumber: '',
      deviationType: '',
      radio: '',
    };
  },

  mounted() {
    this.radio = this.job.deviationReport.radio;
  },

  methods: {
    addRow() {
      const newRow = {
        id: uuidv4(),
        assemblyBOM: '',
        assemblyRef: '',
        itemNumber: '',
        originalPn: '',
        description: '',
      };
      this.editFieldId = newRow.id;
      this.newRows.push(newRow);
    },

    async save() {
      this.editFieldId = null;
      const updatedJob = { ...this.job };
      const { radio } = this;
      const rows = this.deviationRows;
      updatedJob.deviationReport = {
        radio,
        rows,
      };
      this.newRows = [];
      await this.$store.dispatch('updateJobSimple', updatedJob);
    },

    async deleteRow(id) {
      this.editFieldId = null;
      const updatedJob = { ...this.job };
      const { radio } = this;
      const rows = this.deviationRows.filter((row) => row.id !== id);
      updatedJob.deviationReport = {
        radio,
        rows,
      };
      this.newRows = [];
      await this.$store.dispatch('updateJobSimple', updatedJob);
    },

    editRow(id) {
      this.editFieldId = id;
    },
  },
};
</script>

<style scoped lang="scss">
.deviation-container {
  background-color: #fff;
  width: 100%;
}
.modal-cars-header {
  padding: 10px;
}
.modal-card-head {
  background-color: #fff;
}
.dij-deviation-radio {
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}
.empty-header {
  display: flex;
  justify-content: space-between;
}
</style>
