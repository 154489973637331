<template>
  <div class="menu-wrapper" v-if="showMenu">
    <b-dropdown
      position="is-bottom-left"
      aria-role="list"
      :close-on-click="true"
    >
      <a class="navbar-item" slot="trigger" role="button">
        <span>{{ user ? user.fullname : '' }}</span>
        <b-icon icon="menu-down"></b-icon>
      </a>
      <b-dropdown-item
        v-if="isSurrogateAvailable"
        @click="showSurrogateModal()"
      >
        {{ $t('surrogate') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isStopSurrogateAvailable"
        @click="stopSurrogating()"
      >
        {{ $t('stop_surrogating') }}
      </b-dropdown-item>
      <b-dropdown-item @click="openLanguageModal">{{
        $t('language')
      }}</b-dropdown-item>
      <b-dropdown-item :has-link="true">
        <router-link class="dropdown-item" to="/view/profile">
          {{ $t('view_profile') }}
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="userAllowedTools.some((allowedTool) => allowedTool === 'admin')"
        :has-link="true"
      >
        <router-link class="dropdown-item" to="/view/fleetdata">
          {{ $t('manage_fleet_data') }}</router-link
        >
      </b-dropdown-item>
      <b-dropdown-item
        target="_blank"
        href="https://siemensenergy.service-now.com/sp?id=sc_cat_item&sys_id=7cdbfb151b9ca55047071065bb4bcbb9"
      >
        {{ $t('report_issue') }}
      </b-dropdown-item>
      <b-dropdown-item
        target="_blank"
        href="mailto:dij@siemens-energy.com?subject=Contacting DIJ"
      >
        {{ $t('contact_us') }}
      </b-dropdown-item>
      <b-dropdown-item
        target="_blank"
        href="https://siemensenergyagnam.sharepoint.com/sites/svc/pi/sd/dia/_layouts/15/listform.aspx?PageType=8&ListId=%7BBB3DE134-0607-4057-A836-3E36C85E71B4%7D&RootFolder=%2Fsites%2Fsvc%2Fpi%2Fsd%2Fdia%2FLists%2FInspection%20Forms&Source=https%3A%2F%2Fsiemensenergyagnam.sharepoint.com%2Fsites%2Fsvc%2Fpi%2Fsd%2Fdia%2FLists%2FInspection%2520Forms%2FAllItems.aspx%3Fviewpath%3D%252Fsites%252Fsvc%252Fpi%252Fsd%252Fdia%252FLists%252FInspection%2520Forms%252FAllItems%252Easpx%26OR%3DTeams%252DHL%26CT%3D1695999467315%26clickparams%3DeyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA5MDExMjIyOSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%253D%253D&ContentTypeId=0x01003D6CB0A86CF16B4683C6B95507E51BAA"
        >Request Content</b-dropdown-item
      >
      <b-dropdown-item
        target="_blank"
        href="https://siemensenergyagnam.sharepoint.com/sites/svc/pi/sd/dia/WORKING%20DOCUMENTS/Forms/AllItems.aspx?RootFolder=%2Fsites%2Fsvc%2Fpi%2Fsd%2Fdia%2FWORKING%20DOCUMENTS%2FOriginal%20Training%20Videos&FolderCTID=0x01200099D9073A38C01644BAC9A44F7AB83416"
      >
        {{ $t('training') }}
      </b-dropdown-item>
      <b-dropdown-item href="/logout"> {{ $t('log_out') }} </b-dropdown-item>
    </b-dropdown>

    <switch-language
      @isActiveSwitchLanguage="handleActiveSwitchLanguage($event)"
      :isModalActive="isSwitchLanguageModalVisible"
    ></switch-language>
    <user-picker
      v-if="isSurrogateModalVisible"
      :user="user"
      @cancel="hideSurrogateModal"
      @ok="handleSurrogateUser"
    />
  </div>
</template>

<script>
import SwitchLanguage from './SwitchLanguage';
import UserPicker from './UserPicker';

export default {
  components: {
    'switch-language': SwitchLanguage,
    'user-picker': UserPicker,
  },

  data() {
    return {
      showMenu: true,
      isSwitchLanguageModalVisible: false,
      isSurrogateModalVisible: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    surrogate() {
      return this.$store.state.surrogateUser;
    },
    userAllowedTools() {
      return this.$store.state.currentUserAllowedTools;
    },
    isSurrogateAvailable() {
      return (
        this.user &&
        this.user.role === 'admin' &&
        this.user.privil.toString() === '1'
      );
    },
    isStopSurrogateAvailable() {
      return !!this.surrogate;
    },
  },
  mounted() {
    const myself = this;
    document.addEventListener(
      'hideVueMenu',
      () => {
        myself.showMenu = false;
      },
      false
    );
    document.addEventListener(
      'showVueMenu',
      () => {
        myself.showMenu = true;
      },
      false
    );
  },

  methods: {
    handleActiveSwitchLanguage(isActive) {
      this.isSwitchLanguageModalVisible = isActive;
    },
    openLanguageModal() {
      this.isSwitchLanguageModalVisible = true;
    },
    showSurrogateModal() {
      this.isSurrogateModalVisible = true;
    },
    hideSurrogateModal() {
      this.isSurrogateModalVisible = false;
    },
    async handleSurrogateUser(user) {
      this.hideSurrogateModal();
      if (user && user._id !== this.surrogate?._id) {
        await this.$store.dispatch('surrogate', user);
      } else {
        await this.$store.dispatch('clearSurrogate', user);
      }
      location.reload();
    },
    async stopSurrogating() {
      await this.$store.dispatch('clearSurrogate');
      location.reload();
    },
  },
};
</script>

<style scoped lang="scss"></style>
