<template>
  <div class="task-container" :id="task._id">
    <TaskName :taskName="task.taskName" :id="task._id" />
    <section>
      <div id="table-title">
        <span>FORM INSPECTION</span>
        <span>
          <span v-if="task.documentNumber" class="document-info"
            >Document Number: <span>{{ task.documentNumber }}</span></span
          >
          <span v-if="task.revision" class="document-info"
            >Revision: <span>{{ task.revision }}</span></span
          >
        </span>
      </div>
      <b-table :data="task.instructionData" :striped="true" :columns="columns">
        <template #empty>
          <div class="has-text-centered">No records</div>
        </template>
      </b-table>
    </section>

    <template v-for="item in task.subtabelsQuestions">
      <TableInspection v-if="item.tableData" :tableData="item" />
    </template>
    <Findings :task-prop="task" />
    <Attachments :task-prop="task" />
    <div id="divider" />
  </div>
</template>

<script>
import Findings from '../components/Findings';
import Attachments from '../components/Attachments';
import TableInspection from './TableInspection';
import TaskName from '../components/TaskName';
import ReportDataParser from '../../../mixins/Report/ReportDataParserMixin';

export default {
  name: 'Instruction',
  mixins: [ReportDataParser],
  props: {
    taskProp: {
      type: Object,
      required: true,
    },
  },
  components: {
    Findings,
    Attachments,
    TableInspection,
    TaskName,
  },

  computed: {
    task() {
      return this.taskProp;
    },
    formInspectionData() {
      return this.parseInstructionData(this.task);
    },
    isInternalReport() {
      return this.$store.state.ReportsModule.isInternalReport;
    },
    columns() {
      const columnsToReturn = [
        {
          field: 'question',
          label: 'ITEM',
        },
        {
          field: 'answer',
          label: 'ANSWER',
        },
        {
          field: 'attachment',
          label: 'ATTACHMENT(S)',
        },
      ];
      if (this.isInternalReport) {
        columnsToReturn.push({
          field: 'lastModifiedBy',
          label: 'INSPECTOR',
        });
      }
      columnsToReturn.push({
        field: 'lastModifiedAt',
        label: 'DATE',
      });
      return columnsToReturn;
    },
  },
};
</script>

<style lang="scss" scoped>
#task-header {
  height: 35px;
  font-family: 'Siemens Sans';
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 35px;
}

#table-title {
  font-family: 'Siemens Sans';
  font-size: 18px;
  background-color: white;
  padding: 10px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
  display: flex;
  justify-content: space-between;
}

#divider {
  height: 10px;
  background-color: #ccc;
  width: 100%;
  margin-top: 20px;
}

#table-inspection {
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  font-family: 'Siemens Sans';
  font-size: 18px;
}

.document-info {
  border-left: solid;
  border-width: 1px;
  padding: 5px;
  margin-left: 10px;
  border-color: #ccc;
}

.document-info span {
  color: #aaa;
}
</style>
