const MODULE_NAME = 'DocumentsModule';

// eslint-disable-next-line import/prefer-default-export
export const SAVE_DOCUMENTS = `${MODULE_NAME}/SAVE_DOCUMENTS`;
export const ADD_DOCUMENT = `${MODULE_NAME}/ADD_DOCUMENT`;
export const RENAME_DOCUMENT = `${MODULE_NAME}/RENAME_DOCUMENT`;
export const DELETE_DOCUMENT = `${MODULE_NAME}/DELETE_DOCUMENT`;
export const MOVE_DOCUMENTS = `${MODULE_NAME}/MOVE_DOCUMENTS`;
export const GET_JOB_DOCUMENTS = `${MODULE_NAME}/GET_JOB_DOCUMENTS`;
export const GET_RECYCLE_BIN = `${MODULE_NAME}/GET_RECYCLE_BIN`;
export const RESTORE_DOCUMENTS = `${MODULE_NAME}/RESTORE_DOCUMENTS`;
