const options = [
  {
    name: 'TE bearing journal',
    value: 'te_bearing_journal',
  },
  {
    name: 'NTE bearing journal',
    value: 'nte_bearing_journal',
  },
  {
    name: 'TE seal fit',
    value: 'te_seal_fit',
  },
  {
    name: 'NTE seal fit',
    value: 'nte_seal_fit',
  },
  {
    name: 'Coupling fit',
    value: 'coupling_fit',
  },
  {
    name: 'Probe track',
    value: 'probe_track',
  },
  {
    name: 'Bridgeover',
    value: 'bridgeover',
  },
  {
    name: 'Thrust disc fit',
    value: 'thrust_disc_fit',
  },
  {
    name: 'Balance piston',
    value: 'balance_piston',
  },
  {
    name: 'Overspeed trip fit',
    value: 'overspeed_trip_fit',
  },
  {
    name: 'TE gland',
    value: 'te_gland',
  },
  {
    name: 'NTE gland',
    value: 'nte_gland',
  },
  {
    name: '1st stg impeller eye',
    value: '1st_stg_impeller_eye',
  },
  {
    name: '2nd stg impeller eye',
    value: '2nd_stg_impeller_eye',
  },
  {
    name: '3rd stg impeller eye',
    value: '3rd_stg_impeller_eye',
  },
  {
    name: '4th stg impeller eye',
    value: '4th_stg_impeller_eye',
  },
  {
    name: '5th stg impeller eye',
    value: '5th_stg_impeller_eye',
  },
  {
    name: '6th stg impeller eye',
    value: '6th_stg_impeller_eye',
  },
  {
    name: '7th stg impeller eye',
    value: '7th_stg_impeller_eye',
  },
  {
    name: '8th stg impeller eye',
    value: '8th_stg_impeller_eye',
  },
  {
    name: '1st-2nd stg spacer',
    value: '1st_2nd_stg_spacer',
  },
  {
    name: '2nd-3rd stg spacer',
    value: '2nd_3rd_stg_spacer',
  },
  {
    name: '3rd-4th stg spacer',
    value: '3rd_4th_stg_spacer',
  },
  {
    name: '4th-5th stg spacer',
    value: '4th_5th_stg_spacer',
  },
  {
    name: '5th-6th stg spacer',
    value: '5th_6th_stg_spacer',
  },
  {
    name: '6th-7th stg spacer',
    value: '6th_7th_stg_spacer',
  },
  {
    name: '7th-8th stg spacer',
    value: '7th_8th_stg_spacer',
  },
  {
    name: '1st stg disc',
    value: '1st_stg_disc',
  },
  {
    name: '2nd stg disc',
    value: '2nd_stg_disc',
  },
  {
    name: '3rd stg disc',
    value: '3rd_stg_disc',
  },
  {
    name: '4th stg disc',
    value: '4th_stg_disc',
  },
  {
    name: '5th stg disc',
    value: '5th_stg_disc',
  },
  {
    name: '6th stg disc',
    value: '6th_stg_disc',
  },
  {
    name: '7th stg disc',
    value: '7th_stg_disc',
  },
  {
    name: '8th stg disc',
    value: '8th_stg_disc',
  },
  {
    name: '1st stg disc',
    value: '1st_stg_disc',
  },
  {
    name: '2nd stg disc',
    value: '2nd_stg_disc',
  },
  {
    name: '3rd stg disc',
    value: '3rd_stg_disc',
  },
  {
    name: '4th stg disc',
    value: '4th_stg_disc',
  },
  {
    name: '5th stg disc',
    value: '5th_stg_disc',
  },
  {
    name: '6th stg disc',
    value: '6th_stg_disc',
  },
  {
    name: '7th stg disc',
    value: '7th_stg_disc',
  },
  {
    name: '8th stg disc',
    value: '8th_stg_disc',
  },
  {
    name: '1st-2nd stg interstage',
    value: '1st_2nd_stg_interstage',
  },
  {
    name: '2nd-3rd stg interstage',
    value: '2nd_3rd_stg_interstage',
  },
  {
    name: '3rd-4th stg interstage',
    value: '3rd_4th_stg_interstage',
  },
  {
    name: '4th-5th stg interstage',
    value: '4th_5th_stg_interstage',
  },
  {
    name: '5th-6th stg interstage',
    value: '5th_6th_stg_interstage',
  },
  {
    name: '6th-7th stg interstage',
    value: '6th_7th_stg_interstage',
  },
  {
    name: '7th-8th stg interstage',
    value: '7th_8th_stg_interstage',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export default options;
