var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-tasks-container"},[_c('h1',{staticClass:"select-tasks-title"},[_vm._v(_vm._s(_vm.$t('select_tasks')))]),_c('div',{staticClass:"everything-selector-container"},[_c('div',{staticClass:"select-everything",on:{"click":function($event){return _vm.handleSelectEverything()}}},[_c('p',[_vm._v("Select Everything")])]),(_vm.selectedTasks.length > 0)?_c('div',{staticClass:"deselect-everything",on:{"click":function($event){return _vm.handleDeselectEverything()}}},[_c('p',[_vm._v("Deselect Everything")])]):_vm._e()]),_vm._l((_vm.jobsToRender),function(job){return _c('div',{key:job.id,staticClass:"jobs"},[_c('ul',[_c('p',{staticClass:"job-title"},[_vm._v(" "+_vm._s(job.title)+" ")]),_c('div',{staticClass:"selection-buttons"},[_c('div',{staticClass:"select-all",on:{"click":function($event){return _vm.handleSelectAll(job)}}},[_c('p',[_vm._v(_vm._s(_vm.$t('select_all')))])]),(
            _vm.selectedTasks.length > 0 && _vm.enabledJobsToDeselect.includes(job.id)
          )?_c('div',{staticClass:"deselect-all",on:{"click":function($event){return _vm.handleDeselectAll(job)}}},[_c('p',[_vm._v(_vm._s(_vm.$t('deselect_all')))])]):_vm._e()]),_c('div',{staticClass:"tasks-list"},_vm._l((_vm.tasksToRender(job)),function(task){return _c('li',{key:task.taskNumber},[_c('div',{staticClass:"cards-list"},[_c('div',{class:_vm.selectedTasks.includes(task.taskNumber)
                  ? 'task-card selected-task'
                  : 'task-card',on:{"click":function($event){return _vm.handleTaskClick(task, job)}}},[(_vm.selectedTasks.includes(task.taskNumber))?_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"check"}}):_vm._e(),_c('p',{class:_vm.selectedTasks.includes(task.taskNumber)
                    ? 'task-title selected-task-title'
                    : 'task-title'},[_vm._v(" "+_vm._s(task.taskName)+" ")])],1)])])}),0)])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }