import { render, staticRenderFns } from "./QRCodeReader.vue?vue&type=template&id=37d5c839&scoped=true"
import script from "./QRCodeReader.vue?vue&type=script&lang=js"
export * from "./QRCodeReader.vue?vue&type=script&lang=js"
import style0 from "./QRCodeReader.vue?vue&type=style&index=0&id=37d5c839&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d5c839",
  null
  
)

export default component.exports