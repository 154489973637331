const MAX_FOLDER_DEPTH = 1;

export default {
  data() {
    return {
      folder: null,
      subfolder: null,
    };
  },
  async mounted() {
    const { folderId, subfolderId } = this.getIdsFromRoute();
    if (folderId) {
      this.folder = await this.$store.dispatch('getTask', {
        id: folderId,
      });
    }

    if (subfolderId) {
      this.subfolder = await this.$store.dispatch('getTask', {
        id: subfolderId,
      });
    }
  },
  computed: {
    async getFolder() {
      if (!this.folder) {
        const { folderId } = this.getIdsFromRoute();

        this.folder = await this.$store.dispatch('getTask', {
          id: folderId,
        });
      }

      return this.folder;
    },
    async getSubfolder() {
      if (!this.subfolder) {
        const { subfolderId } = this.getIdsFromRoute();

        this.subfolder = await this.$store.dispatch('getTask', {
          id: subfolderId,
        });
      }

      return this.subfolder;
    },
    respectFolderDepth() {
      if (this.subfolder) {
        return this.subfolder.folderLevel < MAX_FOLDER_DEPTH;
      }

      return true;
    },
    folderName() {
      return (this.folder && this.folder.taskName) || '';
    },
    subfolderName() {
      return (this.subfolder && this.subfolder.taskName) || '';
    },
    folderId() {
      return (
        (this.folder && this.folder._id) ||
        this.$route.params.folderId ||
        this.$route.query.folderId ||
        ''
      );
    },
    subfolderId() {
      return (
        (this.subfolder && this.subfolder._id) ||
        this.$route.params.subfolderId ||
        this.$route.query.subfolderId ||
        ''
      );
    },
  },
  methods: {
    getIdsFromParams() {
      const { folderId, subfolderId } = this.$route.params;
      return { folderId, subfolderId };
    },
    getIdsFromQuery() {
      const { folderId, subfolderId } = this.$route.query;
      return { folderId, subfolderId };
    },
    getIdsFromRoute() {
      return {
        folderId:
          this.getIdsFromParams().folderId || this.getIdsFromQuery().folderId,
        subfolderId:
          this.getIdsFromParams().subfolderId ||
          this.getIdsFromQuery().subfolderId,
      };
    },
  },
};
