// import actions from './actions';
// import getters from "./getters";
import mutations from './mutations';

const utilitiesModule = {
  namespaced: true,
  state() {
    return {
      search_text: '',
    };
  },
  mutations,
};

export default utilitiesModule;
