<template>
  <div v-if="!isLoading">
    <TaskView type="publisher" @onSave="onSave">
      <div v-if="isDiagram">
        <DiagramView
          ref="diagramView"
          :show-list="true"
          :show-toolbar="true"
          :tool="'publisher'"
        />
      </div>
      <div v-if="isInstruction">
        <InstructionView
          ref="instructionView"
          :task="currentTask"
          viewType="publisher"
        />
      </div>
    </TaskView>
  </div>
  <div v-else>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import DiagramView from '../../../components/Shared/DiagramView';
import InstructionView from '../../../components/Shared/InstructionView';
import TaskView from '../../../components/Shared/TaskView';
import TaskViewMixin from '../../../mixins/Shared/TaskViewMixin';

export default {
  components: { DiagramView, InstructionView, TaskView },
  mixins: [TaskViewMixin],
  computed: {
    // `isInstruction` computed property is inherited from TaskViewMixin
    // `isDiagram` computed property is inherited from TaskViewMixin
    // `currentTask` computed property is inherited from TaskViewMixin
    // `isLoading` computed property is inherited from TaskViewMixin
  },
  methods: {
    // `onSave` method is inherited from TaskViewMixin
  },
};
</script>
