<template>
  <div :class="isExclude ? 'task-header excluded' : 'task-header'">
    Task:

    {{ taskName }}
    <b-checkbox
      @input="onItemToExclude"
      v-model="isExclude"
      v-if="enableExcludeItems"
    />
  </div>
</template>

<script>
import ExcludeItemsMixin from '../../../mixins/Report/ExcludeItemsMixin';

export default {
  name: 'TaskName',
  props: {
    taskName: {
      type: String,
      default: '',
      required: true,
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
  },

  mixins: [ExcludeItemsMixin],
};
</script>

<style lang="scss" scoped>
.task-header {
  font-family: 'Siemens Sans';
  font-size: 18px;
  display: flex;
  flex: 1;
  margin-top: 35px;
  align-items: center;
  .checkbox {
    margin-left: 10px;
  }
}

.excluded {
  text-decoration: line-through;
}
</style>
