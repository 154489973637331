const MODULE_NAME = 'TasksModule';

export const GET_TASK = `${MODULE_NAME}/GET_TASK`;
export const GET_FOLDER = `${MODULE_NAME}/GET_FOLDER`;
export const DELETE_TASK = `${MODULE_NAME}/DELETE_TASK`;

export const SAVE_TASK = `${MODULE_NAME}/SAVE_TASK`;
export const SIGN_LEADMAN = `${MODULE_NAME}/SIGN_LEADMAN`;
export const SAVE_CURRENT_TASK_STATE = `${MODULE_NAME}/SAVE_CURRENT_TASK_STATE`;

export const LOCAL_GET_FOLDER = 'GET_FOLDER';

export const GET_FOLDER_DATA = `${MODULE_NAME}/GET_FOLDER_DATA`;
