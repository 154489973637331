<template>
  <div>
    <Header ref="header" :isTask="true" :tool="tool" />
    <b-navbar type="is-dark" class="nav-menu">
      <template slot="start">
        <div>{{ $t('table_template.create_table') }}</div>
        <div class="buttons" v-if="tool !== 'inspector'">
          <b-field>
            <p class="control">
              <b-tooltip
                :label="$t('table_template.new_column')"
                type="is-light"
              >
                <b-button class="button is-dark" @click="addColumn">
                  <b-icon icon="table-column-plus-after"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip :label="$t('table_template.new_row')" type="is-light">
                <b-button class="button is-dark" @click="addRow">
                  <b-icon icon="table-row-plus-after"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
            <template v-if="Object.keys(selectedCell).length > 0">
              <p class="control">
                <b-tooltip
                  :label="$t('table_template.delete_column')"
                  type="is-light"
                >
                  <b-button
                    class="button is-dark cell-selected"
                    @click="deleteColumn"
                  >
                    <b-icon icon="table-column-remove"></b-icon>
                  </b-button>
                </b-tooltip>
              </p>
              <p class="control">
                <b-tooltip
                  :label="$t('table_template.delete_row')"
                  type="is-light"
                >
                  <b-button
                    class="button is-dark cell-selected"
                    @click="deleteRow"
                  >
                    <b-icon icon="table-row-remove"></b-icon>
                  </b-button>
                </b-tooltip>
              </p>
              <p class="control">
                <b-button
                  class="button is-dark cell-selected"
                  @click="$refs.isoModal.open()"
                >
                  Add ISO
                </b-button>
              </p>
              <!-- Hidden for now, funtionality not finished -->
              <p class="control" v-if="false">
                <b-button
                  class="button is-dark cell-selected"
                  @click="$refs.calcModal.open()"
                >
                  Add Calculation
                </b-button>
              </p>
            </template>
          </b-field>
        </div>
      </template>
      <template slot="end">
        <div class="buttons">
          <b-field>
            <p class="control" v-if="tool !== 'inspector'">
              <b-tooltip label="Delete" type="is-light">
                <b-button class="button is-dark">
                  <b-icon icon="delete"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip label="Save" type="is-success">
                <b-button class="button is-success" @click="save">
                  <b-icon icon="check"></b-icon>
                </b-button>
              </b-tooltip>
            </p>
          </b-field>
        </div>
      </template>
    </b-navbar>
    <div class="container">
      <div id="legend-container"></div>
      <vue-excel-editor
        v-if="showTable"
        v-model="rows"
        :no-footer="true"
        :free-select="true"
        :no-paging="true"
        @update="onUpdate"
        @cellFocus="onSelect"
      >
        <vue-excel-column
          v-for="(column, index) in columns"
          :key="index"
          :field="column.field"
          :label="column.label"
          :cellId="index"
          :invisible="getColumnInvisible(column)"
          :type="getColumnType(column)"
          :readonly="getColumnReadOnly(column)"
          :init-style="getColumnStyle(column)"
        />
      </vue-excel-editor>
      <!-- <canvas
        id="can"
        width="500"
        height="500"
        style="border: 2px solid; cursor: crosshair;"
      ></canvas> -->
      <div clasS="notification">
        <b-notification
          class="notification-message"
          v-if="showNotification"
          :type="notificationType"
          aria-close-label="Close notification"
          auto-close
        >
          {{ notificationMessage }}
        </b-notification>

        <ISOModal ref="isoModal" />
        <CalculationModal ref="calcModal" />

        <b-notification
          auto-close
          type="is-success"
          :active.sync="showSuccessMessage"
          :duration="500"
          aria-close-label="Close notification"
        >
          Your table has been saved successfully.
        </b-notification>
        <b-notification
          auto-close
          type="is-danger"
          aria-close-label="Close notification"
          :active.sync="showErrorMessage"
          :duration="3000"
        >
          Failed to save your table. Please, try again.
        </b-notification>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../layout/TasksHeader';
import ISOModal from './ISOModal';
import CalculationModal from './CalculationModal';

export default {
  name: 'table-template',
  props: {
    tool: String,
  },
  data() {
    return {
      rows: [],
      rowsData: [],
      columns: [],
      newColumn: '',
      showTable: true,
      taskObject: {},
      showNotification: false,
      notificationMessage: '',
      notificationType: '',
      selectedCell: {},
      cellInUse: false,
      selectingCell: null,

      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },
  components: { Header, ISOModal, CalculationModal },
  computed: {
    task() {
      return this.$store.state.task;
    },
    jobTasks() {
      return this.$store.state.jobTasks;
    },
  },
  async mounted() {
    const jobId = this.$route.params.id;
    this.$parent.isLoading = false;
    if (!this.jobTasks || this.jobTasks.length === 0) {
      await this.$store.dispatch('getJobTasks', { jobId });
    }
    this.taskObject = Object.assign(this.taskObject, this.task);
    if (this.task?.rows && this.task.rows.length > 0) {
      for (let i = 0; i < this.task.rows.length; i++) {
        this.rows.push({});
      }
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      });
    }

    this.$nextTick(async () => {
      await this.mountTable();
    });

    const myself = this;
    // eslint-disable-next-line func-names
    document.addEventListener('click', function (e) {
      return myself.outerclick(e);
    });
    if (this.tool === 'inspector') this.makeLegends();

    // const can1 = new window.handwriting.Canvas(document.getElementById('can'));s
    // const canvas = document.getElementById('can');

    // canvas.addEventListener('mouseup', function() {
    //   console.log('canvas changed');
    //   can1.recognize();
    // });
    // // Set callback function
    // can1.setCallBack(function(data, err) {
    //   if (err) throw err;
    //   else console.log(data);
    // });

    // // Set line width shown on the canvas element (default: 3)
    // can1.setLineWidth(5);

    // // Set options
    // can1.setOptions({
    //   language: 'en',
    //   numOfReturn: 3,
    // });
  },

  methods: {
    makeLegends() {
      const container = document.getElementById('legend-container');
      const colorList = {
        limegreen: 'Characterisitcs from DB',
        yellow: 'Checkbox field',
        dodgerblue: 'Calculation field',
        red: 'Value that needs validation',
      };

      Object.entries(colorList).forEach(([color, legend]) => {
        const boxContainer = document.createElement('DIV');
        const box = document.createElement('DIV');
        const label = document.createElement('SPAN');

        label.innerHTML = legend;
        box.className = 'legend-box';
        box.style.backgroundColor = color;

        boxContainer.appendChild(box);
        boxContainer.appendChild(label);

        container.appendChild(boxContainer);
      });
    },
    getColumnType(column) {
      if (column.type) {
        return column.type;
      }
      return 'string';
    },
    getColumnStyle(column) {
      const style = { color: 'black' };
      if (column.type && column.type === 'checkYN') {
        style['background-color'] = 'yellow';
      }

      if (this.getColumnReadOnly(column)) {
        style['background-color'] = 'limegreen';
      }

      if (column.isMath) {
        style['background-color'] = 'dodgerblue';
      }

      return style;
    },
    getColumnInvisible(column) {
      if (this.tool === 'inspector' && column.hideInInspector) {
        return true;
      }
      return false;
    },
    getColumnReadOnly(column) {
      // tool is inspector and column is not set to be inspected
      if (this.tool === 'inspector' && !column.inspect) {
        return true;
      }
      return false;
    },
    async mountTable() {
      // fill columns
      if (this.task?.columns?.length > 0) {
        this.columns = this.task.columns;
      }
      // fill rows
      if (this.task?.rows?.length > 0) {
        // assign this.rowsData the same values as in the DB
        // meaning calculations here need to show the calculation object/options
        Object.assign(this.rowsData, this.task.rows);

        // this.rows will have the vue-excel-editor data to be shown
        // meaning calculations here need to show the value
        this.rows = [];
        this.task.rows.forEach((taskRow, i) => {
          this.rows[i] = {};
          // each row is e.g: {stage: 1, measurement_point: 2}
          // so we need to iterate them to find complex objects
          Object.entries(taskRow).forEach(async ([field, columnValue]) => {
            if (typeof columnValue === 'object') {
              // handle calculation / complex object
              if (columnValue.type) {
                const options = {
                  rowIndex: i,
                  columnValue,
                };
                let result = await this.$store.dispatch('calculate', options);
                if (typeof result !== 'string' && result % 1 !== 0) {
                  result = result.toFixed(2);
                }

                this.rows[i][field] = result;

                const columnIndex = this.columns.findIndex(
                  (col) => col.field === field
                );
                if (this.columns[columnIndex]?.highlight) {
                  const cell = `[cell-rc="${i}-${field}"]`;
                  if (result > 0.45 || result < -0.45) {
                    if (document.querySelector(cell))
                      document
                        .querySelector(cell)
                        .classList.add('highlight-red');
                  }
                }
              } else {
                this.rows[i][field] = '';
              }
            } else {
              this.rows[i][field] = columnValue;
            }
          });
        });
      }
    },
    addColumn() {
      this.$buefy.dialog.prompt({
        message: 'Name',
        trapFocus: true,
        onConfirm: (value) => {
          let nameExists = false;
          const fieldName = value.replace(/ /g, '_').toLowerCase();
          this.columns.map((column) => {
            if (column.field === fieldName) nameExists = true;
          });
          if (nameExists) {
            return this.dangerMessage('Column name already exists.');
          }
          const column = {
            field: fieldName,
            label: value,
          };
          this.columns.push(column);
        },
      });
    },
    deleteColumn(index = null) {
      if (!index || (typeof index !== 'number' && this.selectedCell)) {
        index = this.selectedCell.colPos;
      }
      if (index > -1 && this.columns[index]) {
        // when deleting column, the change does not show visualy
        // so we need to remove rerender the table component
        this.showTable = false;
        const { field } = this.columns[index];
        this.deleteRowsColumn(field);
        this.columns.splice(index, 1);
        this.$nextTick(() => {
          // Add the component back in
          this.showTable = true;
          this.selectedCell = {};
        });
      }
    },
    addRow() {
      this.rows.push({});
    },
    deleteRow() {
      const index = this.selectedCell.rowPos;
      this.rows.splice(index, 1);
      this.rowsData.splice(index, 1);
      this.selectedCell = {};
    },
    deleteRowsColumn(field) {
      // deletes the field on the rows if the column is removed
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i][field]) delete this.rows[i][field];
        if (this.rowsData[i][field]) delete this.rowsData[i][field];
      }
    },
    async onUpdate(data) {
      // this data is an array
      for (let i = 0; i < data.length; i++) {
        const cell = data[i];
        const field = cell.field.name;
        const columnIndex = this.columns.findIndex(
          (col) => col.field === field
        );
        const column = this.columns[columnIndex];
        // get the position of the row on the table
        const index = this.rows.findIndex((row) => row.$id === cell.$id);
        const value = cell.newVal;
        if (this.rowsData[index]) {
          this.rowsData[index][field] = value;
        } else {
          this.rowsData.push({ [field]: value });
        }
        // eslint-disable-next-line no-await-in-loop
        await this.save();
        if (column.calculateAll) {
          this.mountTable();
        } else {
          this.checkFieldBeingCalculated(field, index);
        }
      }
    },
    /**
     * check if the selected/updated cell is being calculated
     * @param field the selected cell field name
     * @param index the selected cell row index
     * @param calculate (default = true) if false will
     * return value instead of calculate
     */
    checkFieldBeingCalculated(field = '', index = 0, calculate = true) {
      // get the array of fields stored on the rows
      const fieldArray = Object.keys(this.rowsData[index]);
      let beingCalculated = false;
      fieldArray.forEach(async (fieldName) => {
        // get the value for this field name on the selected row index
        const value = this.rowsData[index][fieldName];
        // if the value is found as an object
        if (typeof value === 'object') {
          // if calculate property is false, we return true
          if (!calculate) {
            // check if this calculation object has the selected field
            if (value.params.indexOf(field) > -1) {
              beingCalculated = true;
            }
            return;
          }

          // calculate the value
          const options = {
            rowIndex: index,
            columnValue: value,
          };
          let result = await this.$store.dispatch('calculate', options);
          if (typeof result !== 'string' && result % 1 !== 0) {
            result = result.toFixed(2);
          }
          const columnIndex = this.columns.findIndex(
            (col) => col.field === fieldName
          );
          if (this.columns[columnIndex]?.highlight) {
            const cell = `[cell-rc="${index}-${fieldName}"]`;
            if (result > 0.45 || result < -0.45) {
              document.querySelector(cell).classList.add('highlight-red');
            } else {
              document.querySelector(cell).classList.remove('highlight-red');
            }
          }
          this.rows[index][fieldName] = result;
        }
      });
      if (!calculate) return beingCalculated;
    },
    onSelect(pos) {
      // user is selecting cell to be calculated
      if (this.selectingCell === 'iso') {
        this.selectingCell = null;
        return this.$refs.isoModal.cellSelected(pos);
      }

      this.selectedCell = { ...pos };
    },
    async save() {
      this.taskObject.rows = this.rowsData;
      this.taskObject.columns = this.columns;
      try {
        await this.$store.dispatch('updateTask', this.taskObject);
        this.showSuccessMessage = true;
      } catch (err) {
        this.showErrorMessage = true;
      }
    },
    successMessage(message) {
      this.showNotification = true;
      this.notificationMessage = message;
      this.notificationType = 'is-success';
    },
    dangerMessage(message) {
      this.showNotification = true;
      this.notificationMessage = message;
      this.notificationType = 'is-danger';
    },
    outerclick(e) {
      if (!e.path) {
        return;
      }
      let clearCell = true;
      e.path.map((item) => {
        if (!item.classList) return;
        if (
          item.classList.contains('table-content') ||
          item.classList.contains('cell-selected')
        ) {
          clearCell = false;
        }
      });
      if (clearCell && !this.cellInUse) {
        this.selectedCell = {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  margin: 0 10px;
}
.nav-menu {
  padding: 0 15px;
}
.notification {
  margin-top: 10px;
}
#can {
  height: 500px;
  width: 500px;
}
</style>
