import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { acquireToken } from '../services/auth';

const apiHost = process.env.VUE_APP_API_ENDPOINT;

const client = axios.create({
  baseURL: `${apiHost}/dij`,
});

client.interceptors.request.use(
  async (config) => {
    const accessToken = await acquireToken();

    // urls to not check
    const urls = ['signin', 'callback'];
    const checkToken = !urls.some((url) => document.URL.includes(url));

    const refreshPageEvent = new CustomEvent('showRefreshMessage');

    // check if token is valid

    // invalid token
    if (checkToken && (!accessToken || Object.keys(accessToken).length < 1)) {
      window.dispatchEvent(refreshPageEvent);

      return Promise.reject(new Error('Invalid token'));
    }

    const tokenData = jwt_decode(accessToken);

    if (checkToken && Date.now() >= tokenData.exp * 1000) {
      console.error('Access token expired');
      window.dispatchEvent(refreshPageEvent);

      return Promise.reject(new Error('Access token expired'));
    }
    config.headers.authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => {
    // Handle other types of errors that occur during the request
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const modifiedError = new Error();
    modifiedError.status = error.response && (error.response.status || 500);
    modifiedError.message =
      (error.response && error.response.data.message) ||
      error.message ||
      'An error occurred';
    modifiedError.data = error.response && error.response.data.data;

    // Reject the promise with an instance of the Error class
    return Promise.reject(modifiedError);
  }
);

export default client;
