<template>
  <tool-template tool="publisher"></tool-template>
</template>

<script>
import ToolTemplate from '../ToolsTemplates/Template';

export default {
  name: 'publisher-template',
  components: {
    'tool-template': ToolTemplate,
  },
  data() {
    return {};
  },
  computed: {
    job() {
      return this.$store.state.currentJob;
    },
  },

  async mounted() {},
  methods: {},
};
</script>

<style></style>
