import { render, staticRenderFns } from "./TemplatePol.vue?vue&type=template&id=2489b89b"
import script from "./TemplatePol.vue?vue&type=script&lang=js"
export * from "./TemplatePol.vue?vue&type=script&lang=js"
import style0 from "./TemplatePol.vue?vue&type=style&index=0&id=2489b89b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports