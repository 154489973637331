const en = {
  task_page: {
    create_new_task: 'Create a New Task',
    back_to_product_list:
      'After you are done here, please, go back to Product Template List so that others can work on this template',
    back_to_work_order_list:
      'After you are done here, please, go back to Work Order List so that others can work on this order',
  },

  create_new_user_page: {
    create_new_user: 'Create new user',
    create_user: 'Create user',
    firstName: 'First Name',
    lastName: 'Last Name',
    gid: 'GID',
    email: 'E-mail',
    password: 'Password',
    role: 'Role',
    all_fields_required: 'Please, fill in all the required the fields.',
    user_failed: 'User creation failed.',
    user_success: 'User was successfully created.',
    password_validation: 'Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number, no parts of your username.'
  },

  change_password_page: {
    change_password: 'Change password',
    change_user_password: 'Change user password',
    password_change_success: 'Password was changed successfully',
    secret_question_success: 'Secret question was set successfully',
    password_change_fail: 'Failed to change the password.',
    secret_question_fail: 'Failed to set secret question',
    email: 'E-mail',
    oldPassword: 'Old password',
    password: 'New password',
    confirmNewPassword: 'Confirm new password',
    password_should_match: 'New password and confirmed password must match.',
    password_validation: 'Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number, no parts of your email.',
    passwordLowerCase: "Password must contain at least one lowercase character.",
    passwordUpperCase: "Password must contain at least one uppercase character.",
    passwordNumber: "Password must contain at least one digit.",
    passwordMinLength: "Password must be at least 8 characters long.",
    passwordNoUsernameParts: "Password cannot contain any part of the username."
  },

  profile_page: {
    myProfile: 'My Profile',
    account: 'Account',
    preferences: 'Preferences',
    profileUpdated: 'Profile updated successfully',
    updatePreferences: 'Update Preferences',
    updateAccount: 'Update Account'
  },

  task_view: {
    cancel: 'Cancel',
    back: 'Back',
    save: 'Save',
    delete: 'Delete',
    deleteConfirmation: 'Are you sure you want to <b>delete</b> task <b>{taskTitle}</b>?',
    deleteTask: 'Delete task',
    manageMte: 'Instrumentation Log',
    repairAssessment: 'Repair Assessment',
    importAccuScan: 'Import Accu Scan',
    importLaserscan: 'Import Laser Scan',
    signOff: 'Sign Off',
    orderItem: 'Order Item'
  },

  text_size: 'Text size',
  selection: 'Selection',
  alignedlength: 'Aligned length',
  linearlength: 'Linear length',
  positive_tolerance: '+ Tolerance',
  negative_tolerance: '- Tolerance',

  product_template_list: 'Product Template List',
  work_order_list: 'Work Order List',
  update: 'Update',
  order: 'Order',
  select_folder: 'Select Folder',
  move: 'Move',
  add_task: 'Add Task',
  add_folder: 'Add Folder',
  add_field: 'Add Field',
  message_order_drag: 'Now you can order your tasks, just drag them',
  move_folder: 'Move to folder',

  admin: 'Admin',
  editor: 'Editor',
  publisher: 'Publisher',
  inspector: 'Inspector',
  report: 'Report',
  dashboard: 'Dashboard',
  profile: 'Profile',
  language: 'Language',
  view_profile: 'View Profile',
  manage_fleet_data: 'Manage Fleet Data',
  report_issue: 'Report Issue',
  request_content: 'Request Content',
  contact_us: 'Contact Us',
  training: 'Training',
  log_out: 'Log out',

  choose_language: 'Choose your preferred language.',

  create_new_product: 'Create New Product',
  title: 'Title',
  product_line: 'Product Line',
  original_OEM: 'Original OEM',
  frame_type: 'Frame Type',
  model: 'Model',
  part_number: 'Part Number',
  document_number: 'Document Number',
  revision: 'Revision',
  cancel: 'Cancel',
  submit: 'Submit',
  delete: 'Delete',
  save: 'Save',
  done: 'Done',
  new_product_success: 'New Product created successfully.',
  new_product_fail_message: 'Error while creating new job! Please try again.',

  // Tool names inside diagram
  'Selection Tool': 'Selection Tool',
  'Pan Viewport': 'Pan Viewport',
  'Diameter': 'Diameter',
  'Run-Out': 'Run-out',
  'Linear Length': 'Linear Length',
  'Visual Inspection': 'Visual Inspection',
  'Text': 'Text',
  'Generic Measurement': 'Generic Measurement',
  'Arrow': 'Arrow',
  'Line': 'Line',
  'Replace Image': 'Replace Image',

  // Bubble names
  generic: 'Generic Marker',
  generic_name: 'Generic Name',
  runout: 'Runout',
  length: 'Length',
  diameter: 'Diameter',
  arrow: 'Arrow',
  line: 'Line',
  text: 'Text',
  visualInspection: 'Visual Inspection',

  // Bubble properties
  label_numbers_only: 'Label (Numbers only)',
  expected_value: 'Expected Value',
  decimals: 'Decimals',
  units: 'Units',
  inch: 'inch',
  mm: 'mm',
  tolerance_type: 'Type of tolerance',
  tolerance: 'Tolerance',
  multiple_positions: 'Multiple Positions',
  edit_table: 'Edit Table',
  orientation: 'Orientation',
  measurement_name: 'Measurement Name',
  visual_name: 'Visual Name',
  marker_description: 'Description',
  direction: 'Direction',
  degrees: 'Degrees',
  clock: 'Clock',
  rotate_text: 'Rotate Text',
  rubbed: 'Rubbed',
  damaged: 'Damaged',
  pitted: 'Pitted',
  eroded: 'Eroded',
  high: 'High',
  low: 'Low',
  medium: 'Medium',
  acceptable: 'Acceptable',
  all: 'All',
  position: 'Position',
  tir: 'TIR',
  ok: 'Ok',
  in: 'inch',
  iso_name: 'ISO',
  iso_grade: 'ISO Grade',
  RAT: 'RAT',
  none: 'None',

  // Save or discard dialog
  save_or_discard_dialog_content: 'Do you want to save your changes ?',
  discard: 'Discard',
  stay: 'Stay',
  back: 'Back',
  dismiss: 'Dismiss',

  import_drawing: 'Import a drawing',
  work_order_list: 'Arbeitsauftragsliste',

  edit_name: 'Edit name',
  reopen: 'Reopen',

  //Instruction actions
  create_new_item: 'Create a new item',
  choose_one_item: ' Choose one item',
  order_item: 'Order item',
  action: 'Action',
  yes_no: 'Yes/No',
  options: 'Options',
  text: 'Text',
  table: 'Table',
  import_csv: 'Import CSV',

  action_requested: 'Action Requested',
  picture_taken_is_mandatory: 'Picture taken is mandatory.',
  picture_uploaded_successfully: '{image} Uploaded Successfully',
  picture_required: 'Picture required.',
  your_question: 'Your question',
  question: 'Question',
  options: 'Option',
  add_an_option: 'Add an option',
  multiple_choice: 'Multiple Choice',
  text_or_number: 'Text or Number',

  number_of_rows: 'Number of rows',
  number_of_columns: 'Number of columns',
  edit_rows: 'Edit rows',
  edit_columns: 'Edit columns',
  only_numbers_and_alphabets: 'Only numbers and alphabets upto 16 characters are allowed inside a cell',
  table_headings_not_empty: 'Table headings cannot be empty',
  upload_diagram: 'Upload diagram',

  yes: 'Yes',
  no: 'No',


  product_template_list: 'Product Template List',
  product_line: 'Product Line',
  work_order_list: 'Work Order List',
  favorite_list: 'Favorite List',
  search: 'Search',
  add_product: 'Add Product',
  title: 'Title',
  original_OEM: 'Original OEM',
  frame_type: 'Frame Type',
  model: 'Model',
  part_number: 'Part Number',
  location: 'Location',
  document_number: 'Document Number',
  revision: 'Revision',
  created_by: 'Created By',
  date: 'Date',
  edit: 'Edit',
  delete: 'Delete',
  duplicate: 'Duplicate',
  copyfrom: 'CopyFrom',
  nothing_here: 'Nothing here',

  project_number: 'Project Number',
  customer: 'Customer',
  work_order_number: 'Work Order Number',
  serial_number: 'Serial Number',
  sales_force_number: 'Sales Force Number',
  add_order: 'Add Order',
  edit_order: 'Edit Order',
  original_oem_number: 'Original OEM Number',
  freefinding: 'Free Finding',

  part_level: 'Part Level',

  new_product_success_message: 'New Product Created Successfully!',
  new_product_fail_message: 'Error while creating new job! Please try again.',


  task_count: 'Task Count',
  selection_from_list: 'Please make a selection from the list',
  select_tasks: 'Select Tasks',
  select_products: 'Select Products',

  select_all: 'Select All',
  deselect_all: 'Deselect All',
  select_site: 'Select Site',
  selected_products: 'Selected Products',
  review: 'Review',
  sites: 'Secondary Sites',
  products: 'Products',
  tasks: 'Tasks',
  next: 'Next',

  sign_off: 'Sign Off',
  number_of_measurement_points: 'Number of measurement points',
  stage: 'Stage',
  number_of_stages: 'Number of stages',
  add_job_with_table_message: 'Sorry, you cannot add new jobs since you selected a job with a task table inside it.',
  configure_table_order: 'Configure Table',
  version: 'Version',
  task_completed_message: 'Task already signed off! Please re-open for additional changes!',

  create_task: {
    create_new_task: 'Create New Task',
    diagram: 'Diagram',
    instruction: 'Instruction',
    worksheet: 'Worksheet',
    task_name: 'Task Name',
    cancel: 'Cancel',
    done: 'Done',
    select_software_tool: 'Select Software Tool',
  },

  create_folder: {
    create_new_folder: 'Create New Folder',
    folder_name: 'Folder Name',
    select_software_tool: 'Select Software Tool',
  },

  emfProductList: {
    'workOrderList': 'Work Order List',
    'search': 'Search',
    'addOrder': 'Add Order',
    'next': 'Next',
    'previous': 'Previous',

    'productLine': 'Product Line',
    'title': 'Title',
    'originalOEM': 'Original OEM',
    'frameType': 'Frame Type',
    'model': 'Model',
    'partNumber': 'Part Number',
    'customer': 'Customer',
    'projectNumber': 'Project No',
    'serialNumber': 'Serial No',
    'workOrder': 'Work Order',
    'salesForce': 'Sales Force Opportunity No',
    'createdBy': 'Created By',
  },

  loginSession: {
    'admin': 'Administrator',
    'editor': 'Editor',
    'publisher': 'Publisher',
    'inspector': 'Inspector',
    'report': 'Report',
    'dashboard': 'Dashboard',
    'language': 'Language',
    'profile': 'Profile',
    'viewProfile': 'View Profile',
    'manageFleetData': 'Manage Fleet Data',
    'reportIssue': 'Report Issue',
    'requestContent': 'Request Content',
    'contactUs': 'Contact Us',
    'training': 'Training',
    'logOut': 'Log out'
  },

  table_template: {
    create_table: 'Create Table',
    new_column: 'Add new column',
    new_row: 'Add new row',
    delete_column: 'Delete selected column',
    delete_row: 'Delete selected row'

  },

  calculation: {
    sum: 'Sum',
    sub: 'Subtract',
    mult: 'Multiply'
  },

  duplicate_work_order_number: 'The provided work order number is already in use',

  include_repair_assessment: 'Include Repair Assessment',
  repair_assessment: 'Repair Assessment',
  import: 'Import',
  import_editor_template: 'Existing Templates',
  import_add_excel_file: 'Add From Excel File',
  download_excel_file: 'Download Template File',
  saved: 'Saved',
  not_saved: 'Not Saved',
  level: 'Level',
  assembly: 'Assembly',
  item_number: 'Item Number',
  part_name: 'Part Name',
  part_number: 'Part Number',
  quantity: 'Quantity',
  serialNumber: 'Serial Number',
  actions: 'Actions',
  no_records: 'No Records',
  repair_assessment_empty: 'The Repair Assessment is empty. Start by importing data or adding it manually',
  repair_assessment_empty_readonly: 'The Repair Assessment is empty.',
  close: 'Close',
  import_add: 'Import',
  change_log: 'Change Log',

  add_row: 'Add Row',
  remove_row: 'Remove Row',

  repair_assessment_excel_import_failed: 'Failed to import Repair Assessment from the excel file',

  reuse: 'Reuse',
  repair: 'Repair',
  replace: 'Replace',
  missing: 'Missing',
  client_reject: 'Client Reject',
  received: 'Received',
  quality_control_override: 'QC',
  reviewed: 'Eng. Review',
  comments: 'Comments',

  findings: 'Findings',
  recommendations: 'Recommendations',

  visual: 'Visual',
  dim: 'Dim',
  ndt: 'NDT',

  summary: 'Summary',
  repair_scope: 'Repair Scope',
  estimated_labor: 'Est. Hours',

  not_repair_engineer: 'Only engineers may review',

  pre_assembly_audit: 'Pre-Assembly Audit',
  donwload_rat: 'Export Components',
  repair_assessment_search_empty: 'No items available.',

  delete_all: 'Delete All',
  delete_all_message: 'This will delete all information related to the repair assessment.',

  repair_assessment_row_locked: 'This row is locked by {user}',

  feature_disabled: 'This feature is disabled at your location',


  locations: 'Locations',

  recycle_bin: 'Recycle Bin',
  recycle_bin_empty: 'The recycle bin is empty',

  task_name: 'Name',
  task_type: 'Type',
  deleted_by: 'Deleted By',
  deleted_at: 'Deleted At',

  executing_site: 'Executing Site',

  na: 'N/A',

  owner: 'Owner',

  mtetype_create_title: 'Create MTE Type',
  mtetype_update_title: 'Update MTE Type',

  create: 'Create',

  name: 'Name',
  needs_master: 'Needs Master',
  is_master: 'Is Master',

  mte_type_delete_question: 'Are you sure you want to delete this MTE Type?',
  mte_type_delete_success: 'MTE Type deleted successfully',
  mte_type_delete_fail: 'Failed to delete MTE Type',

  mte_management: 'Instrumentation Log',
  add_using_camera: 'Add Using Camera',
  add_manual_entry: 'Add Manual Entry',

  id: 'Id',
  type: 'Type',
  due_date: 'Due Date',
  master: 'Master',
  created_at: 'Created At',

  masters: 'Masters',
  non_masters: 'Others',

  mandatory_error_message: '{field} is mandatory',
  mandatory_date_must_not_be_in_past_error_message: '{field} is mandatory and must not be in the past',
  unique_error_message: '{field} must be unique',

  task_locked_by_user: 'Task is locked by {user} until {date}',

  clear: 'clear',
  surrogate: 'Surrogate',
  stop_surrogating: 'Stop Surrogating',

  create_new_site_page:
  {
    siteName:'Site Name',
    city:'City',
    state:'State',
    country:'Country',
    create_new_site:'Create New Site',
    all_fields_required: 'Please, fill in all the required the fields.',
    site_failed: 'Site creation failed.',
    site_success: 'Site created successfully.',
  },

  edit_site_page:
  {
    edit_site: 'Edit Site',
    siteName:'Site Name',
    city:'City',
    state:'State',
    country:'Country',
    rat:'Repair Assessment Template',
    mte_m:'Instrumentation Log',
    delete:'Delete',
    update:'Update',
    successMessage: 'Site updated successfully.',
    failedMessage: 'Failed to update site.',
    delete_confirm:'Are you sure you want to delete this site?',
    deletesite: 'Site deleted successfully.',
    deletefail: 'Failed to delete site.',
  },
  site_list:
  {
    siteName:'Site Name',
    city:'City',
    state:'State',
    country:'Country',
    DateModified: 'Date Modified'
  },
  announcement_banner:
  {
    title: 'Announcement Banner',
    description: 'Setup the announcement banner.',
    status: 'Status',
    message: 'Message',
    start_date: 'Start Date',
    end_date: 'End Date',
    clear_action: 'Clear',
    preview_action: 'Preview',
    save_action: 'Save',
    enabled_status: 'Enabled',
    disabled_status: 'Disabled',
    clear_question: 'This will disable the notification banner for all users. Continue?',
    end_date_error: 'End Date must be in the future'
  },
  edit_user_profile: {
    title: 'User profile',
    user_owned_template_count: 'This user is the owner of {count} Templates',
    user_owned_job_count: 'This user is the owner of {count} Jobs',
    reassign_jobs: 'Reassign Jobs',
    reassign_jobs_cancel: 'Cancel Reassign',
    pending_approval_message: 'This user is pending approval. Check the user information and approve the profile so that the user can start using DIJ',
    rejected_message: 'This user was rejected and cannot access DIJ. The user can still be approved if needed.',
    success_message: 'User updated successfully',
    full_name: 'Full Name',
    email: 'Email',
    primary_location: 'Primary Location',
    secondary_locations: 'Secondary Locations',
    phone_number: 'Phone Number',
    role: 'Role',
    power_admin_role: 'Power Admin',
    placeholder_role: 'Role',
    ownership: 'Ownership',
    additional_permissions: 'Additional Permissions',
    enginner_review_permission: 'Engineer Review',
    delete_button: 'Delete',
    update_button: 'Update',
    approve_button: 'Approve',
    reject_button: 'Reject',
    cancel_button: 'Cancel',
    approve_question: 'Your are now approving {name}\'s user with role \'{role}\' and locations \'{locations}\'. Proceed?',
    reject_question: 'Your are now rejecting {name}\'s. The user will not be able to use DIJ. Proceed?',
    approve_success_message: 'The user has been approved successfully',
    reject_success_message: 'The user has been rejected successfully',
    update_success_message: 'The user information has been updated successfully'
  },
  new_user_welcome_page: {
    welcome_configurator_message: 'Welcome to DIJ. Please select the relevant sites to your work',
    pending_approval_part1_message: 'Your request for access is now pending for approval.',
    pending_approval_part2_message: 'Please visit later for access.',
    submit: 'Submit',
  },
  user_list: {
    name: 'Full name',
    email: 'Email',
    location: 'Location',
    role: 'Role',
    last_login: 'Last Login'
  },
  users: {
    approved: 'Approved',
    approved_description: 'The list of approved users. Those users have full access to DIJ.',
    pending_approval: 'Pending Approval',
    pending_approval_description: 'The list of users to be approved. Those users cannot access DIJ until their profile is approved.',
    todays_logins: 'Today\'s Logins',
    todays_logins_description: 'The list of user that logged it today. Resets every day at midnight.',
    rejected: 'Rejected',
    rejected_description: 'The list of user that during the approval process were rejected. Those users aren\'t aware that they were reject.',
  },
  tasks: {
    locked: 'The task is locked by user {fullname} ({email}). Please contact the user or come back later.',
  },
  user_picker: {
    label: 'Pick a user',
    placeholder: 'Type and select available users',
    no_match_found: 'No match found',
    email: 'Email',
    role: 'Role'
  }
};

export default en;
