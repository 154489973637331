<template>
  <div class="">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="closeButton">
      <b-button
        size="is-small"
        icon-right="close"
        type="is-text"
        v-on:click="close"
      />
    </div>
    <div class="container">
      <select-products
        :hidden="activeStep !== 0"
        @selectedProducts="handleSelectedProducts($event)"
      ></select-products>
      <table-configuration
        :selectedJobs="jobs"
        :activeStep="this.activeStep"
        @selectedConfiguration="handleSelectedConfiguration($event)"
        :hidden="activeStep !== 1"
      >
      </table-configuration>

      <b-steps v-model="activeStep" :animated="true" :rounded="true">
        <b-step-item step="1" label="Template" icon="table"> </b-step-item>

        <b-step-item step="2" label="Configuration" icon="cog"> </b-step-item>
      </b-steps>
    </div>
  </div>
</template>

<script>
import SelectProducts from './SelectProducts';
import TableConfiguration from './TableConfiguration';

export default {
  name: 'publisher-create-part',
  components: {
    'select-products': SelectProducts,
    'table-configuration': TableConfiguration,
  },
  data() {
    return {
      activeStep: 0,
      jobs: [],
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    handleSelectedProducts(jobs) {
      this.jobs = jobs;
    },
    async handleSelectedConfiguration(data) {
      const tablesConfiguration = [];
      const tasks = [];
      // eslint-disable-next-line max-len
      for (let i = 0; i < data.length; i++) {
        tasks.push(...data[i].job.tasks);
        const configurationObj = {
          partLevel: data[i].partLevel,
          _id: data[i].job.id,
          stages: data[i].stages,
          measurePointsValues: data[i].measurementPoints,
          partLevelFields: data[i].partLevelFields,
        };
        tablesConfiguration.push(configurationObj);
      }
      let finalData = {};
      tablesConfiguration.forEach((config) => {
        const stages = [];
        for (let i = config.stages[0]; i <= config.stages[1]; i++) {
          stages.push(i);
        }
        config.stages = stages;
      });

      finalData = {
        tablesConfigurations: tablesConfiguration,
        tasks,
        jobId: this.$route.params.id,
      };

      this.isLoading = true;
      await this.$store.dispatch('configureTasks', finalData);

      return this.close();
    },
    close() {
      const jobId = this.$route.params.id;
      if (this.$route.query.token) {
        const { token } = this.$route.query;

        this.$router.push({
          name: 'publisher-tasks',
          params: { id: jobId },
          query: { token },
        });
      } else {
        this.$router.push({
          name: 'publisher-tasks',
          params: { id: jobId },
        });
      }
    },
  },
  watch: {
    activeStep(newStep) {
      if (newStep === 1 && this.jobs.length === 0) {
        this.$nextTick(() => {
          this.activeStep = 0;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.closeButton {
  width: 50px;
  top: 50px;
  button {
    font-size: 20px;
  }
}
</style>
