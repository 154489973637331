import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const jobsModule = {
  namespaced: true,
  state() {
    return {
      currentJob: {},
    };
  },
  actions,
  mutations,
  getters,
};

export default jobsModule;
