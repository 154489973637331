<template>
  <div class="container">
    <div class="form-container">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <h4 v-if="!this.isEdit">{{ $t('add_order') }}</h4>
      <h4 v-else>{{ $t('edit_order') }}</h4>

      <md-field>
        <label>{{ $t('title') }}</label>
        <md-input required v-model="title"></md-input>
      </md-field>
      <md-field>
        <label>{{ $t('original_oem_number') }}</label>
        <md-input v-model="originalOEMNumber"></md-input>
      </md-field>
      <md-field>
        <label>{{ $t('customer') }}</label>
        <md-input required v-model="customerInfoText"></md-input>
      </md-field>
      <md-field>
        <label>{{ $t('project_number') }}</label>
        <md-input required v-model="projectInfoText"></md-input>
      </md-field>
      <md-field :class="getWorkOrderNumberClass">
        <label>{{ $t('work_order_number') }}</label>
        <md-input
          required
          v-model="workOrderNumber"
          v-on:input="validateWorkOrderNumber"
        ></md-input>
        <span class="md-error">{{ $t('duplicate_work_order_number') }}</span>
      </md-field>

      <b-field :label="$t('product_line') + ' *'" class="sitesLabel">
        <v-select
          v-model="productLineSelected"
          class="selectProdLine"
          id="productLine"
          required
          value=""
          :clearable="false"
          :options="productsNames"
        ></v-select>
      </b-field>

      <b-field :label="$t('original_OEM') + ' *'" class="sitesLabel">
        <v-select
          :disabled="!hasProduct"
          v-model="originalOEMBrandSelected"
          class="selectProdLine"
          id="originalOEM"
          required
          value=""
          :clearable="false"
          :options="originalOEMBrandNames"
        ></v-select>
      </b-field>

      <b-field :label="$t('frame_type') + ' *'" class="sitesLabel">
        <v-select
          :disabled="!hasOEM"
          v-model="frameTypeSelected"
          class="selectProdLine"
          id="frameType"
          required
          value=""
          :clearable="false"
          :options="frameTypeNames"
        ></v-select>
      </b-field>

      <b-field :label="$t('model') + ' *'" class="sitesLabel">
        <v-select
          :disabled="!hasFrameType"
          v-model="modelSelected"
          class="selectProdLine"
          id="model"
          required
          value=""
          :clearable="false"
          :options="modelListName"
        ></v-select>
      </b-field>
      <md-field>
        <label>{{ $t('serial_number') }}</label>
        <md-input required v-model="serialNumber"></md-input>
      </md-field>

      <md-field>
        <label>{{ $t('part_number') }}</label>
        <md-input required v-model="partNumber"></md-input>
      </md-field>

      <md-field>
        <label>{{ $t('sales_force_number') }}</label>
        <md-input v-model="salesForceNumber"></md-input>
      </md-field>

      <b-field
        :label="$t('executing_site') + ' *'"
        class="sitesLabel"
        v-if="isEdit"
      >
        <v-select
          :disabled="!hasFrameType"
          v-model="executingSite"
          class="selectProdLine"
          :clearable="false"
          :options="siteNames"
          @input="handleExecutingSiteChange"
        ></v-select>
      </b-field>

      <b-field :label="$t('sites')" class="sitesLabel" v-if="isEdit">
        <v-select
          :disabled="!hasFrameType"
          v-model="secondarySites"
          class="selectProdLine"
          multiple
          :options="sitesNamesWithoutExecutingSite"
        ></v-select>
      </b-field>
    </div>
    <div v-if="!this.isEdit" class="buttons-container">
      <a role="button" class="pagination-previous" v-on:click="cancelForm">
        {{ $t('cancel') }}
      </a>

      <a
        role="button"
        class="pagination-next"
        v-on:click="handleSubmit"
        :disabled="nextStepDisabled"
        ><span class="icon" aria-hidden="true"
          ><i class="mdi mdi-chevron-right mdi-24px"></i></span
      ></a>
      <b-notification
        auto-close
        type="is-success"
        :active.sync="showSuccess"
        :duration="3000"
        aria-close-label="Close notification"
      >
        New Product Created Successfully!
      </b-notification>
      <b-notification
        auto-close
        type="is-danger"
        :active.sync="showFail"
        :duration="5000"
        aria-close-label="Close notification"
      >
        Error while creating new job! Please try again.
      </b-notification>
    </div>
    <div v-else class="buttons-container">
      <button
        class="cancel-button"
        v-if="!isReassignActive"
        v-on:click="cancelForm"
      >
        {{ $t('cancel') }}
      </button>

      <button
        class="submit-button"
        v-if="!isReassignActive"
        v-on:click="handleSubmit"
        :disabled="nextStepDisabled"
      >
        {{ $t('done') }}
      </button>

      <section>
        <reassign-job
          v-if="isReassignAllowed"
          :isReassignJobsActive="isEdit && isReassignActive"
          :currentOwner="currentOwner"
          @siteSelected="handleSiteSelected($event)"
          @userSelected="handleUserSelected($event)"
        ></reassign-job>
      </section>

      <div class="break-rows-edit"></div>

      <div v-if="isReassignAllowed" class="reassign-buttons-edit">
        <button
          class="editor-reassign-job-button"
          v-if="isEdit && isReassignAllowed && !isReassignActive"
          v-on:click="
            () => {
              isReassignActive = true;
            }
          "
          :disabled="!isEdit"
        >
          Reassign Job
        </button>
        <button
          class="editor-reassign-job-button-cancel"
          v-if="isReassignActive"
          @click="
            () => {
              isReassignActive = !isReassignActive;
            }
          "
          :disabled="!isEdit"
        >
          Cancel Reassign
        </button>
        <button
          class="editor-reassign-job-button-confirm"
          v-if="isReassignActive"
          @click="this.handleSubmitReassign"
          :disabled="
            !isEdit || !isSiteReassignSelected || !isUserReassignSelected
          "
        >
          Confirm Reassign
        </button>
      </div>
      <b-notification
        auto-close
        type="is-success"
        :active.sync="showSuccess"
        :duration="3000"
        aria-close-label="Close notification"
      >
        {{ successMessage }}
      </b-notification>
      <b-notification
        auto-close
        type="is-danger"
        :active.sync="showFail"
        :duration="5000"
        aria-close-label="Close notification"
      >
        {{ failedMessage }}
      </b-notification>
    </div>
  </div>
</template>

<script>
import ReassignJob from '../layout/ReassignJob';

export default {
  components: {
    'reassign-job': ReassignJob,
  },
  name: 'publisher-add-order-info-page',
  data() {
    return {
      title: '',
      originalOEMNumber: '',
      partNumber: '',
      projectInfoText: '',
      customerInfoText: '',
      workOrderNumber: '',
      documentNumber: '',
      revision: '',
      hasProduct: false,
      hasOEM: false,
      hasFrameType: false,
      hasModel: false,
      productsNames: [],
      productLineSelected: '',
      originalOEMBrandNames: [],
      originalOEMBrandSelected: '',
      frameTypeNames: [],
      frameTypeSelected: '',
      modelListName: [],
      executingSite: null,
      secondarySites: [],
      sitesToSelect: [],
      modelSelected: '',
      salesForceNumber: '',
      serialNumber: '',
      showSuccess: false,
      showFail: false,
      isLoading: false,
      isEdit: false,
      isReassignActive: false,
      isSiteReassignSelected: false,
      isUserReassignSelected: false,
      successMessage: '',
      failedMessage: '',
      duplicateOrderNumber: false,
      currentOwner: null,
    };
  },
  watch: {
    /* Watching changes in the dropdown selection */
    /* Using data to make get requests so as to
    get data to the other dropdown below the parent one */

    async productLineSelected(newValues, oldValues) {
      if (newValues !== oldValues) {
        this.originalOEMBrandNames = [];
        this.originalOEMBrandSelected = null;
      }
      if (newValues && newValues.length !== 0) {
        this.hasProduct = true;
        const products = [];
        let product;

        if (!Array.isArray(newValues)) {
          product = this.productListName.find(
            // eslint-disable-next-line eqeqeq
            (productFind) => productFind.name == newValues
          );
          products.push(product._id);
        } else {
          // If multiple is eanbled
          newValues.forEach((item) => {
            product = this.productListName.find(
              // eslint-disable-next-line eqeqeq
              (productFind) => productFind.name == item
            );
            products.push(product._id);
          });
        }

        // Getting data to use in the select child component
        await this.$store.dispatch('getOriginalOEMBrandList', products);

        // Filling the select components' data
        this.fillOriginalOEMSelectField();
      } else {
        this.hasProduct = false;
        this.originalOEMBrandNames = [];
        this.originalOEMBrandSelected = null;
      }
    },

    async originalOEMBrandSelected(newValues, oldValues) {
      if (newValues && newValues !== oldValues) {
        this.frameTypeNames = [];
        this.frameTypeSelected = null;
      }
      if (newValues && newValues.length !== 0) {
        this.hasOEM = true;
        const originalOEMBrands = [];
        let originalOEMBrand;
        if (!Array.isArray(newValues)) {
          originalOEMBrand = this.originalOEMBrandList.find(
            // eslint-disable-next-line eqeqeq
            (brand) => brand.name == newValues
          );
          originalOEMBrands.push(originalOEMBrand._id);
        } else {
          // If multiple is enabled
          newValues.forEach((item) => {
            originalOEMBrand = this.originalOEMBrandList.find(
              // eslint-disable-next-line eqeqeq
              (originalOEMBrandFind) => originalOEMBrandFind.name == item
            );
            originalOEMBrands.push(originalOEMBrand._id);
          });
        }
        await this.$store.dispatch('getFrameTypeList', originalOEMBrands);
        this.fillFrameTypeSelectField();
      } else {
        this.hasOEM = false;
        this.frameTypeNames = [];
        this.frameTypeSelected = null;
      }
    },

    async frameTypeSelected(newValues, oldValues) {
      if (newValues && newValues !== oldValues) {
        this.modelsListName = [];
        this.modelSelected = null;
      }
      if (newValues && newValues.length !== 0) {
        this.hasFrameType = true;
        const frameTypes = [];
        let frameType;
        if (!Array.isArray(newValues)) {
          frameType = this.frameTypeList.find(
            // eslint-disable-next-line eqeqeq
            (frameTypeFind) => frameTypeFind.name == newValues
          );
          frameTypes.push(frameType._id);
        } else {
          // If multiple is enabled
          newValues.forEach((item) => {
            frameType = this.frameTypeList.find(
              // eslint-disable-next-line eqeqeq
              (frameTypeFind) => frameTypeFind.name == item
            );
            frameTypes.push(frameType._id);
          });
        }
        await this.$store.dispatch('getModelsList', frameTypes);
        this.fillModelSelectField();
      } else {
        this.hasFrameType = false;
        this.modelListName = [];
        this.modelSelected = null;
      }
    },

    async modelSelected(selectedName) {
      if (selectedName !== null) {
        this.hasModel = true;
      } else {
        this.hasModel = false;
      }
    },
  },

  computed: {
    productListName() {
      return this.$store.state.productListName;
    },

    originalOEMBrandList() {
      return this.$store.state.originalOEMBrandList;
    },
    frameTypeList() {
      return this.$store.state.frameTypeList;
    },
    modelList() {
      return this.$store.state.modelList;
    },
    newJobError() {
      return this.$store.state.jobErrors;
    },
    nextStepDisabled() {
      return (
        // eslint-disable-next-line eqeqeq
        this.title === '' ||
        // eslint-disable-next-line eqeqeq
        this.partNumber === '' ||
        // eslint-disable-next-line eqeqeq
        this.serialNumber === '' ||
        this.customerInfoText === '' ||
        this.projectInfoText === '' ||
        this.workOrderNumber === '' ||
        !this.hasProduct ||
        !this.hasOEM ||
        !this.hasFrameType ||
        !this.hasModel ||
        this.duplicateOrderNumber ||
        (this.isEdit && !this.executingSite)
      );
    },
    job() {
      return this.$store.state.currentJob;
    },

    user() {
      return this.$store.state.currentUser;
    },

    sites() {
      return this.$store.state.sites;
    },

    siteNames() {
      return this.$store.state.sites.map((site) => site.siteName);
    },

    sitesNamesWithoutExecutingSite() {
      return this.siteNames.filter((site) => site !== this.executingSite);
    },

    isReassignAllowed() {
      if (
        this.job?.sites?.some(
          (site) => site === this.user?.location && this.user?.role === 'admin'
        )
      ) {
        return true;
      }

      if (
        this.job?.prodCreatedByUser?._id === this.user._id ||
        (this.user?.role === 'admin' &&
          parseInt(this.user?.privil, 10) === 1) ||
        this.job?.oldOwners?.some((user) => user._id === this.user._id)
      ) {
        return true;
      }

      return false;
    },

    getWorkOrderNumberClass() {
      return {
        'md-invalid': !this.waitingForRead && this.duplicateOrderNumber,
      };
    },

    waitingForRead() {
      return this.$store.state.waitingForRead;
    },
  },

  async mounted() {
    const { id } = this.$route.params;

    if (id) {
      if (Object.keys(this.job).length === 0) {
        await this.$store.dispatch('getJob', id);
      }
      this.title = this.job.title;
      this.originalOEMNumber = this.job.originalOEMNumber;
      this.customerInfoText = this.job.customerInfoText;
      this.projectInfoText = this.job.projectInfoText;
      this.workOrderNumber = this.job.workOrderNumber;
      this.serialNumber = this.job.serialNumber;
      this.partNumber = this.job.userEnteredPartNumber;
      this.salesForceNumber = this.job.salesForceNumber;
      this.isEdit = true;
      this.isFilled = false;

      if (!this.sites || this.sites.length < 1) {
        await this.$store.dispatch('getSites');
      }
      if (this.job.executingSite) {
        this.executingSite = this.sites?.find(
          (site) => site._id === this.job.executingSite
        ).siteName;
      }
      this.secondarySites = this.job.sites?.map((siteId) => {
        const findSite = this.sites.find((site) => site._id === siteId);
        return findSite.siteName;
      });

      this.sitesToSelect = this.sites?.map((site) => site.siteName).sort();

      this.validateWorkOrderNumber();
    }

    this.fillProductNameListSelectField();

    if (this.job?.prodCreatedByUser) {
      this.currentOwner = this.job.prodCreatedByUser;
    }
  },

  methods: {
    async fillProductNameListSelectField() {
      await this.$store.dispatch('getProductNameList');

      this.productListName.forEach((product) => {
        this.productsNames.push(product.name);
      });
      if (!this.isFilled && this.isEdit) {
        if (typeof this.job?.workOrderName === 'string') {
          this.productLineSelected = this.job.workOrderName;
        } else {
          this.productLineSelected = this.job.workOrderName.map(
            (item) => item.text
          );
        }
      }
    },

    async fillOriginalOEMSelectField() {
      this.originalOEMBrandList.forEach((oemBrand) => {
        this.originalOEMBrandNames.push(oemBrand.name);
      });
      // If try to filter the real variable it doesnt filter since it's an observer array
      // Filtering to remove duplicates (so as it doesn't get error when duplicated keys)
      const filteredArray = [];
      this.originalOEMBrandNames.map((item) => {
        if (!filteredArray.includes(item)) {
          filteredArray.push(item);
        }
      });
      this.originalOEMBrandNames = filteredArray;
      this.originalOEMBrandNames.sort();
      if (!this.isFilled && this.isEdit) {
        if (typeof this.job.brand === 'string') {
          this.originalOEMBrandSelected = this.job.brand;
        } else {
          this.originalOEMBrandSelected = this.job.brand.map(
            (item) => item.text
          );
        }
      }
    },

    async fillFrameTypeSelectField() {
      this.frameTypeList.forEach((frameType) => {
        this.frameTypeNames.push(frameType.name);
      });
      const filteredArray = [];
      this.frameTypeNames.map((item) => {
        if (!filteredArray.includes(item)) {
          filteredArray.push(item);
        }
      });
      this.frameTypeNames = filteredArray;
      this.frameTypeNames.sort();
      if (!this.isFilled && this.isEdit) {
        if (typeof this.job.frameType === 'string') {
          this.frameTypeSelected = this.job.frameType;
        } else {
          this.frameTypeSelected = this.job.frameType.map((item) => item.text);
        }
      }
    },

    async fillModelSelectField() {
      this.modelList.forEach((model) => {
        this.modelListName.push(model.name);
      });
      const filteredArray = [];
      this.modelListName.map((item) => {
        if (!filteredArray.includes(item)) {
          filteredArray.push(item);
        }
      });
      this.modelListName = filteredArray;
      this.modelListName.sort();
      if (!this.isFilled && this.isEdit) {
        if (typeof this.job.model === 'string') {
          this.modelSelected = this.job.model;
        } else {
          this.modelSelected = this.job.model.map((item) => item.text);
        }
        this.isFilled = true;
      }
    },

    async handleSubmit() {
      // Checking which data send on the partNumber/partLevel Field
      let partLevelName;
      if (this.selectPartLevelData) {
        const partLevelObject = this.partLevels.find(
          (partLevel) => partLevel.name === this.partLevelSelected
        );
        partLevelName = partLevelObject.name;
      }
      if (!this.isEdit) {
        if (this.nextStepDisabled) return false;
        const data = {
          workOrderName: [],
          brand: [],
          frameType: [],
          title: this.title,
          originalOEMNumber: this.originalOEMNumber,
          model: [],
          documentNumber: this.documentNumber,
          revision: this.revision,
          userEnteredPartNumber: this.selectPartLevelData
            ? partLevelName
            : this.partNumber,
          workOrderNumber: this.workOrderNumber,
          customerInfoText: this.customerInfoText,
          projectInfoText: this.projectInfoText,
          salesForceNumber: this.salesForceNumber,
          serialNumber: this.serialNumber,
        };
        if (Array.isArray(this.productLineSelected)) {
          this.productLineSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.productListName.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.workOrderName.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.productListName.find(
            (item) => item.name === this.productLineSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.workOrderName.push(helperObject);
          }
        }

        if (Array.isArray(this.originalOEMBrandSelected)) {
          this.originalOEMBrandSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.originalOEMBrandList.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.brand.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.originalOEMBrandList.find(
            (item) => item.name === this.originalOEMBrandSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.brand.push(helperObject);
          }
        }

        if (Array.isArray(this.frameTypeSelected)) {
          this.frameTypeSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.frameTypeList.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.frameType.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.frameTypeList.find(
            (item) => item.name === this.frameTypeSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.frameType.push(helperObject);
          }
        }
        if (Array.isArray(this.modelSelected)) {
          this.modelSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.modelList.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.model.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.modelList.find(
            (item) => item.name === this.modelSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.model.push(helperObject);
          }
        }
        this.$emit('goToNextStep', data);
      } else {
        const data = {
          job: {
            workOrderName: [],
            brand: [],
            frameType: [],
            title: this.title,
            originalOEMNumber: this.originalOEMNumber,
            model: [],
            documentNumber: this.documentNumber,
            revision: this.revision,
            userEnteredPartNumber: this.selectPartLevelData
              ? partLevelName
              : this.partNumber,
            workOrderNumber: this.workOrderNumber,
            customerInfoText: this.customerInfoText,
            projectInfoText: this.projectInfoText,
            salesForceNumber: this.salesForceNumber,
            serialNumber: this.serialNumber,
          },
        };
        if (Array.isArray(this.productLineSelected)) {
          this.productLineSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.productListName.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.job.workOrderName.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.productListName.find(
            (item) => item.name === this.productLineSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.job.workOrderName.push(helperObject);
          }
        }

        if (Array.isArray(this.originalOEMBrandSelected)) {
          this.originalOEMBrandSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.originalOEMBrandList.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.job.brand.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.originalOEMBrandList.find(
            (item) => item.name === this.originalOEMBrandSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.job.brand.push(helperObject);
          }
        }

        if (Array.isArray(this.frameTypeSelected)) {
          this.frameTypeSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.frameTypeList.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.job.frameType.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.frameTypeList.find(
            (item) => item.name === this.frameTypeSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.job.frameType.push(helperObject);
          }
        }
        if (Array.isArray(this.modelSelected)) {
          this.modelSelected.forEach((selected) => {
            const helperObject = {
              _id: '',
              text: '',
            };

            const dataChecker = this.modelList.find(
              (item) => item.name === selected
            );
            if (dataChecker !== undefined) {
              helperObject._id = dataChecker._id;
              helperObject.text = dataChecker.name;
              data.job.model.push(helperObject);
            }
          });
        } else {
          const helperObject = {
            _id: '',
            text: '',
          };

          const dataChecker = this.modelList.find(
            (item) => item.name === this.modelSelected
          );
          if (dataChecker !== undefined) {
            helperObject._id = dataChecker._id;
            helperObject.text = dataChecker.name;
            data.job.model.push(helperObject);
          }
        }
        if (this.executingSite) {
          data.job.executingSite = this.sites.find(
            (site) => site.siteName === this.executingSite
          )._id;
        }
        if (this.secondarySites) {
          data.job.sites = this.formatSelectedSites();
        }
        try {
          const newJob = Object.assign(this.job, data.job);
          await this.$store.dispatch('updateJob', newJob);
          this.showSuccess = true;
          if (this.isEdit) {
            this.successMessage = 'Product Updated Successfully!';
          } else {
            this.successMessage = 'Product Created Successfully!';
          }
          setTimeout(
            () =>
              this.$router.push({
                name: 'publisher-list',
              }),
            2000
          );
        } catch (err) {
          this.showFail = true;
          this.failedMessage = 'Error while updating job! Please try again.';
        }

        return true;
      }
    },

    cancelForm() {
      this.$router.push({
        name: 'publisher-list',
      });
      return true;
    },

    handleSiteSelected() {
      this.isSiteReassignSelected = true;
    },

    handleUserSelected(user) {
      this.isUserReassignSelected = true;
      this.userToReassign = user;
    },

    async handleSubmitReassign() {
      this.isLoading = true;
      const DTO = {
        newOwner: this.userToReassign,
        job: this.job,
      };
      try {
        await this.$store.dispatch('reassignJob', DTO);

        this.isLoading = false;
        this.showSuccess = true;
        this.successMessage = 'Job reassigned successfully!';

        setTimeout(
          () =>
            this.$router.push({
              name: 'publisher-list',
            }),
          2000
        );
      } catch (err) {
        console.error('reassign job error inside create page', err);
        this.isLoading = false;

        this.showFail = true;
        this.failedMessage = 'Failed to reassign this job!';
      }
    },

    formatSelectedSites() {
      const formattedSites = this.secondarySites.map((selectedSite) => {
        const findSite = this.sites.find(
          (site) => site?.siteName === selectedSite
        );

        return findSite._id;
      });

      return formattedSites;
    },

    async validateWorkOrderNumber() {
      this.duplicateOrderNumber = true;
      if (this.workOrderNumber) {
        const jobs = await this.$store.dispatch(
          'getJobsByWorkOrderNumber',
          this.workOrderNumber
        );
        this.duplicateOrderNumber =
          (this.isEdit && jobs.length > 1) || (!this.isEdit && jobs.length > 0);
      }
    },

    handleExecutingSiteChange() {
      this.secondarySites = this.secondarySites.filter(
        (site) => site !== this.executingSite
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 70% !important;
  align-items: center;
  justify-content: center;
}
.form-container {
  margin: 8px auto;
  width: 100%;
  overflow: auto;
  padding: 10px;
  max-height: 500px;

  .md-field {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }

  .selectProdLine {
    width: inherit;
    .vs__dropdown-toggle {
      border: none !important;
    }
    .vs__search {
      padding: 0px 4px !important;
      background-color: none !important;
    }
    input.vs--disabled {
      background-color: none !important;
    }
  }

  [placeholder] {
    color: #777;
    font-size: 16px;
    font-family: 'Siemens Sans';
  }
}
.buttons-container {
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: $disabled-button-background;
  color: #666666;
}

.reassign-buttons-edit {
  display: flex;
  flex-direction: row;
}

.editor-reassign-job-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #7957d5;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);

  &:hover {
    cursor: pointer;
  }
}

.editor-reassign-job-button-cancel {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 50px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}

.editor-reassign-job-button-confirm {
  float: left;
  margin: 8px;
  width: 20px;
  height: 50px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #48c774;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);

  &:hover {
    cursor: pointer;
  }
}

.break-rows-edit {
  flex-basis: 100%;
  height: 0;
}

.sitesLabel ::v-deep label.label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}
</style>
