<template>
  <div class="profile-container">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <b-notification
      auto-close
      :duration="5000"
      :active.sync="showSuccess"
      type="is-success"
      has-icon
      aria-close-label="Close notification"
    >
      {{ $t(successMessage) }}
    </b-notification>

    <b-notification
      auto-close
      :duration="5000"
      :active.sync="showError"
      type="is-danger"
      has-icon
      aria-close-label="Close notification"
    >
      {{ $t(errorMessage) }}
    </b-notification>

    <div class="box profile-box">
      <div class="profile-button" @click="goBack">
        <div class="go-back">
          <b-icon icon="arrow-left"></b-icon>
        </div>
        <h4 class="my-profile-text">{{ $t('profile_page.myProfile') }}</h4>
      </div>
      <section>
        <b-tabs type="is-boxed" expanded v-model="activeTab" size="is-medium">
          <b-tab-item
            icon="account-cog-outline"
            :label="$t('profile_page.account')"
          >
            <AccountTab :user="user" @updateProfile="updateProfile" />
          </b-tab-item>

          <b-tab-item
            icon="cog-outline"
            :label="$t('profile_page.preferences')"
          >
            <PreferencesTab :user="user" @updateProfile="updateProfile" />
          </b-tab-item>
        </b-tabs>
      </section>
    </div>
  </div>
</template>

<script>
import AccountTab from './AccountTab';
import PreferencesTab from './PreferencesTab';

export default {
  name: 'my-profile-page',
  components: { AccountTab, PreferencesTab },
  data() {
    return {
      newPassword: '',
      updatePasswordActive: false,
      showSuccess: false,
      showError: false,
      errorMessage: '',
      successMessage: '',
      prevRoute: null,
      activeTab: 0,
      isLoading: false,
    };
  },
  provide() {
    return {
      displayError: this.displayError,
      displaySuccess: this.displaySuccess,
      displayLoading: this.displayLoading,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('getSites');
    this.isLoading = false;
  },
  methods: {
    displayLoading(isLoading) {
      this.isLoading = isLoading;
    },
    displayError(errorMessage) {
      this.errorMessage = errorMessage;
      this.showError = true;
    },
    displaySuccess(errorMessage) {
      this.successMessage = errorMessage;
      this.showSuccess = true;
    },
    async updateProfile() {
      this.isLoading = true;
      const result = await this.$store.dispatch('updateUserProfile', this.user);
      if (result.success) {
        this.successMessage = 'profile_page.profileUpdated';
        this.showSuccess = true;
      } else {
        this.errorMessage = `Failed to update profile - ${result.err}`;
        this.showError = true;
      }
      this.isLoading = false;
    },
    goBack() {
      this.$router.push({ path: this.prevRoute.path });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style lang="scss" scoped>
.profile-box {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  margin-right: 4%;
  margin-left: 4%;
}
.profile-button {
  gap: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.go-back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-container {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;

  .my-profile-text {
    width: 100%;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  .edit-user-form {
    margin-top: 1%;
    margin-right: 70%;
    margin-left: 5%;

    .field,
    select {
      min-width: 14vw !important;
      max-width: 14vw !important;
    }

    .label {
      margin-right: 60% !important;
      color: #aaaaaa !important;
    }

    .inputs-container-row {
      flex-direction: row !important;
      display: flex;
      margin-top: 1%;

      .second-field {
        margin-left: 10%;
      }

      .input[disabled] {
        background-color: white !important;
        border-color: white !important;
      }

      .password-input {
        margin-top: 8px;
      }
    }
  }
}

.update-profile-button {
  margin-left: 20px;
}
</style>
