<template>
  <create-task tool="editor"></create-task>
</template>

<script>
import CreateTask from '../ToolsTemplates/CreateTask';

export default {
  components: {
    'create-task': CreateTask,
  },
};
</script>

<style></style>
