<template>
  <div v-if="showSubmitSelection" class="submit-to-client">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
      class="loading"
    ></b-loading>
    <select-approvers
      v-if="!isLoading"
      :approvalStatus="approvalStatus"
      :class="
        showSubmitButton && approvalStatus !== 'requested' ? 'selector' : ''
      "
      @selectedApprover="handleSelectedApprover($event)"
    ></select-approvers>

    <b-button
      v-if="!isLoading && showSubmitButton && approvalStatus !== 'requested'"
      class="submit-button"
      @click="submitDataToClient"
      icon-right="send"
    >
      Submit to {{ this.externalClient }}
    </b-button>
    <b-notification
      style="font-size: 14px !important"
      v-if="approvalError && approvalError.err"
      type="is-danger"
      aria-close-label="Close notification"
      role="alert"
    >
      Error trying to submit for approval
    </b-notification>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import SelectApprovers from './SelectApprovers';

export default {
  name: 'submit-for-approval',
  components: {
    'select-approvers': SelectApprovers,
  },

  data() {
    return {
      readOnly: false,
      showSubmitSelection: false,
      showSubmitButton: false,
      tokenIsValid: true,
      oilReferral: false,
      externalClient: '',
      taskNumber: this.$route.params.taskNumber,
      selectedApprover: '',
      approvalStatus: null,
      tokenData: null,
      isLoading: false,
    };
  },

  async mounted() {
    const { id } = this.$route.params;
    if (Object.keys(this.template).length === 0 && id) {
      await this.$store.dispatch('getJob', id);
    }
    this.checkToken();
    this.checkCurrentStatus();
  },

  watch: {
    '$route.params.taskNumber': function (taskNumber) {
      this.taskNumber = taskNumber;

      if (this.taskNumber) {
        this.showSubmitSelection = false;
      } else {
        this.showSubmitSelection = true;
      }
    },
    isLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },

  computed: {
    approvalTemplates() {
      return this.$store.state.approvalList;
    },
    template() {
      return this.$store.state.currentJob;
    },
    approvalError() {
      return this.$store.state.approvalError;
    },
  },

  methods: {
    async checkToken() {
      const { token } = this.$route.query;
      if (!token) return false;
      try {
        this.tokenData = await jwt_decode(token);
        const currentTime = Date.now() / 1000;

        if (!this.tokenData || this.tokenData.exp < currentTime) {
          this.tokenIsvalid = false;
          this.$router.push('/logout');
        }

        if (this.tokenData?.referral.includes('OIL')) {
          this.oilReferral = true;
        }

        if (
          (this.oilReferral &&
            !this.taskNumber &&
            this.tokenData.action !== 'view') ||
          (this.tokenData.action === 'view' &&
            (this.approvalStatus === 'requested' ||
              this.approvalStatus === 'approved'))
        ) {
          this.showSubmitSelection = true;
        }
      } catch (err) {
        console.error(err);
      }
    },

    handleSelectedApprover({ selectedApprover, externalClient }) {
      if (selectedApprover) {
        this.showSubmitButton = true;
        this.externalClient = externalClient;
        this.selectedApprover = selectedApprover;
      }
    },

    async submitDataToClient() {
      this.isLoading = true;

      const objHelper = {
        externalClient: this.externalClient,
        selectedApprover: this.selectedApprover,
        templateId: this.$route.params.id,
        userGid: this.tokenData.userGid,
        approvalStatus: 'requested',
        job: this.template,
      };

      await this.$store.dispatch('updateApprovalStatus', objHelper);

      this.isLoading = false;

      if (!this.approvalError.err) {
        this.approvalStatus = 'requested';
        this.checkCurrentStatus();
      }
      setTimeout(() => {
        window.close();
      }, 2000);
    },

    checkCurrentStatus() {
      if (this.template?.approvalStatus) {
        this.approvalStatus = this.template.approvalStatus;
        if (
          this.approvalStatus === 'requested' ||
          this.approvalStatus === 'approved'
        ) {
          this.readOnly = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.submit-to-client {
  align-items: center;
  margin-left: auto;
  margin-right: 1%;
  display: flex;
  flex-direction: row;

  .selector {
    margin-top: 12px;
    margin-right: 8px;
  }

  .submit-button {
    color: $siemens-logo-color;
    background-color: white;
    inline-size: fit-content;
  }
}
</style>
