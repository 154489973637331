export default {
  GET_ISO_NAMES: (state) => {
    return state.isoNames;
  },
  GET_ISO_GRADES: (state) => {
    return state.isoGrades;
  },
  GET_ISO_VALUES: (state) => {
    return state.isoValues;
  },
};
