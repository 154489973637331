<template>
  <div>
    <div class="goBack" @click="goBack">
      <b-icon icon="arrow-left"></b-icon>
    </div>
    <div class="container">
      <p class="title">Manage Fleet Data</p>
      <FleetDataDropdowns
        :placeHolder="'Product Line'"
        :dropValues="productLineDrop"
        @dropSelected="getBrands"
        :type="'prodLine'"
        @action="setAction"
      ></FleetDataDropdowns>

      <FleetDataDropdowns
        :placeHolder="'Brand'"
        :dropValues="brandDrop"
        @dropSelected="getFrameTypes"
        :disabled="!productLineSelected"
        :type="'brand'"
        @action="setAction"
      ></FleetDataDropdowns>

      <FleetDataDropdowns
        :placeHolder="'Frame Type'"
        :dropValues="frameDrop"
        @dropSelected="getModels"
        :disabled="!brandSelected"
        :type="'frame'"
        @action="setAction"
      ></FleetDataDropdowns>

      <FleetDataDropdowns
        :placeHolder="'Model'"
        :dropValues="modelDrop"
        @dropSelected="selectedModel"
        :disabled="!frameSelected"
        :type="'model'"
        @action="setAction"
      ></FleetDataDropdowns>
    </div>
  </div>
</template>

<script>
import FleetDataDropdowns from './FleetDataDropdowns';

export default {
  name: 'fleet-data',
  components: {
    FleetDataDropdowns,
  },
  data() {
    return {
      productLineDrop: [],
      brandDrop: [],
      frameDrop: [],
      modelDrop: [],
      productLineSelected: '',
      brandSelected: '',
      frameSelected: '',
      modelSelected: '',
      selectedType: '',
      prevRoute: null,
    };
  },
  computed: {
    productListName() {
      return this.$store.state.productListName;
    },
    originalOEMBrandList() {
      return this.$store.state.originalOEMBrandList;
    },
    frameTypeList() {
      return this.$store.state.frameTypeList;
    },
    modelList() {
      return this.$store.state.modelList;
    },
  },
  mounted() {
    this.getProductLineDrop();
  },
  methods: {
    setAction(action, dropSelected, newValue) {
      return this[action](dropSelected, newValue);
    },
    success() {
      if (this.selectedType === 'prodLine') {
        return this.getProductLineDrop();
      }
      if (this.selectedType === 'brand') {
        return this.getBrands(this.productLineSelected);
      }
      if (this.selectedType === 'frame') {
        return this.getFrameTypes(this.brandSelected);
      }
      if (this.selectedType === 'model') {
        return this.getModels(this.frameSelected);
      }
    },
    async create(dropSelected, newValue) {
      const options = {
        type: 'Product',
        name: newValue,
        level: 1,
      };
      switch (this.selectedType) {
        case 'brand':
          options.type = 'OriginalOEM';
          options.level = 2;
          options.level_1 = this.productLineSelected.id;
          break;
        case 'frame':
          options.type = 'FrameFamily';
          options.level = 3;
          options.level_1 = this.productLineSelected.id;
          options.level_2 = this.brandSelected.id;
          break;
        case 'model':
          options['Product Line'] = this.productLineSelected.label;
          options['Original OEM'] = this.brandSelected.label;
          options['Frame Familiy'] = this.frameSelected.label;
          options.Model = newValue;
          options.type = 'Model';
          options.level = 4;
          options.level_1 = this.productLineSelected.id;
          options.level_2 = this.brandSelected.id;
          options.level_3 = this.frameSelected.id;
          break;
        default:
          break;
      }
      try {
        await this.$store.dispatch('createDropDown', options);
        return this.success();
      } catch (err) {
        console.error('Failed to create!', err);
      }
    },
    async edit(dropSelected, newValue) {
      const data = { id: dropSelected.id, name: newValue };
      try {
        await this.$store.dispatch('editDropDown', data);
        return this.success();
      } catch (err) {
        console.error('Failed to save!', err);
      }
    },
    async delete(dropSelected) {
      try {
        await this.$store.dispatch('deleteDropDown', dropSelected.id);
        return this.success();
      } catch (err) {
        console.error('Failed to delete!', err);
      }
    },
    async getProductLineDrop() {
      this.productLineDrop = [];
      this.productLineSelected = '';
      this.clearValues(2);
      await this.$store.dispatch('getProductNameList');

      this.productListName.forEach((product) => {
        this.productLineDrop.push({ label: product.name, id: product._id });
      });
    },
    clearValues(level) {
      this.modelDrop = [];
      this.modelSelected = '';
      if (level > 0) {
        this.frameDrop = [];
        this.frameSelected = '';
      }
      if (level > 1) {
        this.brandDrop = [];
        this.brandSelected = '';
      }
    },
    async getBrands(prodLine) {
      this.clearValues(2);
      this.productLineSelected = prodLine;
      if (!prodLine) return true;

      await this.$store.dispatch('getOriginalOEMBrandList', [prodLine.id]);
      this.originalOEMBrandList.forEach((brand) => {
        this.brandDrop.push({ label: brand.name, id: brand._id });
      });
    },
    async getFrameTypes(brand) {
      this.clearValues(1);
      this.brandSelected = brand;
      if (!brand) return true;

      await this.$store.dispatch('getFrameTypeList', [brand.id]);
      this.frameTypeList.forEach((frame) => {
        this.frameDrop.push({ label: frame.name, id: frame._id });
      });
    },
    async getModels(frame) {
      this.clearValues(0);
      this.frameSelected = frame;
      if (!frame) return true;

      await this.$store.dispatch('getModelsList', [frame.id]);
      this.modelList.forEach((model) => {
        this.modelDrop.push({ label: model.name, id: model._id });
      });
    },
    selectedModel(model) {
      this.modelSelected = model;
    },
    goBack() {
      this.$router.push({ path: this.prevRoute.path });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped lang="scss">
.goBack {
  margin: 40px 0 0 40px;
  cursor: pointer;
}
.title {
  color: #aaaaaa;
}
</style>
