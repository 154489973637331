<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add New Version</p>
      <button class="delete" @click="$emit('close')"></button>
    </header>
    <section class="modal-card-body">
      <b-field label="Version">
        <b-input v-model="localVersion.version"></b-input>
      </b-field>
      <b-field label="Date">
        <b-datepicker
          placeholder="Click to select a date"
          v-model="localVersion.date"
        ></b-datepicker>
      </b-field>
      <b-field label="Section">
        <b-select placeholder="Select a section" v-model="localVersion.section">
          <option value="fixes">Fixes</option>
          <option value="features">Features</option>
          <option value="removals">Removals</option>
        </b-select>
      </b-field>
      <b-field label="Details">
        <b-input v-model="localVersion.details" type="textarea"></b-input>
      </b-field>
      <b-field label="Tag">
        <b-input v-model="localVersion.tag"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Close" @click="$emit('close')"></b-button>
      <b-button label="Save" type="is-success" @click="saveVersion"></b-button>
    </footer>
  </div>
</template>

<script>
export default {
  props: ['version'],
  data() {
    return {
      localVersion: { ...this.version },
    };
  },
  methods: {
    saveVersion() {
      this.$emit('save', this.localVersion);
      this.$emit('close');
    },
  },
};
</script>
