var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{staticClass:"dij-modal-dynamic-width",attrs:{"active":true,"aria-role":"dialog","aria-modal":"","trap-focus":"","scroll":"keep","full-screen":""}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('mte_management'))+" ")])]),_c('div',{staticClass:"dij-mte-header"},[_c('b-button',{attrs:{"icon-left":"camera"},on:{"click":_vm.toggleCamera}},[(!_vm.isQrCodeReaderVisible)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('add_using_camera'))+" ")]):_vm._e(),(_vm.isQrCodeReaderVisible)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('close_camera'))+" ")]):_vm._e()]),_c('div',[_c('b-button',{attrs:{"icon-left":"plus"},on:{"click":_vm.add}},[_vm._v(_vm._s(_vm.$t('add_manual_entry')))])],1)],1),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"camera"},[(_vm.isQrCodeReaderVisible)?_c('QRCodeReaderVue',{on:{"data":_vm.parseQrCodeData}}):_vm._e()],1),_c('div',{staticClass:"table"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('id')))]),_c('th',[_vm._v(_vm._s(_vm.$t('type')))]),_c('th',[_vm._v(_vm._s(_vm.$t('due_date')))]),_c('th',[_vm._v(_vm._s(_vm.$t('master')))]),_c('th',[_vm._v(_vm._s(_vm.$t('created_by')))]),_c('th',[_vm._v(_vm._s(_vm.$t('created_at')))]),_c('th',[_vm._v(_vm._s(_vm.$t('actions')))])])]),_c('tbody',_vm._l((_vm.mtes),function(mte,index){return _c('tr',{key:index},[(_vm.itemsBeingEdited[mte.uid])?[_c('td',[_c('b-field',{attrs:{"type":_vm.isIdEmpty(mte) || _vm.isIdDuplicated(mte)
                          ? 'is-danger'
                          : undefined,"message":_vm.isIdEmpty(mte)
                          ? _vm.$t('mandatory_error_message', { field: _vm.$t('id') })
                          : _vm.isIdDuplicated(mte)
                          ? _vm.$t('unique_error_message', { field: _vm.$t('id') })
                          : undefined}},[_c('b-input',{model:{value:(mte.id),callback:function ($$v) {_vm.$set(mte, "id", $$v)},expression:"mte.id"}})],1)],1),_c('td',[_c('b-field',{attrs:{"type":_vm.isTypeEmpty(mte) ? 'is-danger' : undefined,"message":_vm.isTypeEmpty(mte)
                          ? _vm.$t('mandatory_error_message', {
                              field: _vm.$t('type'),
                            })
                          : undefined}},[_c('b-select',{model:{value:(mte.type),callback:function ($$v) {_vm.$set(mte, "type", $$v)},expression:"mte.type"}},[_c('optgroup',{attrs:{"label":_vm.$t('masters')}},_vm._l((_vm.masterMteTypes),function(mteType){return _c('option',{key:mteType._id,domProps:{"value":mteType}},[_vm._v(" "+_vm._s(mteType.name)+" ")])}),0),_c('optgroup',{attrs:{"label":_vm.$t('non_masters')}},_vm._l((_vm.nonMasterMteTypes),function(mteType){return _c('option',{key:mteType._id,domProps:{"value":mteType}},[_vm._v(" "+_vm._s(mteType.name)+" ")])}),0)])],1)],1),_c('td',[_c('b-field',{attrs:{"type":_vm.isDueDateEmptyOrInThePast(mte)
                          ? 'is-danger'
                          : undefined,"message":_vm.isDueDateEmptyOrInThePast(mte)
                          ? _vm.$t(
                              'mandatory_date_must_not_be_in_past_error_message',
                              { field: _vm.$t('due_date') }
                            )
                          : undefined}},[_c('b-datepicker',{attrs:{"icon":"calendar-today","min-date":_vm.minDate,"years-range":[0, 10]},model:{value:(mte.dueDate),callback:function ($$v) {_vm.$set(mte, "dueDate", $$v)},expression:"mte.dueDate"}})],1)],1),_c('td',[(mte.type && mte.type.needsMaster)?[_c('b-field',{attrs:{"type":_vm.isMasterNeededAndEmpty(mte)
                            ? 'is-danger'
                            : undefined,"message":_vm.isMasterNeededAndEmpty(mte)
                            ? _vm.$t('mandatory_error_message', {
                                field: _vm.$t('master'),
                              })
                            : undefined}},[_c('b-select',{model:{value:(mte.master),callback:function ($$v) {_vm.$set(mte, "master", $$v)},expression:"mte.master"}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('none')))]),_vm._l((_vm.masterMtes),function(masterMte){return _c('option',{key:masterMte.id,domProps:{"value":masterMte}},[_vm._v(" "+_vm._s(masterMte.id)+" - "+_vm._s(masterMte.type.name)+" ")])})],2)],1)]:[_vm._v(" "+_vm._s(_vm.$t('none'))+" ")]],2),_c('td',[_vm._v(_vm._s(mte.createdBy))]),_c('td',[_vm._v(" "+_vm._s(_vm.getDateString(mte.createdAt))+" ")]),_c('td',{staticClass:"dij-mte-actions"},[_c('b-button',{on:{"click":function($event){return _vm.cancel(mte)}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('b-button',{attrs:{"disabled":!_vm.isSaveAllowed(mte),"type":"is-primary"},on:{"click":function($event){return _vm.save(mte)}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)]:[_c('td',[_vm._v(_vm._s(mte.id))]),_c('td',[_vm._v(_vm._s(mte.type.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.getDateString(mte.dueDate))+" ")]),_c('td',[(mte.master)?[_vm._v(" "+_vm._s(mte.master.id)+" - "+_vm._s(mte.master.type.name)+" ")]:[_vm._v(" "+_vm._s(_vm.$t('none'))+" ")]],2),_c('td',[_vm._v(_vm._s(mte.createdBy))]),_c('td',[_vm._v(" "+_vm._s(_vm.getDateString(mte.createdAt))+" ")]),_c('td',{staticClass:"dij-mte-actions"},[_c('b-button',{on:{"click":function($event){return _vm.edit(mte)}}},[_vm._v(_vm._s(_vm.$t('edit')))]),_c('b-button',{attrs:{"disabled":!_vm.isRemoveAllowed(mte)},on:{"click":function($event){return _vm.remove(mte)}}},[_vm._v(_vm._s(_vm.$t('delete')))])],1)]],2)}),0)])])]),_c('footer',{staticClass:"modal-card-foot dij-mte-actions"},[_c('b-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }