<template>
  <div class="container">
    <div class="close-form" @click="cancelForm">
      <img src="/img/closebtn.png" />
    </div>
    <b-notification
      auto-close
      type="is-success"
      :active.sync="showSuccess"
      :duration="3000"
      aria-close-label="Close notification"
    >
      {{ $t(successMessage) }}
    </b-notification>
    <b-notification
      auto-close
      type="is-danger"
      :active.sync="showFail"
      :duration="5000"
      aria-close-label="Close notification"
    >
      {{ $t(failedMessage) }}
    </b-notification>
    <div class="form-container">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <h4>{{ $t(title) }}</h4>

      <slot></slot>

      <div class="buttons-container">
        <button class="cancel-button" v-on:click="cancelForm">
          {{ $t('cancel') }}
        </button>

        <button
          @click="$emit('onSubmit')"
          class="submit-button"
          :disabled="!validForm || isLoading"
        >
          {{ $t(submitBtnLabel) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    validForm: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['onSubmit'],
  data() {
    return {
      showSuccess: false,
      showFail: false,
      isLoading: false,
      successMessage: 'Successfully.',
      failedMessage: 'Failed. Please, try again.',
      submitBtnLabel: 'submit',
    };
  },
  methods: {
    cancelForm() {
      this.$router.push({
        name: 'admin-root',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.close-form {
  position: fixed;
  width: 16px;
  height: 16px;
  right: 0;
  margin-right: 5%;
}

.close-form:hover {
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

.form-container {
  margin: 8px auto;
  width: 50%;
  overflow: auto;
  padding: 10px;
  margin-top: 3%;
  display: flex;
  flex-direction: column;

  .md-field {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }

  [placeholder] {
    color: #777;
    font-size: 16px;
    font-family: 'Siemens Sans';
  }
}

.buttons-container {
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cancel-button {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;
}

.cancel-button:hover {
  cursor: pointer;
  font-family: 'Siemens Sans';
  font-size: 16px;
}

.submit-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #4a86e8;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.submit-button:hover {
  cursor: pointer;
}
</style>
