/**
 ** Helper Functions that get values from the state
 */
const getters = {
  siteName(state) {
    return (id) => {
      let siteName = '';
      state.sites.filter((site) => {
        if (site._id === id) {
          siteName = site.siteName;
        }
      });
      return siteName;
    };
  },
};

export default getters;
