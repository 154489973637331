import { GET_JOB } from '../../store/jobs/actions/actionTypes';
import {
  GET_TASK,
  // SAVE_TASK,
  GET_FOLDER_DATA,
} from '../../store/tasks/actions/actionTypes';
import { USERS_GET_CURRENT } from '../../store/users/actionTypes';
import { GET_CURRENT_TASK } from '../../store/tasks/getters/getterTypes';
import { GET_QUESTIONS } from '../../store/questions/actions/actionTypes';
import { GET_BUBBLES } from '../../store/bubbles/actions/actionTypes';
import { GET_LOADING_STATE } from '../../store/loading/getters/getterTypes';
import { UNLOAD_CURRENT_TASK } from '../../store/tasks/mutations/mutationTypes';
import { SET_QUESTIONS } from '../../store/questions/mutations/mutationTypes';
import { SET_BUBBLES } from '../../store/bubbles/mutations/mutationTypes';

import SET_LOADING_STATE from '../../store/loading/mutations/mutationTypes';

// import TaskDTO from '../../shared/dtos/TaskDTO';

import LockHelper from '../../helpers/LockHelper';
import { GET_CURRENT_USER } from '../../store/users/getters/getterTypes';

export default {
  async created() {
    this.$store.commit(SET_LOADING_STATE, { value: true });
    await this.$store.dispatch(GET_TASK, this.$route.params.taskNumber);

    await this.$store.dispatch(USERS_GET_CURRENT);
    await this.$store.dispatch(GET_JOB, this.$route.params.id);

    if (this.currentTask.folderId) {
      await this.$store.dispatch(GET_FOLDER_DATA, this.currentTask.folderId);
    }

    if (this.isInstruction) {
      await this.$store.dispatch(GET_QUESTIONS, this.currentTask._id);
    }

    if (this.isDiagram) {
      await this.$store.dispatch(GET_BUBBLES, this.currentTask._id);
    }
    // Check if lock is owned by current user
    if (LockHelper.isTaskAvailable(this.currentTask, this.currentUser)) {
      this.$store.commit(SET_LOADING_STATE, { value: false });
    } else {
      this.$store.dispatch(
        'setWarningMessage',
        this.$t('tasks.locked', {
          fullname: this.currentTask.modifier.fullname,
          email: this.currentTask.modifier._id,
        })
      );
      this.$router.push({
        path: this.generatePreviousURLForTask(this.currentJob),
      });
    }
  },
  computed: {
    currentJob() {
      return this.$store.state.JobsModule.currentJob;
    },
    currentTask() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    currentUser() {
      return this.$store.getters[GET_CURRENT_USER];
    },
    isLoading() {
      return this.$store.getters[GET_LOADING_STATE];
    },
    isDiagram() {
      return this.currentTask ? this.currentTask.taskType === 'diagram' : false;
    },
    isInstruction() {
      return this.currentTask
        ? this.currentTask.taskType === 'instruction'
        : false;
    },
  },
  methods: {
    onSave(updatedTask, onCancelCb) {
      // There is not need anymore to trigger
      // bubbles/question save when the user press save
      // const taskHandlers = {
      //   diagram: () => this.$refs.diagramView.onSave(),
      //   instruction: () => this.$refs.instructionView.onSave(),
      // };

      // const { taskType } = this.currentTask;
      // if (taskHandlers[taskType]) {
      //   taskHandlers[taskType]();
      // } else {
      //   console.warn(`Unsupported task type ${taskType}`);
      // }

      if (this.currentTask.diagram && this.currentTask.diagram.inspections) {
        delete this.currentTask.diagram.inspections;
      }

      // if (updatedTask) {
      //   this.$store.dispatch(SAVE_TASK, new TaskDTO(updatedTask));
      // } else {
      //   this.$store.dispatch(SAVE_TASK, new TaskDTO(this.currentTask));
      // }

      if (onCancelCb) {
        onCancelCb();
      } else {
        this.$router.back();
      }
    },

    generatePreviousURLForTask(job) {
      let toolUrl;
      if (window.location.href.includes('/editor')) {
        toolUrl = '/view/editor/';
      } else if (window.location.href.includes('/publisher')) {
        toolUrl = '/view/publisher/';
      } else {
        return '/view/dashboard';
      }

      if (window.location.href.includes('token')) {
        const token = window.location.href.split('?token=')[1];
        return `${toolUrl}${job._id}?token=${token}`;
      }
      return toolUrl + job._id;
    },
  },
  beforeDestroy() {
    this.$store.commit(UNLOAD_CURRENT_TASK);
    this.$store.commit(SET_QUESTIONS, []);
    this.$store.commit(SET_BUBBLES, []);
  },
};
