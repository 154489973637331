<template>
  <section>
    <b-table
      :paginated="true"
      :per-page="10"
      :current-page.sync="currentPage"
      default-sort-direction="asc"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="site.sitename"
      @click="editSite"
      :row-class="(row, index) => 'table-list-row'"
      :hoverable="true"
      :data="formattedSites"
    >
      <template slot-scope="props">
        <b-table-column width="60">
          <img src="/img/icons/user.png" />
        </b-table-column>

        <b-table-column
          field="siteName"
          :label="$t(`${TRANSLATION_ROOT}.siteName`)"
          sortable
          searchable
        >
          <template #searchable="props">
            <div v-if="isSearchActive">
              <b-input
                v-model="props.filters['siteName']"
                placeholder="Filter"
                size="is-small"
              />
            </div>
          </template>
          {{ props.row.siteName }}
        </b-table-column>

        <b-table-column
          field="city"
          :label="$t(`${TRANSLATION_ROOT}.city`)"
          sortable
          searchable
        >
          <template #searchable="props">
            <div v-if="isSearchActive">
              <b-input
                v-model="props.filters['city']"
                placeholder="Filter"
                size="is-small"
              />
            </div>
          </template>
          {{ props.row.city }}
        </b-table-column>

        <b-table-column
          field="state"
          :label="$t(`${TRANSLATION_ROOT}.state`)"
          sortable
          searchable
        >
          <template #searchable="props">
            <div v-if="isSearchActive">
              <b-input
                v-model="props.filters['state']"
                placeholder="Filter"
                size="is-small"
              />
            </div>
          </template>
          {{ props.row.state }}
        </b-table-column>

        <b-table-column
          field="country"
          :label="$t(`${TRANSLATION_ROOT}.country`)"
          sortable
          searchable
        >
          <template #searchable="props">
            <div v-if="isSearchActive">
              <b-input
                v-model="props.filters['country']"
                placeholder="Filter"
                size="is-small"
              />
            </div>
          </template>
          {{ props.row.country }}
        </b-table-column>

        <b-table-column
          field="date"
          width="20"
          :label="$t(`${TRANSLATION_ROOT}.DateModified`)"
          sortable
        >
          {{ props.row.date }}
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import adminPageConstants from '../../constants/adminPageConstants';

const TRANSLATION_ROOT = 'site_list';
export default {
  name: 'sites-list',
  props: {
    search: String,
  },

  data() {
    return {
      TRANSLATION_ROOT,
      currentPage: 1,
      formattedSites: [],
      isSearchActive: false,
    };
  },

  computed: {
    sites() {
      return this.$store.state.sites;
    },

    user() {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    search() {},
  },

  async mounted() {
    const parsedSites = JSON.parse(JSON.stringify(this.sites));
    if (Object.keys(parsedSites).length < 1) {
      await this.$store.dispatch('getSites');
    }
  },
  methods: {
    async getSites(query) {
      try {
        await this.$store.dispatch('getSites', query);
        if (query && this.search) {
          this.isSearchActive = true;
        } else {
          this.isSearchActive = false;
        }
      } catch (err) {
        console.error('error when trying to get sites: ', err);
      }

      this.formatSites();
      if (this.user.privil === 0) {
        this.filterSitesbyUser();
      }
    },
    editSite(site) {
      this.$cookies.set(
        adminPageConstants.adminCookies.admin_selected_tab,
        adminPageConstants.adminPage.tabs.siteList
      );
      this.$router.push({
        name: 'edit-site',
        params: { site, id: site._id },
      });
    },

    formatSites() {
      this.formattedSites = this.sites.map((site) => {
        site.date = new Date(site.timestamp).toLocaleDateString();
        return site;
      });
    },

    // to filter the Sites/locations assigned to local admin
    filterSitesbyUser() {
      const locations = [this.user.location];
      if (this.user.secondaryLocations) {
        locations.push(...this.user.secondaryLocations);
      }
      this.formattedSites = this.formattedSites.filter((formatSite) =>
        locations.some((l) => l === formatSite._id)
      );
    },
  },
};
</script>

<style></style>
