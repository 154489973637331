const MODULE_NAME = 'QuestionsModule';

export const GET_QUESTIONS = `${MODULE_NAME}/GET_QUESTIONS`;
export const SAVE_QUESTIONS = `${MODULE_NAME}/SAVE_QUESTIONS`;
export const DELETE_QUESTION = `${MODULE_NAME}/DELETE_QUESTION`;
export const CREATE_QUESTION = `${MODULE_NAME}/CREATE_QUESTION`;
export const CREATE_QUESTIONS = `${MODULE_NAME}/CREATE_QUESTIONS`;
export const UPDATE_QUESTION = `${MODULE_NAME}/UPDATE_QUESTION`;
export const LOCK_QUESTION = `${MODULE_NAME}/LOCK_QUESTION`;
export const UNLOCK_QUESTION = `${MODULE_NAME}/UNLOCK_QUESTION`;
