<template>
  <tool-template tool="editor"></tool-template>
</template>

<script>
import ToolTemplate from '../ToolsTemplates/Template';

export default {
  name: 'editor-template',
  components: {
    'tool-template': ToolTemplate,
  },
};
</script>

<style></style>
