<template>
  <b-table
    :paginated="true"
    :per-page="10"
    default-sort-direction="asc"
    sort-icon="arrow-up"
    sort-icon-size="is-small"
    default-sort="user.first_name"
    @click="handleUserEdit"
    :row-class="(row, index) => 'table-list-row'"
    :hoverable="true"
    :data="users"
  >
    <template slot-scope="props">
      <b-table-column
        field="fullname"
        :label="$t('user_list.name')"
        sortable
        searchable
      >
        <template #searchable="props">
          <div v-if="isSearchActive">
            <b-input
              v-model="props.filters['fullname']"
              placeholder="Filter"
              size="is-small"
            />
          </div>
        </template>
        {{ props.row.fullname }}
      </b-table-column>

      <b-table-column
        field="_id"
        :label="$t('user_list.email')"
        sortable
        searchable
      >
        <template #searchable="props">
          <div v-if="isSearchActive">
            <b-input
              v-model="props.filters['_id']"
              placeholder="Filter"
              size="is-small"
            />
          </div>
        </template>
        {{ props.row._id }}
      </b-table-column>

      <b-table-column
        field="location"
        :label="$t('user_list.location')"
        sortable
        searchable
      >
        <template #searchable="props">
          <div v-if="isSearchActive">
            <b-input
              v-model="props.filters['location']"
              placeholder="Filter"
              size="is-small"
            />
          </div>
        </template>

        {{ props.row.location }}
      </b-table-column>

      <b-table-column
        field="role"
        :label="$t('user_list.role')"
        sortable
        searchable
      >
        <template #searchable="props">
          <div v-if="isSearchActive">
            <b-input
              v-model="props.filters['role']"
              placeholder="Filter"
              size="is-small"
            />
          </div>
        </template>
        {{ props.row.role }}
      </b-table-column>

      <b-table-column
        field="date"
        :label="$t('user_list.last_login')"
        sortable
        centered
        searchable
      >
        <template #searchable="props">
          <div v-if="isSearchActive">
            <b-input
              v-model="props.filters['last_login']"
              placeholder="Filter"
              size="is-small"
            />
          </div>
        </template>
        <span>
          {{ props.row.lastLoginDate }}
        </span>
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'user-list',
  props: {
    users: Array,
  },
  data() {
    return {
      searchableFields: false,
      isSearchActive: false,
    };
  },
  methods: {
    handleUserEdit(user) {
      this.$emit('selected', user);
    },
  },
};
</script>

<style></style>
