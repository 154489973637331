<template>
  <div class="edit-site-container">
    <b-notification
      auto-close
      type="is-success"
      :active.sync="showSuccess"
      :duration="3000"
      aria-close-label="Close notification"
    >
      {{ successMessage }}
    </b-notification>
    <b-notification
      auto-close
      type="is-danger"
      :active.sync="showFail"
      :duration="5000"
      aria-close-label="Close notification"
    >
      {{ failedMessage }}
    </b-notification>
    <div class="close-form" @click="handleClose">
      <img src="/img/closebtn.png" />
    </div>

    <div class="edit-site-form-container">
      <!-- <div class="column"> -->
      <!-- </div> -->
      <div class="column">
        <h4>{{ $t(`${TRANSLATION_ROOT}.edit_site`) }}</h4>

        <b-field :label="this.$t(`${TRANSLATION_ROOT}.siteName`)">
          <b-input v-model="site.siteName"></b-input>
        </b-field>
        <b-field :label="this.$t(`${TRANSLATION_ROOT}.city`)">
          <b-input v-model="site.city"></b-input>
        </b-field>
        <b-field :label="this.$t(`${TRANSLATION_ROOT}.state`)">
          <b-input v-model="site.state"></b-input>
        </b-field>
        <b-field :label="this.$t(`${TRANSLATION_ROOT}.country`)">
          <b-input v-model="site.country"></b-input>
        </b-field>
        <b-field label="Features">
          <div class="dij-site-feature-list">
            <b-checkbox v-model="site.repairAssessmentEnabled">
              {{ this.$t(`${TRANSLATION_ROOT}.rat`) }}
            </b-checkbox>
            <b-checkbox v-model="site.mteManagementEnabled">
              {{ this.$t(`${TRANSLATION_ROOT}.mte_m`) }}
            </b-checkbox>
          </div>
        </b-field>
      </div>
    </div>

    <div class="delete-update-buttons">
      <b-button @click="this.deleteSite" type="is-danger" outlined>
        {{ this.$t(`${TRANSLATION_ROOT}.delete`) }}
      </b-button>
      <b-button
        @click="this.updateSite"
        type="is-info"
        style="margin-left: 8px"
      >
        {{ this.$t(`${TRANSLATION_ROOT}.update`) }}
      </b-button>
    </div>
  </div>
</template>

<script>
const TRANSLATION_ROOT = 'edit_site_page';

export default {
  name: 'edit-site',
  props: {
    site: Object,
  },
  data() {
    return {
      TRANSLATION_ROOT,
      showFail: false,
      showSuccess: false,
      successMessage: this.$t(`${TRANSLATION_ROOT}.successMessage`),
      failedMessage: this.$t(`${TRANSLATION_ROOT}.failedMessage`),
    };
  },

  methods: {
    async updateSite() {
      try {
        await this.$store.dispatch('updateSite', this.site);

        this.showSuccess = true;
        setTimeout(() => {
          this.$router.push({
            name: 'admin-root',
          });
        }, 3000);
      } catch (err) {
        console.error(err);
        this.showFail = true;
      }
    },
    async deleteSite() {
      this.$buefy.dialog.confirm({
        message: this.$t(`${TRANSLATION_ROOT}.delete_confirm`),
        type: 'is-danger',
        hasIcon: true,
        icon: 'delete',
        confirmText: this.$t(`${TRANSLATION_ROOT}.delete`),
        onConfirm: async () => {
          try {
            await this.$store.dispatch('deleteSite', this.site);

            this.successMessage = this.$t(`${TRANSLATION_ROOT}.deletesite`);
            this.showSuccess = true;
            setTimeout(() => {
              this.$router.push({
                name: 'admin-root',
              });
            }, 3000);
          } catch (err) {
            console.error(err);
            this.failedMessage = this.$t(`${TRANSLATION_ROOT}.deletefail`);
            this.showFail = true;
          }
        },
      });
    },

    handleClose() {
      this.$router.push({
        name: 'admin-root',
      });
    },
  },
};
</script>

<style lang="scss">
.close-form {
  position: fixed;
  width: 16px;
  height: 16px;
  right: 0;
  margin-right: 5%;
}

.close-form:hover {
  cursor: pointer;
}

.edit-site-container {
  margin-top: 2%;
  h4 {
    // margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 4%;
    font-weight: bold;
    color: #333333;
    font-size: 20px;
  }
}

.edit-site-form-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-left: 10%;
}

.first-column {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}
select,
.select,
.is-empty {
  width: 100%;
}

.delete-update-buttons {
  right: 0;
  bottom: 0;
  position: absolute;
  margin-right: 10%;
  margin-bottom: 5%;
}

.dij-site-feature-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
