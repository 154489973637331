<template>
  <b-loading :is-full-page="true" :active="isLoading"> </b-loading>
</template>

<script>
export default {
  name: 'link-redirect',
  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {
    try {
      const { id } = this.$route.params;
      let { url } = await this.$store.dispatch('getMaskedURL', id);
      [, url] = url.split('/view');
      const [, token] = url.split('?token=');
      let tool;

      if (url.includes('editor')) {
        tool = 'editor';
      } else if (url.includes('publisher')) {
        tool = 'publisher';
      } else if (url.includes('inspector')) {
        tool = 'inspector';
      } else if (url.includes('report')) {
        tool = 'report';
      }

      const [jobId] = url.split(`/${tool}/`)[1].split('?token');
      console.log({ tool, jobId, token });
      if (tool === 'report') {
        this.$router.push({
          name: 'report-template',
          params: { id: jobId },
          query: { token },
        });
      } else {
        this.$router.push({
          name: `${tool}-tasks`,
          params: { id: jobId },
          query: { token },
        });
      }
    } catch (err) {
      this.$router.push({ name: 'logout' });
    }
  },
};
</script>

<style></style>
