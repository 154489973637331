<template>
  <b-modal
    :active="isActive"
    :can-cancel="['escape', 'x', 'outside']"
    @close="onClose"
    custom-class="modal-wrapper"
  >
    <div class="content">
      <h2>Sign Off</h2>
      <div v-if="currentTask.taskType === 'instruction'">
        {{ questionsCompleteness }}/{{ taskQuestions.length }} completed.
      </div>
      <div v-else>
        Diagram {{ parseFloat(currentTask.completenessPercentage).toFixed(2) }}%
        completed.
      </div>
      <b-field>
        <b-input
          v-model="inspectorName"
          placeholder="Enter your name/initials"
        ></b-input>
      </b-field>
      <b-field label="Please enter your comments here... ">
        <b-input
          v-model="inspectorComments"
          maxlength="200"
          type="textarea"
        ></b-input>
      </b-field>
      <footer class="footer-buttons">
        <b-button label="Close" @click="onClose" />
        <b-button
          label="Submit"
          :disabled="submitInProgress"
          type="is-primary"
          @click="onSubmit"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { GET_TASK_QUESTIONS } from '../../../store/questions/getters/getterTypes';
import { SAVE_TASK } from '../../../store/tasks/actions/actionTypes';
import { GET_CURRENT_TASK } from '../../../store/tasks/getters/getterTypes';

export default {
  name: 'SignOffDialog',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    async onSubmit() {
      this.submitInProgress = true;
      await this.$store.dispatch(SAVE_TASK, {
        ...this.currentTask,
        signedBy: this.inspectorName,
        taskComment: this.inspectorComments,
        workStatusCode: '2',
      });
      this.submitInProgress = false;
      this.onClose();
    },
  },
  data() {
    return {
      inspectorName: '',
      inspectorComments: '',
      submitInProgress: false,
    };
  },
  computed: {
    currentTask() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    taskQuestions() {
      return this.$store.getters[GET_TASK_QUESTIONS];
    },
    questionsCompleteness() {
      let completedQuestions = 0;
      this.taskQuestions.forEach((instruction) => {
        if (
          instruction.inputValue ||
          instruction.checked ||
          instruction.yesOrNo
        ) {
          completedQuestions++;
        }

        if (instruction.type === 'multiplechoice') {
          if (
            instruction.multipleChoices.some(
              (choice) => choice.checked === true
            )
          ) {
            completedQuestions++;
          }
        }

        if (instruction.type === 'tablequestion') {
          let tableCompleted = true;
          for (let i = 1; i < instruction.tableData.length; i++) {
            for (let j = 1; j < instruction.tableData[i].length; j++) {
              if (
                instruction.tableData[i][j] === '' ||
                instruction.tableData[i][j] == null
              ) {
                tableCompleted = false;
                break;
              }
            }
          }

          if (tableCompleted) {
            completedQuestions++;
          }
        }
      });
      return completedQuestions;
    },
  },
};
</script>

<style scoped>
.footer-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.footer-buttons button {
  flex-grow: 1; /* Causes the buttons to grow and fill the container */
}

.modal-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  background: white;
  border-radius: 10px;
  padding: 38px;
  margin: 0 25%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
</style>
