import client from '../../../config/api';
import { LOCAL_SET_CURRENT_JOB } from '../mutations/mutationTypes';
import { ITEM_TO_EXCLUDE } from '../../reports/mutations/mutationTypes';

export default {
  async JOBS_GET_FOLDERS(_1, jobId) {
    try {
      return await client.get(`/job/${jobId}/folders`);
    } catch (error) {
      throw new Error(error);
    }
  },

  async UPDATE_JOB_REPORT(_1, payload) {
    try {
      return await client.put(`/job/${payload._id}`, payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async GET_JOB({ commit }, id) {
    const result = await client.get(`/job/${id}`);

    commit(LOCAL_SET_CURRENT_JOB, result.data);
    if (result.data.excludedItems) {
      result.data.excludedItems.forEach((item) => {
        commit(ITEM_TO_EXCLUDE, { id: item, value: true }, { root: true });
      });
    }
  },
};
