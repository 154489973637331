<template>
  <b-modal :active="true" :width="640">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-field :label="$t('user_picker.label')">
          <b-autocomplete
            ref="userPicker"
            class="user-picker"
            :data="filteredUsers"
            field="fullname"
            :placeholder="$t('user_picker.placeholder')"
            icon="magnify"
            clearable
            @select="(option) => (selectedUser = option)"
            @typing="handleTyping"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <div
                    class="image-placeholder"
                    :style="
                      'background-color:' + getColor(props.option.fullname)
                    "
                  >
                    {{ getInitials(props.option.fullname) }}
                  </div>
                </div>
                <div class="media-content">
                  {{ props.option.fullname }}
                  <br />
                  <small>
                    {{ $t('user_picker.email') }}: {{ props.option._id }},<br />
                    {{ $t('user_picker.role') }}:
                    <b>{{ props.option.role }}</b>
                  </small>
                </div>
              </div>
            </template>
            <template #empty>{{ $t('user_picker.no_match_found') }}</template>
          </b-autocomplete>
        </b-field>
        <div class="actions">
          <b-button @click="handleCancel">{{ $t('cancel') }}</b-button>
          <b-button type="is-primary" @click="handleOk">{{
            $t('ok')
          }}</b-button>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['user'],
  emits: ['cancel', 'ok'],
  data() {
    return {
      selectedUser: '',
      filteredUsers: [],
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  async created() {
    if (this.users.length === 0) {
      await this.$store.dispatch('getUsers');
    }
    if (this.user) {
      this.selectedUser = this.users.find((user) => user._id === this.user._id);
      this.$refs.userPicker.setSelected(this.selectedUser);
    }
  },
  methods: {
    getColor(fullname) {
      /* eslint-disable no-bitwise */
      if (!fullname) {
        return '#ffffff';
      }
      let hash = 0;
      fullname.split('').forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
      });
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, '0');
      }
      return colour;
      /* eslint-enable no-bitwise */
    },
    getInitials(fullname) {
      if (!fullname) {
        return '??';
      }
      const splittedFullName = fullname.split(' ');
      if (splittedFullName.length === 1) {
        return splittedFullName[0].substring(0, 2);
      }
      return (
        splittedFullName[0].charAt(0) +
        splittedFullName[splittedFullName.length - 1].charAt(0)
      );
    },
    handleTyping(text) {
      this.filteredUsers = this.users.filter(
        (user) =>
          (user._id && user._id.includes(text)) ||
          (user.fullname && user.fullname.includes(text))
      );
    },
    handleCancel() {
      this.$emit('cancel');
    },
    handleOk() {
      this.$emit('ok', this.selectedUser);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  height: 50vh;
}
.user-picker {
  ::v-deep .dropdown-menu .dropdown-content {
    background-color: white;
    .dropdown-item {
      height: 76px;
    }
  }
}
.image-placeholder {
  width: 64px;
  height: 64px;
  border-radius: 128px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
</style>
