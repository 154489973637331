<template>
  <section>
    <b-field class="second-field" label="Units">
      <b-select
        size="is-medium"
        icon="ruler"
        v-model="user.unit"
        :placeholder="user.unit"
      >
        <option value="inch">Inches</option>
        <option value="mm">Millimetres</option>
      </b-select>
    </b-field>

    <div class="update-buttons">
      <div class="update-profile-button">
        <b-button
          @click="updateProfile"
          size="is-medium"
          class="submit-button"
          type="is-primary"
          >{{ $t('profile_page.updatePreferences') }}</b-button
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ['updateProfile'],
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    updateProfile() {
      this.$emit('updateProfile');
    },
  },
};
</script>

<style scoped lang="scss">
.update-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.update-profile-button,
.update-password-button {
  .submit-button {
    color: $siemens-logo-color !important;
    background-color: white !important;
    inline-size: fit-content !important;

    &:hover {
      color: white !important;
      background-color: $siemens-logo-color !important;
    }
  }
}
</style>
