<template>
  <b-modal :active.sync="showModal" @close="onClose">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add ISO Value</p>
      </header>
      <section class="modal-card-body">
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field class="selectField">
              <v-select
                placeholder="ISO Name"
                v-model="isoNameSelected"
                class="selectProdLine"
                :options="isoNames"
              ></v-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="selectField">
              <v-select
                placeholder="Grade"
                v-model="gradeSelected"
                class="selectProdLine"
                :options="isoGrades"
              ></v-select>
            </md-field>
          </div>
        </div>
        <div v-if="isoValuesFailed">
          Sorry, there are no standards for this value
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="close">Close</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      isoNameSelected: '',
      isoNames: [],
      isoGrades: [],
      gradeSelected: '',
      cell: {},
      isoValues: null,
      isoValuesFailed: false,
    };
  },
  async mounted() {},
  methods: {
    async getGrades(name) {
      this.isoGrades = await this.$store.dispatch('getIsoGrades', name);
    },
    async getIsoValues() {
      // called after grade is selected
      this.isoValuesFailed = false;
      this.isoValues = null;

      // get the name of the column to calculate iso values from
      const nominalField = Object.keys(this.cell.record)[this.cell.colPos];
      const values = [];

      // save all values from the nominal column
      this.$parent.rows.map((row) => {
        values.push(row[nominalField]);
      });

      // values to filter on DB
      const options = {
        name: this.isoNameSelected,
        grade: this.gradeSelected,
        values,
      };

      // get the tolerances values from db
      try {
        this.isoValues = await this.$store.dispatch('getIsoValues', options);
      } catch (error) {
        console.error('Failed to get iso Values', error);
        this.isoValuesFailed = true;
        return;
      }

      // iterate all values and add them to the table rows
      this.isoValues.forEach((tolerances, key) => {
        if (!tolerances) {
          // show message if failed to get iso values
          this.isoValuesFailed = true;
          return;
        }
        // get the tolerance type and set the values
        const result = this.getToleranceType(tolerances);
        // save the values to the table row
        this.$parent.rows[key][
          `${nominalField}_iso_ut`
        ] = `${result.positiveSignal}${result.positiveTolerance}`;
        this.$parent.rows[key][
          `${nominalField}_iso_lt`
        ] = `${result.negativeSignal}${result.negativeTolerance}`;

        // save the values to the rowsData to be savet into DB
        this.$parent.rowsData[key][
          `${nominalField}_iso_ut`
        ] = `${result.positiveSignal}${result.positiveTolerance}`;
        this.$parent.rowsData[key][
          `${nominalField}_iso_lt`
        ] = `${result.negativeSignal}${result.negativeTolerance}`;
      });
      // if there is no failed value
      if (!this.isoValuesFailed) {
        // create iso columns
        const utColumn = {
          field: `${nominalField}_iso_ut`,
          label: 'ISO UT',
        };
        const ltColumn = {
          field: `${nominalField}_iso_lt`,
          label: 'ISO LT',
        };

        // check if columns already exists
        const utIndex = this.$parent.columns.findIndex(
          (column) => column.field === utColumn.field
        );
        const ltIndex = this.$parent.columns.findIndex(
          (column) => column.field === ltColumn.field
        );

        // if columns does not exists then add them
        if (utIndex < 0) {
          this.$parent.columns.splice(this.cell.colPos + 1, 0, utColumn);
        }
        if (ltIndex < 0) {
          this.$parent.columns.splice(this.cell.colPos + 1, 0, ltColumn);
        }

        // rerender the table component so columns can show correctly
        this.$parent.showTable = false;
        this.$nextTick(() => {
          // Add the component back in
          this.$parent.showTable = true;
        });

        return this.close();
      }
    },
    async open() {
      // open the modal for new calculation
      if (this.isoNames.length === 0) {
        // get iso names
        this.isoNames = await this.$store.dispatch('getIsoNames');
      }
      // clear values
      this.cell = this.$parent.selectedCell;
      this.isoGrades = [];
      this.isoNameSelected = '';
      this.gradeSelected = '';
      this.isoValues = null;
      this.isoValuesFailed = false;

      // open modal
      this.showModal = true;
      this.$parent.cellInUse = true; // tell the parent to 'block' the selected cell
    },
    close() {
      // called when clkicked on close button
      this.showModal = false;
      return this.onClose();
    },
    onClose() {
      // called when modal is closed (clicked outside, pressed 'Esc')
      this.$parent.cellInUse = false; // tell the parent to let go the selected cell
      this.$parent.selectedCell = {};
    },
    getToleranceType({ tolerance1, tolerance2 }) {
      // get the tolerance type based on the tolerances values
      const result = {
        toleranceType: 0,
        positiveSignal: '',
        negativeSignal: '',
        positiveTolerance: 0,
        negativeTolerance: 0,
      };
      if (tolerance1 * tolerance2 > 0) {
        if (tolerance1 >= 0) {
          result.toleranceType = 1; // +/-
          result.positiveSignal = '+';
          result.negativeSignal = '-';
          result.positiveTolerance = Math.abs(tolerance1);
          result.negativeTolerance = Math.abs(tolerance2);
        } else {
          result.toleranceType = 2; // -/-
          result.positiveSignal = '-';
          result.negativeSignal = '-';
          result.positiveTolerance = Math.abs(tolerance1);
          result.negativeTolerance = Math.abs(tolerance2);
        }
      } else {
        result.toleranceType = 0; // +/+
        result.positiveSignal = '+';
        result.negativeSignal = '+';
        if (tolerance1 >= 0) {
          result.positiveTolerance = Math.abs(tolerance1);
          result.negativeTolerance = Math.abs(tolerance2);
        } else {
          result.positiveTolerance = Math.abs(tolerance2);
          result.negativeTolerance = Math.abs(tolerance1);
        }
      }
      return result;
    },
  },
  watch: {
    isoNameSelected: {
      handler(name) {
        if (name) this.getGrades(name);
      },
    },
    gradeSelected: {
      handler(grade) {
        if (grade) this.getIsoValues();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-card-body {
  height: 400px;
}
.t-button {
  min-width: 100px;
}
</style>
