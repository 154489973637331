<template>
  <div class="welcome-page">
    <template v-if="shouldDisplaySiteSelector">
      <h1 class="welcome-message">
        {{ $t('new_user_welcome_page.welcome_configurator_message') }}
      </h1>
      <select-sites @selectedSites="handleSelectedSites($event)"></select-sites>
      <div class="actions">
        <b-button v-if="isSurrogating" @click="stopSurrogating">{{
          $t('stop_surrogating')
        }}</b-button>
        <b-button type="is-primary" @click="updateUser">{{
          $t('new_user_welcome_page.submit')
        }}</b-button>
      </div>
    </template>
    <template v-else>
      <div class="wrapper">
        <div class="login">
          <div class="se-logo" />
          <div class="content">
            <div class="dij-logo">
              <div class="abbreviation">DIJ</div>
              <div class="name">
                <div class="name-top">Digital</div>
                <div class="name-bottom">Inspection Jacket</div>
              </div>
            </div>
            <div class="pending-approval-message">
              <div class="part1">
                {{ $t('new_user_welcome_page.pending_approval_part1_message') }}
              </div>
              <div class="part2">
                {{ $t('new_user_welcome_page.pending_approval_part2_message') }}
              </div>
            </div>
            <b-button v-if="isSurrogating" @click="stopSurrogating">{{
              $t('stop_surrogating')
            }}</b-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SelectSites from '../Publisher/SelectSites';

export default {
  name: 'new-user-welcome-page',
  components: {
    'select-sites': SelectSites,
  },
  data() {
    return {
      primarySite: null,
      secondarySites: null,
    };
  },
  methods: {
    handleSelectedSites(sites) {
      if (sites && sites.length > 0) {
        [this.primarySite] = sites;
        [, ...this.secondarySites] = sites;
      } else {
        this.primarySite = null;
        this.secondarySites = null;
      }
    },
    async updateUser() {
      await this.$store.dispatch('updateUserSelf', {
        location: this.primarySite,
        secondaryLocations: this.secondarySites,
      });
    },
    stopSurrogating() {
      this.$store.dispatch('clearSurrogate');
      location.reload();
    },
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    roles() {
      return this.$store.state.userRoles;
    },
    shouldDisplaySiteSelector() {
      return !this.user.location;
    },
    isSurrogating() {
      return !!this.$store.state.surrogateUser;
    },
  },
};
</script>

<style scoped lang="scss">
.welcome-page {
  margin: 20px;
}
.welcome-message {
  display: flex;
  justify-content: center;
  font-size: 32px;
  margin-bottom: 10px;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
.wrapper {
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  width: 100%;
  background-color: white;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px grey;
}

.se-logo {
  width: 100px;
  height: 50px;
  background-image: url('/img/se_logo_color_rgb.svg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  margin-bottom: 50px;
}

.content {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dij-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: rgb(100, 30, 140);
  /*margin: 0 0 0 -4px;*/

  .abbreviation {
    font-size: 96px;
    border-right: 2px solid rgb(100, 30, 140);
    line-height: 62px;
    height: 82px;
    padding-right: 10px;
  }

  .name {
    font-size: 36px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }

  .name-top {
    height: 48px;
  }

  .name-bottom {
    height: 48px;
    margin-bottom: 5px;
  }
}
.pending-approval-message {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
