<template>
  <div id="header">
    <div class="header-logo-outer">
      <router-link
        :to="tokenExists ? this.$router.currentRoute : '/view/dashboard'"
        class="header-logo"
      >
        <img
          src="/img/siemens-energy-logo.png"
          alt
          srcset
          style="width: inherit"
        />
      </router-link>
    </div>
    <div class="header-inner header-current-tool">
      DIJ - {{ toolName ? toolName : 'Digital Inspection Jacket' }}
    </div>
    <router-link
      to="/view/admin"
      v-if="isAdminVisible && !isDashboard && !this.$route.query.token"
    >
      <div class="header-inner headerTool">{{ $t('admin') }}</div>
    </router-link>
    <router-link
      to="/view/editor"
      v-if="isEditorVisible && !isDashboard && !this.$route.query.token"
    >
      <div class="header-inner headerTool">{{ $t('editor') }}</div>
    </router-link>
    <router-link
      to="/view/publisher"
      v-if="isPublisherVisible && !isDashboard && !this.$route.query.token"
    >
      <div class="header-inner headerTool">{{ $t('publisher') }}</div>
    </router-link>
    <router-link
      to="/view/inspector"
      v-if="
        toolName !== 'Inspector' &&
        !tokenExists &&
        !isDashboard &&
        this.userAllowedTools.some((tool) => tool === 'inspector') &&
        !this.$route.query.token
      "
    >
      <div class="header-inner headerTool">{{ $t('inspector') }}</div>
    </router-link>
    <router-link
      to="/view/report"
      v-if="isReportVisible && !isDashboard && !this.$route.query.token"
    >
      <div v-if="!tokenExists && !isDashboard" class="header-inner headerTool">
        {{ $t('report') }}
      </div>
    </router-link>
    <submit-for-approval v-if="isToolEditor"></submit-for-approval>
    <HeaderMenu v-if="!tokenExists"></HeaderMenu>
  </div>
</template>

<script>
import SubmitForApproval from '../ToolsTemplates/TemplateApproval/SubmitForApproval';
import HeaderMenu from './HeaderMenu';

export default {
  name: 'main-header',
  data() {
    return {
      tokenExists: false,
      navigation: 'home',
      isToolEditor: false,
      isDashboard: false,
      token: '',
    };
  },
  watch: {
    '$route.path': {
      handler(path) {
        if (path.includes('dashboard') || path === '/') {
          this.isDashboard = true;
        } else {
          this.isDashboard = false;
        }
      },
    },
    '$route.query': {
      handler(query) {
        if (query.token) {
          this.token = query.token;
          if (this.token) {
            this.tokenExists = true;
            if (this.$route.path.includes('editor')) {
              this.isToolEditor = true;
            }
          }
        }
      },
    },
  },
  async mounted() {
    if (this.$route.path.includes('dashboard')) {
      this.isDashboard = true;
    }
    this.token = this.$route.query.token;
    if (this.token) {
      this.tokenExists = true;
      if (this.$route.path.includes('editor')) {
        this.isToolEditor = true;
      }
    }
  },
  components: {
    HeaderMenu,
    'submit-for-approval': SubmitForApproval,
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    userRoles() {
      return this.$store.state.userRoles;
    },
    userAllowedTools() {
      return this.$store.state.currentUserAllowedTools;
    },
    route() {
      return this.$route.path;
    },

    toolName() {
      const path = this.route.split('/')[2];

      let tool = path ? this.$t(`${path}`) : undefined;
      if (this.$route.path.includes('dashboard') || this.$route.path === '/') {
        tool = 'Dashboard';
      }
      return tool;
    },

    isMobile() {
      if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      ) {
        return true;
      }
      return false;
    },

    isEditorVisible() {
      if (
        this.userAllowedTools.some((tool) => tool === 'editor') &&
        !this.isMobile &&
        this.toolName !== 'Editor' &&
        !this.tokenExists
      ) {
        return true;
      }
      return false;
    },
    isPublisherVisible() {
      if (
        this.userAllowedTools.some((tool) => tool === 'publisher') &&
        !this.isMobile &&
        this.toolName !== 'Publisher' &&
        !this.tokenExists
      ) {
        return true;
      }
      return false;
    },
    isReportVisible() {
      if (
        this.userAllowedTools.some((tool) => tool === 'report') &&
        !this.isMobile &&
        this.toolName !== 'Report' &&
        !this.tokenExists
      ) {
        return true;
      }
      return false;
    },
    isAdminVisible() {
      if (
        this.userAllowedTools.some((tool) => tool === 'admin') &&
        !this.isMobile &&
        this.toolName !== 'Admin' &&
        !this.tokenExists
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
#header {
  display: flex;
  position: static !important;
}
.header-logo-outer {
  width: 100px;
  height: 100%;
  margin: 8px 10px 10px 40px;
}

.header-logo {
  width: 100%;

  img {
    width: 90px !important;
    margin-top: 2px;
  }
}

.header-current-tool {
  margin-left: 30px;
}

#header .header-inner {
  position: relative;
  padding: 16px 0;
  vertical-align: top;
  display: inline-flex;
}

#header .header-inner-logo a {
  position: relative;
  height: 100px;
  margin: 0;
  top: 0;
  text-decoration: none;
  color: white;
  overflow-y: hidden;
}

.headerTool {
  padding: 13px 32px !important;
  border: 3px solid #afafaf;
  height: 50px;
}

.headerTool:hover {
  color: white !important;
  border-bottom: 3px solid white;
}

.offlineToggle {
  position: absolute;
  top: 13px;
  right: 180px;
}

.offlineToggle paper-toggle-button {
  --paper-toggle-button-unchecked-bar-color: #4a86e8;
  --paper-toggle-button-unchecked-button-color: #4a86e8;
  --paper-toggle-button-checked-bar-color: gray;
  --paper-toggle-button-checked-button-color: white;
}

.offlineToggle .offlineLabel {
  color: white;
  font-family: 'Siemens Sans';
}
</style>
