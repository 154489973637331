<template>
  <div>
    <template v-if="isRepairAssessmentEnabledForCurrentLocation">
      <repair-assessment-detail-dialog
        tool="inspector"
        v-if="isRepairAssessmentVisible"
        :readOnly="readOnly"
        @close="hideRepairAssessment"
      />
      <button class="dij-ra-polymer-show" @click="showRepairAssessment" />
      <button class="dij-ra-polymer-hide" @click="hideRepairAssessment" />
    </template>
    <template v-if="isRepairAssessmentEnabledForCurrentLocation">
      <mte-management v-if="isMteVisible" @close="hideMte" />
      <button class="dij-mte-polymer-show" @click="showMte" />
      <button class="dij-mte-polymer-hide" @click="hideMte" />
    </template>
    <tool-template tool="inspector" />
  </div>
</template>

<script>
import ToolTemplate from '../ToolsTemplates/Template';
import RepairAssessmentDetailDialog from '../layout/RepairAssessmentDetailDialog';
import MteManagement from '../layout/MteManagement';

export default {
  name: 'inspector-template',
  components: {
    'tool-template': ToolTemplate,
    RepairAssessmentDetailDialog,
    MteManagement,
  },
  data() {
    return {
      isRepairAssessmentVisible: false,
      isMteVisible: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    sites() {
      return this.$store.state.sites;
    },
    isRepairAssessmentEnabledForCurrentLocation() {
      if (!this.user || !this.sites) {
        return false;
      }
      if (this.user.privil === 1) {
        return true;
      }
      if (this.sites) {
        const currentLocation = this.sites.filter(
          (site) => site._id === this.user.location
        )[0];

        if (currentLocation) {
          return currentLocation.repairAssessmentEnabled;
        }
        return false;
      }

      return false;
    },
    isMteEnabledForCurrentLocation() {
      if (!this.user || !this.sites) {
        return false;
      }
      if (this.user.privil === 1) {
        return true;
      }
      if (this.sites) {
        const currentLocation = this.sites.filter(
          (site) => site._id === this.user.location
        )[0];

        if (currentLocation) {
          return currentLocation.mteManagementEnabled;
        }
        return false;
      }

      return false;
    },
  },
  async created() {
    if (!this.sites) {
      await this.$store.dispatch('getSites');
    }
  },
  methods: {
    showRepairAssessment() {
      this.isRepairAssessmentVisible = true;
    },
    hideRepairAssessment() {
      this.isRepairAssessmentVisible = false;
    },
    showMte() {
      this.isMteVisible = true;
    },
    hideMte() {
      this.isMteVisible = false;
    },
  },
};
</script>

<style></style>
