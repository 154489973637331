<template>
  <tasks-template ref="tasks" tool="publisher"> </tasks-template>
</template>

<script>
import TaskTemplate from '../ToolsTemplates/TaskPage';
import store from '../../store/index';

export default {
  components: {
    'tasks-template': TaskTemplate,
  },

  async beforeRouteEnter(to, {}, next) {
    const { id } = to.params;
    const { state } = store;
    if (!state.job || state.job.id !== id) {
      await store.dispatch('getJob', id);
    }
    next();
  },

  async beforeRouteLeave({}, {}, next) {
    await this.$refs.tasks.updateThumbnail();
    await this.$store.dispatch('updateJobSimple', this.$refs.tasks.job);

    next();
  },
};
</script>

<style></style>
