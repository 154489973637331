<template>
  <b-modal :active="active" full-screen class="dij-modal-dynamic-width">
    <div class="dij-deviation-container">
      <DeviationReport ref="deviationReport" />
    </div>

    <footer class="modal-card-foot">
      <div>
        <b-button type="is-primary" icon-right="plus" @click="addRow" />
      </div>
      <div class="rigth-actions">
        <b-button @click="close">
          {{ $t('close') }}
        </b-button>
      </div>
    </footer>
  </b-modal>
</template>
<script>
import DeviationReport from './DeviationReport';

export default {
  name: 'DeviationReportModal',
  components: {
    DeviationReport,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    addRow() {
      this.$refs.deviationReport.addRow();
    },
    save() {
      this.$refs.deviationReport.save();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="scss">
.dij-deviation-container {
  flex: 1;
  display: flex;
}
div.dij-modal-dynamic-width {
  font-size: 16px;
  ::v-deep div.animation-content {
    max-width: 100%;
  }
}
.modal-card-foot {
  padding: 10px;
}
.rigth-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
