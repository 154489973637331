<template>
  <div class="wrapper">
    <b-modal
      v-if="isCameraDisplayed"
      :active="true"
      class="dij-diagrambuilder-camera-modal"
      full-screen
    >
      <camera @cancel="hideCamera" @ok="processCamera" />
    </b-modal>
    <div class="items-container">
      <div
        class="instruction full"
        v-for="(instruction, index) in taskQuestions"
      >
        <div class="instruction-title">
          {{ instruction.label }}. {{ instruction.question }}
          <div class="right-side-title">
            <div
              v-if="instruction.isPictureMandatory"
              class="req-picture"
              :style="iconRequirePictureStyle(instruction)"
            >
              Picture required.
            </div>
            <template v-if="!isTablet">
              <b-dropdown
                :expanded="true"
                position="is-bottom-left"
                :close-on-click="false"
                :ref="'fileUploadDropdown' + index"
              >
                <template #trigger>
                  <b-icon
                    icon="camera"
                    size="is-large"
                    class="instruction-action camera"
                    :style="iconRequirePictureStyle(instruction)"
                    :class="{
                      'animate-slide':
                        instruction.isPictureMandatory &&
                        instruction.image &&
                        instruction.image.length === 0,
                    }"
                  />
                </template>
                <b-dropdown-item>
                  <b-upload
                    :disabled="isTaskCompleted"
                    multiple
                    accept=".jpg, .jpeg, .png, .pdf"
                    class="file-label"
                    @input="
                      (event) => onUploadImageWrapper(event, instruction, index)
                    "
                    v-model="imagesToUpload"
                  >
                    <span class="file-upload icon instruction-action is-large"
                      ><i class="mdi mdi-folder mdi-48px"> </i
                    ></span>
                    <span>Choose file</span>
                  </b-upload>
                </b-dropdown-item>
                <b-dropdown-item @click.native="showCamera(instruction)">
                  <b-icon
                    icon="camera"
                    size="is-large"
                    class="instruction-action camera"
                  />
                  <span>Take photo</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-if="isTablet">
              <b-upload
                :disabled="isTaskCompleted"
                multiple
                accept=".jpg, .jpeg, .png, .pdf"
                class="file-label"
                @input="(event) => onUploadImage(event, instruction)"
                v-model="imagesToUpload"
              >
                <span
                  class="file-upload icon instruction-action is-large"
                  :class="{
                    'animate-slide':
                      instruction.isPictureMandatory &&
                      instruction.image &&
                      instruction.image.length === 0,
                  }"
                  :style="iconRequirePictureStyle(instruction)"
                  ><i class="mdi mdi-camera mdi-48px"></i
                ></span>
              </b-upload>
              <!-- <div class="dij-diagrambuilder-camera">
                <b-icon
                  icon="camera"
                  size="is-large"
                  class="instruction-action camera"
                  :style="iconRequirePictureStyle(instruction)"
                  :class="{
                    'animate-slide':
                      instruction.isPictureMandatory &&
                      instruction.image &&
                      instruction.image.length === 0,
                  }"
                  @click.native="showCamera(instruction)"
                />
              </div> -->
            </template>

            <div class="icon-container">
              <b-icon
                icon="image"
                size="is-large"
                class="instruction-action"
                @click.native="showGallery(instruction)"
                :style="iconRequirePictureStyle(instruction)"
              ></b-icon>
              <span
                v-if="instruction.image && instruction.image.length > 0"
                class="image-counter"
              >
                {{ instruction.image.length }}
              </span>
            </div>
            <b-button
              v-if="['tablequestion', 'importcsv'].includes(instruction.type)"
              type="is-success"
              @click="onSaveTable(instruction)"
            >
              Save
            </b-button>
          </div>
        </div>
        <div class="image-container-helper" v-if="instruction.imageReference">
          <img :src="getImageReference(instruction)" alt="image" />
        </div>
        <div class="instruction-details">
          <div class="question-details" v-if="instruction.type === 'action'">
            <b-checkbox
              :disabled="isTaskCompleted"
              v-model="instruction.checked"
              type="is-success"
              size="is-medium"
              @input="debouncedUpdateQuestion(instruction)"
            >
              Done
            </b-checkbox>
          </div>
          <div
            class="question-details question-details-yes-no"
            v-if="isYesNoType(instruction)"
          >
            <b-radio-button
              v-for="option in options"
              :key="option.value"
              :disabled="isTaskCompleted"
              v-model="instruction.yesOrNo"
              :native-value="option.value"
              size="is-medium"
              :type="option.type"
              @input="debouncedUpdateQuestion(instruction)"
            >
              <b-icon :icon="option.icon"></b-icon>
              <span>{{ option.label }}</span>
            </b-radio-button>
          </div>
          <div
            class="question-details"
            v-if="instruction.type === 'textnumber'"
          >
            <b-input
              expanded
              :disabled="isTaskCompleted"
              placeholder="Tap me to start writing"
              v-model="instruction.inputValue"
              @input="debouncedUpdateQuestion(instruction)"
            ></b-input>
          </div>
          <div
            v-if="instruction.type === 'multiplechoice'"
            class="question-details"
            style="display: flex; gap: 10px"
          >
            <b-checkbox
              v-for="(option, optionIndex) in instruction.multipleChoices"
              :key="optionIndex"
              :disabled="isTaskCompleted"
              size="is-medium"
              v-model="option.checked"
              type="is-success"
              @input="debouncedUpdateQuestion(instruction)"
            >
              {{ option.text?.defaultMC || option?.text || option.defaultMC }}
            </b-checkbox>
          </div>
          <div
            v-if="['tablequestion', 'importcsv'].includes(instruction.type)"
            class="question-details"
          >
            <div
              v-if="instruction.type === 'tablequestion'"
              class="table-description"
            >
              Table {{ instruction.dimensions }}
            </div>

            <div
              v-if="instruction.type === 'tablequestion'"
              class="image-container"
            >
              <div v-if="getTableImage(instruction)">
                <img :src="getTableImage(instruction)" alt="Table image" />
              </div>
              <div v-else>
                <b-icon icon="image-area" size="is-medium"></b-icon>
              </div>
            </div>
            <TableQuestionLayout
              :ref="'tableQuestion' + instruction._id"
              :rows="instruction.tableRow"
              :cols="instruction.tableColumn"
              :data="instruction.tableData"
              :isCSV="instruction.type === 'importcsv'"
              displayType="inspector"
              :readOnly="isTaskCompleted"
              @onCellLeave="onCellLeave(instruction)"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal v-if="isAnnotatorDisplayed" :active="true">
      <image-annotator
        :image="imageToBeAnnotated"
        @cancel="hideAnnotator"
        @ok="processAnnotator"
        @okandnew="newAnnotator"
        :nextCamera="annotatorCameraNext"
      />
    </b-modal>
    <image-gallery
      v-if="isGalleryDisplayed"
      v-model="selectedQuestion.image"
      type="instruction"
      :readOnly="isTaskCompleted"
      @close="hideGallery"
      @onDeleteImage="debouncedUpdateQuestion(selectedQuestion)"
      @onEditInformation="debouncedUpdateQuestion(selectedQuestion)"
    />
  </div>
</template>

<script>
import { v4 } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getDocument } from 'pdfjs-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'pdfjs-dist/webpack.mjs';
import { debounce } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import MobileDetect from 'mobile-detect';
import {
  GET_QUESTIONS,
  CREATE_QUESTIONS,
  UPDATE_QUESTION,
} from '../../../store/questions/actions/actionTypes';
import Camera from '../../layout/Camera';
import ImageAnnotator from '../../layout/ImageAnnotator';
import ImageGallery from '../../layout/ImageGallery';
import { GET_CURRENT_USER } from '../../../store/users/getters/getterTypes';
import { GET_TASK_QUESTIONS } from '../../../store/questions/getters/getterTypes';
import { GET_CURRENT_JOB } from '../../../store/jobs/getters/getterTypes';
import TableQuestionLayout from './components/TableQuestionLayout';
import DIJVueConfigurations from '../../../helpers/DIJVueConfigurations';
import imageHelper from '../../../../public/codebase/imageFunctions';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../../store/websocket/actions/actionTypes';
import {
  ADD_QUESTION,
  REMOVE_QUESTION,
  MUTATION_UPDATE_QUESTION,
} from '../../../store/questions/mutations/mutationTypes';

const uuidv4 = v4;

export default {
  components: {
    Camera,
    TableQuestionLayout,
    ImageAnnotator,
    ImageGallery,
  },
  data() {
    return {
      uploadedFiles: 0,
      totalFilesToBeUploaded: 0,
      multipleImageUploaderToast: null,
      isItemSelectionDialogActive: false,
      selectedQuestion: null,
      tableData: [],
      isAnnotatorDisplayed: false,
      imageToBeAnnotated: null,
      isGalleryDisplayed: false,
      isCameraDisplayed: false,
      options: [
        {
          value: 'no',
          label: 'No',
          icon: 'close',
          type: 'is-danger is-light is-outlined',
        },
        {
          value: 'yes',
          label: 'Yes',
          icon: 'check',
          type: 'is-success is-light is-outlined',
        },
      ],
      imagesToUpload: [],
      debouncedUpdateQuestionFunctions: {},
      isTablet: false,
      annotatorCameraNext: false,
    };
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  async created() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },

  beforeDestroy() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  computed: {
    job() {
      return this.$store.getters[GET_CURRENT_JOB];
    },
    iconRequirePictureStyle() {
      return (instruction) => ({
        color:
          instruction.isPictureMandatory && instruction.image.length === 0
            ? 'red'
            : '',
      });
    },
    taskQuestions() {
      return this.$store.getters[GET_TASK_QUESTIONS];
    },
    user() {
      return this.$store.getters[GET_CURRENT_USER];
    },
    isTaskCompleted() {
      return this.task.workStatusCode
        ? this.task.workStatusCode.toString() === '2' && this.job.jobCompleted
        : false;
    },
  },
  mounted() {
    const md = new MobileDetect(navigator.userAgent);
    this.isTablet = md.tablet() !== null;
  },
  methods: {
    wsQuestionUpdated(data) {
      this.$store.commit(MUTATION_UPDATE_QUESTION, data);
    },
    wsQuestionDeleted(data) {
      this.$store.commit(REMOVE_QUESTION, data);
    },
    wsQuestionCreated(data) {
      this.$store.commit(ADD_QUESTION, data);
    },
    isYesNoType(instruction) {
      return instruction.type === 'yesno';
    },
    onCellLeave(instruction) {
      if (['tablequestion', 'importcsv'].includes(instruction.type)) {
        instruction.tableData =
          this.$refs[`tableQuestion${instruction._id}`][0].getTempTableData();
        this.debouncedUpdateQuestion(instruction);
      }
    },
    showGallery(question) {
      if (question.image && question.image.length > 0) {
        this.selectedQuestion = question;
        this.isGalleryDisplayed = true;
      }
    },
    hideGallery() {
      this.isGalleryDisplayed = false;
    },
    showAnnotator() {
      this.isAnnotatorDisplayed = true;
    },
    hideAnnotator() {
      this.isAnnotatorDisplayed = false;
      this.imageToBeAnnotated = null;
    },
    async onUploadImageWrapper(event, instruction, index) {
      if (this.annotatorCameraNext) {
        this.annotatorCameraNext = false;
      }
      await this.onUploadImage(event, instruction);

      this.$refs[`fileUploadDropdown${index}`][0].toggle();
    },
    async onUploadImage(_, instruction, fileNew) {
      const images = this.imagesToUpload;
      this.selectedQuestion = instruction;
      if (fileNew) {
        images.push(fileNew);
      }

      if (images.length === 0) {
        return;
      }

      if (images.length === 1 && images[0].type !== 'application/pdf') {
        const image = images[0];

        const fileReader = new FileReader();
        fileReader.onload = () => {
          const imageAnnotated = new Image();
          imageAnnotated.onload = () => {
            this.imageToBeAnnotated = imageAnnotated;
            this.showAnnotator();
          };
          imageAnnotated.src = fileReader.result;
        };
        fileReader.readAsDataURL(image);
      } else {
        this.multipleImageUploaderToast = this.$buefy.toast.open({
          duration: 50000,
          message: 'Preparing files to be uploaded',
          type: 'is-warning',
          position: 'is-bottom',
        });

        const files = images;
        this.uploadedFiles = 0;
        this.totalFilesToBeUploaded = files.length;
        this.multipleImageUploaderToast.message = `Uploading images ${this.uploadedFiles} / ${this.totalFilesToBeUploaded}`;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (file.type === 'application/pdf') {
            // eslint-disable-next-line no-await-in-loop
            const dataURL = await this.handlePDF(file);
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL
              .split(',')[0]
              .split(':')[1]
              .split(';')[0];
            const byteNumbers = new Array(byteString.length);

            for (let j = 0; j < byteString.length; j++) {
              byteNumbers[j] = byteString.charCodeAt(j);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeString });
            const fileJsonName = `${uuidv4()}_pdf.png`;
            const fileJson = new File([blob], fileJsonName);

            const site = this.user.location;
            const path = `uploads/sites/${site}/${new Date().getFullYear()}/${
              this.task._id
            }`;
            // eslint-disable-next-line no-await-in-loop
            const url = await this.uploadToS3(fileJson, path);
            if (!this.selectedQuestion.image) {
              this.selectedQuestion.image = [];
            }
            this.selectedQuestion.image.push({
              description: '',
              name: '',
              path: url,
            });
          } else {
            const site = this.user.location;
            const path = `uploads/sites/${site}/${new Date().getFullYear()}/${
              this.task._id
            }`;

            // eslint-disable-next-line no-await-in-loop
            const url = await this.uploadToS3(file, path);
            if (!this.selectedQuestion.image) {
              this.selectedQuestion.image = [];
            }
            this.selectedQuestion.image.push({
              description: '',
              name: '',
              path: url,
            });
          }

          this.uploadedFiles++;
          this.multipleImageUploaderToast.message = `Uploading images ${this.uploadedFiles} / ${this.totalFilesToBeUploaded}`;

          if (this.uploadedFiles === this.totalFilesToBeUploaded) {
            setTimeout(() => {
              if (this.multipleImageUploaderToast) {
                this.multipleImageUploaderToast.close();
                this.multipleImageUploaderToast = null;
              }
            }, 2000);
          }
        }

        this.debouncedUpdateQuestion(this.selectedQuestion);
      }
      this.imagesToUpload = [];
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    async newAnnotator({ image, file }) {
      await this.processAnnotator(image);
      if (this.annotatorCameraNext) {
        this.showCamera(this.selectedQuestion);
      } else {
        await this.onUploadImage(null, this.selectedQuestion, file);
      }
    },
    async processAnnotator(image) {
      this.hideAnnotator();

      this.$buefy.snackbar.open({
        duration: 3000,
        message: this.$t('picture_uploaded_successfully', {
          // image: this.imageOriginalName.toUpperCase(),
        }),
        type: 'is-success',
        position: 'is-bottom-left',
        queue: false,
      });
      this.imageOriginalName = '';

      const fileName = `${uuidv4()}.jpg`;
      const canvas = document.createElement('canvas');
      imageHelper.drawImageFor1920px(canvas, image);
      const file = this.dataURLtoFile(
        canvas.toDataURL('image/jpeg', 0.9),
        fileName
      );

      const site = this.user.location;
      const path = `uploads/sites/${site}/${new Date().getFullYear()}/${
        this.task._id
      }`;
      const uploadUrl = await this.uploadToS3(file, path);
      if (!this.selectedQuestion.image) {
        this.selectedQuestion.image = [];
      }
      this.selectedQuestion.image.push({
        description: '',
        name: '',
        path: uploadUrl,
      });

      this.debouncedUpdateQuestion(this.selectedQuestion);
    },
    async uploadToS3(file, path) {
      const dijConfigurations = new DIJVueConfigurations();
      const url = await dijConfigurations.uploadFileToS3(file, file.name, path);
      return url;
    },
    async onSaveTable(question) {
      if (['tablequestion', 'importcsv'].includes(question.type)) {
        question.tableData =
          this.$refs[`tableQuestion${question._id}`][0].getTempTableData();
      }

      this.updateQuestion(question);
    },

    // Very ugly workaround untill we move the logic to use pdf on backend
    async handlePDF(file) {
      const pdf = await getDocument(URL.createObjectURL(file)).promise;
      const page = await pdf.getPage(1);
      const scale = 1;
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: context,
        viewport,
      };
      await page.render(renderContext).promise;
      return canvas.toDataURL();
    },
    async forceSave() {
      const questions = [];
      this.taskQuestions.forEach((question) => {
        if (['tablequestion', 'importcsv'].includes(question.type)) {
          question.tableData =
            this.$refs[`tableQuestion${question._id}`][0].getTempTableData();
          questions.push(question);
        } else {
          questions.push(question);
        }
      });

      await this.$store.dispatch(CREATE_QUESTIONS, {
        taskId: this.task._id,
        questions,
      });
      await this.$store.dispatch(GET_QUESTIONS, this.task._id);
    },
    getTableImage(instruction) {
      // For local env we need to point to the local s3
      if (instruction.tableImg && window.location.host.includes('localhost')) {
        return `http://localhost:4566/images${instruction.tableImg}`;
      }

      return instruction.tableImg;
    },
    getImageReference(instruction) {
      // For local env we need to point to the local s3
      if (
        instruction.imageReference &&
        window.location.host.includes('localhost')
      ) {
        return `http://localhost:4566/images${instruction.imageReference}`;
      }
      return instruction.imageReference;
    },
    debouncedUpdateQuestion(question) {
      // If there's no call to update this question, then create a new debounced function
      if (!this.debouncedUpdateQuestionFunctions[question._id]) {
        this.debouncedUpdateQuestionFunctions[question._id] = debounce(
          this.updateQuestion,
          1500
        );
      }

      // Run the debounced function
      this.debouncedUpdateQuestionFunctions[question._id](question);
    },
    async updateQuestion(question) {
      await this.$store.dispatch(UPDATE_QUESTION, {
        ...question,
        isInspector: true,
      });
    },
    showCamera(instruction) {
      this.annotatorCameraNext = true;
      this.selectedQuestion = instruction;
      this.isCameraDisplayed = true;
    },
    hideCamera() {
      this.isCameraDisplayed = false;
    },
    processCamera(image) {
      this.hideCamera();
      this.imageToBeAnnotated = image;
      this.showAnnotator();
      this.filename = `${uuidv4()}.jpg`;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-new {
  margin-bottom: 20px;
  min-height: 75px;
  font-size: larger;
  font-weight: bold;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
}

button span {
  width: 100%;
}

.full-width {
  width: 100%;
}

.subtitle {
  display: flex;
  flex-direction: row;
}

.table-description {
  align-items: start;
  display: flex;
  width: 100%;
  font-size: larger;
}

.question-details {
  font-size: medium;
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.question-details-yes-no {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.right-side-title {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.req-picture {
  color: gray;
  font-style: italic;
  font-size: larger;
  white-space: nowrap; /* Keeps text on a single line */
}

.animate-slide {
  animation: slide-in-out 1s linear infinite;
}

@keyframes slide-in-out {
  0%,
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
  50% {
    transform: translateY(
      7.5%
    ); /* Adjust this value for greater or less movement */
    opacity: 0.2;
  }
}

.instruction-action {
  color: gray;
  font-size: medium;
}

.instruction-action:hover {
  cursor: pointer;
}

.items-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0 10px;
  height: 100%;
}

.instruction {
  font-size: larger;
  font-weight: bold;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 10px;
}

.instruction:hover {
  background: #f5f5f5a6;
}

.instruction-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  font-size: x-large;
}

.instruction-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.justify-start {
  justify-content: start;
}

.wrapper {
  padding: 20px 10px;
  background-color: #dddddd;
  text-align: center;
  height: calc(100vh - 100px);
}

.btn-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-right: 25px;
}

.btn-center-content-column {
  position: absolute;
  top: 10%;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 5px;
}

.option {
  margin-right: 10px; /* Adjust spacing between options as needed */
  font-size: medium;
  font-weight: 600;
  color: gray;
}

.description {
  font-size: medium;
  font-weight: 600;
  color: gray;
}

.image-container {
  margin-bottom: 10px;
  width: 100%;
}

.image-container-helper {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  img {
    max-height: 250px;
  }
}

.icon-container {
  position: relative;
  display: inline-block;
  margin-top: 2.5px;
}

.image-counter {
  position: absolute;
  top: -5px; /* Adjust based on your icon's size */
  left: -5px; /* Adjust based on your icon's size */
  background-color: #23d160;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 0.75rem;
  min-width: 20px; /* Ensure it's round for single digits */
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dij-diagrambuilder-camera-modal ::v-deep div.animation-content.modal-content {
  overflow: hidden;
}

// .upload.control.file-label {
//   max-width: 45px;
// }
</style>

<style lang="scss">
.dropdown-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 300px;

  span {
    display: flex;
    align-items: center;
    color: #808080;
  }
}
.dropdown-content {
  a {
    display: flex;
  }
}
.upload.control.file-label {
  align-items: center;
  font-size: 0.875rem;
  color: #808080;
}
</style>
