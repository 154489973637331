/**
 ** Functions to manipulate values from the state
 */
const mutations = {
  SET_CURRENT_USER: (state, user) => {
    state.currentUser = user;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_USER_ROLES: (state, roles) => {
    state.userRoles = roles;
  },
  SET_USERS: (state, users) => {
    // Filter OIL user
    const filteredUsers = users.filter(
      (user) => user._id !== process.env.VUE_APP_OIL_USER_EMAIL
    );
    state.users = filteredUsers;
  },
  SET_USERS_PENDING_APPROVAL: (state, users) => {
    state.usersPendingApproval = users;
  },
  SET_USERS_LOGGED_IN_TODAY: (state, users) => {
    state.usersLoggedInToday = users;
  },
  SET_USERS_REJECTED: (state, users) => {
    state.usersRejected = users;
  },
  CREATE_USER_ROLE: (state, role) => {
    state.sites.push(role);
  },
  UPDATE_USER_ROLE: (state, { index, role }) => {
    state.userRoles[index] = role;
  },
  DELETE_USER_ROLE: (state, index) => {
    state.userRoles.splice(index, 1);
  },
  SET_CURRENT_USER_ALLOWED_TOOLS: (state, tools) => {
    state.currentUserAllowedTools = tools;
  },
  SET_USER_WITHOUT_SITE: (state, bool) => {
    state.userWithoutSite = bool;
  },
  CREATE_USER: (state, createdUser) => {
    state.users.push(createdUser);
  },
  UPDATE_USER: (state, userToUpdate) => {
    if (state.currentUser._id === userToUpdate._id) {
      state.currentUser = { ...state.currentUser, ...userToUpdate };
    }
    const index = state.users.findIndex(
      (user) => user._id === userToUpdate._id
    );
    if (index >= 0) {
      state.users[index] = { ...state.users[index], ...userToUpdate };
    }
  },
  DELETE_USER: (state, { userToDelete }) => {
    const index = state.users.findIndex(
      (user) => user._id === userToDelete._id
    );
    if (index >= 0) {
      state.users.splice(index, 1);
    }
  },
  SET_USER_OWNED_TEMPLATES_COUNT: (state, userOwnedTemplatesCount) => {
    state.userOwnedTemplatesCount = userOwnedTemplatesCount;
  },

  SET_USER_OWNED_JOBS_COUNT: (state, userOwnedJobsCount) => {
    state.userOwnedJobsCount = userOwnedJobsCount;
  },

  SET_JOBS: (state, jobs) => {
    state.jobs = jobs;
  },
  PUSH_JOBS: (state, jobs) => {
    state.jobs.push(...jobs);
  },
  SET_SITES: (state, sites) => {
    const filteredSites = sites.filter((site) => site.siteName !== 'OIL');
    filteredSites.sort((a, b) => (a.siteName > b.siteName ? 1 : -1));
    state.sites = filteredSites;
  },
  CREATE_SITE: (state, site) => {
    state.sites.push(site);
  },
  UPDATE_SITE: (state, { index, site }) => {
    state.sites[index] = site;
  },
  DELETE_SITE: (state, index) => {
    state.sites.splice(index, 1);
  },
  SET_PRODUCT_LIST_NAME: (state, productListName) => {
    state.productListName = productListName;
  },
  SET_ORIGINAL_OEM_BRAND_LIST: (state, originalOEMBrandList) => {
    state.originalOEMBrandList = originalOEMBrandList;
  },
  SET_FRAME_TYPE_LIST: (state, frameTypeList) => {
    state.frameTypeList = frameTypeList;
  },
  SET_MODEL_LIST: (state, modelList) => {
    state.modelList = modelList;
  },
  SET_CURRENT_JOB: (state, newJob) => {
    state.currentJob = newJob;
  },
  SET_ERROR_NEW_JOB: (state, data) => {
    state.jobErrors.push({ status: 'creation', data });
  },

  SET_TASKS_CURRENT_JOB: (state, updatedTasks) => {
    state.currentTasks = updatedTasks;
    if (Object.keys(state.currentJob).length > 0)
      state.currentJob.childConfig[0].childConfig = updatedTasks;
  },

  SET_CURRENT_TASK: (state, task) => {
    state.currentTask = task;
  },

  SET_JOBS_LASTKEY: (state, lastKey) => {
    state.jobsLastKey = lastKey;
  },
  SET_JOBS_QUERY: (state, jobsQuery) => {
    state.jobsQuery = jobsQuery;
  },
  ADD_JOB: (state, job) => {
    state.jobs.unshift(job);
  },
  ADD_JOB_BEFORE: (state, { existingJob, newJob }) => {
    const index = state.jobs.findIndex((job) => job._id === existingJob._id);
    state.jobs.splice(index, 0, newJob);
  },
  REMOVE_JOB: (state, id) => {
    const index = state.jobs.findIndex((job) => job._id === id);
    if (index < 0) return true;
    state.jobs.splice(index, 1);
  },

  // Connection between OIL and DIJ
  SET_OIL_TOKEN: (state, token) => {
    state.oilToken = token;
  },

  SET_OIL_APPROVERS: (state, approvers) => {
    state.oilApprovers = approvers;
  },

  SET_TEMPLATE_IN_APPROVAL_LIST: (state, data) => {
    const objApproval = {
      approver: data.selectedApprover,
      templateId: data.templateId,
      status: data.status,
    };

    state.approvalList.push(objApproval);
  },
  UPDATE_APPROVAL_STATUS: (state, data) => {
    state.currentJob.approvalStatus = data.approvalStatus;
  },
  SET_APPROVAL_ERROR: (state, data) => {
    state.approvalError = data;
  },

  SET_TASK_OBJECT: (state, data) => {
    state.task = data;
  },

  SET_PART_LEVELS: (state, data) => {
    state.partLevels = data;
  },

  SET_TASKS: (state, data) => {
    state.tasks = data;
  },

  SET_JOB_TASKS: (state, data) => {
    state.jobTasks = data;
  },

  UPDATE_JOB_TASKS: (state, { task, index }) => {
    state.jobTasks[index] = task;
  },

  SET_VISUAL_CONDITIONS: (state, data) => {
    state.visualConditions = data;
  },

  UPDATE_JOB_IN_LIST: (state, updateJob) => {
    const index = state.jobs.findIndex((job) => job._id === updateJob._id);
    if (index >= 0) {
      state.jobs[index] = updateJob;
    }
  },

  REMOVE_JOB_FROM_THE_LIST: (state, updatedJob) => {
    const index = state.jobs.findIndex((job) => job._id === updatedJob._id);
    state.jobs.splice(index, 1);
  },

  SET_NEW_TASK: (state, newTask) => {
    state.tasks.push(newTask);
  },

  REMOVE_TASK: (state, id) => {
    const index = state.tasks.findIndex((task) => task._id === id);
    if (index < 0) return true;
    state.tasks.splice(index, 1);
  },

  SET_WAITING_FOR_READ: (state, waitingForRead) => {
    state.waitingForRead = waitingForRead;
  },

  SET_WAITING_FOR_WRITE: (state, waitingForWrite) => {
    state.waitingForWrite = waitingForWrite;
  },

  SET_DELETED_JOBS_QUERY: (state, deletedJobsQuery) => {
    state.deletedJobsQuery = deletedJobsQuery;
  },
  SET_DELETED_JOBS: (state, deletedJobs) => {
    state.deletedJobs = deletedJobs;
  },

  SET_DELETED_JOBS_LASTKEY: (state, deletedJobsLastKey) => {
    state.deletedJobsLastKey = deletedJobsLastKey;
  },

  ADD_DELETED_JOB: (state, job) => {
    state.deletedJobs.unshift(job);
  },
  REMOVE_DELETED_JOB: (state, id) => {
    const index = state.deletedJobs.findIndex((job) => job._id === id);
    if (index < 0) return true;
    state.deletedJobs.splice(index, 1);
  },

  SET_DELETED_TASKS: (state, deletedTasks) => {
    state.deletedTasks = deletedTasks;
  },

  SET_MTE_TYPES: (state, mteTypes) => {
    state.mteTypes = mteTypes;
  },

  SET_MTE_TYPE: (state, mteType) => {
    state.mteType = mteType;
  },

  ADD_MTE_TYPE: (state, mteType) => {
    state.mteTypes.push(mteType);
  },

  REPLACE_MTE_TYPE: (state, mteType) => {
    const index = state.mteTypes.findIndex(
      (element) => element._id === mteType._id
    );
    state.mteTypes[index] = mteType;
  },

  DELETE_MTE_TYPE: (state, id) => {
    const index = state.mteTypes.findIndex((element) => element._id === id);
    state.mteTypes.splice(index, 1);
  },

  ADD_LOCKED_TASK_ID: (state, lockedTaskId) => {
    state.lockedTaskIds.push(lockedTaskId);
  },

  REMOVE_LOCKED_TASK_ID: (state, lockedTaskId) => {
    state.lockedTaskIds = state.lockedTaskIds.filter(
      (taskId) => taskId !== lockedTaskId
    );
  },

  SET_INFO_MESSAGE: (state, message) => {
    state.infoMessage = message;
  },

  CLEAR_INFO_MESSAGE: (state) => {
    state.infoMessage = null;
  },

  SET_SUCCESS_MESSAGE: (state, message) => {
    state.successMessage = message;
  },

  CLEAR_SUCCESS_MESSAGE: (state) => {
    state.successMessage = null;
  },

  SET_WARNING_MESSAGE: (state, message) => {
    state.warningMessage = message;
  },

  CLEAR_WARNING_MESSAGE: (state) => {
    state.warningMessage = null;
  },

  SET_ERROR_MESSAGE: (state, message) => {
    state.errorMessage = message;
  },

  CLEAR_ERROR_MESSAGE: (state) => {
    state.errorMessage = null;
  },

  SET_ANNOUNCEMENT_BANNER: (state, { message, startDate, endDate }) => {
    const oldMessage = state.announcementBannerMessage;
    if (oldMessage !== message) {
      state.announcementBannerVisible = true;
    }
    state.announcementBannerMessage = message;
    if (startDate) {
      state.announcementBannerStartDate = new Date(startDate);
    }
    if (endDate) {
      state.announcementBannerEndDate = new Date(endDate);
    }

    // Local timeout is 3 minutes (try to fetch a new message every 3 minutes)
    state.announcementBannerDataTimeout = new Date().getTime() + 3 * 60 * 1000;
  },

  HIDE_ANNOUNCEMENT_BANNER: (state) => {
    state.announcementBannerVisible = false;
  },

  SET_SURROGATE: (state, user) => {
    state.surrogateUser = user;
  },

  CLEAR_SURROGATE: (state) => {
    state.surrogateUser = null;
  },
};

export default mutations;
